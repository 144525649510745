<template>
  <div class="login1">
    <div class="login1_box flex-wrap">
      <div class="flex-con" style="position: relative">
        <router-link to="/">
          <img
            :src="require('@/assets/logo_1.png')"
            fit="contain"
            class="avatar"
            alt=""
          />
        </router-link>
        <div class="jie">
          <img
            :src="require('@/assets/login_title.png')"
            fit="contain"
            class="jie1"
            alt=""
          />
          <div class="jie2">
            厂家直销 <span style="font-size: 16px">/</span> 品类齐全
            <span style="font-size: 16px">/</span> 价格优惠
          </div>
        </div>
        <div class="phone">
          官方服务热线：<span>{{ phoneHeader }}</span>
        </div>
      </div>
      <div class="login_right">
        <div class="box2">
          <div class="login_register">注册/登录</div>
          <div class="xian2"></div>

          <div class="input_item flex-wrap">
            <div class="align-start">
              <div class="input_left align-center">
                <!-- <el-image
                  style="width: 15px; height: 22px"
                  :src="require('@/assets/login_phone1.png')"
                  fit="cover"
                ></el-image> -->
                <img
                  :style="
                    loginType
                      ? 'width: 15px; height: 22px'
                      : 'width: 17px; height: 20px'
                  "
                  :src="
                    loginType
                      ? require('@/assets/login_phone1.png')
                      : require('@/assets/login_zh.png')
                  "
                  fit="cover"
                  alt=""
                />
              </div>
              <input
                type="tel"
                name=""
                value=""
                v-model="phone"
                :placeholder="loginType ? '请输入您的手机号' : '请输入您的账号'"
              />
            </div>
          </div>

          <div class="input_item flex-wrap" v-if="loginType">
            <div class="flex-con align-start">
              <div class="input_left align-center">
                <!-- <el-image
                  style="width: 17px; height: 21px"
                  :src="require('@/assets/login_yzm.png')"
                  fit="cover"
                ></el-image> -->
                <img
                  style="width: 17px; height: 21px"
                  :src="require('@/assets/login_yzm.png')"
                  fit="cover"
                  alt=""
                />
              </div>
              <input
                type="tel"
                name=""
                value=""
                v-model="code"
                placeholder="请输入验证码"
                @keyup.enter="fnLogin"
              />
            </div>
            <div class="">
              <div class="yzmmm align-center pointer" @click="need_ma">
                {{ codeTxt }}
              </div>
            </div>
          </div>

          <div class="input_item flex-wrap" v-else>
            <div class="align-start">
              <div class="input_left align-center">
                <!-- <el-image
                  style="width: 18px; height: 21px"
                  :src="require('@/assets/login_pwd1.png')"
                  fit="cover"
                ></el-image> -->
                <img
                  style="width: 18px; height: 21px"
                  :src="require('@/assets/login_pwd1.png')"
                  fit="cover"
                  alt=""
                />
              </div>
              <input
                type="password"
                name=""
                value=""
                v-model="password"
                placeholder="请输入您的密码"
                @keyup.enter="fnLogin"
              />
            </div>
          </div>
          <!-- <div class="input_item flex-wrap" v-else>
              <div class="input_left">
                <el-image
                  style="width: 26px; height: 26px; margin: 12px"
                  :src="require('@/assets/login_pwd.png')"
                  fit="cover"
                ></el-image>
              </div>
              <input
                type="password"
                name=""
                value=""
                v-model="password"
                placeholder="请输入您的密码"
              />
            </div> -->
          <div class="align-start font-333-12 xyTxt">
            <input type="checkbox" name="" value="" v-model="checked" />
            我已阅读并同意<span
              class="pointer DEA422 cor_f08300"
              @click="fnXy(3)"
              >《本牛网站服务协议》</span
            ><span class="pointer DEA422 cor_f08300" @click="fnXy(4)"
              >《隐私政策》</span
            >
          </div>
          <div
            class="btn align-center pointer"
            :class="{ bg2: isOver }"
            @click="fnLogin"
          >
            登录
          </div>
          <div class="align-between">
            <div
              class="forget_pwd pointer"
              @click="fnTab(false)"
              v-show="loginType"
            >
              密码登录
            </div>
            <div
              class="forget_pwd pointer"
              @click="fnTab(true)"
              v-show="!loginType"
            >
              验证码登录
            </div>
            <div
              class="forget_pwd pointer"
              v-show="!loginType"
              @click="fnForget"
            >
              忘记密码？
            </div>
          </div>

          <div class="flex-wrap" style="padding: 0; text-align: center">
            <div class="flex-con">
              <div style="width: 98px; height: 98px; margin: 30px auto 16px">
                <el-image
                  style="width: 98px; height: 98px; border-radius: 4px"
                  :src="data.content2"
                  fit="cover"
                ></el-image>
              </div>
              <div class="font-fff-14">扫一扫关注公众号</div>
            </div>
            <div class="flex-con">
              <div style="width: 98px; height: 98px; margin: 30px auto 16px">
                <el-image
                  style="width: 98px; height: 98px; border-radius: 4px"
                  :src="data.content1"
                  fit="cover"
                ></el-image>
              </div>
              <div class="font-fff-14">小程序</div>
            </div>
          </div>
          <a
            class="beian pointer"
            href="http://beian.miit.gov.cn"
            target="_blank"
            >苏ICP备2023015553号</a
          >
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="1200px">
      <div class="dialog">
        <div
          style="
            background: #ffffff;
            border-radius: 4px;
            height: 635px;
            padding: 0 20px;
            overflow-y: scroll;
          "
          class="scrollbar"
        >
          <div
            style="
              font-size: 26px;
              text-align: center;
              font-weight: 500;
              color: #333333;
              line-height: 57px;
              margin-bottom:20px;
            "
          >
            {{ type == 3 ? '《本牛网站服务协议》' : '《隐私政策》' }}
          </div>
          <div
            class="font-fff-333"
            style="line-height: 20px"
            v-html="type == 3 ? xyServe : xyYs"
          ></div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible111" width="435px">
      <div class="dialog">
        <div
          style="
            background: #ffffff;
            border-radius: 4px;
            height: 335px;
            padding:0 20px;
          "
          class="scrollbar"
        >
          <div
            style="
              font-size: 28px;
              font-weight: 500;
              color: #333333;
              line-height: 40px;
              margin: 5px auto 50px;
              text-align: center;
            "
          >
            忘记密码
          </div>
          <div v-if="step">
            <div class="flex-wrap">
              <div class="font-333 align-start" style="min-width: 75px">
                <span class="C52B33">*</span>手机号:
              </div>
              <div class="input_item111">
                <input
                  type="tel"
                  name=""
                  value=""
                  v-model="phone111"
                  placeholder="请输入您的手机号"
                  class="input111"
                />
              </div>
            </div>
            <div class="flex-wrap" style="margin: 30px 0">
              <div class="font-333 align-start" style="min-width: 75px">
                <span class="C52B33">*</span>验证码:
              </div>
              <div class="input_item111 align-between">
                <input
                  type="tel"
                  name=""
                  value=""
                  v-model="code111"
                  placeholder="请输入验证码"
                  class="input111"
                />
                <div
                  class="C52B33 align-center pointer cor_f08300"
                  style="height: 100%; padding-right: 10px"
                  @click="need_ma111"
                >
                  {{ codeTxt111 }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex-wrap" style="margin-bottom: 30px">
              <div class="font-333 align-start" style="min-width: 75px">
                <span class="C52B33">*</span>新密码:
              </div>
              <div class="input_item111">
                <input
                  type="password"
                  name=""
                  value=""
                  v-model="first_password"
                  placeholder="请输入您的新密码"
                  class="input111"
                />
              </div>
            </div>
            <div class="flex-wrap">
              <div class="font-333 align-start" style="min-width: 75px">
                <span class="C52B33">*</span>确认密码:
              </div>
              <div class="input_item111">
                <input
                  type="password"
                  name=""
                  value=""
                  v-model="second_password"
                  placeholder="请再次输入新密码"
                  class="input111"
                />
              </div>
            </div>
          </div>

          <div class="btn111 align-center pointer bg_f08300" @click="fnNext">
            {{ next111 }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { passwordLogin, codeLogin, sendCode, loginAgreement,forgetPasswordFirstStep,forgetPasswordSecondStep } from '../api'
export default {
  data() {
    return {
      loginType: true, //true是验证码登录
      phone: '',
      code: '',
      password: '',
      isOnclick: true,
      onClickTime: '',
      isinerval: '',
      onClickTime1: '',
      codeTxt: '获取验证码',
      phoneHeader: '',
      checked: false,
      dialogVisible: false,
      xyServe: '',
      xyYs: '',
      type: '',
      data: {
        content1: '',
        content2: '',
      },
      isOver: false,



      dialogVisible111:false,
      step: true,
      phone111: '',
      code111: '',
      first_password: '',
      second_password: '',
      isOnclick111: true,
      onClickTime111: '',
      isinerval111: '',
      onClickTime222: '',
      codeTxt111: '获取验证码',
      next111: '下一步',
    }
  },
  watch: {
    code(newValue, oldValue) {
      if (this.loginType) {
        if (newValue.length >= 6 && this.phone.length >= 11) {
          this.isOver = true
        } else {
          this.isOver = false
        }
      }
    },
    password(newValue, oldValue) {
      if (!this.loginType) {
        if (newValue.length >= 6 && this.phone.length >= 11) {
          this.isOver = true
        } else {
          this.isOver = false
        }
      }
    },
    phone(newValue, oldValue) {
      if (this.loginType) {
        if (newValue.length >= 11 && this.code.length >= 6) {
          this.isOver = true
        } else {
          this.isOver = false
        }
      } else {
        if (newValue.length >= 11 && this.password.length >= 6) {
          this.isOver = true
        } else {
          this.isOver = false
        }
      }
    },
  },
  methods: {

    fnNext() {
      if (this.step) {
        this.fnFirst()
      } else {
        this.fnSecond()
      }
    },
    fnFirst() {
      if (!this.phone111) {
        this.$message.error('请输入您的手机号')
        return
      }
      if (!this.code111) {
        this.$message.error('请输入验证码')
        return
      }
      let params = {
        phone: this.phone111,
        code: this.code111,
      }
      forgetPasswordFirstStep(params).then((data) => {
        if (data.status == 200) {
          this.step = false
          this.next111 = '确定'
          clearInterval(this.isinerval111)
          // localStorage.setItem('allUser', data.msg)
          // this.$router.push({ path: 'Login' })
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    fnSecond() {
      if (!this.first_password) {
        this.$message.error('请输入您的新密码')
        return
      }
      if (!this.second_password) {
        this.$message.error('请再次输入新密码')
        return
      }
      if (this.first_password != this.second_password) {
        this.$message.error('两次密码输入不一致')
        return
      }
      let params = {
        phone: this.phone111,
        first_password: this.first_password,
        second_password: this.second_password,
      }
      forgetPasswordSecondStep(params).then((data) => {
        if (data.status == 200) {
          this.$message.success('设置成功')
          this.dialogVisible111=false
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    ///验证手机号
    need_ma111() {
      let regBox = {
        regMobile: /^1[3456789]\d{9}$/, //手机
      }
      let rPhone = regBox.regMobile.test(this.phone111)
      if (!(rPhone && this.phone111)) {
        this.$message.error('请输入正确的手机号')
        return
      }
      if (this.isOnclick111) {
        //调用接口判断
        this.sendSMS111()
      }
    },
    //获取验证码

    sendSMS111() {
      let params = {
        phone: this.phone111,
      }
      sendCode(params).then((data) => {
        if (data.status == 200) {
          if (this.isOnclick111) {
            this.onClickTime111 = Math.floor(new Date().getTime() / 1000)
            this.isinerval111 = setInterval(this.CountDown111, 1000)
            this.isOnclick111 = false
          }
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    //验证码60秒倒数
    CountDown111() {
      this.onClickTime222 =
        60 - (Math.floor(new Date().getTime() / 1000) - this.onClickTime111)
      if (this.onClickTime222 < 1) {
        this.codeTxt111 = '重新获取'
        clearInterval(this.isinerval111)
        this.isOnclick111 = true
        return
      }
      this.codeTxt111 = this.onClickTime222 + 's'
    },

    ///////////





    fnTab(boole) {
      this.isOver = false
      clearInterval(this.isinerval)
      this.codeTxt = '获取验证码'
      this.code = ''
      this.phone = ''
      this.loginType = boole
    },
    fnRegister() {
      this.$router.push({
        name: 'Register',
        params: {},
      })
    },
    fnForget() {
      this.dialogVisible111=true
      clearInterval(this.isinerval111)
      this.step=true
      this.phone111= ''
      this.code111= ''
      this.first_password= ''
      this.second_password= ''
      this.isOnclick111=true
      this.onClickTime111=''
      this.isinerval111= ''
      this.onClickTime222=''
      this.codeTxt111= '获取验证码'
      this.next111= '下一步'
    },
    fnLogin() {
      if (this.isOver) {
        this.loginType ? this.fnLoginCode() : this.fnLoginPwd()
      }
    },
    fnLoginCode() {
      if (!this.phone) {
        this.$message.error('请输入您的手机号')
        return
      }
      if (!this.code) {
        this.$message.error('请输入验证码')
        return
      }
      if (!this.checked) {
        this.$message.error('请勾选服务协议与隐私政策')
        return
      }

      let params = {
        phone: this.phone,
        code: this.code,
        type: 1,
        from_source: localStorage.getItem('from_source') || '',
      }
      codeLogin(params).then((data) => {
        if (data.status == 200) {
          data.msg.expire = 172800000
          data.msg.time111 = Date.now()
          localStorage.setItem('allUser', JSON.stringify(data.msg))
          clearInterval(this.isinerval)
          this.$router.push({ path: '/' })
        }
        // console.log("|||||" + localStorage.getItem("allUser"));
      })
    },
    fnLoginPwd() {
      if (!this.phone) {
        this.$message.error('请输入您的手机号')
        return
      }
      if (!this.password) {
        this.$message.error('请输入您的密码')
        return
      }
      if (!this.checked) {
        this.$message.error('请勾选服务协议与隐私政策')
        return
      }
      let params = {
        phone: this.phone,
        password: this.password,
      }
      passwordLogin(params).then((data) => {
        if (data.status == 200) {
          data.msg.expire = 172800000
          data.msg.time111 = Date.now()
          localStorage.setItem('allUser', JSON.stringify(data.msg))
          clearInterval(this.isinerval)
          this.$router.push({ path: '/' })
        }
        // console.log("|||||" + localStorage.getItem("allUser"));
      })
    },
    ///验证手机号
    need_ma() {
      if (!this.checked) {
        this.$message.error('请勾选服务协议与隐私政策')
        return
      }
      let regBox = {
        regMobile: /^1[3456789]\d{9}$/, //手机
      }
      let rPhone = regBox.regMobile.test(this.phone)
      if (!(rPhone && this.phone)) {
        this.$message.error('请输入正确的手机号')
        return
      }
      if (this.isOnclick) {
        //调用接口判断
        this.sendSMS()
      }
    },
    //获取验证码

    sendSMS() {
      let params = {
        phone: this.phone,
      }
      sendCode(params).then((data) => {
        if (data.status == 200) {
          if (this.isOnclick) {
            this.onClickTime = Math.floor(new Date().getTime() / 1000)
            this.isinerval = setInterval(this.CountDown, 1000)
            this.isOnclick = false
          }
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    fnXy(type) {
      this.dialogVisible = true
      this.type = type
      this.fnAgreement(this.type)
    },
    fnAgreement(type) {
      let params = {
        type: type,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          // 3 本牛网站服务协议   4 本牛隐私协议
          if (type == 10) {
            this.phoneHeader = data.msg.content1 || ''
            localStorage.setItem('phoneHeader', data.msg.content1)
          }
          if (type == 3) {
            this.xyServe = data.msg.content1 || ''
          }
          if (type == 4) {
            this.xyYs = data.msg.content1 || ''
          }
          if (type == 1) {
            this.data = data.msg
          }
        }
      })
    },
    //验证码60秒倒数
    CountDown() {
      this.onClickTime1 =
        60 - (Math.floor(new Date().getTime() / 1000) - this.onClickTime)
      if (this.onClickTime1 < 1) {
        this.codeTxt = '重新获取'
        clearInterval(this.isinerval)
        this.isOnclick = true
        return
      }
      this.codeTxt = this.onClickTime1 + 's'
    },
  },
  mounted() {
    document.title = '本牛'
    this.fnAgreement(10)
    this.fnAgreement(1)
  },
}
</script>

<style scoped>
input[type='text'].input111,
input[type='password'].input111,
input[type='tel'].input111 {
  background: rgba(0, 0, 0, 0);
  border: none;
  box-sizing: border-box;
  height: 50px;
  /* width: 300px; */
  padding: 0 12px;
  color: #333333;
  font-size: 14px;
  outline: none;
}
input[type='password'].input111::-webkit-input-placeholder,
input[type='text'].input111::-webkit-input-placeholder,
input[type='tel'].input111::-webkit-input-placeholder {
  color: #aaa;
}
input[type='password'].input111:active,
input[type='text'].input111:active,
input[type='tel'].input111:active {
  box-sizing: border-box;
  border: none;
  border-right: none;
}

.input_item111 {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  width: 300px;
  margin-top: 0px;
}

.btn111 {
  height: 40px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 4px;
  margin: 50px 40px 16px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}


.beian {
  display: block;
  font-size: 18px;
  /* font-family: Source Han Sans CN; */
  font-weight: 400;
  color: #949ec3;
  text-align: center;
  /* margin-top: 55px; */
  margin-top: 38px;
  text-decoration-line: none;
}
.forget_pwd {
  font-size: 14px;
  /* font-family: Source Han Sans CN; */
  font-weight: 400;
  color: #cbd4ec;
}
.btn {
  height: 53px;
  /* background: linear-gradient(90deg, #ff6d01 0%, #ff8b1f 100%); */
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  background: #f2a466;
}

.bg1 {
  background: linear-gradient(90deg, #ff6d01 0%, #ff8b1f 100%);
}
.bg2 {
  background: linear-gradient(90deg, #ff6d01 0%, #ff8b1f 100%) !important;
}
.xyTxt {
  font-size: 14px;
  /* font-family: Source Han Sans CN; */
  font-weight: 400;
  color: #cbd4ec;
  margin: 25px 0 30px;
}
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border: 1px solid #dfe3ed;
  border-radius: 3px;
  background: none;
  /* background-size: 100% 100%; */
}
input[type='checkbox']:checked {
  background: url('@/assets/dui.png') no-repeat center center;
  background-size: 14px 12px;
}
.yzmmm {
  background-color: #f2a466;
  width: 98px;
  height: 38px;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
}

input[type='text'],
input[type='password'],
input[type='tel'] {
  background: rgba(0, 0, 0, 0);
  border: none;
  box-sizing: border-box;
  height: 50px;
  /* width: 125px; */
  padding: 0;
  color: #fff;
  font-size: 18px;
  outline: none;
}
input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder {
  color: #cbd4ec;
}
input[type='text']:active,
input[type='password']:active,
input[type='tel']:active {
  box-sizing: border-box;
  border: none;
  border-right: none;
}
.input_left {
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
}
.input_item {
  height: 60px;
  border: none;
  border-bottom: 2px solid #7b88ab;
  padding-top: 10px;
  border-radius: 0;
  box-sizing: border-box;
  margin-top: 0;
}

.login_register {
  height: 26px;
  font-size: 24px;
  /* font-family: Source Han Sans CN; */
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}
.box2 {
  width: 394px;
  margin: 85px auto 0;
  /* margin: 150px auto 0; */
  /* scale: 1.25; */
}
.xian2 {
  width: 394px;
  height: 2px;
  background: #dfe3ed;
  margin: 36px 0 20px;
}
.phone {
  font-size: 20px;
  position: absolute;
  bottom: 34px;
  left: 56px;
  color: #fff;
}
.phone span {
  font-weight: bold;
}
.login1 {
  width: 100%;
  height: 100vh;
  /* background: url('@/assets/static/login_bg7.png') no-repeat center center;
  background-size: 100% 100%;
  filter: blur(5px); */
}
.login1::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('@/assets/static/login_bg8.png');
  background-size: 100% 100%;
  /* filter: blur(5px); */
}
.login1_box {
  width: 1300px;
  height: 700px;
  background: url('@/assets/static/login_bg9.png') no-repeat center center;
  background-size: 1300px 700px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* scale: 0.7; */
}
.login_right {
  width: 565px;
  height: 100%;
  background: url('@/assets/static/login_meng.png') no-repeat center center;
  background-size: 565px 100%;
}
.avatar {
  /* width: 139px;
  height: 114px; */
  /* margin: 23px 0 0 56px; */
  /* width: 208px; */
  height: 94px;
  margin: 65px 0 0 65px;
}
.jie {
  width: 553px;
  margin: 94px 0 0 100px;
}

/* .jie {
  width: 553px;
  margin: 98px 0 0 153px;
} */
.jie1 {
  width: 553px;
  height: 66px;
  display: block;
  margin-bottom: 18px;
}
.jie2 {
  height: 24px;
  font-size: 24px;
  /* font-family: Source Han Sans CN; */
  font-weight: 400;
  color: #fffefe;
  text-align: center;
  letter-spacing: 4px;
}
</style>

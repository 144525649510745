<template>
  <div>
    <Header />
    <div class="middle1" style="margin-top: 14px">
      <div class="box">
        <div class="font-333-20-bold cheng_head11 align-between">
          <div class="align-start">
            <div class="align-start pointer" @click="fnBack">
              <img src="../assets/back.png" class="topppImg" />
              <!-- <div class="back font-666-16">返回</div> -->
            </div>
            <div>收货地址</div>
          </div>

          <div class="btn111 align-center pointer" @click="fnOpen(1)">
            选择地址
          </div>
        </div>
        <!-- <div class="align-between" style="margin-bottom: 10px">
          <div class="font-333-16">收货地址</div>
        </div> -->
        <div
          class="align-start font-333-18"
          style="padding: 22px 22px 32px"
          v-if="obj1.address_id"
        >
          <div class="user_info align-center" style="white-space: nowrap;">
            {{ obj1.address_name || '' }} {{ obj1.address_phone || '' }}
          </div>
          <div class="address align-start">
            {{
              (obj1.sheng || '') +
              (obj1.shi || '') +
              (obj1.qu || '') +
              (obj1.address_detail || '')
            }}
          </div>
        </div>
        <div v-else class="font-aaa" style="text-align: center;margin:20px 0;">
          暂无收货地址，请点击选择地址去添加
        </div>
      </div>
      <div class="box">
        <!-- <div class="font-333-16" style="margin--bottom: 10px">发票信息</div> -->
        <!-- <div class="align-start">
        <el-popover placement="bottom-start" v-model="visible1" trigger="click">
          <p class="DCA01E-18" @click="fnAdd(1)">添加发票信息</p>
          如果有填写过显示下面信息
          <div>
            <div class="align-start">
              发票抬头：1234567
              <div class="blue" @click="fnAdd(3)">编辑</div>
            </div>
            <div>纳税人识别号：12345678</div>
          </div>
          <div style="text-align: right; margin: 0"></div>
          <div class="tab1 active align-center font-333-12" slot="reference">
            增值税发票
          </div>
        </el-popover>
        <el-popover placement="bottom-start" v-model="visible">
          <p class="DCA01E-18" @click="fnAdd(2)">添加发票信息</p>
          <div style="text-align: right; margin: 0"></div>
          <div class="tab1 align-center font-333-12" slot="reference">
            普通发票
          </div>
        </el-popover>
      </div> -->
        <div class="font-333-20-bold cheng_head11 align-between">
          <div>发票信息 <span class="font-999-16">(请选择发票类型)</span></div>
          <div
            class="btn111 align-center pointer"
            @click="fnOpen(2)"
            v-show="fapiao_type == 2 || fapiao_type == 1"
          >
            选择发票
          </div>
        </div>
        <div class="align-start font-333-18 m_b22 m_t22">
          <div
            class="tab1 align-center pointer"
            :class="{ active: fapiao_type == 2 }"
            @click="fnSelectFpType(2)"
          >
            增值税发票
          </div>
          <div
            class="tab1 align-center font-333-18 pointer"
            :class="{ active: fapiao_type == 1 }"
            @click="fnSelectFpType(1)"
          >
            普通发票
          </div>
          <div
            class="tab1 align-center font-333-18 pointer"
            :class="{ active: fapiao_type == 3 }"
            @click="fnSelectFpType(3)"
          >
            不开发票
          </div>
        </div>
        <div v-show="fapiao_type == 2 || fapiao_type == 1">
          <div
            class="align-start font-333-18"
            style="padding: 0 22px 22px"
            v-if="obj2.fp_header"
          >
            <div class="user_info align-center" style="min-width: 213px;" v-show="fapiao_type == 1">
              {{ obj2.fp_user_type == 1 ? '个人' : '企业' }}
            </div>
            <div class="address align-start">{{ obj2.fp_header || '' }}</div>
          </div>
          <div
            v-else
            class="font-aaa"
            style="text-align: center; margin-bottom: 22px"
          >
            暂无发票抬头，请点击选择发票去添加
          </div>

          <!-- <div class="align-between" style="margin: 16px 0 8px">
            <div class="font-333-16">收票地址</div>
            <div class="btn align-center pointer cor_f08300 border_f08300" @click="fnOpen(3)">
              选择地址
            </div>
          </div> -->
          <!-- <div class="align-center">
          <div class="btn align-center" style="margin-right: 16px">
            切换地址
          </div>
        </div> -->
          <!-- <div class="align-start font-333" v-if="obj3.address_id">
            <div class="user_info align-center border_f08300">
              {{ obj3.address_name || '' }} {{ obj3.address_phone || '' }}
            </div>
            <div class="address align-start">
              {{
                (obj3.sheng || '') +
                (obj3.shi || '') +
                (obj3.qu || '') +
                (obj3.address_detail || '')
              }}
            </div>
          </div>
          <div v-else class="font-aaa" style="text-align: center">
            暂无收票地址，请点击选择地址去添加
          </div> -->
        </div>
      </div>
      <div class="box">
        <div class="font-333-20-bold cheng_head11 align-between">
          <div>支付方式</div>
        </div>
        <div class="flex-wrap font-333-18" style="padding: 22px 22px 40px">
          <div class="align-center">
            <input type="radio" name="pay" value="2" v-model="pay_type" />
            <img src="../assets/main1_42.png" class="pay" />
            <div class="pay1">支付宝</div>
          </div>
          <div class="align-center">
            <input type="radio" name="pay" value="3" v-model="pay_type" />
            <img src="../assets/main1_43.png" class="pay" />
            <div class="pay1">微信</div>
          </div>
          <!-- <div class="align-center">
            <input type="radio" name="pay" value="1" v-model="pay_type" />
            <img src="../assets/main1_44.png" class="pay" />
            <div class="pay1">银联支付</div>
          </div> -->
          <div class="align-center">
            <input type="radio" name="pay" value="4" v-model="pay_type" />
            <img src="../assets/wallet.png" class="pay" />
            <div class="pay1">对公转账</div>
          </div>
        </div>
        <!-- <div class="align-start">
        <div class="tab1 active align-center font-333-12">在线支付</div>
        <div class="tab1 align-center font-333-12">公司转账</div>
      </div>
      <div class="font-777-12" style="margin-top: 10px">
        支持 支付宝 微信 银联在线支付
        注:企业主体采购时请使用公对公转账
      </div> -->
      </div>
      <div class="box">
        <div class="font-333-20-bold cheng_head11 align-between">
          <div>产品清单</div>
        </div>
        <div
          class="flex-wrap font-333-18-bold"
          style="
            margin-top: 20px;
            text-align: left;
            border-bottom: 1px solid #eee;
            padding: 0 26px 16px;
          "
        >
          <div style="width: 140px; margin-right: 16px">产品信息</div>
          <div style="width: 280px">产品名称</div>
          <div style="width: 160px; margin-left: 22px">产品规格</div>
          <div style="width: 180px; text-align: center">单价</div>
          <div class="flex-con"></div>
          <div style="width: 180px; text-align: center">数量</div>
          <div class="flex-con"></div>
          <div style="width: 80px; text-align: center">小计</div>
        </div>
        <div class="order_box">
          <div
            class="order_item pointer"
            v-for="(item, index) in data"
            :key="'sureOrder_' + index"
            @click="fnGoodsDetails(item.goods_id)"
          >
            <div class="flex-wrap font-333">
              <el-image
                style="width: 140px; height: 140px; margin-right: 16px"
                :src="item.goods_thumb"
                fit="cover"
              ></el-image>
              <div style="width: 280px" class="font-333-16">
                {{ item.goods_name || '' }}
              </div>
              <div style="min-width: 160px; margin-left: 22px">
                <div class="flex-wrap">
                  <div class="font-333-16">
                    <div
                      v-for="(item, index) in item.guige_name"
                      :key="'guige_name_' + index"
                    >
                      {{ item }}：
                    </div>
                  </div>
                  <div class="flex-con font-666-16" style="line-height: 24px;">
                    <div
                      v-for="(item, index) in item.guige_value"
                      :key="'guige_value_' + index"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 180px" class="font-666-16 align-center">
                {{ item.price > 0 ? item.price : '面议' }}
              </div>
              <div class="flex-con"></div>
              <div style="width: 180px" class="font-333-16 align-center">
                {{ item.num }}
              </div>
              <div class="flex-con"></div>
              <div style="width: 80px" class="font-333-16-bold align-center">
                {{
                  item.price > 0
                    ? ((item.price * item.num * 100) / 100).toFixed(2)
                    : '面议'
                }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <!-- <div class="font-333-16">采购信息</div> -->
        <div class="font-333-20-bold cheng_head11 align-between">
          <div>采购信息</div>
        </div>
        <!-- <div class="align-start">
        <div class="tab1 active align-center font-333-12">任意时间</div>
        <div class="tab1 align-center font-333-12">工作日送达</div>
        <div class="tab1 align-center font-333-12">非工作日送达</div>
      </div> -->
        <!-- <div class="align-start" style="margin-top: 16px">
        <div class="font-333 order_num">订单号：</div>
        <input
          type="text"
          name=""
          value=""
          placeholder="请填写贵公司便准采购订单编号"
        />
      </div> -->
        <div class="align-start" style="padding: 22px">
          <div class="font-333-18 order_num">备注：</div>
          <input
            type="text"
            name=""
            value=""
            placeholder="选填"
            v-model="remark"
          />
        </div>
      </div>
      <div class="box">
        <div class="font-333-20-bold cheng_head11 align-between">
          <div>总计</div>
        </div>
        <div class="flex-wrap">
          <div
            class="flex-con font-333-16"
            style="padding: 16px 0 0 0; text-align: right"
          >
            <div style="margin-bottom: 16px">已选：</div>
            <div style="margin-bottom: 16px">总计：</div>
            <div style="margin-bottom: 16px">运费：</div>
          </div>
          <div
            style="
              min-width: 118px;
              padding: 16px 20px 0 0;
              text-align: right;
              box-sizing: border-box;
              line-height: 24px;
            "
            class="font-333-16-bold"
          >
            <div style="margin-bottom: 16px">
              {{ price_data.allnums || 0 }}件
            </div>
            <div style="margin-bottom: 16px">
              {{ price_data.allprice > 0 ? ('¥' + (Number(price_data.allprice)-Number(price_data.postman)).toFixed(2)) : '面议' }}
            </div>
            <div style="margin-bottom: 16px">{{price_data.postman>0?('¥'+price_data.postman):'包邮'}}</div>
          </div>
        </div>
      </div>

      <div
        class="align-end order_header font-333-16"
        style="border: none; padding-right: 0; padding-top: 38px"
      >
        <div class="">
          应付含税总额：
          <span class="price2">{{
            price_data.allprice > 0 ? '¥' + price_data.allprice : '面议'
          }}</span>
        </div>

        <div
          class="bg_f08300 align-center pointer"
          @click="fnOrder"
          style="position: relative"
        >
          提交订单

          <div class="tips11 align-center">
            <img :src="require('@/assets/deng.png')" alt="" class="deng" />
            请仔细核对您的商品
          </div>
        </div>
      </div>

      <!-- <el-dialog
      :visible.sync="dialogVisible"
      width="840px"
      :before-close="handleClose"
    >
      <div class="dialog">
        <div class="align-start font-333" style="margin-bottom: 14px">
          <div class="width115"><span class="C52B33">*</span>发票抬头:</div>
          <input type="text" placeholder="请填写发票抬头" />
        </div>
        <div class="align-start font-333" style="margin-bottom: 14px">
          <div class="width115"><span class="C52B33">*</span>纳税人识别号:</div>
          <input type="text" name="" value="" placeholder="请填写" />
        </div>
        <div v-show="type != 2">
          <div class="align-start font-333" style="margin-bottom: 14px">
            <div class="width115"><span class="C52B33">*</span>注册地址:</div>
            <input type="text" placeholder="请填写注册地址" />
          </div>
          <div class="align-start font-333" style="margin-bottom: 14px">
            <div class="width115"><span class="C52B33">*</span>注册电话:</div>
            <input type="text" name="" value="" placeholder="请填写注册电话" />
          </div>
          <div class="align-start font-333" style="margin-bottom: 14px">
            <div class="width115"><span class="C52B33">*</span>开户银行:</div>
            <input type="text" placeholder="请填写开户银行" />
          </div>
          <div class="align-start font-333" style="margin-bottom: 14px">
            <div class="width115"><span class="C52B33">*</span>银行账号:</div>
            <input type="text" name="" value="" placeholder="请填写银行账号" />
          </div>
          <div class="align-start font-333" style="margin-bottom: 14px">
            <div class="width115"><span class="C52B33">*</span>电子邮箱:</div>
            <input type="text" name="" value="" placeholder="请填写电子邮箱" />
          </div>
        </div>

        <div class="align-end" style="margin-top: 40px">
          <div class="btn2 btn1 align-center" @click="dialogVisible = false">
            取消
          </div>
          <div class="btn2 align-center" @click="dialogVisible = false">
            保存
          </div>
        </div>
      </div>
    </el-dialog> -->
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { sureOrderCar, payOrder, sureOrder } from '../api'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  data() {
    return {
      // visible: false,
      // visible1: false,
      // dialogVisible: false,
      type: '', //选择地址和抬头 1收货地址 2发票抬头 3收票地址
      type1: '', //1是立即购买  2是购物车的购买
      data: [],
      price_data: {},
      obj1: {
        address_id: '',
        address_name: '1111',
        address_phone: '',
        address_detail: '',
        sheng: '',
        shi: '',
        qu: '',
      },
      obj2: {},
      obj3: {},
      remark: '',
      pay_type: 0,
      fapiao_type: '',
      goods_id: '',
      attr_id: '',
      num: '',
      ids: '',
    }
  },
  methods: {
    initData1() {
      let params = {
        goods_id: this.goods_id,
        attr_id: this.attr_id,
        num: this.num,
        address_id: localStorage.getItem('addressObj1')?JSON.parse(localStorage.getItem('addressObj1')).address_id:'',
        address_id2: '',
        fp_id: '',
      }
      this.data = []
      sureOrder(params).then((data) => {
        if (data.status == 200) {
          data.msg.num = this.num
          this.data.push(data.msg)
          this.price_data = {
            allprice: data.msg.allprice,
            allnums: data.msg.num,
            postman:data.msg.postman,
          }
          this.obj1 = data.address
          this.obj2 = data.fapiao
          this.obj3 = data.address2
          this.obj1 = localStorage.getItem('addressObj1')
            ? JSON.parse(localStorage.getItem('addressObj1'))
            : data.address
          this.obj2 = localStorage.getItem('addressObj2')
            ? JSON.parse(localStorage.getItem('addressObj2'))
            : data.fapiao
          this.obj3 = localStorage.getItem('addressObj3')
            ? JSON.parse(localStorage.getItem('addressObj3'))
            : data.address2
          this.fapiao_type = localStorage.getItem('fapiao_type') || ''
        }
      })
    },
    initData2() {
      let params = {
        ids: this.ids,
        address_id: localStorage.getItem('addressObj1')?JSON.parse(localStorage.getItem('addressObj1')).address_id:'',
        address_id2: '',
        fp_id: '',
      }
      sureOrderCar(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.price_data = data.price_data
          this.obj1 = localStorage.getItem('addressObj1')
            ? JSON.parse(localStorage.getItem('addressObj1'))
            : data.address
          this.obj2 = localStorage.getItem('addressObj2')
            ? JSON.parse(localStorage.getItem('addressObj2'))
            : data.fapiao
          this.obj3 = localStorage.getItem('addressObj3')
            ? JSON.parse(localStorage.getItem('addressObj3'))
            : data.address2
          this.fapiao_type = localStorage.getItem('fapiao_type') || ''
        }
      })
    },

    fnOrder() {
      if (!this.obj1.address_id) {
        this.$message.error('请选择收货地址')
        return
      }
      if (!this.fapiao_type) {
        this.$message.error('请选择发票类型')
        return
      }
      if (this.fapiao_type != 3) {
        if (!this.obj2.fp_id) {
          this.$message.error('请选择发票信息')
          return
        }
        if (this.obj2.fp_type != this.fapiao_type) {
          this.$message.error('发票类型不一致')
          return
        }
      }
      if (!this.pay_type) {
        this.$message.error('请选择支付类型')
        return
      }
      let params
      if (this.type1 == 2) {
        params = {
          type: this.type1,
          ids: this.ids,
          address_id: this.obj1.address_id,
          address_id2: '',
          // address_id2: this.fapiao_type == 3 ? '' : this.obj3.address_id,
          fp_id: this.fapiao_type == 3 ? '' : this.obj2.fp_id,
          fapiao_type: this.fapiao_type,
          remark: this.remark,
          pay_type: this.pay_type,
          payFrom: 1,
        }
      }
      if (this.type1 == 1) {
        params = {
          type: this.type1,
          address_id: this.obj1.address_id,
          address_id2: '',
          // address_id2: this.fapiao_type == 3 ? '' : this.obj3.address_id,
          fp_id: this.fapiao_type == 3 ? '' : this.obj2.fp_id,
          fapiao_type: this.fapiao_type,
          remark: this.remark,
          pay_type: this.pay_type,
          goods_id: this.goods_id,
          attr_id: this.attr_id,
          num: this.num,
          payFrom: 1,
        }
      }
      if (this.pay_type == 1) {
        this.$message.error('银联支付暂未开通，请选择其他支付方式')
        return
      }
      payOrder(params).then((data) => {
        if (data.status == 200) {
          if (this.pay_type == 1) {
            return
          } else if (this.pay_type == 2) {
            //支付宝
            window.open(data.msg)
          } else if (this.pay_type == 4) {
            this.$router.push({
              name: 'Pay',
              params: {
                id: '.html',
                type: this.pay_type,
                number: data.number,
                price: data.price,
                order_id: data.oid,
              },
            })
            // this.$message.success('订单已生成,请尽快线下付款哦~')
          } else {
            this.$router.push({
              name: 'Pay',
              params: {
                id: '.html',
                url: data.msg,
                type: this.pay_type,
                order_id: data.oid,
              },
            })
          }
          //  else {
          //   this.$message.success('订单已生成,请尽快线下付款哦~')
          // }
          if (this.type1 == 2) {
            this.$bus.$emit('carNum111', 111)
          }
        } else if (data.status == 100100) {
          this.$router.push({
            name: 'OrderZx',
            params: {
              id: 0 + '.html',
            },
          })
          return
        } else if (data.status == 100) {
          return
        }
      })
    },
    fnOpen(type) {
      this.type = type
      let name11 = 'MyAddress'
      if (type == 2) {
        name11 = 'FaPiao'
      }
      this.$router.push({
        name: name11,
        params: {
          from: type,
          fapiao_type: this.fapiao_type,
        },
      })
    },
    updated(val) {
      // this.$set(this.obj1, 'address_name', val.address_name)
    },
    fnSelectFpType(type) {
      this.fapiao_type = type
      // console.log(this.fapiao_type)
    },
    // fnAdd(type) {
    //   this.type = type
    //   this.dialogVisible = true
    // },
    fnBack() {
      this.$router.go(-1)
    },
  },
  mounted() {
    document.title = '本牛'
    let can = this.$route.params.id.split('.')[0]
    let can1 = can.split('-')
    this.type1 = can1[0]
    if (this.type1 == 1) {
      this.goods_id = can1[1]
      this.attr_id = can1[2]
      this.num = can1[3]
      this.initData1()
    } else {
      this.ids = can1[1].split('_').join(',')
      this.initData2()
    }

    this.$bus.$on('addressObj', (val, type, fapiao_type) => {
      this.type = type
      this.fapiao_type = fapiao_type
      console.log(type+'+'+fapiao_type);
      // console.log(val)
      localStorage.setItem('fapiao_type', fapiao_type)
      localStorage.setItem('addressObj' + type, JSON.stringify(val))
    })
  },
  beforeDestroy() {
    //收尾操作，销毁
    // this.$bus.$off('addressObj') //$off解绑当前组件所用到的事件
  },
}
</script>

<style scoped>
.tips11 {
  width: 210px;
  height: 40px;
  background: url('@/assets/bg.png') no-repeat center
    center;
  background-size: 100% 100%;
  position: absolute;
  top: -58px;
  left: -85px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #ec7902;
}
.deng {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.price2 {
  font-size: 22px;
  font-weight: bold;
  color: #ec7902;
  line-height: 18px;
}
.cheng_head11 {
  padding: 0 22px;
}
.middle1 {
  background-color: #fff;
  padding: 10px;
}
.topppImg {
  width: 18px;
  height: 18px;
  /* margin: 20px; */
  margin-right: 22px;
}
.btn2 {
  width: 115px;
  height: 32px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  margin-left: 16px;
}
.btn1 {
  color: #777777;
  background: none;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
}
.dialog {
  padding: 0 107px 20px 90px;
}
.width115 {
  width: 115px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;
}

.bg_f08300 {
  /* background: linear-gradient(90deg, #efc845 0%, #dca01e 100%); */
  border-radius: 2px;
  margin-left: 15px;
  color: #fff;
  width: 115px;
  height: 34px;
}
.order_header {
  height: 60px;
  padding: 0 26px;
  border-bottom: 1px solid #d8d8d8;
}
.order_box1 {
  background: #ffffff;
  border-radius: 4px;
}
.order_num {
  /* width: 76px; */
  text-align: right;
  padding-right: 12px;
}
.address {
  width: 1001px;
  height: 36px;
  background: #f5f5f5;
  /* border-radius: 2px; */
  padding-left: 12px;
}
.user_info {
  
  height: 36px;
  border: 1px solid #d8d8d8;
  margin-right: 22px;
  padding: 0 20px;
  box-sizing: border-box;
}
.tab1 {
  width: 134px;
  height: 36px;
  border: 1px solid #d8d8d8;
  margin-left: 22px;
}

.tab1.active {
  border: none;
  color: #ffffff;
  background: #ec7902;
  border: 1px solid #ec7902;
}

.box {
  background: #ffffff;
  /* border-radius: 4px; */
  /* padding: 16px 26px 20px; */
  margin-bottom: 16px;

  border: 1px solid #eeeeee;
}
.btn111 {
  width: 103px;
  height: 27px;
  border: 1px solid #ec7902;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  color: #ec7902;
  background-color: transparent;
}

::v-deep .el-popover {
  background: #f5f5f5;
}

/* ::v-deep .popper__arrow::after {
    top: 1px;
    margin-left: -6px;
    border-top-width: 0;
    border-bottom-color: #f5f5f5;
} */
.order_box {
  background: #ffffff;
  /* border-radius: 4px; */
  /* min-height: 514px; */
  box-sizing: border-box;
  padding: 20px 26px;
}
.order_item {
  margin-bottom: 16px;
}
.order_box > .order_item:last-child {
  margin-bottom: 0;
}
.my_order {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 26px;
  font-size: 14px;
  margin-bottom: 10px;
}
input[type='text'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 36px;
  width: 400px;
  padding: 0 12px;
  color: #333333;
  font-size: 16px;
  outline: none;
}
input[type='text']::-webkit-input-placeholder {
  color: #999;
}
input[type='text']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
}

.blue {
  color: #21319a;
  margin-left: 30px;
}
.pay {
  width: 28px;
  height: 28px;
  margin: 0 8px;
}
.pay1 {
  margin: 0 30px 0 0;
}
input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: url('@/assets/check2.png') no-repeat center center;
  background-size: 100% 100%;
}
input[type='radio']:checked {
  background: url('@/assets/checked21.png') no-repeat center center;
  background-size: 100% 100%;
}
</style>

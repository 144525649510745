<template>
  <div>
    <Header />
    <div class="middle1" style="margin-top: 14px">
      <div
        style="text-align: center; margin: 160px auto"
        v-if="data.length == 0"
      >
        <el-image
          style="width: 160px; height: 160px; margin-bottom: 16px"
          :src="require('@/assets/quesheng/no_content.png')"
          fit="cover"
        ></el-image>
        <div class="font-666-14">空空如也~</div>
      </div>

      <div v-else style="padding: 10px; background-color: #fff">
        <div class="border_eee">
          <div class="">
            <div class="flex-wrap cheng_head11">
              <div class="font-333-20-bold align-start">
                购物车共
                <span class="font-FA9C22-22-bold">{{ carsNum }}</span> 件产品
              </div>
              <div class="tips_out flex-con">
                <img
                  :src="require('@/assets/tips2.png')"
                  style="
                    width: 22px;
                    height: 22px;
                    display: block;
                    margin: 13px 0 0 7px;
                  "
                  class="pointer"
                />
                <div class="tips_in font-666-16" style="line-height: 26px">
                  温馨提示：
                  您可以选择一个或多个商品一起结算（注：结算的商品中若包含面议商品，请
                  <span class="cor_EC7902 pointer" @click="fnKf">联系客服</span>
                  确定具体价格）。
                </div>
              </div>
            </div>
            <div
              class="flex-wrap font-333-16-bold"
              style="
                line-height: 56px;
                border-bottom: 1px solid #eeeeee;
                padding: 0 22px;
                align-items: center;
              "
            >
              <input
                type="checkbox"
                name="allCheckbox"
                value=""
                id="allCheck"
                @change="allCheck"
              />
              <div class="font-333" style="width: 152px">全选</div>
              <div style="width: 130px; margin-right: 60px">产品信息</div>
              <div style="width: 155px">产品规格</div>
              <div style="width: 180px; text-align: center">单价</div>
              <div style="width: 185px; text-align: center">数量</div>
              <div style="width: 165px; text-align: center">小计</div>
              <div class="flex-con" style="text-align: center">操作</div>
            </div>
          </div>

          <div style="padding: 8px 22px 0">
            <div
              class="order_item"
              v-for="(item, index) in data"
              :key="'car_' + index"
              
            >
              <div
                class="flex-wrap font-333-16"
                :style="
                  index == data.length - 1
                    ? 'padding:8px 0;'
                    : 'border-bottom: 1px solid #EEEEEE;padding:8px 0;'
                "
              >
                <div class="align-start">
                  <input
                    type="checkbox"
                    name="checkbox"
                    value=""
                    class="checkbox"
                    @change="oneCheck"
                    @click.stop
                    :data-price="
                      ((item.price * item.num * 100) / 100).toFixed(2)
                    "
                    :data-idd="item.id"
                    :id="'check_' + item.id"
                  />
                </div>
                <el-image
                  style="width: 140px; height: 140px; margin-right: 12px"
                  :src="item.goods_thumb"
                  fit="cover"
                  class="pointer"
                  @click="fnGoodsDetails(item.goods_id)"
                ></el-image>
                <div
                  style="width: 130px; margin-right: 60px"
                  class="align-center2 pointer"
                  @click="fnGoodsDetails(item.goods_id)"
                >
                  {{ item.goods_name || '' }}
                </div>
                <div style="min-width: 155px" class="align-center2">
                  <div class="flex-wrap">
                    <div class="">
                      <div
                        v-for="(item, index) in item.guige_name"
                        :key="'guige_name_' + index"
                        class="font-333-16"
                      >
                        {{ item }}：
                      </div>
                    </div>
                    <div class="flex-con">
                      <div
                        v-for="(item, index) in item.guige_value"
                        :key="'guige_value_' + index"
                        class="font-666-16"
                        style="line-height: 24px;"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style="width: 180px; text-align: center"
                  class="font-666-16 align-center"
                >
                  {{ item.price > 0 ? item.price : '面议' }}{{ item.gu_name }}
                </div>
                <div style="width: 185px" class="align-center" @click.stop>
                  <el-input-number
                    v-model="item.num"
                    @change="
                      (val) => {
                        handleChange(
                          val,
                          item.id,
                          index,
                          ((item.price * item.num * 100) / 100).toFixed(2) || 0
                        )
                      }
                    "
                    :min="item.goods_limit_buy"
                    label="描述文字"
                  ></el-input-number>
                </div>
                <div style="width: 165px" class="font-333-16-bold align-center">
                  {{
                    item.price > 0
                      ? ((item.price * item.num * 100) / 100).toFixed(2)
                      : '面议'
                  }}
                </div>
                <div
                  class="flex-con pointer align-center font-999-16"
                  style=""
                  @click.stop="fnDelete(item.id)"
                >
                  删除
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ref="settle"></div>
        <div
          class="flex-wrap order_header"
          :class="{ order_fixed: showFixed }"
          style="border: none"
        >
          <div class="left flex-wrap flex-con">
            <div
              class="font-666-16 pointer"
              style="margin-left: 26px"
              @click="fnDelete('', 1)"
            >
              删除选中的商品
            </div>
          </div>
          <div class="flex-wrap font-333-16" style="margin-right: 16px">
            已选：<span class="font-EC7902-18-bold">{{ allNum || 0 }}件</span>
          </div>
          <div class="flex-wrap font-333-16">
            应付含税总额：<span class="font-EC7902-18-bold"
              >¥{{ allPrice }}</span
            >
          </div>
          <div class="btn pointer" @click="fnPay">
            去结算（{{ allNum || 0 }}）
          </div>
        </div>
        <!-- </div> -->
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="300px"
        :show-close="false"
      >
        <div class="dialog">
          <div class="font-333" style="text-align: center; font-size: 20px">
            确认删除吗？
          </div>
          <div class="align-around" style="margin-top: 40px">
            <div
              class="btn222 btn111 align-center pointer"
              @click="dialogVisible = false"
            >
              取消
            </div>
            <div class="btn222 align-center pointer" @click="fnCun">确认</div>
          </div>
        </div>
      </el-dialog>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'
import { mycarPc, carDel, carUpDown, carSetNum } from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    right,
  },
  data() {
    return {
      dialogVisible: false,
      num: 1,
      data: [],
      total: 0,
      carsNum: 0,
      allPrice: 0,
      allNum: 0,
      checked: true,
      ids: '',
      tipObj: {},
      showFixed: false,
      scroll: '',
    }
  },
  methods: {
    fnKf() {
      this.$bus.$emit('goodsKf')
    },
    initData(type) {
      let params = {}
      mycarPc(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.carsNum = data.carsNum
          if (type) {
            setTimeout(() => {
              let allCk2 = document.querySelectorAll(
                "input[name='checkbox']:checked"
              )
              this.allNum = allCk2.length || 0
              this.allPrice = 0
              for (let index = 0; index < allCk2.length; index++) {
                this.allPrice = (Number(allCk2[index].dataset.price)*100+Number(this.allPrice)*100)/100
              }
            }, 0)
          }
        }
      })
    },
    fnCun() {
      let goods_id = this.tipObj.idd
      if (this.tipObj.type == 1) {
        goods_id = []
        let allCk2 = document.querySelectorAll("input[name='checkbox']:checked")
        allCk2.forEach((element) => {
          goods_id.push(element.dataset.idd)
        })
        goods_id = goods_id.join(',')
      }
      if (!goods_id) {
        this.$message.error('请选择删除的产品')
        return
      }
      let params = {
        ids: goods_id,
      }
      carDel(params).then((data) => {
        if (data.status == 200) {
          this.dialogVisible = false
          this.initData(1)
          this.$bus.$emit('carNum111', 111)
        }
      })
    },
    fnDelete(idd, type) {
      this.dialogVisible = true
      this.tipObj.idd = idd
      this.tipObj.type = type
      return
    },
    oneCheck(e) {
      // console.log(e.target.checked)
      let allCk1 = document.querySelectorAll("input[name='checkbox']")
      let allCk2 = document.querySelectorAll("input[name='checkbox']:checked")
      if (allCk1.length == allCk2.length && allCk2.length > 0) {
        document.querySelector('#allCheck').checked = true
      } else if (allCk1.length != allCk2.length) {
        document.querySelector('#allCheck').checked = false
      }
      if (e.target.checked) {
        this.allNum = this.allNum + 1
      } else {
        this.allNum = this.allNum - 1
      }
      this.allPrice = 0
      for (let index = 0; index < allCk2.length; index++) {
        if (Number(allCk2[index].dataset.price) == 0) {
          this.allPrice = '面议'
          return
        }
        this.allPrice = (Number(allCk2[index].dataset.price)*100+Number(this.allPrice)*100)/100
        
      }
    },
    allCheck(e) {
      let allCk = document.querySelectorAll("input[name='checkbox']")
      if (allCk.length > 0) {
        this.allPrice = 0
        this.allNum = 0
        allCk.forEach((element) => {
            if (e.target.checked) {
              element.checked = true
              if(this.allPrice != '面议'){
                // this.allPrice = this.allPrice + Number(element.dataset.price)*100/100
                this.allPrice = (Number(element.dataset.price)*100+Number(this.allPrice)*100)/100
              }
              this.allNum = this.allNum + 1
              if (Number(element.dataset.price) == 0) {
                this.allPrice = '面议'
                return
              }
            } else {
              element.checked = false
              this.allNum = 0
            }
          })
      }
    },
    handleChange(value, id, index, price) {
      let params = {
        carnum: value,
        id: id,
      }
      carSetNum(params).then((data) => {
        if (data.status == 200) {
          this.data[index].num = value
          if (document.getElementById('check_' + id).checked) {
            let allCk2 = document.querySelectorAll(
              "input[name='checkbox']:checked"
            )
            this.allPrice = 0
            allCk2.forEach((element) => {
              this.allPrice = (Number(element.dataset.price)*100+Number(this.allPrice)*100)/100
            })
          }
        }
      })
    },
    fnPay() {
      let goods_id = []
      let allCk2 = document.querySelectorAll("input[name='checkbox']:checked")
      allCk2.forEach((element) => {
        goods_id.push(element.dataset.idd)
      })
      goods_id = goods_id.join('_')
      if (!goods_id) {
        this.$message.error('请选择产品')
        return
      }
      this.$router.push({
        name: 'SureOrder',
        params: {
          id: 2 + '-' + goods_id + '.html',
          // ids: goods_id,
          // type1: 2,
        },
      })
    },
    handleScroll() {
      // console.log(document.getElementById("main").offsetHeight);
      //或者使用document.querySelector('.class或者#id').scrollTop
      let settle = this.$refs.settle.offsetTop
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop
      console.log(
        settle + '||' + this.scroll + '||' + document.body.clientHeight
      )
      if (settle + 40 > this.scroll + document.body.clientHeight) {
        this.showFixed = true
      } else {
        this.showFixed = false
      }
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    document.title = '本牛'
    this.initData()
    window.addEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped>
.tips_out {
  position: relative;
}
.tips_out:hover .tips_in {
  display: block;
}

.tips_in {
  display: none;
  position: absolute;
  padding: 10px;
  /* background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
  left: 36px;
  bottom: 0;
}
.order_status div {
  margin-top: 6px;
}

.order_status div:first-child {
  margin: 0;
}

.order_header {
  height: 48px;
  background: #ffffff;
  /* border-radius: 4px; */
  margin-top: 12px;
  align-items: center;
  /* box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2); */
}

.order_fixed {
  position: fixed;
  bottom: 0;
  width: 1280px;
  z-index: 99999;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.order_box {
  border-radius: 4px;
}

.my_order {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 26px;
  font-size: 14px;
  margin-bottom: 10px;
}

input[type='text'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-right: none;
  box-sizing: border-box;
  height: 24px;
  width: 125px;
  padding: 0 12px;
  color: #333333;
  font-size: 14px;
  outline: none;
}

input[type='text']::-webkit-input-placeholder {
  color: #aaa;
}

input[type='text']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-right: none;
}

.search_txt {
  font-size: 14px;
  color: #203398;
  width: 52px;
  height: 24px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  color: #ffffff;
}

.btn_padding {
  padding: 1px 11px;
  margin-left: 10px;
  border: 1px solid #d8d8d8;
}

.ljzf {
  padding: 1px 11px;
  border: 1px solid #c52b33;
  display: inline-block;
}
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  margin: 0;
  margin-right: 32px;
  background: url('@/assets/check2.png') no-repeat center center;
  background-size: 100% 100%;
}
input[type='checkbox']:checked {
  background: url('@/assets/checked21.png') no-repeat center center;
  background-size: 100% 100%;
}
/* input[type='checkbox'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #d8d8d8;
  margin-right: 15px;
}

input[type='checkbox']:checked {
  border: 1px solid #333;
  position: relative;
}

input[type='checkbox']:checked:after {
  position: absolute;
  width: 13px;
  height: 8px;
  top: 50%;
  left: 50%;
  margin-left: -7px;
  margin-top: -7px;
  content: '';
  color: #000;
  display: inline-block;
  visibility: visible;
  border: 2px solid #333;
  border-top: none;
  border-right: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
} */

.C52B33 {
  color: #c52b33;
}

.shopping > div {
  margin-bottom: 16px;
}

.shopping > div:last-child {
  margin-bottom: 0;
}
.checkbox {
  margin-top: 40px;
}
::v-deep .el-input-number {
  width: 154px;
  height: 32px;
  border: 1px solid #d8d8d8;
  line-height: 30px;
}
::v-deep .el-input-number__increase {
  border-left: 1px solid #d8d8d8;
  width: 32px;
  height: 32px;
  background: transparent;
}
::v-deep .el-input-number__decrease {
  border-right: 1px solid #d8d8d8;
  width: 32px;
  height: 32px;
  background: transparent;
}
::v-deep .el-input-number .el-input {
  height: 32px;
}
::v-deep .el-input-number .el-input__inner {
  padding-left: 0;
  padding-right: 0;
  border: none;
  font-size: 16px;
}
::v-deep .el-input__inner {
  height: 32px;
}
.order_item {
  background-color: #fff;
}
.order_item:hover {
  /* box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2); */
  z-index: 1;
}
.left {
  align-items: center;
}
.red {
  color: #c52b33;
}
.btn {
  line-height: 48px;
  text-align: center;
  margin: 0 0 0 16px;

  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  width: 183px;
  height: 48px;
  background: #fa9c22;
  border-radius: 2px;
}
</style>

<template>
  <div>
    <EnHeader :num111="1" />
    <div class="bg_fff">
      <!-- <el-image style="width: 100%" :src="require('@/assets/en/banner3.png')" fit="cover"></el-image> -->
      <img style="width: 100%" :src="require('@/assets/en/banner3.png')" fit="cover" alt="">
      <div class="w1200">
        <div class="tab_out font-333-14 align-start" style="white-space: nowrap">
          <div class="pointer" :class="{ active: index == tabIndex }" v-for="(item, index) in tabData"
            :key="'tabData_' + index" @click="fnTab(index)">
            {{ item.title }} <span class="xian"></span>
          </div>
        </div>
        <div class="flex-wrap font-333">
          <div class="box_left">
            <div class="item_left" :class="{ activeTabLeft: index == tabLeftIndex }" v-for="(item, index) in tabLeft"
              :key="'tabLeft_' + index" @click="fnTabLeft(index)">{{ item.title }}</div>
          </div>
          <div class="flex-con">
            <div id="goods_detail" class="flex-wrap" style="padding: 30px;background: #F6F6F6;"
              :class="{ hidden: !showXq }">
              <div>
                <div class="swiper-container gallery-top" style="height: 320px">
                  <div class="swiper-wrapper swiper-wrapper1">
                    <div class="swiper-slide" v-for="(item, index) in goods_images" :key="'goods_images1_' + index">
                      <img :src="item" />
                    </div>
                  </div>
                </div>
                <div class="swiper-container gallery-thumbs">
                  <div class="swiper-wrapper" style="padding: 0 20px; box-sizing: border-box">
                    <div class="swiper-slide" v-for="(item, index) in goods_images" :key="'goods_images2_' + index">
                      <img :src="item" />
                    </div>
                  </div>
                  <div class="swiper-button-next swiper-button-white" style="top: 48px"></div>
                  <div class="swiper-button-prev swiper-button-white" style="top: 48px"></div>
                </div>
              </div>
              <div class="flex-con" style="margin-left: 20px;">
                <div class="font-333-20">
                  AAS PRESSED GLASS COTTON SOUND INSULATION BOARD
                </div>
                <div class="font-333-bold " style="text-decoration: underline; margin: 20px 0;color: #EFC845;">
                  HARD GLASS WOOL
                </div>
                <div class="font-333">
                  SPECIFICATIONS
                  <br />
                  UNIT WEIGHT（KG/M³）：32/40/48/56/64/80/96 <br />
                  THICKNESS（MM）：25/30/40/50 <br />
                  TYPE： FORMALDEHYDE-FREE/FORMALDEHYDE FREE AND ACRYLIC FREE/DEDICATED FOR SMOKE CONTROL AND
                  EXHAUST/INDUSTRIAL HIGH-TEMPERATURE RESISTANCE
                  LIKE THIS PRODUCT? YOU CAN CONTACT US
                </div>
                <div class="xian"></div>
                <div class="font-333-bold">
                  Like this product? You can contact us
                </div>
                <div class="font-333">
                  <div class="align-start" style="margin-top: 20px">
                    <el-image style="width: 24px; height: 24px; margin-right: 6px" :src="require('@/assets/phone1.png')"
                      fit="cover"></el-image>
                    <span style="text-decoration: underLine">400-025-9025</span>
                  </div>
                  <div class="align-start" style="margin-top: 20px">
                    <el-image style="width: 24px; height: 24px; margin-right: 6px" :src="require('@/assets/en/icon4.png')"
                      fit="cover"></el-image>
                    <span>insulation@wincellchina.com</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="goods_list" :class="{ hidden: showXq }">
              <div style="background: #d8d8d8" class="item_left">
                HARD GLASS WOOL
              </div>
              <div class="align-start box_right" style="flex-wrap: wrap">
                <div class="item_right pointer" v-for="(item, index) in goodsData" :key="'goodsData_' + index"
                  @click="fnXq">
                  <el-image :src="item.img" fit="cover" class="goods_img"></el-image>
                  <div class="aui-ellipsis-2">{{ item.title }} </div>
                </div>
              </div>
              <div style="background: #d8d8d8" class="item_left">
                HARD GLASS WOOL
              </div>
              <div class="align-start box_right" style="flex-wrap: wrap">
                <div class="item_right pointer" v-if="index < 11" v-for="(item, index) in goodsData"
                  :key="'goodsData_' + index">
                  <el-image :src="item.img" fit="cover" class="goods_img"></el-image>
                  <div class="aui-ellipsis-2">{{ item.title }} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EnFooter />
  </div>
</template>
<script>
import EnHeader from '@/components/EnHeader.vue'
import EnFooter from '@/components/EnFooter.vue'
import Swiper from 'swiper/bundle'
export default {
  components: {
    EnHeader,
    EnFooter,
  },
  data() {
    return {
      tdkTitle: '本牛',
      tdkKeywords: '本牛',
      tdkDescription: '本牛',
      showXq: false,
      tabLeftIndex: 0,
      tabLeft: [{
        title: 'GLASS WOOL',
      }, {
        title: 'FORMALDEHYDE FREE ACRYLIC GLASS WOOL',
      }, {
        title: 'HIGH TEMPERATURE GLASS WOOL',
      }, {
        title: 'GLASS WOOL PIPE',
      }, {
        title: 'GLASS WOOL',
      }, {
        title: 'FORMALDEHYDE FREE ACRYLIC GLASS WOOL',
      }, {
        title: 'HIGH TEMPERATURE GLASS WOOL',
      }, {
        title: 'GLASS WOOL PIPE',
      }],
      goodsData: [{
        title: 'Hard Glass wool',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/b0936f6abdade37d6da4af7e0d4917a0.jpg'
      }, {
        title: 'Classic rubber plastic insulation pipe',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/095cef6df0ea370846ed4ca249aa1198.jpg'
      }, {
        title: 'Hard Glass wool',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/6b09740d9df75588eae6a864a619a050.jpeg'
      }, {
        title: 'Classic rubber plastic insulation pipe',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/084020758848f38299fc78a1c66a6635.jpg'
      }, {
        title: 'Hard Glass wool',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/b0936f6abdade37d6da4af7e0d4917a0.jpg'
      }, {
        title: 'Classic rubber plastic insulation pipe',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/095cef6df0ea370846ed4ca249aa1198.jpg'
      }, {
        title: 'Hard Glass wool',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/6b09740d9df75588eae6a864a619a050.jpeg'
      }, {
        title: 'Classic rubber plastic insulation pipe',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/084020758848f38299fc78a1c66a6635.jpg'
      }, {
        title: 'Hard Glass wool',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/b0936f6abdade37d6da4af7e0d4917a0.jpg'
      }, {
        title: 'Classic rubber plastic insulation pipe',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/095cef6df0ea370846ed4ca249aa1198.jpg'
      }, {
        title: 'Hard Glass wool',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/6b09740d9df75588eae6a864a619a050.jpeg'
      }, {
        title: 'Classic rubber plastic insulation pipe',
        img: 'https://benbull.oss-cn-nanjing.aliyuncs.com/084020758848f38299fc78a1c66a6635.jpg'
      }],
      tabIndex: 0,
      tabData: [{
        title: 'RUBBER AND PLASTIC',
      }, {
        title: 'GLASS WOOL',
      }, {
        title: 'AIR DUCT',
      }, {
        title: 'CERAMIC FIBER',
      }, {
        title: 'AEROGEL',
      }, {
        title: 'SHELL TREASURE OUTER PROTECTION',
      }, {
        title: 'AEROGEL',
      }],
      goods_images: [
        'https://benbull.oss-cn-nanjing.aliyuncs.com/5899c38621cec75c66fee9b1c478a2e6.jpg',
        'https://benbull.oss-cn-nanjing.aliyuncs.com/d8375c9e6ee8927385fc25313f7b2607.jpg',
      ],
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    fnTab(index) {
      this.tabIndex = index
      this.showXq = false
    },
    fnTabLeft(index) {
      this.tabLeftIndex = index
      this.showXq = false
    },
    fnXq() {
      this.showXq = true
      setTimeout(() => {
        var galleryThumbs = new Swiper('.gallery-thumbs', {
          spaceBetween: 10,
          slidesPerView: 3,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        })
        new Swiper('.gallery-thumbs', {
          spaceBetween: 10,
          slidesPerView: 3,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        })
        new Swiper('.gallery-top', {
          spaceBetween: 10,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          thumbs: {
            swiper: galleryThumbs,
          },
        })
      }, 1);
    }
  },
  mounted() {

  },
}
</script>

<style scoped>
@import url('swiper/swiper-bundle.css');

.activeTabLeft {
  background: #d8d8d8
}

.w1200 {
  padding: 35px 0 60px;
}

.tab_out {
  border-bottom: 1px solid #f6f6f6;
  margin-bottom: 60px;
  overflow-x: scroll;
}

.box_left {
  width: 280px;
  background: #f6f6f6;
  margin-right: 40px;
  height: 634px;
  overflow-y: scroll;
}

.item_left {
  padding: 10px 20px;
}

.tab_out>div {
  margin-right: 40px;
  padding: 25px 0;
}

.tab_out .active {
  font-size: 14px;
  font-weight: 500;
  color: #efc845;
  line-height: 20px;
  position: relative;
}

.tab_out .active .xian {
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
}

.box_left::-webkit-scrollbar {
  display: none;
}

.tab_out::-webkit-scrollbar {
  /* width: 12px; */
  height: 2px;
}

.tab_out::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
  display: none;
}

.tab_out::-webkit-scrollbar-corner {
  background-color: transparent;
}

.tab_out::-webkit-scrollbar-thumb {
  /* border-top: 8px solid rgba(0, 0, 0, 0); */
  /* height: 6px; */
  /* border-radius: 25px; */
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.3);
}

.box_right {
  padding: 20px 20px 10px;
  background: #f6f6f6;
}

.item_right {
  width: 198px;
  height: 220px;
  background: #ffffff;
  padding: 10px;
  margin: 0 16px 20px 0;
  box-sizing: border-box;
}

.item_right:nth-child(4n) {
  margin-right: 0;
}

.item_right .goods_img {
  display: block;
  width: 140px;
  height: 140px;
  margin: 0 auto 10px;
}

.swiper-container {
  width: 320px;
  height: 320px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-wrapper1,
.swiper-wrapper1 .swiper-slide {
  width: 320px;
  height: 320px;
}

/* .swiper-wrapper,
.swiper-slide {
  width: 320px;
  height: 320px;
} */

.swiper-slide img {
  width: 100%;
  height: 100%;
}

.gallery-top {
  height: 40%;
  width: 100%;
}

.gallery-thumbs {
  height: 68px;
  box-sizing: border-box;
  margin: 20px 0;
}

.gallery-thumbs .swiper-slide {
  width: 68px !important;
  height: 100%;
  opacity: 1;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.gallery-thumbs img {
  height: 68px;
  width: 68px;
}

.swiper-button-prev {
  width: 18px;
  height: 18px;
  background: url('@/assets/main1_38.png') no-repeat center center;
  background-size: 18px 18px;
  left: 0;
}

.swiper-button-next {
  width: 18px;
  height: 18px;
  background: url('@/assets/main1_39.png') no-repeat center center;
  background-size: 18px 18px;
  right: 0;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

.xian {
  height: 2px;
  background: #EFC845;
  margin: 20px 0;
}
</style>

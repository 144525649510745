<template>
  <div>
    <Header />
    <div class="middle1" style="margin-top:14px;">
      <div class="toppp flex-wrap">
        <div class="pointer" @click="toMain()">首页</div>
        <img src="../assets/arrow1.png" class="topppImg" />
        <div>轮播</div>
      </div>
      <div class="contain">
        <div class="notice_title">{{ data.banner_title || '' }}</div>
        <div class="notice_time">发布时间：{{ data.banner_time || '' }}</div>
        <div v-html="data.banner_content"></div>
      </div>
    </div>
    <!-- <right /> -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { bannerDetail } from '../api'
export default {
  components: {
    Header,
    Footer,
    right
  },
  data() {
    return {
      data: '',
    }
  },
  methods: {
    initData() {
      let params = {
        banner_id: this.$route.params.id.split('.')[0],
      }
      bannerDetail(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
        }
      })
    },
  },
  mounted() {
    document.title = '本牛'
    this.initData()
  },
}
</script>
<style scoped>
.toppp {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  margin: 20px 0;
  align-items: center;
}

.topppImg {
  width: 20px;
  height: 20px;
  margin: 0 4px;
}
.fwb {
  height: auto;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
}

.notice_title {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  text-align: center;
}
.notice_time {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin: 8px auto 20px;
  text-align: center;
}
.contain {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  /* overflow-y: scroll;
  max-height: 700px; */
  box-sizing: border-box;
}
.contain::-webkit-scrollbar {
  display: none;
}
</style>

<template>
  <div>
    <Header :num111="3" />
    <div class="hyyx_contain">
      <el-image
        style="width: 100%"
        :src="liApp.app_image"
        fit="cover"
        class="hyyx_banner"
      ></el-image>
      <div class="hyyx_meng align-start">
        <div class="hyyx_txt_out">
          <div style="line-height: 30px; font-size: 30px">
            {{ liApp.app_title }}
          </div>
          <div
            style="
              font-size: 18px;
              margin: 29px 0 25px;
              line-height: 26px;
              text-align: justify;
            "
          >
            {{ liApp.app_intro }}
          </div>
          <div
            class="align-start write_table pointer bg_EC7902"
            @click="fnWrite"
          >
            <el-image
              style="width: 26px; height: 23px; margin-right: 24px"
              :src="require('@/assets/edit.png')"
              fit="cover"
            ></el-image>
            填写产品需求表单
          </div>
        </div>
      </div>
    </div>
    <div class="middle1">
      <div
        class="flex-wrap"
        style="background-color: #fff; margin-bottom: 20px"
      >
        <div class="s_cate_title align-end">行业分类</div>
        <div class="align-start cate_out flex-con">
          <div
            class="font-333-14 pointer fl"
            v-for="(item, index) in appList1"
            :key="'appList_' + index"
            @click="fnChangeAppId(item.app_id)"
            :class="{
              cor_EC7902: item.app_id == app_id,
            }"
          >
            {{ item.app_title || '' }}
          </div>
        </div>
      </div>

      <div v-if="data.length > 0">
        <!-- <div
          class="two pointer"
          v-for="(item, index) in data"
          :key="'goods_' + index"
          @click="fnGoodsDetails(item.goods_id)"
        >
          <img :src="item.goods_thumb" class="twoImg" />
          <div class="twoTitle aui-ellipsis-2">{{ item.goods_name }}</div>
          <div class="li-label-out">
            <div
              class="li-label"
              v-for="(item, index) in item.goods_tag"
              :key="'goods_tag_' + index"
            >
              {{ item }}
            </div>
          </div>
          <div class="price2">
            <span class="price3">￥</span
            >{{ item.goods_floor_price > 0 ? item.goods_floor_price : '面议' }}
          </div>
          <div class="btn bg_f08300" @click="fnGoodsDetails(item.goods_id)">
            立即抢购
          </div>
        </div> -->
        <div
          class="one pointer"
          v-for="(item, index) in data"
          :key="'goods_' + index"
          @click="fnGoodsDetails(item.goods_id)"
        >
          <el-image
            :src="item.goods_thumb"
            fit="cover"
            class="oneImg"
          ></el-image>
          <div style="padding: 10px 20px 0">
            <div class="twoTitle aui-ellipsis-2">
              {{ item.goods_name }}
            </div>
            <div class="price2">
              ¥{{ item.goods_floor_price > 0 ? item.goods_floor_price : '面议'
              }}<span
                class="font-333-bold"
                style="position: relative; bottom: 1px"
                >{{ item.goods_floor_price > 0 ? item.danwei : '' }}</span
              >
              <span class="hua-xian-jia" >
                                {{
                                  item.goods_old_price > 0 ? ('¥'+item.goods_old_price) : ''
                                }}
                              </span>
            </div>
          </div>
          <div class="align-between" style="padding: 20px 15px 17px">
            <div
              class="collect1 align-center"
              @click.stop="fnCollect(item.goods_id)"
            >
              <el-image
                :src="
                  item.is_collect == 1
                    ? require('@/assets/collect4.png')
                    : require('@/assets/collect3.png')
                "
                fit="cover"
                style="width: 17px; height: 15px"
                class="collect2"
              ></el-image>
            </div>

            <div class="buy1 align-start">
              <div class="shan"></div>
              <div>立即购买</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div style="text-align: center; margin: 225px auto">
          <el-image
            style="width: 160px; height: 160px; margin-bottom: 16px"
            :src="require('@/assets/quesheng/no_content.png')"
            fit="cover"
          ></el-image>
          <div class="font-666-14">商品完善中~</div>
        </div>
      </div>
      <div class="align-center">
        <Page
          :limit="limit"
          :totalNum="total"
          @onLimit="changeLimit"
          @onPage="changePage"
        />
      </div>

      <el-dialog :visible.sync="dialogVisible" width="1300px">
        <div class="dialog">
          <div class="kuang">
            <div class="title1 font-333-36-bold">填写表单信息</div>
            <div class="mb align-start">
              <div class="mb1 align-start">
                <div class="info">
                  <span class="FF940A m_r5 xing">*</span>姓名:
                </div>
                <input
                  type="text"
                  placeholder="长度不超过20个字符"
                  v-model="name"
                  style="width: 402px"
                />
              </div>
              <div class="mb1 align-start">
                <div class="info" style="width: 242px">
                  <span class="FF940A m_r5 xing">*</span>联系电话:
                </div>
                <input
                  type="tel"
                  placeholder="请输入您的联系方式"
                  v-model="phone"
                  style="width: 402px"
                />
              </div>
            </div>
            <div class="mb mb1 align-start">
              <div class="info">
                <span style="color: rgba(0, 0, 0, 0)">*</span>产品:
              </div>
              <input
                type="text"
                placeholder=""
                v-model="product"
                style="width: 1045px"
              />
            </div>
            <div class="mb mb2 flex-wrap">
              <div class="info">
                <span style="color: rgba(0, 0, 0, 0)">*</span>规格:
              </div>
              <textarea
                class="flex-con"
                placeholder=""
                v-model="spec"
              ></textarea>
            </div>
            <div style="margin: 37px 0 0 185px; padding-bottom: 46px">
              <div class="blue align-start">
                <div class="align-start" style="margin-right: 133px">
                  <img
                    :src="require('@/assets/download.png')"
                    alt=""
                    style="width: 18px; height: 24px; margin-right: 9px"
                  />
                  <a :href="demo" class="pointer down1">下载模板</a>
                </div>

                <input
                  type="file"
                  accept="application/msexcel,text/plain,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  name="file"
                  value="附件上传您的需求"
                  id="fileImg"
                  @change="uploadData"
                  class="pointer input1"
                />
                <!-- accept="text/plain,application/pdf,application/msword" -->
              </div>
              <div class="tips">
                提示：收集电话只为方便联系您，您所需要的产品规格尽量填写清晰，方便后续平台联系您
              </div>
              <div class="btn pointer" @click="fnTi">提交表单</div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'
import {
  limit,
  appDetail,
  doCollect,
  loginAgreement,
  uploadFile,
  appOrder,
  tdkPages,
  appList,
} from '../api'
export default {
  components: { Page, Header, Footer, right },
  data() {
    return {
      data: [],
      page: 1,
      limit: limit,
      total: 0,
      dialogVisible: false,
      demo: '',
      name: '',
      phone: '',
      product: '',
      spec: '',
      file: '',
      app_id: '',
      liApp: {
        app_image: '',
      },
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
      appList1: [],
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    fnAppList() {
      //应用领域
      let params = {}
      appList(params).then((data) => {
        if (data.status == 200) {
          this.appList1 = data.msg
        }
      })
    },
    fnChangeAppId(app_id) {
      this.app_id = app_id
      this.page = 1
      this.initData()
      this.$router.push({
        name: 'Hyyx',
        params: {
          id: app_id + '.html',
        },
      })
    },
    fnCollect(goods_id) {
      if (!localStorage.getItem('allUser')) {
        this.$bus.$emit('login', 1)
        return
      }
      // if (num == 2) {
      //   this.dialogVisible111 = true
      //   return
      // }
      let params = {
        goods_id: goods_id,
      }
      doCollect(params).then((data) => {
        if (data.status == 200) {
          this.initData()
          // this.data.is_collect = num == 1 ? 2 : 1
        }
      })
    },
    fnGetTdk(type) {
      let params = {
        type: type,
      }
      tdkPages(params).then((data) => {
        if (data.status == 200) {
          this.tdkTitle = data.msg.title || '本牛'
          this.tdkKeywords = data.msg.keyword || '本牛'
          this.tdkDescription = data.msg.desc || '本牛'
        }
      })
    },
    initData() {
      //应用领域
      let params = {
        app_id: this.app_id,
        page: this.page,
      }
      appDetail(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count
          this.liApp = data.app
        }
      })
    },
    fnTi() {
      if (!localStorage.getItem('allUser')) {
        this.$bus.$emit('login', 1)
        return
      }
      if (!this.name) {
        this.$message.error('请输入姓名')
        return
      }
      if (!this.phone) {
        this.$message.error('请输入联系电话')
        return
      }
      // if (!this.product) {
      //   this.$message.error('请输入产品')
      //   return
      // }
      let params = {
        name: this.name,
        phone: this.phone,
        product: this.product,
        spec: this.spec,
        file: this.file,
      }
      appOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success('提交成功')
          this.dialogVisible = false
          this.name = ''
          this.phone = ''
          this.product = ''
          this.spec = ''
          this.file = ''
        }
      })
    },
    uploadData(e) {
      let files = e.target.files[0]
      let formData = new FormData()
      formData.append('file', files)
      // console.log(files)
      let _self = this
      return new Promise((resolve, reject) => {
        uploadFile(formData)
          .then((response) => {
            this.file = response.msg
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fnWrite() {
      this.dialogVisible = true
      this.fnAgreement(9)
      // if (localStorage.getItem('allUser')) {
      //   this.dialogVisible = true
      //   this.fnAgreement(9)
      // } else {
      //   this.$bus.$emit('login', 1)
      // }
    },
    fnAgreement(type) {
      let params = {
        type: type,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          if (type == 9) {
            this.demo = data.msg.content1 || ''
          }
          // if (type == 10) {
          //   this.phoneHeader = data.msg.content1 || ''
          //   localStorage.setItem('phoneHeader', data.msg.content1)
          // }
        }
      })
    },
    // fnCollect() {
    //   //收藏、取消收藏
    //   let params = {
    //     goods_id:goods_id,
    //   }
    //   doCollect(params).then((data) => {
    //     if (data.status == 200) {
    //       this.data = data.msg
    //       this.total = data.count
    //     }
    //   })
    // },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      this.initData()
    },
  },
  mounted() {
    let can = this.$route.params.id.split('.')[0]
    this.app_id = can
    this.initData()
    this.fnGetTdk(4)
    this.fnAppList()
  },
}
</script>

<style scoped>
.s_cate_title {
  width: 119px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  box-sizing: border-box;
  padding-right: 31px;
  line-height: 56px;
}
.fl:hover {
  color: #ec7902;
}
.cate_out {
  flex-flow: row wrap;
  min-height: 56px;
  /* margin-bottom:20px; */
}
.cate_out div {
  line-height: 56px;
  margin-left: 19px;
}
.input1::file-selector-button {
  width: 101px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 3px;
  margin-right: 18px;
  cursor: pointer;
}
.input1::-ms-browse {
  width: 101px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 3px;
  cursor: pointer;
}

.input1 {
  font-size: 18px;
  font-weight: 400;
  color: #6eaaf9;
}
.down1 {
  height: 18px;
  font-size: 18px;
  font-weight: 400;
  color: #6eaaf9;
  /* line-height: 18px ; */
  text-decoration: none;
}
.buy1 {
  width: 168px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #ec7902;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #ec7902;
  box-sizing: border-box;
  padding-left: 37px;
  line-height: 29px;
}
.buy1:hover {
  color: #ffffff;
  background: #ec7902;
}
.buy1 .shan {
  display: inline-block;
  width: 12px;
  height: 16px;
  background: url('@/assets/buy2.png') no-repeat center center;
  background-size: 100% 100%;
  margin-right: 10px;
  margin-top: 2px;
}
.buy1:hover .shan {
  background: url('@/assets/buy1.png') no-repeat center center;
  background-size: 100% 100%;
}
.collect1 {
  width: 37px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #ec7902;
  border-radius: 5px;
  box-sizing: border-box;
}
.collect1:hover {
  background: url('@/assets/collect3.png') no-repeat center center;
  background-size: 17px 15px;
}
.collect1:hover .collect2 {
  display: none;
}

.two {
  position: relative;
  display: inline-block;
  width: calc((100% - 40px) / 5);
  height: 274px;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin-right: 10px;
  padding: 6px 8px;
  margin-bottom: 10px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: transform 0.5s, box-shadow 0.5s;
}

.two:hover {
  border: 0;
  transform: scale(1.02);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.twoImg {
  display: block;
  width: 126px;
  height: 126px;
  margin: 0 auto 1px;
}
.two:nth-child(5n + 5) {
  margin-right: 0;
}
.price1 {
  font-size: 12px;
  font-weight: 400;
}
.twoTitle {
  font-size: 16px;
  color: #333333;
  line-height: 20px;
  height: 41px;
  margin: 0 0 20px;
}
.price2 {
  /* font-size: 18px;
  color: #c52b33;
  margin-top: 4px;
  line-height: 25px;
  height: 27px; */

  font-size: 24px;
  font-weight: 500;
  color: #ec7902;
}
.price3 {
  font-size: 12px;
  font-weight: 400;
  /* color: #333333; */
}
.banner {
  height: 83px;
  width: 1080px;
  margin-top: 10px;
}
.label {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #dea422;
  border-radius: 2px;
  border: 1px solid;
  border-image: linear-gradient(
      90deg,
      rgba(239, 200, 69, 1),
      rgba(220, 160, 30, 1)
    )
    1 1;
  margin-top: 4px;
  padding: 3px 8px;
}

.btn {
  width: 187px;
  height: 64px;
  background: #ff940a;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 64px;
  text-align: center;
  margin: 39px 0 0 376px;
  letter-spacing: 2px;
}

.hyyx_contain {
  /* height: 315px; */
  width: 100%;
  position: relative;
  margin-bottom: 29px;
}
.hyyx_banner {
  position: relative;
}
.hyyx_meng {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.hyyx_txt_out {
  width: 1300px;
  margin: auto;
  font-weight: 400;
  color: #ffffff;
  padding-left: 38px;
  box-sizing: border-box;
}
.write_table {
  width: 217px;
  height: 43px;
  background: #ec7902;
  border-radius: 5px;
  padding-left: 14px;
  box-sizing: border-box;
}
.tips {
  margin-top: 30px;

  font-size: 18px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
}
.btn11 {
  width: 247px;
  height: 34px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 34px;
  text-align: center;
  margin: 22px auto 40px;
}

input[type='tel'],
input[type='text'],
input[type='password'] {
  height: 46px;
  margin: 0;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 400;
  color: #333;
  line-height: 46px;
  background: transparent;
  outline: none;
  border: 1px solid #dcdcdc;
}
input[type='tel']:focus,
input[type='text']:focus,
input[type='password']:focus {
  border: 1px solid #dcdcdc;
}

textarea {
  max-width: 1045px;
  height: 341px;
  border: 1px solid #dcdcdc;
  padding: 10px;
  font-size: 18px;
  color: #333;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0);
  outline: none;
  box-sizing: border-box;
  font-family: '微软雅黑';
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: 16px;
  color: #999999;
}
.blue {
  font-size: 12px;
  font-weight: 400;
  color: #3b9bff;
  text-align: right;
  margin-top: 17px;
}
.kuang {
  margin: auto;
  /* width: 580px; */
}

.title1 {
  padding-top: 68px;
  margin-bottom: 68px;
  text-align: center;
}

.info {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  width: 149px;
  box-sizing: border-box;
  padding-right: 19px;
  text-align: right;
  /* margin-right: 19px; */
}
.mb {
  margin-bottom: 32px;
}

.mb1 {
  align-items: center;
}

.mb2 {
  align-items: baseline;
}

.one {
  /* position: relative; */
  display: inline-block;
  width: calc((100% - 80px) / 5);
  /* height: 302px; */
  background: #ffffff;
  border-radius: 5px;
  margin-right: 20px;
  /* padding: 20px; */
  margin-bottom: 20px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: box-shadow 0.5s;
}
.one:hover {
  /* transform: scale(1.02); */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.one:nth-child(5n) {
  margin-right: 0;
}

.oneImg {
  display: block;
  width: 178px;
  height: 178px;
  margin: 0 auto;
}

.xing {
  display: inline-block;
  font-size: 30px;
  line-height: 15px;
  font-weight: 500;
  position: relative;
  top: 10px;
}

::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #666;
  font-size: 32px;
}
::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: 35px;
  right: 37px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 32px;
}
</style>

<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top: 14px">
      <div>
        <MyInfo :num111="11" />
      </div>

      <div class="my_right flex-con">
        <div class="border_eee">
          <div class="">
            <div class="align-between cheng_head11">
              <div class="font-333-20-bold">收藏产品</div>
            </div>
            <div
              class="flex-wrap font-333-16-bold"
              style="
                text-align: left;
                line-height: 56px;
                border-bottom: 1px solid #eeeeee;
                padding: 0 22px;
              "
            >
              <div style="width: 140px; margin-right: 12px">图例</div>
              <div style="width: 300px">产品名称</div>
              <!-- <div style="width: 160px">产品规格</div> -->
              <div style="width: 180px; text-align: center">价格</div>
              <div class="flex-con"></div>
              <div style="width: 80px; text-align: center">操作</div>
            </div>
          </div>
          <div class="order_box">
            <div
              class="order_item pointer"
              v-for="(item, index) in data"
              :key="'goods_' + item.goods_id + '_' + index"
              @click="fnGoodsDetails(item.goods_id)"
            >
              <div
                class="flex-wrap font-333-16"
                :style="
                  index == data.length - 1
                    ? 'padding:8px 0;'
                    : 'border-bottom: 1px solid #EEEEEE;padding:8px 0;'
                "
              >
                <el-image
                  style="width: 140px; height: 140px; margin-right: 12px"
                  :src="item.goods_thumb"
                  fit="cover"
                ></el-image>
                <div style="width: 300px">
                  {{ item.goods_name || '' }}
                </div>
                <div style="width: 180px" class="align-center font-333-16-bold">
                  {{
                    item.goods_floor_price > 0 ? item.goods_floor_price : '面议'
                  }}
                </div>
                <div class="flex-con"></div>
                <div
                  style="width: 80px; text-align: center"
                  class="order_status align-center"
                >
                  <div>
                    <div
                      class="font-999-16 pointer"
                      @click.stop="fnDelete(item.goods_id)"
                    >
                      取消收藏
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="text-align: center; margin: 160px auto"
              v-show="data.length == 0"
            >
              <el-image
                style="width: 160px; height: 160px; margin-bottom: 16px"
                :src="require('@/assets/quesheng/no_content.png')"
                fit="cover"
              ></el-image>
              <div class="font-666-14">暂无内容~</div>
            </div>
          </div>
        </div>
        <div class="align-end m_t20" v-if="total > 0">
          <Page
            :limit="limit"
            :totalNum="total"
            @onLimit="changeLimit"
            @onPage="changePage"
          />
        </div>

        <el-dialog
          :visible.sync="dialogVisible111"
          width="980px"
          custom-class="confirm_dialog"
        >
          <div class="dialog" style="padding: 0">
            <div class="dialog_title align-start"> <img :src="require('@/assets/tip_delete.png')" alt="" style="width:26px;height:26px;margin-right:18px;">取消收藏</div>
            <div class="font-333-18-bold" style="text-align: center;margin-top: 36px;">
              确认取消吗？
            </div>
            <div class="align-center" style="margin-top: 36px">
              <div
                class="dialog_btn2 align-center pointer"
                @click="dialogVisible111 = false"
              >
                取消
              </div>
              <div class="dialog_btn1 align-center pointer" @click="fnCun">确认</div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->

    <!-- <div style="min-width: 160px">
            <div class="flex-wrap">
              <div class="">
                <div
                  v-for="(item, index) in item.guige_name"
                  :key="'guige_name_' + item.goods_id + '_' + index"
                >
                  {{ item }}：
                </div>
              </div>
              <div class="flex-con">
                <div
                  v-for="(item, index) in item.guige_value"
                  :key="'guige_value_' + item.goods_id + '_' + index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'
import { limit, myCollect, doCollect } from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    MyInfo,
    right,
  },
  data() {
    return {
      data: [],
      page: 1,
      limit: limit,
      total: 0,
      dialogVisible111: false,
      goods_id: '',
    }
  },
  methods: {
    initData() {
      let params = {
        page: this.page,
      }
      myCollect(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count
        }
      })
    },

    fnCun() {
      let params = {
        goods_id: this.goods_id,
      }
      doCollect(params).then((data) => {
        if (data.status == 200) {
          this.initData()
          this.dialogVisible111 = false
        }
      })
    },
    fnDelete(goods_id) {
      this.goods_id = goods_id
      this.dialogVisible111 = true
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      this.initData()
    },
  },
  mounted() {
    document.title = '本牛'
    this.initData()
  },
}
</script>

<style scoped>

::v-deep .confirm_dialog.el-dialog {
  border-radius: 4px;
}
::v-deep .confirm_dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 42px;
}
::v-deep .confirm_dialog .el-dialog__header {
  padding: 0;
}
::v-deep .confirm_dialog .el-dialog__headerbtn {
  position: absolute;
  top: 22px;
  right: 40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 24px;
}
::v-deep .confirm_dialog .el-dialog__headerbtn .el-dialog__close {
  color: #979797;
}
.order_status div {
  margin-top: 6px;
}
.order_status div:first-child {
  margin: 0;
}
.order_header {
  height: 60px;
  padding: 0 26px;
  border-bottom: 1px solid #d8d8d8;
}
.order_box {
  background: #ffffff;
  /* border-radius: 4px; */
  /* min-height: 514px; */
  box-sizing: border-box;
  padding: 8px 22px 0;
}
.order_item {
  /* margin-bottom: 16px; */
}

.border111 {
  border-bottom: 1px solid #eeeeee;
}

.border111:last-child {
  border-bottom: none;
}
.my_order {
  /* background: #ffffff;
  border-radius: 4px;
  padding: 20px 26px;
  font-size: 14px;
  margin-bottom: 10px; */
}

input[type='text'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-right: none;
  box-sizing: border-box;
  height: 24px;
  width: 125px;
  padding: 0 12px;
  color: #333333;
  font-size: 14px;
  outline: none;
}
input[type='text']::-webkit-input-placeholder {
  color: #aaa;
}
input[type='text']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-right: none;
}
.search_txt {
  font-size: 14px;
  color: #203398;
  width: 52px;
  height: 24px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  color: #ffffff;
}
.btn_padding {
  padding: 1px 11px;
  margin-left: 10px;
  border: 1px solid #d8d8d8;
}
.ljzf {
  padding: 1px 11px;
  border: 1px solid #c52b33;
  display: inline-block;
}
.C52B33 {
  color: #c52b33;
}
</style>

<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top: 14px">
      <div>
        <MyInfo :num111="14" />
      </div>

      <div class="my_right flex-con">
        <div class="border_eee">
        <div class="">
          <div class="align-between cheng_head11">
              <div class="font-333-20-bold">收益明细</div>
            </div>
          <div
            class="flex-wrap font-333-16-bold"
            style="text-align: left; line-height: 56px;
                border-bottom: 1px solid #eeeeee;
                padding: 0 22px;"
          >
            <div style="width: 146px">用户名称</div>
            <div style="width: 176px">手机号</div>
            <div style="width: 235px">订单号</div>
            <div style="width: 180px; text-align: center">订单总额</div>
            <div class="flex-con"></div>
            <div style="width: 80px; text-align: center">佣金</div>
          </div>
        </div>
        <div class="order_box">
          <div
            class="order_item"
            v-for="(item, index) in data"
            :key="'come_' + index"
          >
            <div class="flex-wrap font-333-16">
              <div style="width: 146px">{{ item.user_username || '' }}</div>
              <div style="width: 176px" class="font-666-16">{{ item.user_phone || '' }}</div>
              <div style="width: 235px" class="font-666-16">{{ item.order_number || '' }}</div>
              <div style="width: 180px" class="align-center">
                {{ item.order_price || '0' }}
              </div>
              <div class="flex-con"></div>
              <div style="width: 80px" class="font-333-16-bold align-center">
                {{ item.tc_price || '0' }}
              </div>
            </div>
          </div>
          <div
            style="text-align: center; margin: 160px auto"
            v-show="data.length == 0"
          >
            <el-image
              style="width: 160px; height: 160px; margin-bottom: 16px"
              :src="require('@/assets/quesheng/no_content.png')"
              fit="cover"
            ></el-image>
            <div class="font-666-14">暂无内容~</div>
          </div>
        </div>
      </div>
        <div class="align-end m_t20" v-if="total > 0">
          <Page
            :limit="limit"
            :totalNum="total"
            @onLimit="changeLimit"
            @onPage="changePage"
          />
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'
import { limit, tiChengList } from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    MyInfo,
    right
  },
  data() {
    return {
      data: [],
      page: 1,
      limit: limit,
      total: 0,
    }
  },
  methods: {
    initData() {
      let params = {
        page: this.page,
      }
      tiChengList(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count||1
        }
      })
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      this.initData()
    },
  },
  mounted() {
    document.title = '本牛'
    this.initData()
  },
}
</script>

<style scoped>
.order_box {
  background: #ffffff;
  /* border-radius: 4px; */
  /* min-height: 514px; */
  box-sizing: border-box;
  padding: 22px 22px 0;
}
.order_item {
  margin-bottom: 22px;
}
.order_box > .order_item:last-child {
  margin-bottom: 0;
}
.my_order {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 26px;
  font-size: 14px;
  margin-bottom: 10px;
}
.C52B33 {
  color: #c52b33;
}
</style>

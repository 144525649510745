<template>
  <div>
    <Header />
    <div class="middle1 align-start-top" style="margin-top: 15px">
      <div class="my_left">
        <div class="my_zx font-fff-20-bold align-center">帮助中心</div>
        <div class="my_menu">
          <div v-for="(item, index) in helpList" :key="'help_' + index">
            <div
              class="align-between order1 pointer"
              :class="{ order1_active: !item.choose }"
              @click="fnSwitch(item)"
              @mouseenter="mouseenterOut(item)"
              @mouseleave="mouseleaveOut(item)"
            >
            <div class="align-start">
              <el-image
                style="width: 26px; height: 26px; margin: 0 22px 0 26px"
                :src="
                  !item.choose || item.hover
                    ? item.pt_icon_yes
                    : item.pt_icon_no
                "
                fit="cover"
              ></el-image>
              <div class="">{{ item.pt_name || '' }}</div>
            </div>
              
              <el-image
                :style="
                  !item.choose
                    ? 'width: 11px; height: 7px;'
                    : 'width: 7px; height: 11px;'
                "
                :src="
                  !item.choose
                    ? require('@/assets/bottom_arrow2.png')
                    : require('@/assets/arrow_right3.png')
                "
                fit="cover"
              ></el-image>
            </div>
            <div style="padding-left: 79px" class="pointer" v-if="!item.choose">
              <div
                class="order1_item font-666-18 align-start"
                v-for="(item, index) in item.son"
                :key="'problem_' + index"
                @click="fnXq(item.problem_id)"
                :class="{ FF940A: problem_id == item.problem_id }"
              >
                {{ item.problem_title || '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="my_right">
        <div class="help_head">{{ helpObj.problem_title||'' }}</div>
        <div v-html="helpObj.problem_content" class="fwb"></div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { getHelps, getHelpInfo } from '../api'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  data() {
    return {
      helpList: [],
      problem_id: '',
      helpObj:{}
    }
  },
  methods: {
    initData() {
      let _this = this
      let params = {}
      getHelps(params).then((data) => {
        if (data.status == 200) {
          _this.helpList = data.msg
        }
      })
    },
    fnXq(idd) {
      this.problem_id = idd
      let params = {
        problem_id: idd,
      }
      getHelpInfo(params).then((data) => {
        if (data.status == 200) {
          this.helpObj = data.msg
          this.$router.push({
            name: 'help',
            params: {
              id: idd + '.html',
            },
          })
        }
      })
    },
    mouseenterOut(num1) {
      num1.hover = true
      this.$forceUpdate()
    },
    mouseleaveOut(num1) {
      num1.hover = false
      this.$forceUpdate()
    },
    fnSwitch(num1) {
      num1.choose = !num1.choose
      this.$forceUpdate()
    },
  },
  mounted() {
    document.title = '本牛'
    let can = this.$route.params.id.split('.')[0].split('-')
    this.problem_id = can[0]
    this.initData()
    this.fnXq(this.problem_id)
  },
}
</script>
<style scoped>

.help_head {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid rgba(240, 244, 248, 0.5);
  line-height: 63px;
  padding: 0 60px;

}
.help_head img {
  width: 44px;
  height: 40px;
  display: block;
  margin-right: 25px;
  position: relative;
  top: 2px;
}


.fwb {
  padding:10px 60px 30px;
}

.order1 {
  height: 48px;
  font-size: 18px;
  color: #666;
  font-weight: 500;
  border-left: 4px solid #fff;
  padding-right:35px;
}
.order1_active {
  border-left: 4px solid #ff940a;
  color: #ff940a;
  background-color: rgba(255, 226, 189, 0.2);
}
.order1:hover {
  border-left: 4px solid #ff940a;
  color: #ff940a;
  background-color: rgba(255, 226, 189, 0.2);
}
.order1_item {
  height: 48px;
}
.my_left {
  width: 256px;
  min-width:256px;
  background: #ffffff;
  text-align: left;
  margin-right: 16px;
  padding-bottom: 20px;
}
.my_zx {
  height: 63px;

  background: #fa9c22;
  margin-bottom: 20px;
}
.my_menu {
  /* padding: 0 0 0 60px; */
}
.my_right {
  padding: 0;
}
.myTab:hover {
  color: #ff940a;
}
.order1_item:hover {
  color: #ff940a;
}
</style>

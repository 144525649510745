<template>
  <div>
    <Header :num111="5" />
    <img
      :src="allBg"
      alt=""
      style="width: 100%; height: 400px;display: block;"
    />
    <div class="middle1">
      <!-- <div class="toppp flex-wrap">
      <div @click="toMain()">首页</div>
      <img src="../assets/arrow1.png" class="topppImg" />
      <div>内容资讯</div>
      <img src="../assets/arrow1.png" class="topppImg" />
      <div>本牛活动</div>
      <img src="../assets/arrow1.png" class="topppImg" />
      <div>{{ title }}</div>
    </div> -->
      <div class="fwb">
        <div
          class="font-333-30-bold"
          style="text-align: center; margin-bottom: 25px; letter-spacing: 0px"
        >
          {{ title }}
        </div>
        <div
          class="font-666-16"
          v-html="data"
          style="
            line-height: 40px;
            padding-bottom: 90px;
            border-bottom: 1px dashed #c9c9c9;
            margin-bottom: 74px;
          "
        ></div>

        <div class="mb align-start">
          <div class="align-start mb1">
            <div class="info" style="width: 85px">
              <span class="FF940A m_r5 xing" v-show="!apply">*</span>姓名:
            </div>
            <input
              type="text"
              name=""
              value=""
              v-model="user_name"
              placeholder="请输入姓名"
              :readonly="apply"
              :class="{ border_none: apply }"
              style="width: 402px"
            />
          </div>
          <div class="mb1 align-start">
            <div class="info" style="width: 242px">
              <span class="FF940A m_r5 xing" v-show="!apply">*</span>手机号:
            </div>
            <input
              type="text"
              name=""
              value=""
              v-model="phone"
              placeholder="请输入手机号"
              :readonly="apply"
              :class="{ border_none: apply }"
              style="width: 402px"
            />
          </div>
        </div>

        <div class="mb mb1 align-start" v-show="!apply">
          <div class="info">公司:</div>
          <input
            type="text"
            name=""
            value=""
            v-model="company"
            placeholder="请输入公司名称"
            :readonly="apply"
            style="width: 1045px"
          />
        </div>

        <div class="mb mb2 flex-wrap" v-show="!apply">
          <div class="info">
            <span style="color: rgba(0, 0, 0, 0)">*</span>备注:
          </div>
          <textarea
            class="flex-con"
            placeholder=""
            v-model="remark"
            :readonly="apply"
          ></textarea>
        </div>

        <div
          class="btn align-center pointer bg_f08300"
          :class="{ bggg: apply }"
          @click="fnTi"
        >
          {{ apply ? '已报名' : '提交' }}
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { activityDetail, applyActivity,loginAgreement } from '../api'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  data() {
    return {
      data: '',
      title: '',
      user_name: '',
      phone: '',
      remark: '',
      activity_id: '',
      company: '',
      apply: false,
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
      allBg:''
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    initData() {
      let params = {
        activity_id: this.activity_id,
      }
      activityDetail(params).then((data) => {
        if (data.status == 200) {
          this.tdkTitle = data.msg.activity_tdk_title || '本牛'
          this.tdkKeywords = data.msg.activity_keyword || '本牛'
          this.tdkDescription = data.msg.activity_desc || '本牛'
          this.data = data.msg ? data.msg.activity_content : ''
          this.title = data.msg.activity_title || ''
          this.apply = false
          if (data.apply) {
            this.apply = true
            this.user_name = data.apply.aa_username
            this.phone = data.apply.aa_phone
            this.remark = data.apply.aa_remark
            this.company = data.apply.aa_company
          }
        }
      })
    },
    fnTi() {
      if (this.apply) {
        return
      }
      if (!localStorage.getItem('allUser')) {
        this.$bus.$emit('login', 1)
        return
      }
      if (!this.user_name) {
        this.$message.error('请输入姓名')
        return
      }
      if (!this.phone) {
        this.$message.error('请输入手机号')
        return
      }
      let params = {
        user_name: this.user_name,
        phone: this.phone,
        company: this.company,
        remark: this.remark,
        activity_id: this.activity_id,
      }
      applyActivity(params).then((data) => {
        if (data.status == 200) {
          this.$message.success('报名成功')
          this.initData()
        }
      })
    },
    fnLoginAgreement(type) {
      let params = {
        type: type,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          this.allBg = data.msg.content1 || ''
          // this.tuiUrl = data.msg.content2 || ''
        }
      })
    },
  },
  mounted() {
    let can = this.$route.params.id.split('.')[0]
    this.activity_id = can
    this.initData()
    this.fnLoginAgreement(11)
  },
}
</script>
<style scoped>
.toppp {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  margin: 20px 0;
  align-items: center;
}

.topppImg {
  width: 20px;
  height: 20px;
  margin: 0 4px;
}
.fwb {
  height: auto;
  background: #ffffff;
  /* border-radius: 4px; */
  padding: 52px 67px 89px 83px;
  box-sizing: border-box;
}
input[type='tel'],
input[type='text'],
input[type='password'] {
  height: 46px;
  margin: 0;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 400;
  color: #333;
  line-height: 46px;
  background: transparent;
  outline: none;
  border: 1px solid #dcdcdc;
}
input[type='tel']:focus,
input[type='text']:focus,
input[type='password']:focus {
  border: 1px solid #dcdcdc;
}

textarea {
  max-width: 1045px;
  height: 341px;
  border: 1px solid #dcdcdc;
  padding: 10px;
  font-size: 18px;
  color: #333;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0);
  outline: none;
  box-sizing: border-box;
  font-family: '微软雅黑';
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: 16px;
  color: #999999;
}

.btn {
  width: 187px;
  height: 64px;
  background: #ff940a;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 64px;
  text-align: center;
  margin: 39px auto 0;
  letter-spacing: 2px;
}
.width66 {
  min-width: 70px;
  text-align: right;
  margin-right: 16px;
}
.bggg {
  background: rgb(240, 131, 0, 0.4) !important;
}
.border_none {
  border: none !important;
}
.info {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  width: 85px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;
  /* margin-right: 19px; */
}

.mb {
  margin-bottom: 32px;
}
</style>

<template>
  <div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="pageSizes" :page-size="limit" layout="prev, pager, next, jumper"
        :total="totalNum">
    </el-pagination>
  </div>
</template>

<script>
export default {
    props: {
        limit: {
            type:Number
        },
        totalNum:{
            type:Number
        },
    },
    data() {
        return {
            currentPage: 1,
            pageSizes:[10, 20, 30, 40]
        }
    },
    methods: {
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.$emit('onLimit',val);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.$emit('onPage',val);
        }
    }
}
</script>

<style scoped>
::v-deep  .el-pager li {
    padding: 0 4px;
    background: rgba(0,0,0,0);
    font-size: 13px;
    min-width: 35.5px;
    height: 28px;
    line-height: 28px;
    box-sizing: border-box;
    text-align: center;
}
::v-deep .el-pager li.active {
    color: #f08300;
    cursor: default;
}

::v-deep .el-pagination button:disabled {
    color: #C0C4CC;
    background-color: rgba(0,0,0,0);
    cursor: not-allowed;
}

::v-deep .el-pagination .btn-next,::v-deep .el-pagination .btn-prev {
    background: center center no-repeat rgba(0,0,0,0);
    background-size: 16px;
    cursor: pointer;
    margin: 0;
    color: #303133;
}

::v-deep .el-input__inner {
    -webkit-appearance: none;
    background-color:rgba(0,0,0,0);
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
}
</style>

<template>
  <div>
    <div class="head_contain">
      <div class="head_bg align-between">
        <!-- <div style="width:46px;"></div> -->
        <div class="head_in" style="padding: 12px 0">
          <div class="head_top align-between">
            <div>
              <span>HI，欢迎来到本牛电商平台！</span>
              <span class="blue pointer" @click="toLogin()" v-if="!allUser"
                >注册/登录</span
              >
              <span class="blue pointer" @click="fnTui" v-else>退出登录</span>
            </div>
            <div class="align-center">
              <div class="align-center pointer" @click="fnGrzx">
                <!-- <el-image
                  style="width: 24px; height: 24px; margin: 0 6px 0 30px"
                  :src="require('@/assets/grzx_white.png')"
                  fit="cover"
                ></el-image> -->
                <img
                  style="width: 24px; height: 24px; margin: 0 6px 0 30px"
                  :src="require('@/assets/grzx_white.png')"
                  fit="cover"
                  alt=""
                />
                <span>个人中心</span>
              </div>
              <div class="align-center" pointer>
                <!-- <el-image
                  style="width: 24px; height: 24px; margin: 0 6px 0 30px"
                  :src="require('@/assets/zxkf_white.png')"
                  fit="cover"
                ></el-image> -->
                <img
                  style="width: 24px; height: 24px; margin: 0 6px 0 30px"
                  :src="require('@/assets/zxkf_white.png')"
                  fit="cover"
                  alt=""
                />
                <span
                  ><a
                    href="https://1800498.s4.udesk.cn/im_client/?web_plugin_id=63975"
                    style="color: #fff; text-decoration: none"
                    target="_blank"
                    >在线客服</a
                  ></span
                >
              </div>
              <div class="align-center">
                <!-- <el-image
                  style="width: 24px; height: 24px; margin: 0 6px 0 30px"
                  :src="require('@/assets/phone1_white.png')"
                  fit="cover"
                ></el-image> -->
                <img
                  style="width: 24px; height: 24px; margin: 0 6px 0 30px"
                  :src="require('@/assets/phone1_white.png')"
                  fit="cover"
                  alt=""
                />
                <span style="text-decoration: underLine; min-width: 82px">{{
                  phoneHeader
                }}</span>
              </div>
            </div>
          </div>
          <div class="flex-wrap">
            <!-- <el-image
              style="width: 208px; height: 72px"
              :src="require('@/assets/logo_1.png')"
              fit="cover"
              class="pointer"
              @click="toMain()"
            ></el-image> -->
            <img
              style="width: 208px; height: 72px"
              :src="require('@/assets/logo_1.png')"
              fit="cover"
              class="pointer"
              @click="toMain()"
              alt=""
            />

            <div class="flex-con"></div>
            <!-- <div style="width: 460px" v-show="payShow">
            <div class="flex-wrap" style="text-align: center">
              <div class="flex-con">
                <div class="order_step active align-center">1</div>
                <div class="fff" style="margin-top: 4px">我的购物车</div>
              </div>
              <div class="flex-con">
                <div class="step_out">
                  <div class="order_step active align-center">2</div>
                  <span class="step_xian active"></span>
                </div>
                <div class="fff" style="margin-top: 4px">我的订单</div>
              </div>
              <div class="flex-con">
                <div class="step_out">
                  <div class="order_step active align-center">3</div>
                  <span class="step_xian active"></span>
                </div>
                <div class="fff" style="margin-top: 4px">完成</div>
              </div>
            </div>
          </div> -->

            <div>
              <div class="head_search align-center">
                <input
                  type="text"
                  name=""
                  value=""
                  v-model="keyword"
                  placeholder="请输入商品名称搜索"
                  @keyup.enter="fnSearch(0)"
                />
                <div class="search_icon align-center pointer" @click="fnSearch(0)">
                  <!-- <el-image
                    style="width: 24px; height: 24px; margin-right: 6px"
                    :src="require('@/assets/head_search.png')"
                    fit="cover"
                  ></el-image> -->
                  <img
                    style="width: 24px; height: 24px; margin-right: 6px"
                    :src="require('@/assets/head_search.png')"
                    fit="cover"
                    alt=""
                  />
                  <span class="search_txt">搜索</span>
                </div>
              </div>
              <div class="hot_search">
                热门搜索：<span
                  v-for="(item, index) in hotSearch"
                  :key="'hot_' + index"
                  @click="fnHot(item.hs_name)"
                  class="pointer"
                  >{{ item.hs_name || "" }} &nbsp;
                </span>
              </div>
            </div>
            <div class="flex-con"></div>
            <div class="head_shop align-center pointer" @click="fnCar">
              <!-- <el-image
                style="width: 24px; height: 24px"
                :src="require('@/assets/shop_car2.png')"
                fit="cover"
              ></el-image> -->
              <img
                style="width: 24px; height: 24px"
                :src="require('@/assets/shop_car2.png')"
                fit="cover"
                alt=""
              />
              <span class="shop_car">购物车</span>
              <span class="shop_num align-center">{{ shopCarNumber || 0 }}</span>
            </div>
            <div class="flex-con"></div>
          </div>
        </div>
        <!-- <div class="" style="height: 100%; position: relative; top: -42px">
          <a
            class="gjz pointer"
            href="https://niutop.xuanwumobile.com/en"
            target="_blank"
            >国际站</a
          >
          <a
            class="dp pointer"
            href="https://niutop.xuanwumobile.com/pc/#/Shop?store_id=1"
            >店铺</a
          >
        </div> -->
      </div>
      <!-- @click="fnShop" -->
      <!-- href="http://localhost:8080/#/Shop" -->
      <!-- https://niutop.xuanwumobile.com/Shop -->
    </div>
    <div class="head_tab" v-show="!payShow">
      <div class="head_in flex-wrap">
        <div
          class="tab1 align-center"
          @mouseenter="mouseenterOutFl()"
          @mouseleave="mouseleaveOutFl()"
        >
          <!-- <el-image
            style="width: 24px; height: 24px; margin-right: 6px"
            :src="require('@/assets/cpfl.png')"
            fit="cover"
          ></el-image> -->
          <img
            style="width: 24px; height: 24px; margin-right: 6px"
            :src="require('@/assets/cpfl.png')"
            fit="cover"
            alt=""
          />
          <span class="tab_color1">产品分类</span>
          <div
            class="tab1_menu_out"
            @mouseenter="mouseenterOut()"
            @mouseleave="mouseleaveOut()"
            v-show="cateShow"
          >
            <div class="tab1_menu">
              <div
                class="menu_item pointer"
                @mouseenter="mouseenter(index, item)"
                @mouseleave="mouseleave(index, item)"
                v-for="(item, index) in liGoodsCate"
                :key="'first_' + index"
                @click="fnSearch(1, item.gc_url_one)"
              >
                <div class="align-start" style="height: 26px">
                  <el-image
                    style="width: 20px; height: 20px; margin-right: 6px"
                    :src="isHover == index ? item.gc_icon1 : item.gc_icon"
                    fit="cover"
                  ></el-image>
                  <span class="font_bold">{{ item.gc_name || "" }}</span>
                </div>
                <!-- <div class="font_small">
                  <span
                    v-for="(item, index) in item.secondCate"
                    :key="'second_' + index"
                    >{{ item.gc_name || '' }}</span
                  >
                </div> -->
              </div>
            </div>
            <div class="item_hover flex-wrap" v-show="showHover">
              <div class="item_hover_left flex-con">
                <div
                  class="left1 align-top pointer"
                  v-for="(item, index) in data.goodsCate"
                  :key="'second_all_' + index"
                  @click="fnSearch(2, item.gc_url_one, item.gc_url_two)"
                >
                  <div
                    class="align-end-top"
                    style="line-height: 20px; width: 200px; min-width: 200px"
                  >
                    <span
                      class="font_333"
                      style="margin-bottom: 10px; text-align: right"
                      >{{ item.gc_name || "" }}</span
                    >
                    <div style="width: 18px; height: 18px; margin: 0 24px 0 4px">
                      <el-image
                        style="width: 18px; height: 18px; margin: 0 24px 0 4px"
                        :src="require('@/assets/right_arrow1.png')"
                        fit="cover"
                        v-show="item.thirdCate.length > 0"
                      ></el-image>
                    </div>
                  </div>
                  <div class="font_small1 after">
                    <div
                      v-for="(item, index) in item.thirdCate"
                      :key="'third_' + index"
                      class="third_txt pointer"
                      @click.stop="
                        fnSearch(3, item.gc_url_one, item.gc_url_two, item.gc_url_three)
                      "
                    >
                      {{ item.gc_name || "" }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="item_hover_right" style="width: 90px">
                <el-image
                  style="width: 90px; height: 90px; display: block"
                  :src="item"
                  fit="cover"
                  v-for="(item, index) in data.images"
                  :key="'second_img_' + index"
                ></el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-con align-center">
          <div
            class="tab2 align-center pointer"
            :class="{ active: index == tab2Index }"
            @click="fnTab2(index)"
            v-for="(item, index) in tab2Item"
            :key="'tab2_' + index"
          >
            <div class="tab2_item">
              {{ item }}
              <span class="xian"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  goodsCate,
  loginAgreement,
  uploadFile,
  appOrder,
  mycarNum,
  indexData,
} from "../api";
export default {
  props: {
    num111: {
      Type: Number,
      default: 999,
    },
  },
  data() {
    return {
      keyword: "",
      payShow: false,
      cateShow: true,
      cateShowMain: true,
      tab2Index: -1,
      tab2Item: ["首页", "尾货清仓", "热卖产品", "行业优选", "品牌定制", "内容资讯"],
      liGoodsCate: JSON.parse(localStorage.getItem("goodsCate")),
      hotSearch: JSON.parse(localStorage.getItem("hotSearch")),
      shopCarNumber: localStorage.getItem("shopCarNumber"),
      isHover: -1,
      data: [],
      first_cate_id: "",
      first_cate_name: "",
      showHover: false,
      // liApp: JSON.parse(localStorage.getItem('liApp')) || {
      //   app_image: '',
      // },
      allUser: localStorage.getItem("allUser")
        ? JSON.parse(localStorage.getItem("allUser")).user_id
        : "",
      phoneHeader: "",
      searchBox: true,
    };
  },

  methods: {
    initData() {
      let params = {
        first_cate_id: this.first_cate_id,
      };
      goodsCate(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg;
        }
      });
    },
    mouseenter(index, item) {
      this.isHover = index;
      this.first_cate_id = item.gc_id;
      this.first_cate_name = item.gc_name;
      this.initData();
      // console.log(index + '||' + JSON.stringify(item))
    },
    mouseleave(index, item) {
      this.isHover = -1;
    },
    mouseenterOut() {
      this.showHover = true;
    },
    mouseleaveOut() {
      this.showHover = false;
    },
    mouseenterOutFl() {
      if (!this.cateShowMain) {
        this.cateShow = true;
      }
    },
    mouseleaveOutFl() {
      if (!this.cateShowMain) {
        this.cateShow = false;
      }
    },
    fnTab2(index) {
      this.keyword = "";
      this.tab2Index = index;
      let tab2Url = ["Main", "Whqc", "HotGoods", "Selection", "Brand", "zixun"];
      // if (index == 4) {
      //   if (!localStorage.getItem('allUser')) {
      //     this.$bus.$emit('login', 1)
      //     return
      //   }
      // }
      if (index == 1) {
        this.$router.push({
          path: `/sale.html`,
        });
        // this.$router.push({
        //   name: tab2Url[index],
        //   params: {},
        // });
      } else if (index == 2) {
        this.$router.push({
          path: `/hotsales.html`,
        });
        // this.$router.push({
        //   name: tab2Url[index],
        //   params: {},
        // });
      } else {
        this.$router.push({
          name: tab2Url[index],
          params: {
            // num: index,
            id: ".html",
          },
        });
      }
    },

    fnRegister() {
      this.$router.push({
        name: "Register",
        params: {},
      });
    },
    fnLogin() {
      this.$router.push({
        name: "Login",
        params: {},
      });
    },
    fnGrzx() {
      // this.$router.push('/MyInfo/MyActive')
      // return;
      if (localStorage.getItem("allUser")) {
        this.$router.push({
          name: "OrderZx",
          params: {
            id: 0 + ".html",
          },
        });
      } else {
        this.$bus.$emit("login", 1);
        // this.$router.push('/Login')
      }
    },
    fnSearch(type, one, two, three) {
      if (
        this.$router.history.current.name == "HotGoods" ||
        this.$router.history.current.name == "Whqc" ||
        this.$router.history.current.name == "Recommend" ||
        this.$router.history.current.name == "Search"
      ) {
        if (this.$router.history.current.name == "Search") {
          if (type != 0) {
            if (type == 1) {
              this.$router.push({
                path: `/${one}`,
              });
            }
            if (type == 2) {
              this.$router.push({
                path: `/${one}/${two}`,
              });
            }
            if (type == 3) {
              this.$router.push({
                path: `/${one}/${two}/${three}`,
              });
            }
            this.$bus.$emit("cate_id");
            this.cateShow = false;
          } else {
            if (this.keyword) {
              // console.log(this.$route);
              // this.$router.push({
              //   path: `/search`,
              //   query: {
              //     keyword: this.keyword,
              //   },
              // });
              this.$bus.$emit("keyword", this.keyword);
            } else {
              // this.$router.push({
              //   path: `/search`,
              // })
              this.$message.error("请输入搜索内容");
            }
          }
        } else {
          if (type != 0) {
            if (type == 1) {
              this.$router.push({
                path: `/${one}`,
              });
            }
            if (type == 2) {
              this.$router.push({
                path: `/${one}/${two}`,
              });
            }
            if (type == 3) {
              this.$router.push({
                path: `/${one}/${two}/${three}`,
              });
            }
          } else {
            // let search = 'Search'
            // this.$router.push({
            //   path: `/list`,
            //   query: {
            //     keyword: this.keyword,
            //   },
            // })
            // this.$bus.$emit('keyword', this.keyword)
            if (this.keyword) {
              this.$bus.$emit("keyword", this.keyword);
            } else {
              this.$message.error("请输入搜索内容");
            }
          }
        }
        return;
      } else {
        if (type == 0) {
          if (this.keyword) {
            this.$router.push({
              path: `/search`,
              query: {
                keyword: this.keyword,
              },
            });
            // this.$bus.$emit('keyword', this.keyword)
          } else {
            // this.$router.push({
            //   path: `/search`,
            // })
            this.$message.error("请输入搜索内容");
          }
        }
        if (type == 1) {
          this.$router.push({
            path: `/${one}`,
          });
        }
        if (type == 2) {
          this.$router.push({
            path: `/${one}/${two}`,
          });
        }
        if (type == 3) {
          this.$router.push({
            path: `/${one}/${two}/${three}`,
          });
        }
      }
    },
    // fnSearch(type, cateId1, cateId2) {
    //   // console.log(this.$router.history.current.name)
    //   let idd = ''
    //   if (type == 1) {
    //     idd = type + '-' + this.first_cate_id
    //   }
    //   if (type == 2) {
    //     idd = type + '-' + this.first_cate_id + '-' + cateId1
    //   }
    //   if (type == 3) {
    //     idd = type + '-' + this.first_cate_id + '-' + cateId1 + '-' + cateId2
    //   }
    //   if (
    //     this.$router.history.current.name == 'HotGoods' ||
    //     this.$router.history.current.name == 'Whqc' ||
    //     this.$router.history.current.name == 'Recommend' ||
    //     this.$router.history.current.name == 'Search'
    //   ) {
    //     if (this.$router.history.current.name == 'Search') {
    //       if (type != 0) {
    //         this.$router.push({
    //           name: 'Search',
    //           params: {
    //             id: idd + '.html',
    //             keyword: '',
    //           },
    //         })
    //         this.$bus.$emit('cate_id', idd)
    //         this.cateShow = false
    //       } else {
    //         this.$bus.$emit('keyword', this.keyword)
    //       }
    //     } else {
    //       if (type != 0) {
    //         this.$router.push({
    //           name: 'Search',
    //           params: {
    //             id: idd + '.html',
    //             keyword: '',
    //           },
    //         })
    //       } else {
    //         this.$bus.$emit('keyword', this.keyword)
    //       }
    //     }

    //     return
    //   } else {
    //     this.$router.push({
    //       name: 'Search',
    //       params: {
    //         id: idd + '.html',
    //         keyword: this.keyword,
    //       },
    //     })
    //   }
    // },
    fnHot(hs_name) {
      this.keyword = hs_name;
      this.fnSearch(0);
    },
    fnCar() {
      if (localStorage.getItem("allUser")) {
        this.$router.push({
          name: "Car",
          params: {
            id: ".html",
          },
        });
      } else {
        this.$bus.$emit("login", 1);
        // this.$router.push('/Login')
      }
    },
    fnAgreement(type) {
      let params = {
        type: type,
      };
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          if (type == 9) {
            this.demo = data.msg.content1 || "";
          }
          if (type == 10) {
            this.phoneHeader = data.msg.content1 || "";
            localStorage.setItem("phoneHeader", data.msg.content1);
          }
        }
      });
    },
    fnTui() {
      this.toLogin();
      localStorage.setItem("allUser", "");
    },

    fnCarNum() {
      let params = {};
      mycarNum(params).then((data) => {
        if (data.status == 200) {
          localStorage.setItem("shopCarNumber", data.msg);
          this.shopCarNumber = data.msg;
          // this.$message.success(data.msg)
        }
      });
    },
    initData111() {
      let params = {};
      indexData(params).then((data) => {
        if (data.status == 200) {
          this.liGoodsCate = data.msg.allFirstCate;
        }
      });
    },
    // fnShop(){
    //   this.$router.push({
    //     name: 'Shop',
    //     params: {
    //       id:0
    //     },
    //   })
    // }
  },
  mounted() {
    if (localStorage.getItem("allUser")) {
      let expire = JSON.parse(localStorage.getItem("allUser")).expire;
      let time111 = JSON.parse(localStorage.getItem("allUser")).time111;
      if (Date.now() > expire + time111) {
        localStorage.setItem("allUser", "");
      }
    }
    // console.log(this.$route)
    if (this.$route.query.s) {
      localStorage.setItem("from_source", this.$route.fullPath.split("=")[1]);
      console.log(localStorage.getItem("from_source"));
    }

    this.initData111();
    this.$bus.$on("keywordGet", (val) => {
      this.keyword = val || "";
      // alert(this.keyword)
      // console.log('627||'+this.keyword);
    });
    if (this.num111 != 999) {
      this.tab2Index = this.num111;
    } else {
      if (this.$router.name == "goodDetail") {
        this.tab2Index = "";
        return;
      }
    }
    this.cateShow = this.cateShowMain = this.$route.name == "Main"||this.$route.name == "MainOld" ? true : false;
    if (localStorage.getItem("phoneHeader")) {
      this.phoneHeader = localStorage.getItem("phoneHeader");
    } else {
      this.fnAgreement(10);
    }
    this.$bus.$on("carNum", (val) => {
      this.fnCarNum();
    });
  },
};
</script>

<style scoped>
.item_hover_left::-webkit-scrollbar {
  display: none;
}
.item_hover_left {
  overflow-y: scroll;
}
.left1 {
  margin-bottom: 2px;
}
.item_hover {
  height: 400px;
  width: 734px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  left: 230px;
  top: 10px;
  padding: 16px 10px 2px;
  z-index: 99;
}
.tab1_menu_out {
  position: absolute;
  top: 48px;
  width: 230px;
  height: 410px;
}
.tab1_menu {
  position: absolute;
  top: 0px;
  width: 230px;
  height: 410px;
  background: #fff;
  border-radius: 0px 0px 4px 4px;
  box-sizing: border-box;
  padding: 24px 0 10px;
  z-index: 99;
  /* overflow-y: scroll; */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.tab1_menu::-webkit-scrollbar {
  display: none;
}
.menu_item {
  padding: 2px 8px;
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  /* position: relative; */
}
.menu_item:hover {
  color: #fff;
  /* background: #eec644; */
  background: #f08300;
}

.font_bold {
  font-wight: 500;
  font-size: 14px;
}
.font_333 {
  font-wight: 400;
  font-size: 14px;
  color: #333333;
  text-align: left;
}
.third_txt:hover {
  color: #333333;
}
.font_small {
  padding-left: 26px;
  line-height: 23px;
  text-align: left;
}
.font_small span {
  margin-right: 8px;
}
.font_small1 {
  line-height: 20px;
  text-align: left;
}
.font_small1 div {
  margin-right: 28px;
  font-wight: 400;
  font-size: 14px;
  color: #777777;
  float: left;
  margin-bottom: 10px;
}
.head_contain {
  width: 100%;
  min-height: 110px;
  /* background: linear-gradient(180deg, #9c9b96 0%, #676664 100%); */
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
}
.head_top {
  margin-bottom: 12px;
}
.blue {
  /* color: #dca01e; */
  color: #fff;
  margin-left: 30px;
}
.head_in {
  width: 1200px;
  margin: auto;
  position: relative;
}
.head_shop {
  width: 148px;
  height: 40px;
  /* background: linear-gradient(90deg, ##9B9A95 0%, #dca01e 100%); */
  background: #9b9a95;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  /* margin-left: 40px; */
}
.shop_car {
  margin: 0 8px;
}
.shop_num {
  min-width: 18px;
  height: 18px;
  padding: 0 4px;
  background: #fff;
  border-radius: 9px;
  box-sizing: border-box;
  /* color: #dca01e; */
  color: #9b9a95;
}
input[type="text"] {
  background: #ffffff;
  border-radius: 4px 0px 0px 4px;
  border: 2px solid #9b9a95;
  box-sizing: border-box;
  height: 40px;
  width: 530px;
  padding: 0 12px;
  color: #333333;
  font-size: 12px;
  outline: none;
}
input[type="text"]::-webkit-input-placeholder {
  color: #666666;
}
input[type="text"]:active {
  box-sizing: border-box;
  border: 2px solid #9b9a95;
}
.hot_search {
  margin-top: 12px;
  text-align: left;
}
.search_icon {
  box-sizing: border-box;
  width: 94px;
  height: 40px;
  background: #9b9a95;
  /* background: linear-gradient(90deg, #9B9A95 0%, #dca01e 100%); */
  border-radius: 0px 4px 4px 0px;
}
.search_txt {
  font-size: 14px;
  color: #fff;
}
.head_tab {
  height: 48px;
  background: #4a4a4a;
}

.tab1 {
  width: 230px;
  height: 48px;

  background: #9b9a95;
  /* background: linear-gradient(90deg, ##9B9A95 0%, #dca01e 100%); */
  position: relative;
}

.tab_color1 {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.tab2 {
  width: 161px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.tab2.active .tab2_item {
  color: #fff;
  position: relative;
}

.tab2_item {
  width: 100%;
  text-align: center;
}

.tab2.active .tab2_item .xian {
  position: absolute;
  width: 50px;
  height: 3px;
  background: #fff;
  bottom: -8px;
  right: 0;
  left: 0;
  margin: auto;
}

.order_step {
  margin: auto;
  width: 41px;
  height: 41px;
  font-weight: 500;
  color: #aaaaaa;
  background: #ffffff;
  border: 2px solid #d5d5d5;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.order_step.active {
  color: #ffffff;
  background: #c52b33;
  border: none;
}
.step_xian {
  width: 100%;
  height: 7px;
  background: #d5d5d5;
  position: absolute;
  top: 17px;
  left: -50%;
}
.step_xian.active {
  background: #c52b33;
}
.step_out {
  position: relative;
  width: 100%;
}

.gjz {
  display: block;
  text-decoration: none;
  /* width: 115px;
  height: 32px;
  background: linear-gradient(90deg, ##9B9A95 0%, #dca01e 100%) ;
  border-radius: 4px;
  */
  /* margin-left: 16px;
  text-align: center;
  line-height:32px; */
  font-size: 12px;
  /* position: absolute;
  right: 20px;
  top: 13px; */
  color: #fff;
  margin-right: 10px;
  margin-bottom: 5px;
}

.dp {
  display: block;
  text-decoration: none;
  font-size: 12px;
  /* position: absolute;
  right: 25px;
  top: 50px; */
  color: #fff;
  margin-right: 10px;
}

.head_bg {
  /* background: url('@/assets/header_bg.png') no-repeat center center;
  background-size: 100% 100%; */
  background: #f08300;
}
</style>

<template>
  <div>
    <Header />
    <div style="background-color: #fff; padding-bottom: 68px">
      <div class="zs_bg">
        <div class="banner_txt">
          <div style="letter-spacing: 5px; font-style: italic">
            本牛保温 <br />
            品牌合作计划 火热进行中
          </div>
          <div
            class="font-fff-24"
            style="line-height: 48px; margin-top: 16px; letter-spacing: 2px"
          >
            本着“合作共赢、开放互惠”的理念，旨为客户提供全品类、高品质、优价格
            <br />
            的保温用品采购与管理服务，实现保温行业供应链透明、高效、降本。
          </div>
        </div>
      </div>
      <div class="zs_bg1">
        <div class="bg1_title">您面临的现状</div>
        <div class="flex-wrap" style="width: 1300px; margin: auto">
          <div class="flex-con">
            <img
              :src="require('@/assets/static/cbsx.png')"
              alt=""
              class="img1"
            />
            <div class="align-start" style="padding-left: 70px">
              <img
                :src="require('@/assets/static/wh_icon.png')"
                alt=""
                class="img2"
              />
              <div class="bg1_info">
                采购成本不受控制，<br />
                急需降本增效
              </div>
            </div>
          </div>
          <div class="flex-con">
            <img
              :src="require('@/assets/static/ywsx.png')"
              alt=""
              class="img1"
            />
            <div class="align-start" style="padding-left: 70px">
              <img
                :src="require('@/assets/static/wh_icon.png')"
                alt=""
                class="img2"
              />
              <div class="bg1_info">
                业务单一，<br />
                无法满足客户多样化需求
              </div>
            </div>
          </div>
          <div class="flex-con">
            <img
              :src="require('@/assets/static/xmxz.png')"
              class="img1"
              alt=""
            />
            <div class="align-start" style="padding-left: 70px">
              <img
                :src="require('@/assets/static/wh_icon.png')"
                alt=""
                class="img2"
              />
              <div class="bg1_info">
                手握资源却无法撬动，<br />
                缺少优质项目
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="zs_bg2">
        <div class="bg1_title">本牛带给您的权益</div>
        <img
          :src="require('@/assets/static/zs_bg3_min.png')"
          alt=""
          style="
            width: 1212px;
            height: 500px;
            margin: 62px auto 0;
            display: block;
          "
        />
      </div>
      <div class="zs_bg3">
        <div class="bg1_title" style="margin-bottom: 43px">收益方式</div>
        <div class="align-between-top" style="width: 1300px; margin: auto">
          <img
            :src="require('@/assets/static/money.png')"
            alt=""
            class="money_img"
          />
          <div>
            <img
              :src="require('@/assets/static/zybx.png')"
              alt=""
              style="width: 638px; height: 128px; display: block"
            />
            <img
              :src="require('@/assets/static/zybx.png')"
              alt=""
              style="
                width: 638px;
                height: 128px;
                display: block;
                margin: 16px 0;
              "
            />
            <img
              :src="require('@/assets/static/zybx.png')"
              alt=""
              style="width: 638px; height: 128px; display: block"
            />
          </div>
        </div>
      </div>
      <div class="zs_bg4">
        <div class="bg1_title" style="margin-top: 78px">成为我们的合作伙伴</div>
        <div class="bg4_info">
          本牛为合伙人开通专属账号，提供优势供货价以及完整的在线收益查看渠道，根据发展层级，给予不同比例的收益，
          <br />
          保证在线交易公开透明，积极构建深度合作关系。
        </div>
        <div
          class="align-between-top"
          style="
            width: 1300px;
            padding: 0 40px;
            margin: auto;
            box-sizing: border-box;
          "
        >
          <div>
            <img
              :src="require('@/assets/static/zs_icon1.png')"
              alt=""
              class="zs_icon"
            />
            <div class="bg4_icon_title">保温材料贸易商</div>
          </div>
          <div>
            <img
              :src="require('@/assets/static/zs_icon2.png')"
              alt=""
              class="zs_icon"
            />
            <div class="bg4_icon_title" style="text-align: center">
              建材销售商
            </div>
          </div>
          <div>
            <img
              :src="require('@/assets/static/zs_icon3.png')"
              alt=""
              class="zs_icon"
            />
            <div class="bg4_icon_title">设备安装公司</div>
          </div>
          <div>
            <img
              :src="require('@/assets/static/zs_icon4.png')"
              alt=""
              class="zs_icon"
            />
            <div class="bg4_icon_title">
              工程总承包商/<br />
              分包商
            </div>
          </div>
          <div>
            <img
              :src="require('@/assets/static/zs_icon5.png')"
              alt=""
              class="zs_icon"
            />
            <div class="bg4_icon_title">
              各类防水/防腐/<br />
              保温协会会员
            </div>
          </div>
          <div>
            <img
              :src="require('@/assets/static/zs_icon6.png')"
              alt=""
              class="zs_icon"
            />
            <div class="bg4_icon_title" style="text-align: center">其他</div>
          </div>
        </div>
      </div>

      <div class="kuang">
        <div class="title1 font-333-36-bold">用户申请表单</div>
        <div class="mb align-start">
          <div class="mb1 align-start">
            <div class="info">
              <span class="FF940A m_r5 xing">*</span>
              姓名：
            </div>
            <input
              type="text"
              placeholder="长度不超过20个字符"
              v-model="name"
              style="width: 444px"
            />
          </div>
          <div class="mb1 align-start">
            <div class="info" style="width: 194px">
              <span class="FF940A m_r5 xing">*</span>
              电话：
            </div>
            <input
              type="tel"
              placeholder="请输入您的联系方式"
              v-model="phone"
              style="width: 444px"
            />
          </div>
        </div>
        <div class="mb mb1 align-start">
          <div class="info">
            <!-- <span style="color: rgba(0, 0, 0, 0)">*</span> -->
            公司：
          </div>
          <input
            type="text"
            class=""
            placeholder=""
            v-model="company"
            style="width: 1082px"
          />
        </div>
        <div class="mb mb2 flex-wrap">
          <div class="info">
            <!-- <span style="color: rgba(0, 0, 0, 0)">*</span> -->
            备注：
          </div>
          <textarea class="flex-con" placeholder="" v-model="remark"></textarea>
        </div>
        <div style="margin: 37px 0">
          <div class="btn pointer" @click="fnTi">确定发送</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { joinInNiu } from '../api'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  data() {
    return {
      name: '',
      phone: '',
      company: '',
      remark: '',
      status1: true,
    }
  },
  methods: {
    fnTi() {
      if (!this.status1) {
        return
      }
      this.status1 = false
      let params = {
        name: this.name,
        phone: this.phone,
        company: this.company,
        remark: this.remark,
      }
      joinInNiu(params).then((data) => {
        this.status1 = true
        if (data.status == 200) {
          this.name = ''
          this.phone = ''
          this.company = ''
          this.remark = ''
          this.$message.success('发送成功')
        }
      })
    },
  },
  mounted() {
    document.title = '本牛'
    //   this.initData()
  },
}
</script>
<style scoped>
.xing {
  display: inline-block;
  font-size: 30px;
  line-height: 15px;
  font-weight: 500;
  position: relative;
  top: 8px;
}
.btn {
  width: 204px;
  height: 70px;
  background: url('@/assets/static/btn_bg.png') no-repeat center center;
  background-size: 100% 100%;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
  letter-spacing: 2px;
  margin: auto;
}
.kuang {
  width: 1300px;
  min-height: 750px;
  background: #ffffff;
  border-radius: 20px;
  /* padding: 67px 0 0; */
  box-sizing: border-box;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  margin: 68px auto 0;
}

.title1 {
  font-size: 40px;
  font-weight: 500;
  color: #050923;
  line-height: 116px;
  text-align: center;
}

.info {
  font-size: 24px;
  font-weight: 400;

  color: #4d546d;
  width: 158px;
  box-sizing: border-box;
  text-align: right;
  padding-right: 10px;
}

.mb {
  margin-bottom: 22px;
}

.mb1 {
  align-items: center;
}

.mb2 {
  align-items: baseline;
}

input[type='tel'],
input[type='text'],
input[type='password'] {
  height: 72px;
  margin: 0;
  padding: 0 10px;
  font-size: 24px;
  font-weight: 400;
  color: #333;
  /* line-height: 46px; */
  background: transparent;
  outline: none;

  border: 1px solid #eeeeee;
}
input[type='tel']:focus,
input[type='text']:focus,
input[type='password']:focus {
  border: 1px solid #eeeeee;
}

textarea {
  max-width: 1082px;
  min-height: 294px;
  border: 1px solid #eeeeee;
  padding: 10px;
  font-size: 24px;
  color: #333;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0);
  outline: none;
  box-sizing: border-box;
  font-family: '微软雅黑';
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: 22px;
  color: #999999;
}
.bg4_icon_title {
  font-size: 20px;
  font-weight: 400;
  color: #4d546d;
  line-height: 30px;
}
.zs_icon {
  width: 140px;
  height: 140px;
  display: block;
  margin-bottom: 35px;
}
.bg4_info {
  font-size: 20px;
  font-weight: 400;
  color: #4d546d;
  line-height: 48px;
  margin: 45px auto 56px;
  text-align: center;
}
.money_img {
  width: 473px;
  height: 480px;
  display: block;
  position: relative;
  top: 12px;
}
.zs_bg2 {
  width: 100%;
  height: 728px;
  background: url('@/assets/static/zs_bg2_min.png') no-repeat center center;
  background-size: 100% 100%;
  padding-top: 66px;
  box-sizing: border-box;
}
.zs_bg3 {
  width: 100%;
  height: 584px;
  background: url('@/assets/static/zs_bg4.png') no-repeat center center;
  background-size: 100% 100%;
  padding-top: 48px;
  box-sizing: border-box;
}
.img1 {
  width: 247px;
  height: 176px;
  display: block;
  margin: 0 auto 35px;
}
.img2 {
  position: relative;
  top: -21px;
  width: 40px;
  height: 40px;
  display: block;
  margin-right: 20px;
}
.bg1_info {
  font-size: 18px;
  font-weight: 400;
  color: #4d546d;
  line-height: 36px;
}
.bg1_title {
  font-size: 40px;
  font-weight: 500;
  color: #050923;
  line-height: 42px;
  text-align: center;
  margin: 0 auto 70px;
}
.zs_bg {
  width: 100%;
  height: 400px;
  background: url('@/assets/static/zs_bg.png') no-repeat center center;
  background-size: 100% 100%;
}
.zs_bg1 {
  width: 100%;
  height: 536px;
  background: url('@/assets/static/zs_bg1_min.png') no-repeat center center;
  background-size: 100% 100%;
  padding-top: 66px;
  box-sizing: border-box;
}
.banner_txt {
  width: 1300px;
  box-sizing: border-box;
  font-size: 55px;
  font-weight: bold;
  color: #ffffff;
  line-height: 72px;
  padding-left: 30px;
  padding-top: 75px;
  margin: auto;
}
</style>

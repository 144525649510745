<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
    <el-dialog
      :visible.sync="dialogVisible1"
      width="440px"
      :destroy-on-close="true"
    >
      <div class="dialog">
        <div class="l_out">
          <div class="l_tab align-around">
            <div class="color333 pointer" @click="fnTab(true)">立即登录</div>
          </div>
          <div class="input_item flex-wrap">
            <div class="input_left">
              <el-image
                style="width: 26px; height: 26px; margin: 12px"
                :src="require('@/assets/login_phone.png')"
                fit="cover"
              ></el-image>
            </div>
            <input
              type="tel"
              name=""
              value=""
              v-model="phone"
              placeholder="请输入您的手机号"
              :readonly="readonly22"
            />
          </div>
          <div class="flex-wrap" v-if="loginType">
            <div class="input_item" style="max-width: 182px">
              <input
                type="tel"
                name=""
                value=""
                v-model="code"
                placeholder="请输入验证码"
                style="border-radius: 4px; max-width: 182px"
                @keyup.enter="fnLogin"
              />
            </div>
            <div class="flex-con"></div>
            <div
              class="input_item align-center pointer DEA422 cor_f08300"
              style="min-width: 102px; font-size: 14px"
              @click="need_ma"
            >
              {{ codeTxt }}
            </div>
          </div>
          <div class="input_item flex-wrap" v-else>
            <div class="input_left">
              <el-image
                style="width: 26px; height: 26px; margin: 12px"
                :src="require('@/assets/login_pwd.png')"
                fit="cover"
              ></el-image>
            </div>
            <input
              type="password"
              name=""
              value=""
              v-model="password"
              placeholder="请输入您的密码"
              @keyup.enter="fnLogin"
            />
          </div>
          <div class="align-start font-333-12 xyTxt">
            <input type="checkbox" name="" value="" v-model="checked" />
            我已阅读并同意<span
              class="pointer DEA422 cor_f08300"
              @click="fnXy(3)"
              >《本牛网站服务协议》</span
            ><span class="pointer DEA422 cor_f08300" @click="fnXy(4)"
              >《隐私政策》</span
            >
          </div>
          <div class="btn align-center pointer bg_f08300" @click="fnLogin">
            登录/注册
          </div>
          <div class="align-between">
            <div
              class="forget_pwd pointer"
              @click="fnTab(false)"
              v-show="loginType"
            >
              密码登录
            </div>
            <div
              class="forget_pwd pointer"
              @click="fnTab(true)"
              v-show="!loginType"
            >
              验证码登录
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" width="1200px">
      <div class="dialog">
        <div
          style="
            background: #ffffff;
            border-radius: 4px;
            height: 635px;
            padding: 0 20px;
            overflow-y: scroll;
          "
          class="scrollbar"
        >
          <div
            style="
              font-size: 26px;
              text-align: center;
              font-weight: 500;
              color: #333333;
              line-height: 57px;
              margin-bottom:20px;
            "
          >
            {{ type == 3 ? '《本牛网站服务协议》' : '《隐私政策》' }}
          </div>
          <div
            class="font-fff-333"
            style="line-height: 20px"
            v-html="type == 3 ? xyServe : xyYs"
          ></div>
        </div>
      </div>
    </el-dialog>

    <right v-show="routerUrl != 'Login' && routerUrl != 'FrogetPwd'" />
  </div>
</template>
<script>
import { passwordLogin, codeLogin, sendCode, loginAgreement } from './api'
import right from '@/components/right.vue'
export default {
  components: {
    right,
  },
  data() {
    return {
      dialogVisible1: false,
      loginType: true, //true是验证码登录
      phone: '',
      code: '',
      password: '',
      isOnclick: true,
      onClickTime: '',
      isinerval: '',
      onClickTime1: '',
      codeTxt: '获取验证码',
      phoneHeader: '',
      checked: false,
      dialogVisible: false,
      xyServe: '',
      xyYs: '',
      type: '',
      routerUrl: '',
      customer: {
        nonce: '',
        signature: '',
        timestamp: '',
        web_token: '',
      },

      readonly22: false,
    }
  },
  mounted() {
    this.routerUrl = this.$route.name
    if (localStorage.getItem('allUser')) {
      this.fnSelect()
    } else {
      this.initUdesk()
    }

    this.$bus.$on('login', (val) => {
      if (val) {
        this.dialogVisible1 = true
        this.readonly22 = false
        this.phone = ''
        // console.log(val);
        if (val == 222) {
          this.readonly22 = true
          this.phone = 15067554231
        }
      }
    })

    this.isPC()
  },
  beforeUpdate() {
    this.routerUrl = this.$route.name
  },
  // created() {
  //   this.isPC()
  // },
  methods: {
    isPC() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info)
      if (isPhone) {
        if (
          this.$route.name == 'Search' ||
          this.$route.name == 'zixun' ||
          this.$route.name == 'zixun2' ||
          this.$route.name == 'zixun3' ||
          this.$route.name == 'Selection' ||
          this.$route.name == 'Hyyx' ||
          this.$route.name == 'Whqc' ||
          this.$route.name == 'Brand' ||
          this.$route.name == 'goodDetail'
        ) {
          window.location.href = 'https://m.niutop.com' + this.$route.fullPath
        }else if(this.$route.name=='Shop'){
            window.location.href = 'https://m.niutop.com'+location.pathname+'?store_id='+this.$route.query.store_id;
          }else if(this.$route.name=='jingbeijituan'){
            window.location.href = 'https://m.niutop.com'+location.pathname;
          } else {
          window.location.href = 'https://m.niutop.com'
        }
      }
    },

    initUdesk(type) {
      ;(function (a, h, c, b, f, g) {
        a['UdeskApiObject'] = f
        a[f] =
          a[f] ||
          function () {
            ;(a[f].d = a[f].d || []).push(arguments)
          }
        g = h.createElement(c)
        g.async = 1
        g.charset = 'utf-8'
        g.src = b
        c = h.getElementsByTagName(c)[0]
        c.parentNode.insertBefore(g, c)
      })(
        window,
        document,
        'script',
        'https://assets-cli.s4.udesk.cn/im_client/js/udeskApi.js',
        'ud'
      )
      if (type) {
        ud({
          targetSelector: '#customer',
          code: '2763f5bf',
          link: 'https://1800498.s4.udesk.cn/im_client/?web_plugin_id=63975',
          customer: this.customer,
        })
      } else {
        ud({
          targetSelector: '#customer',
          code: '2763f5bf',
          link: 'https://1800498.s4.udesk.cn/im_client/?web_plugin_id=63975',
        })
      }
    },
    fnTab(boole) {
      clearInterval(this.isinerval)
      this.codeTxt = '获取验证码'
      this.code = ''
      this.phone = ''
      this.loginType = boole
      if(this.readonly22){
        this.phone = 15067554231
      }
    },
    fnLogin() {
      this.loginType ? this.fnLoginCode() : this.fnLoginPwd()
    },
    fnLoginCode() {
      if (!this.phone) {
        this.$message.error('请输入您的手机号')
        return
      }
      if (!this.code) {
        this.$message.error('请输入验证码')
        return
      }
      if (!this.checked) {
        this.$message.error('请勾选服务协议与隐私政策')
        return
      }
      let params = {
        phone: this.phone,
        code: this.code,
        type: 1,
        from_source: localStorage.getItem('from_source') || '',
      }
      codeLogin(params).then((data) => {
        if (data.status == 200) {
          data.msg.expire = 172800000
          data.msg.time111 = Date.now()
          localStorage.setItem('allUser', JSON.stringify(data.msg))
          clearInterval(this.isinerval)
          if (this.$route.name != 'Shop') {
            this.$router.go(0)
          } else {
            this.dialogVisible1 = false
            setTimeout(() => {
              this.$bus.$emit('ossSignMain')
            }, 200)
          }
        }
      })
    },
    fnLoginPwd() {
      if (!this.phone) {
        this.$message.error('请输入您的手机号')
        return
      }
      if (!this.password) {
        this.$message.error('请输入您的密码')
        return
      }
      if (!this.checked) {
        this.$message.error('请勾选服务协议与隐私政策')
        return
      }
      let params = {
        phone: this.phone,
        password: this.password,
      }
      passwordLogin(params).then((data) => {
        if (data.status == 200) {
          data.msg.expire = 172800000
          data.msg.time111 = Date.now()
          localStorage.setItem('allUser', JSON.stringify(data.msg))
          clearInterval(this.isinerval)
          if (this.$route.name != 'Shop') {
            this.$router.go(0)
          } else {
            this.dialogVisible1 = false
            setTimeout(() => {
              this.$bus.$emit('ossSignMain')
            }, 200)
          }
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    ///验证手机号
    need_ma() {
      if (!this.checked) {
        this.$message.error('请勾选服务协议与隐私政策')
        return
      }
      let regBox = {
        regMobile: /^1[3456789]\d{9}$/, //手机
      }
      let rPhone = regBox.regMobile.test(this.phone)
      if (!(rPhone && this.phone)) {
        this.$message.error('请输入正确的手机号')
        return
      }
      if (this.isOnclick) {
        //调用接口判断
        this.sendSMS()
      }
    },
    //获取验证码

    sendSMS() {
      let params = {
        phone: this.phone,
      }
      sendCode(params).then((data) => {
        if (data.status == 200) {
          if (this.isOnclick) {
            this.onClickTime = Math.floor(new Date().getTime() / 1000)
            this.isinerval = setInterval(this.CountDown, 1000)
            this.isOnclick = false
          }
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    //验证码60秒倒数
    CountDown() {
      this.onClickTime1 =
        60 - (Math.floor(new Date().getTime() / 1000) - this.onClickTime)
      if (this.onClickTime1 < 1) {
        this.codeTxt = '重新获取'
        clearInterval(this.isinerval)
        this.isOnclick = true
        return
      }
      this.codeTxt = this.onClickTime1 + 's'
    },
    fnXy(type) {
      this.dialogVisible = true
      this.type = type
      this.fnAgreement(this.type)
    },
    fnAgreement(type) {
      let params = {
        type: type,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          // 3 本牛网站服务协议   4 本牛隐私协议
          if (type == 3) {
            this.xyServe = data.msg.content1 || ''
          }
          if (type == 4) {
            this.xyYs = data.msg.content1 || ''
          }
        }
      })
    },
    fnSelect() {
      var nonce = Date.parse(new Date())
      var timestamp = Date.parse(new Date())
      var web_token = JSON.parse(localStorage.getItem('allUser')).user_phone
      var sign_str =
        'nonce=' +
        nonce +
        '&timestamp=' +
        timestamp +
        '&web_token=' +
        web_token +
        '&f4e02400be01856212d1b465688490f0'
      sign_str = this.sha1(sign_str)
      sign_str = sign_str.toUpperCase()
      ;(this.customer = {
        c_name: JSON.parse(localStorage.getItem('allUser')).user_phone,
        c_phone: JSON.parse(localStorage.getItem('allUser')).user_phone,
        nonce: nonce,
        signature: sign_str,
        timestamp: timestamp,
        web_token: web_token,
      }),
        this.initUdesk(1)
      // // window.location.href = 'https://1800498.s4.udesk.cn/im_client/?web_plugin_id=63975'
      //  + '&c_phone=' + JSON.parse(localStorage.getItem('allUser')).user_phone +
      //   '&nonce=' + nonce + '&signature=' + sign_str + '&timestamp=' + timestamp + '&web_token=' + web_token;
    },
    encodeUTF8(s) {
      var i,
        r = [],
        c,
        x
      for (i = 0; i < s.length; i++)
        if ((c = s.charCodeAt(i)) < 0x80) r.push(c)
        else if (c < 0x800) r.push(0xc0 + ((c >> 6) & 0x1f), 0x80 + (c & 0x3f))
        else {
          if ((x = c ^ 0xd800) >> 10 == 0)
            //对四字节UTF-16转换为Unicode
            (c = (x << 10) + (s.charCodeAt(++i) ^ 0xdc00) + 0x10000),
              r.push(0xf0 + ((c >> 18) & 0x7), 0x80 + ((c >> 12) & 0x3f))
          else r.push(0xe0 + ((c >> 12) & 0xf))
          r.push(0x80 + ((c >> 6) & 0x3f), 0x80 + (c & 0x3f))
        }
      return r
    },
    sha1(s) {
      var data = new Uint8Array(this.encodeUTF8(s))
      var i, j, t
      var l = (((data.length + 8) >>> 6) << 4) + 16,
        s = new Uint8Array(l << 2)
      s.set(new Uint8Array(data.buffer)), (s = new Uint32Array(s.buffer))
      for (t = new DataView(s.buffer), i = 0; i < l; i++)
        s[i] = t.getUint32(i << 2)
      s[data.length >> 2] |= 0x80 << (24 - (data.length & 3) * 8)
      s[l - 1] = data.length << 3
      var w = [],
        f = [
          function () {
            return (m[1] & m[2]) | (~m[1] & m[3])
          },
          function () {
            return m[1] ^ m[2] ^ m[3]
          },
          function () {
            return (m[1] & m[2]) | (m[1] & m[3]) | (m[2] & m[3])
          },
          function () {
            return m[1] ^ m[2] ^ m[3]
          },
        ],
        rol = function (n, c) {
          return (n << c) | (n >>> (32 - c))
        },
        k = [1518500249, 1859775393, -1894007588, -899497514],
        m = [1732584193, -271733879, null, null, -1009589776]
      ;(m[2] = ~m[0]), (m[3] = ~m[1])
      for (i = 0; i < s.length; i += 16) {
        var o = m.slice(0)
        for (j = 0; j < 80; j++)
          (w[j] =
            j < 16
              ? s[i + j]
              : rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1)),
            (t =
              (rol(m[0], 5) +
                f[(j / 20) | 0]() +
                m[4] +
                w[j] +
                k[(j / 20) | 0]) |
              0),
            (m[1] = rol(m[1], 30)),
            m.pop(),
            m.unshift(t)
        for (j = 0; j < 5; j++) m[j] = (m[j] + o[j]) | 0
      }
      t = new DataView(new Uint32Array(m).buffer)
      for (var i = 0; i < 5; i++) m[i] = t.getUint32(i << 2)

      var hex = Array.prototype.map
        .call(new Uint8Array(new Uint32Array(m).buffer), function (e) {
          return (e < 16 ? '0' : '') + e.toString(16)
        })
        .join('')

      return hex
    },
  },
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center; */
  /* color: #2c3e50; */
  height: 100%;
  /* font-family: "Microsoft Yahei"; */
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

input[type='text'],
input[type='password'],
input[type='tel'] {
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  height: 50px;
  /* width: 125px; */
  padding: 0 12px;
  color: #333333;
  font-size: 14px;
  outline: none;
}
input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder {
  color: #aaa;
}
input[type='text']:active,
input[type='password']:active,
input[type='tel']:active {
  box-sizing: border-box;
  border: none;
  border-right: none;
}
.input_left {
  width: 50px;
  height: 50px;
  background: #ebebeb;
  border-radius: 4px 0px 0px 4px;
}
.input_item {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  margin-top: 30px;
}
.color333 {
  color: #333;
}
.l_tab {
  font-size: 20px;
  color: #777777;
}
.l_out {
  width: 385px;
  height: 330px;
  background: #ffffff;
  border-radius: 4px;
  margin: auto;
  padding: 8px 42px 0;
  box-sizing: border-box;
}
.forget_pwd {
  font-size: 12px;
  color: #aaaaaa;
  line-height: 17px;
}
.btn {
  height: 40px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 4px;
  margin: 20px 0 10px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background: url('@/assets/check2.png') no-repeat center center;
  background-size: 100% 100%;
}
input[type='checkbox']:checked {
  background: url('@/assets/checked21.png') no-repeat center center;
  background-size: 100% 100%;
}
/* input[type='checkbox'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  margin-right: 5px;
}
input[type='checkbox']:checked {
  border: 1px solid #333;
  position: relative;
}
input[type='checkbox']:checked:after {
  position: absolute;
  width: 8px;
  height: 5px;
  top: 50%;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  content: '';
  color: #000;
  display: inline-block;
  visibility: visible;
  border: 2px solid #333;
  border-top: none;
  border-right: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
} */
.xyTxt {
  line-height: 17px;
  margin: 20px 0;
}
::v-deep .el-dialog__body {
  padding-top: 0;
}
</style>

<template>
  <div class="" style="height: 100%">
    <div class="l_head align-center">
      <div class="l_head_in align-between">
        <div class="align-center">
          <el-image
            style="width: 208px; height: 72px; margin-right: 20px"
            :src="require('@/assets/logo.png')"
            fit="cover"
          ></el-image>
          <div>忘记密码</div>
        </div>
        <div class="align-center font-fff-14">
          <div>我已注册，去登录</div>
          <div
            class="align-center pointer"
            style="
              width: 92px;
              height: 40px;
              border-radius: 4px;
              border: 1px solid #ffffff;
              margin-left: 20px;
            "
            @click="toLogin()"
          >
            登录
          </div>
        </div>
      </div>
    </div>
    <div class="meng_out">
      <div class="meng">
        <div class="meng_in align-center">
          <div
            style="
              width: 375px;
              padding: 30px;
              border-radius: 4px;
              background: #fff;
              margin-top: 50px;
            "
          >
            <div
              style="
                font-size: 28px;
                font-weight: 500;
                color: #333333;
                line-height: 40px;
                margin: 25px auto 50px;
                text-align: center;
              "
            >
              忘记密码
            </div>
            <div v-if="step">
              <div class="flex-wrap">
                <div class="font-333 align-start" style="min-width: 75px">
                  <span class="C52B33">*</span>手机号:
                </div>
                <div class="input_item">
                  <input
                    type="tel"
                    name=""
                    value=""
                    v-model="phone"
                    placeholder="请输入您的手机号"
                  />
                </div>
              </div>
              <div class="flex-wrap" style="margin: 30px 0">
                <div class="font-333 align-start" style="min-width: 75px">
                  <span class="C52B33">*</span>验证码:
                </div>
                <div class="input_item align-between">
                  <input
                    type="tel"
                    name=""
                    value=""
                    v-model="code"
                    placeholder="请输入验证码"
                  />
                  <div
                    class="C52B33 align-center pointer cor_f08300"
                    style="height: 100%; padding-right: 10px"
                    @click="need_ma"
                  >
                    {{ codeTxt }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="flex-wrap" style="margin-bottom: 30px">
                <div class="font-333 align-start" style="min-width: 75px">
                  <span class="C52B33">*</span>新密码:
                </div>
                <div class="input_item">
                  <input
                    type="password"
                    name=""
                    value=""
                    v-model="first_password"
                    placeholder="请输入您的新密码"
                  />
                </div>
              </div>
              <div class="flex-wrap">
                <div class="font-333 align-start" style="min-width: 75px">
                  <span class="C52B33">*</span>确认密码:
                </div>
                <div class="input_item">
                  <input
                    type="password"
                    name=""
                    value=""
                    v-model="second_password"
                    placeholder="请再次输入新密码"
                  />
                </div>
              </div>
            </div>

            <div class="btn align-center pointer bg_f08300" @click="fnNext">
              {{ next }}
            </div>

            <!-- <div class="align-center font-333-12">
              <input type="checkbox" name="" value="" v-model="checked" />
              我已阅读并同意
              <span class="C52B33">《本牛网站服务协议》《本牛隐私政策》</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import {
  forgetPasswordFirstStep,
  forgetPasswordSecondStep,
  sendCode,
} from '../api'
export default {
  data() {
    return {
      step: true,
      phone: '',
      code: '',
      first_password: '',
      second_password: '',
      isOnclick: true,
      onClickTime: '',
      isinerval: '',
      onClickTime1: '',
      codeTxt: '获取验证码',
      next: '下一步',
      // checked: false,
    }
  },
  components: {
    Footer,
  },
  mounted() {
    document.title = '本牛'
  },
  methods: {
    fnNext() {
      if (this.step) {
        this.fnFirst()
      } else {
        this.fnSecond()
      }
    },
    fnFirst() {
      if (!this.phone) {
        this.$message.error('请输入您的手机号')
        return
      }
      if (!this.code) {
        this.$message.error('请输入验证码')
        return
      }
      let params = {
        phone: this.phone,
        code: this.code,
      }
      forgetPasswordFirstStep(params).then((data) => {
        if (data.status == 200) {
          this.step = false
          this.next = '确定'
          clearInterval(this.isinerval)
          // localStorage.setItem('allUser', data.msg)
          // this.$router.push({ path: 'Login' })
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    fnSecond() {
      if (!this.first_password) {
        this.$message.error('请输入您的新密码')
        return
      }
      if (!this.second_password) {
        this.$message.error('请再次输入新密码')
        return
      }
      if (this.first_password != this.second_password) {
        this.$message.error('两次密码输入不一致')
        return
      }
      let params = {
        phone: this.phone,
        first_password: this.first_password,
        second_password: this.second_password,
      }
      forgetPasswordSecondStep(params).then((data) => {
        if (data.status == 200) {
          this.$message.success('设置成功')
          // localStorage.setItem('allUser', data.msg)
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000)
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    ///验证手机号
    need_ma() {
      let regBox = {
        regMobile: /^1[3456789]\d{9}$/, //手机
      }
      let rPhone = regBox.regMobile.test(this.phone)
      if (!(rPhone && this.phone)) {
        this.$message.error('请输入正确的手机号')
        return
      }
      if (this.isOnclick) {
        //调用接口判断
        this.sendSMS()
      }
    },
    //获取验证码

    sendSMS() {
      let params = {
        phone: this.phone,
      }
      sendCode(params).then((data) => {
        if (data.status == 200) {
          if (this.isOnclick) {
            this.onClickTime = Math.floor(new Date().getTime() / 1000)
            this.isinerval = setInterval(this.CountDown, 1000)
            this.isOnclick = false
          }
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    //验证码60秒倒数
    CountDown() {
      this.onClickTime1 =
        60 - (Math.floor(new Date().getTime() / 1000) - this.onClickTime)
      if (this.onClickTime1 < 1) {
        this.codeTxt = '重新获取'
        clearInterval(this.isinerval)
        this.isOnclick = true
        return
      }
      this.codeTxt = this.onClickTime1 + 's'
    },
  },
}
</script>

<style scoped>
.forget_pwd {
  font-size: 12px;
  color: #aaaaaa;
  line-height: 17px;
}
.to_register {
  font-size: 12px;
  color: #efc845;
  line-height: 17px;
}
.btn {
  height: 40px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 4px;
  margin: 30px 0 16px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}
input[type='text'],
input[type='password'],
input[type='tel'] {
  background: rgba(0, 0, 0, 0);
  border: none;
  box-sizing: border-box;
  height: 50px;
  /* width: 300px; */
  padding: 0 12px;
  color: #333333;
  font-size: 14px;
  outline: none;
}
input[type='password']::-webkit-input-placeholder,
input[type='text']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder {
  color: #aaa;
}
input[type='password']:active,
input[type='text']:active,
input[type='tel']:active {
  box-sizing: border-box;
  border: none;
  border-right: none;
}
.input_left {
  width: 50px;
  height: 50px;
  background: #ebebeb;
  border-radius: 4px 0px 0px 4px;
}
.input_item {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  width: 300px;
  margin-top: 0px;
}
.xian {
  width: 1px;
  height: 28px;
  background: #d8d8d8;
}
.color333 {
  color: #333;
}
.l_tab {
  font-size: 20px;
  color: #777777;
}
.l_out {
  width: 385px;
  height: 400px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  top: 80px;
  padding: 50px 42px 0;
  box-sizing: border-box;
}
.l_head {
  height: 110px;
  background: linear-gradient(180deg, #9c9b96 0%, #676664 100%);
  width: 100%;
}
.l_head_in {
  width: 1300px;
  margin: auto;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}
.meng_out {
  height: 560px;
  position: relative;
  background: url('@/assets/static/login_bg8.png') no-repeat center center;
  background-size: cover;
}
.meng {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
}
.l_left {
  color: #ffffff;
  width: 540px;
}
.meng_in {
  width: 1080px;
  margin: auto;
}
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  margin-right: 15px;
  background: url('@/assets/check2.png') no-repeat center center;
  background-size: 100% 100%;
}
input[type='checkbox']:checked {
  background: url('@/assets/checked21.png') no-repeat center center;
  background-size: 100% 100%;
}
/* input[type='checkbox'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  margin-right: 15px;
}
input[type='checkbox']:checked {
  border: 1px solid #333;
  position: relative;
}
input[type='checkbox']:checked:after {
  position: absolute;
  width: 8px;
  height: 5px;
  top: 50%;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  content: '';
  color: #000;
  display: inline-block;
  visibility: visible;
  border: 2px solid #333;
  border-top: none;
  border-right: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
} */
</style>

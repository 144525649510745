<template>
  <div>
    <EnHeader :num111="2" />
    <div class="bg_fff">
      <!-- <el-image
        style="width: 100%"
        :src="require('@/assets/en/banner2.png')"
        fit="cover"
      ></el-image> -->
      <img style="width: 100%" :src="require('@/assets/en/banner2.png')" fit="cover" alt="">
      <div class="font-333-22" style="text-align: center; margin: 60px auto 40px; width: 1200px">
        A global insulation and energy-saving e-commerce platform that gathers
        high-quality resources <br> from the global insulation and energy-saving
        industry, strictly selects high-quality industry <br> products, and provides
        one-stop procurement services for insulation and energy-saving materials <br>
        for various production enterprises and distribution partners.
      </div>
      <div class="about_us after">
        <el-image :src="require('@/assets/en/home_10.png')" fit="cover" class="about_img"></el-image>
        <div class="about_intro">
          <div class="font-fff-50" style="margin-bottom: 48px; position: relative">
            <div style="opacity: 0.2">ABOUT US</div>

            <div class="font-fff-40 about_title">ABOUT US</div>
          </div>

          <div class="font-fff-16">
            Our platform (www.niutop.com) integrates global suppliers and
            product resources through a global supply chain network, providing
            various insulation material procurement, upstream and downstream
            supply chain matching, online procurement management and production
            control, warehousing and logistics services for upstream and
            downstream enterprises in the industry. Our aim is to promote the
            sharing of high-quality product resources and open up a "fast lane"
            for common development. Our platform adheres to the principle of
            "high quality, excellent price" and provides customized services for
            all categories, high quality, and closed-loop processes for
            customers in the entire market. The main product line includes
            various rubber and plastic insulation materials, Glass wool/rock
            wool series products, composite/flexible air duct series products,
            ceramic fiber series products, Aerogel series products, etc.
          </div>
        </div>
      </div>

      <div style="width: 1200px; margin: auto; text-align: center" class="p_b60">
        <div class="font-333-40-bold m_t60">PLATFORM ADVANTAGES</div>
        <div class="EFC845-14" style="margin: 16px 0 60px">
          BENNIU (JIANGSU) E-COMMERCE CO., LTD
        </div>
        <div class="align-center">
          <el-image style="width: 66px; height: 66px; margin-right: 114px" :src="require('@/assets/en/home_icon2.png')"
            fit="cover"></el-image>
          <div class="font-333-22" style="width: 635px">
            There are more than 100 kinds of insulation products covering rubber
            and plastic, Glass wool, rock wool, ceramic fiber, composite air
            duct, flexible air duct, magnesium silicate, foam glass, Aerogel,
            polyurethane, polyisocyanurate, polyimide, melamine resin, etc
          </div>
        </div>

        <div class="flex-wrap font-333-16-bold" style="padding-top: 40px; line-height: 19px; text-align: center">
          <div class="flex-con">
            <div class="order_step "></div>
            <div class="width40">8 years of deep industry cultivation</div>
          </div>
          <div class="flex-con">
            <div class="step_out">
              <div class="order_step active"></div>
              <span class="step_xian"></span>
            </div>
            <div class="width40">Hundreds of insulation products</div>
          </div>
          <div class="flex-con">
            <div class="step_out">
              <div class="order_step"></div>
              <span class="step_xian"></span>
            </div>
            <div class="width40">
              Directly supplied by the source manufacturer
            </div>
          </div>
          <div class="flex-con">
            <div class="step_out">
              <div class="order_step"></div>
              <span class="step_xian"></span>
            </div>
            <div class="width40">National logistics layout</div>
          </div>
        </div>

        <div class="font-333-40-bold m_t60">PARTNERS</div>
        <div class="EFC845-14" style="margin: 16px 0 60px">
          BENNIU (JIANGSU) E-COMMERCE CO., LTD
        </div>
        <div class="swiper-container swiper-container3 p_b60" id="swiper-container3">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in partner" :key="'partner_' + index">
              <img :src="item.partner_icon1" class="parther" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <EnFooter />
  </div>
</template>

<script>
import EnHeader from '@/components/EnHeader.vue'
import EnFooter from '@/components/EnFooter.vue'
import Swiper from 'swiper/bundle'
import { indexData } from '../../api'
export default {
  components: {
    EnHeader,
    EnFooter,
  },
  data() {
    return {
      tdkTitle: '本牛',
      tdkKeywords: '本牛',
      tdkDescription: '本牛',
      partner: [],
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    initData() {
      let params = {
      }
      indexData(params).then((data) => {
        if (data.status == 200) {
          this.partner = data.msg.partner
        }
      })
    },
  },
  mounted() {
    this.initData()
  },
  watch: {
    partner: {
      handler: function (newVal, oldVal) {
        this.$nextTick(() => {
          new Swiper('#swiper-container3', {
            loop: true,
            loopAdditionalSlides: 7,
            autoplay: {
              delay: 1000, // 轮播间隔时间
              stopOnLastSlide: false, // 循环播放
              disableOnInteraction: false, // 用户操作后轮播仍然继续
            },
            speed: 500,
            spaceBetween: 6,
            slidesPerView: 5,
            observer: true,
          })
        })
      },
    },
  },
}
</script>

<style scoped>
.about_us {
  /* height: 100px; */
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  width: calc(((100% - 1200px) / 2) + 782px);
  padding: 40px 200px 80px 0;
  box-sizing: border-box;
  position: relative;
}

.about_intro {
  width: 582px;
  float: right;
}

.about_img {
  position: absolute;
  margin: auto;
  right: -418px;
  top: 0;
  bottom: 0;
  height: 415px;
  width: 554px;
}

.about_title {
  position: absolute;
  top: 28px;
  left: 35px;
}

@import url('swiper/swiper-bundle.css');

.swiper-container {
  width: 590px;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
}

.swiper-button-prev:after {
  display: none;
}

.swiper-button-next:after {
  display: none;
}

.swiper-button-prev {
  width: 30px;
  height: 60px;
  background: url('../../assets/main1_7.png') no-repeat center center;
  background-size: 30px 60px;
  left: 0;
  top: 53px;
}

.swiper-button-next {
  width: 30px;
  height: 60px;
  background: url('../../assets/main1_6.png') no-repeat center center;
  background-size: 30px 60px;
  right: 0;
  top: 53px;
}

.swiper-container3 {
  height: 122px;
  width: 1200px;
  position: relative;
}

.parther {
  width: 128px;
  height: 104px;
  filter: grayscale(1);
}

.parther:hover {
  filter: none;
}

.order_step {
  margin: auto;
  width: 20px;
  height: 20px;
  background: #e6e6e6;
  position: relative;
  z-index: 1;
}

.order_step.active {
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
}

.step_xian {
  width: 100%;
  height: 1px;
  background: #aeaeae;
  position: absolute;
  top: 10px;
  left: -50%;
}

.step_out {
  position: relative;
  width: 100%;
  margin-bottom: 11px;
}

.width40 {
  width: 40%;
  margin: auto;
}
</style>

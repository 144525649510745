<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top: 14px">
      <div>
        <MyInfo :num111="7" />
      </div>

      <div class="my_right flex-con">
        <div class="order_box" style="margin-bottom: 16px">
          <div class="font-333-20-bold cheng_head11 align-start" style="">
            基本资料
          </div>
          <div style="padding-right: 32px">
            <div class="flex-wrap" style="margin-top: 22px">
              <div class="width102 font-333-16-bold align-end">用户名:</div>
              <input
                type="text"
                name=""
                value=""
                v-model="data.user_username"
                placeholder="请输入"
                class="flex-con"
              />
            </div>

            <div class="font-333 flex-wrap" style="margin-top: 22px">
              <div class="width102 font-333-16-bold align-end">
                <span class="cor_FA9C22">*</span>行业类别:
              </div>
              <el-select
                class="flex-con"
                v-model="data.user_industry_first_cate"
                placeholder="请选择一级分类"
                size="mini"
                style="width: 150px"
                @change="
                  (val) => {
                    handleChange1(val, 1)
                  }
                "
              >
                <el-option
                  v-for="item in industry1"
                  :key="item.ic_id"
                  :label="item.ic_name"
                  :value="item.ic_id"
                >
                </el-option>
              </el-select>
              <el-select
                class="flex-con"
                v-model="data.user_industry_second_cate"
                placeholder="请选择二级分类"
                size="mini"
                style="width: 150px; margin-left: 19px"
                @change="
                  (val) => {
                    handleChange1(val, 2)
                  }
                "
              >
                <el-option
                  v-for="item in industry2"
                  :key="item.ic_id"
                  :label="item.ic_name"
                  :value="item.ic_id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="font-333 flex-wrap" style="margin-top: 22px">
              <div class="width102 font-333-16-bold align-end">所属区域:</div>
              <el-select
                class="flex-con"
                v-model="data.user_province"
                placeholder="请选择省份"
                size="mini"
                style="width: 150px"
                @change="
                  (val) => {
                    handleChange(val, 1)
                  }
                "
              >
                <el-option
                  v-for="item in sheng"
                  :key="item.district_id"
                  :label="item.district"
                  :value="item.district_id"
                >
                </el-option>
              </el-select>
              <el-select
                class="flex-con"
                v-model="data.user_city"
                placeholder="请选择城市"
                size="mini"
                style="width: 150px; margin-left: 19px"
                @change="
                  (val) => {
                    handleChange(val, 2)
                  }
                "
              >
                <el-option
                  v-for="item in shi"
                  :key="item.district_id"
                  :label="item.district"
                  :value="item.district_id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="font-333 flex-wrap" style="margin-top: 22px">
              <div class="width102 font-333-16-bold align-end">岗位:</div>
              <el-select
                v-model="data.user_post"
                placeholder="请选择岗位"
                size="mini"
                class="flex-con"
              >
                <el-option
                  v-for="item in post"
                  :key="item.post_id"
                  :label="item.post_name"
                  :value="item.post_id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="font-333 flex-wrap" style="margin-top: 22px">
              <div class="width102 font-333-16-bold align-end">
                <span class="cor_FA9C22">*</span>采购意向:
              </div>
              <input
                type="text"
                name=""
                value=""
                v-model="data.user_buy"
                placeholder="请输入"
                class="flex-con"
              />
            </div>
            <div class="font-333 flex-wrap" style="margin-top: 22px">
              <div class="width102 font-333-16-bold align-end">主营产品:</div>
              <input
                type="text"
                name=""
                value=""
                v-model="data.user_product"
                placeholder="请输入"
                class="flex-con"
              />
            </div>
          </div>
          <div
            class="btn align-center pointer"
            style="margin: 30px auto 35px"
            @click="fnSure(3)"
          >
            提交
          </div>
        </div>

        <div class="order_box">
          <div class="font-333-20-bold cheng_head11 align-start" style="">
            账号信息
          </div>
          <div style="padding-right: 32px; padding-bottom: 35px">
            <div class="font-333 align-between" style="margin-top: 22px">
              <div class="align-center">
                <div class="width102 font-333-16-bold">手机号:</div>
                <div class="font-333-16-22">{{ data.user_phone }}</div>
                <div class="font-aaa" style="margin-left: 16px">
                  如手机号停用，请及时更换
                </div>
              </div>
              <div class="font-333-16-22 pointer cor_f08300" @click="fnOpen(1)">
                修改
              </div>
            </div>
            <div class="font-333 align-between" style="margin-top: 22px">
              <div class="align-center">
                <div class="width102 font-333-16-bold">登录密码:</div>
                <div v-if="data.user_password" class="align-center">
                  <div class="font-333">******</div>
                  <div class="font-aaa" style="margin-left: 16px">
                    建议定期更换
                  </div>
                </div>
                <input
                  v-else
                  type="text"
                  name=""
                  value=""
                  v-model="pwd1"
                  placeholder="请输入"
                />
              </div>
              <div class="font-333-16-22 pointer cor_f08300" @click="fnSure(6)">
                {{ data.user_password ? '修改' : '保存' }}
              </div>
            </div>
            <div
              class="font-333-16-bold align-between"
              style="margin-top: 22px"
            >
              <div class="align-center">
                <div class="width102">邮箱:</div>
                <div class="font-333" v-if="data.user_email">
                  {{ data.user_email }}
                </div>
                <input
                  v-else
                  type="text"
                  name=""
                  value=""
                  v-model="email"
                  placeholder="请输入"
                />
              </div>
              <div class="font-333-16-22 cor_f08300 pointer" @click="fnSure(4)">
                {{ data.user_email ? '修改' : '保存' }}
              </div>
            </div>
          </div>
        </div>
        <el-dialog :visible.sync="dialogVisible1" width="980px">
          <div class="dialog">
            <div class="dialog_title">{{ txt111 }}</div>
            <div v-if="phoneType == 1" style="padding: 0 70px">
              <div class="align-between font-333" style="margin-top: 36px">
                <div class="align-start">
                  <div class="width115">
                    <span class="cor_FA9C22">*</span>旧手机号:
                  </div>
                  <input
                    type="text"
                    name=""
                    value=""
                    v-model="phone1"
                    placeholder="请输入"
                    class="dialog_input"
                    style="
                      background: #f7f7f7;
                      border-color: #f7f7f7;
                      width: 744px;
                    "
                    readonly
                  />
                </div>
              </div>
              <div class="align-between font-333" style="margin-top: 36px">
                <div class="align-start">
                  <div class="width115">
                    <span class="cor_FA9C22">*</span>验证码:
                  </div>
                  <input
                    type="text"
                    name=""
                    value=""
                    v-model="code1"
                    placeholder="请输入"
                    class="dialog_input"
                    style="width: 546px"
                  />
                  <div class="align-center pointer code111" @click="need_ma(1)">
                    {{ codeTxt }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="padding: 0 70px">
              <div class="align-between font-333" style="margin-top: 36px">
                <div class="align-start">
                  <div class="width115">
                    <span class="cor_FA9C22">*</span>新手机号:
                  </div>
                  <input
                    type="text"
                    name=""
                    value=""
                    v-model="phone2"
                    placeholder="请输入"
                    class="dialog_input"
                    style="width: 744px"
                  />
                </div>
              </div>
              <div class="align-between font-333" style="margin-top: 36px">
                <div class="align-start">
                  <div class="width115">
                    <span class="cor_FA9C22">*</span>验证码:
                  </div>
                  <input
                    type="text"
                    name=""
                    value=""
                    v-model="code2"
                    placeholder="请输入"
                    class="dialog_input"
                    style="width: 546px"
                  />
                  <div class="align-center pointer code111" @click="need_ma(2)">
                    {{ codeTxt }}
                  </div>
                </div>
              </div>
            </div>
            <div class="align-center" style="margin-top: 46px">
              <div
                class="btn2 align-center pointer"
                @click="dialogVisible1 = false"
              >
                取消
              </div>

              <div
                class="btn1 align-center pointer"
                @click="fnNext"
                v-if="phoneType == 1"
              >
                下一步
              </div>
              <div class="btn1 align-center pointer" @click="fnSure(1)" v-else>
                保存
              </div>
            </div>
          </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible2" width="980px">
          <div class="dialog">
            <div class="dialog_title">修改登录密码</div>
            <div
              class="align-between font-333"
              style="margin-top: 36px; padding: 0 70px"
            >
              <div class="align-start">
                <div class="width115">
                  <span class="cor_FA9C22">*</span
                  >{{ data.user_password ? '新' : '' }}密码:
                </div>
                <input
                  type="text"
                  name=""
                  value=""
                  v-model="pwd"
                  placeholder="请输入"
                  class="dialog_input"
                  style="width: 744px"
                />
              </div>
            </div>
            <div class="align-center" style="margin-top: 46px">
              <div
                class="btn2 align-center pointer"
                @click="dialogVisible2 = false"
              >
                取消
              </div>
              <div class="btn1 align-center pointer" @click="fnSure(2)">
                保存
              </div>
            </div>
          </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible4" width="980px">
          <div class="dialog">
            <div class="dialog_title">修改邮箱</div>
            <div
              class="align-between font-333"
              style="margin-top: 36px; padding: 0 70px"
            >
              <div class="align-start">
                <div class="width115">
                  <span class="cor_FA9C22">*</span>新邮箱:
                </div>
                <input
                  type="text"
                  name=""
                  value=""
                  v-model="email1"
                  placeholder="请输入"
                  class="dialog_input"
                  style="width: 744px"
                />
              </div>
            </div>
            <div class="align-center" style="margin-top: 46px">
              <div
                class="btn2 align-center pointer"
                @click="dialogVisible4 = false"
              >
                取消
              </div>
              <div class="btn1 align-center pointer" @click="fnSure(5)">
                保存
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import right from '@/components/right.vue'
import {
  showUserBase,
  updateUserBase,
  checkOldPhone,
  bandNewPhone,
  getPost,
  getArea,
  getIndustry,
  sendCode,
} from '../api'
export default {
  components: {
    Header,
    Footer,
    MyInfo,
    right,
  },
  data() {
    return {
      codeTxt: '获取验证码',
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible4: false,
      sheng: [],
      shi: [],
      data: {},
      post: [],
      phone1: '',
      code1: '',
      phone2: '',
      code2: '',
      isOnclick: true,
      onClickTime: '',
      isinerval: '',
      onClickTime1: '',
      phoneType: 1,
      pwd: '',
      pwd1: '',
      industry1: [],
      industry2: [],
      email: '',
      email1: '',

      txt111: '修改手机号',
    }
  },
  methods: {
    fnOpen(type) {
      this['dialogVisible' + type] = true
      if (type == 1) {
        this.phone1 = this.data.user_phone
        this.txt111 = '修改手机号'
      }
    },
    fnNext() {
      if (!this.phone1) {
        this.$message.error('请输入您的旧手机号')
        return
      }
      if (!this.code1) {
        this.$message.error('请输入验证码')
        return
      }
      let params = {
        phone: this.phone1,
        code: this.code1,
      }
      checkOldPhone(params).then((data) => {
        if (data.status == 200) {
          this.phoneType = 2
          this.codeTxt = '获取验证码'
          this.txt111 = '绑定新手机号'
          this.isOnclick = true
          clearInterval(this.isinerval)
        }
      })
    },
    fnSure(type) {
      if (type == 1) {
        if (!this.phone2) {
          this.$message.error('请输入您的新手机号')
          return
        }
        if (!this.code2) {
          this.$message.error('请输入验证码')
          return
        }
        let params = {
          phone: this.phone2,
          code: this.code2,
        }
        bandNewPhone(params).then((data) => {
          if (data.status == 200) {
            this['dialogVisible' + type] = false
            this.codeTxt = '获取验证码'
            this.isOnclick = true
            clearInterval(this.isinerval)
            this.phoneType = 1
            this.data.user_phone = this.phone2
          }
        })
      } else if (type == 2) {
        if (!this.pwd) {
          this.$message.error('请输入您的新密码')
          return
        }
        let params = {
          user_password: this.pwd,
        }
        updateUserBase(params).then((data) => {
          if (data.status == 200) {
            this.data.password = this.pwd
            this['dialogVisible' + type] = false
            this.pwd = ''
          }
        })
      } else if (type == 4) {
        let params
        if (!this.data.user_email) {
          if (!this.email) {
            this.$message.error('请输入您的邮箱')
            return
          }
          params = {
            user_email: this.email,
          }
          updateUserBase(params).then((data) => {
            if (data.status == 200) {
              this.data.user_email = this.email
            }
          })
        } else {
          this['dialogVisible' + type] = true
        }
      } else if (type == 5) {
        if (!this.email1) {
          this.$message.error('请输入您的新邮箱')
          return
        }
        let params = {
          user_email: this.email1,
        }
        updateUserBase(params).then((data) => {
          if (data.status == 200) {
            this.dialogVisible4 = false
            this.data.user_email = this.email1
            this.email1 = ''
          }
        })
      } else if (type == 6) {
        if (!this.data.user_password) {
          if (!this.pwd1) {
            this.$message.error('请输入您的密码')
            return
          }
          let params = {
            user_password: this.pwd1,
          }
          updateUserBase(params).then((data) => {
            if (data.status == 200) {
              this.data.password = this.pwd1
            }
          })
        } else {
          this.dialogVisible2 = true
        }
      } else {
        if (Boolean(this.data.user_province) != Boolean(this.data.user_city)) {
          this.$message.error('请选择所属区域')
          return
        }
        if (!this.data.user_industry_second_cate) {
          this.$message.error('请选择行业类别')
          return
        }
        if (!this.data.user_buy) {
          this.$message.error('请输入采购意向')
          return
        }
        let params = {
          user_username: this.data.user_username,
          // user_email: this.data.user_email,
          industry_first_cate: this.data.user_industry_first_cate,
          industry_second_cate: this.data.user_industry_second_cate,
          province: this.data.user_province,
          city: this.data.user_city,
          post_id: this.data.user_post,
          user_product: this.data.user_product,
          user_buy: this.data.user_buy,
        }
        updateUserBase(params).then((data) => {
          if (data.status == 200) {
            this.$message.success('保存成功')
            this.initData(1)
          }
        })
      }
    },

    initData(type) {
      let params = {}
      showUserBase(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          if (this.data.user_email) {
            this.readonlyEmail = true
          } else {
            this.readonlyEmail = false
          }
          let all = JSON.parse(localStorage.getItem('allUser'))
          // console.log(all);
          all.user_username = data.msg.user_username || ''
          all.user_phone = data.msg.user_phone || ''
          localStorage.setItem('allUser', JSON.stringify(all))
          // console.log(localStorage.getItem('allUser'))
          for (let key in this.data) {
            if (!this.data[key]) {
              this.data[key] = ''
            }
          }
          if (!type) {
            this.initSsq()
          }
        }
      })
    },
    initPost() {
      let params = {}
      getPost(params).then((data) => {
        if (data.status == 200) {
          this.post = data.msg
        }
      })
    },
    handleChange(val, type) {
      let obj = {}
      if (type == 1) {
        obj = this.sheng.find((item) => item.district_id == val)
        this.shi = obj.items
        this.data.user_city = ''
      }
    },
    handleChange1(val, type) {
      let obj = {}
      if (type == 1) {
        getIndustry({ ic_id: val }).then((data) => {
          this.industry2 = data.msg
          this.data.user_industry_second_cate = ''
        })
      }
    },
    initSsq() {
      let params = {}
      getArea(params).then((data) => {
        if (data.status == 200) {
          this.sheng = data.data
          console.log(this.data.user_province + '|' + this.data.user_city)
          if (this.data.user_province > 0) {
            let obj = this.sheng.find(
              (item) => item.district_id == this.data.user_province
            )
            this.shi = obj.items
          }
        }
      })
    },
    initIndustry() {
      let params = {}
      getIndustry(params).then((data) => {
        if (data.status == 200) {
          this.industry1 = data.msg
          if (this.data.user_industry_first_cate > 0) {
            getIndustry({ ic_id: this.data.user_industry_first_cate }).then(
              (data) => {
                this.industry2 = data.msg
              }
            )
          }
        }
      })
    },
    // /验证手机号
    need_ma(type) {
      let regBox = {
        regMobile: /^1[3456789]\d{9}$/, //手机
      }
      let rPhone = regBox.regMobile.test(this['phone' + type])
      console.log(type)
      if (!(rPhone && this['phone' + type])) {
        this.$message.error('请输入正确的手机号')
        return
      }
      if (this.isOnclick) {
        //调用接口判断
        this.sendSMS(this['phone' + type])
      }
    },
    //获取验证码
    sendSMS(phone) {
      let params = {
        phone: phone,
      }
      sendCode(params).then((data) => {
        if (data.status == 200) {
          if (this.isOnclick) {
            this.onClickTime = Math.floor(new Date().getTime() / 1000)
            this.isinerval = setInterval(this.CountDown, 1000)
            this.isOnclick = false
          }
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    //验证码60秒倒数
    CountDown() {
      this.onClickTime1 =
        60 - (Math.floor(new Date().getTime() / 1000) - this.onClickTime)
      if (this.onClickTime1 < 1) {
        this.codeTxt = '重新获取'
        clearInterval(this.isinerval)
        this.isOnclick = true
        return
      }
      this.codeTxt = this.onClickTime1 + 's'
    },
  },
  mounted() {
    document.title = '本牛'
    this.initData()
    this.initPost()
    this.initIndustry()
  },
}
</script>

<style scoped>
.code111 {
  width: 166px;
  height: 36px;
  border-radius: 1px;
  border: 1px solid #fa9c22;
  box-sizing: border-box;
  margin-left: 32px;
  font-size: 16px;
  font-weight: 500;
  color: #fa9c22;
}
.dialog_title {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 70px;
  padding-left: 40px;
  border-bottom: 1px solid #eeeeee;
  border-radius: 4px 4px 0 0;
}
::v-deep .el-dialog {
  border-radius: 4px;
}
::v-deep .el-dialog__body {
  padding: 0;
  padding-bottom: 42px;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__headerbtn {
  position: absolute;
  top: 22px;
  right: 40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 24px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #979797;
}
.my_order {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 26px;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}
.order_box {
  background: #ffffff;
  border: 1px solid #eeeeee;
}
input[type='text'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 32px;
  padding: 0 12px;
  color: #333333;
  font-size: 16px;
  outline: none;
  font-weight: normal;
}
input[type='text']::-webkit-input-placeholder {
  color: #999;
}
input[type='text']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
}

input[type='text'].dialog_input {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 36px;
  padding: 0 12px;
  color: #333333;
  font-size: 16px;
  outline: none;
  font-weight: normal;
}
input[type='text'].dialog_input::-webkit-input-placeholder {
  color: #999;
}
input[type='text'].dialog_input:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
}

.btn {
  /* width: 177px;
  height: 32px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 4px; */
  font-size: 20px;
  color: #ffffff;
  font-weight: normal;
  width: 256px;
  height: 46px;
  background: #fa9c22;
  border-radius: 4px;
}

.btn2 {
  width: 168px;
  height: 46px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  margin-right: 60px;
  font-size: 20px;
  font-weight: 400;
  color: #999999;
}
.width102 {
  min-width: 102px;
  text-align: right;
  margin-right: 26px;
}
.btn1 {
  width: 168px;
  height: 46px;
  background: #fa9c22;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
}
.dialog {
  /* padding: 0 107px 20px 90px; */
}
.width115 {
  width: 94px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
::v-deep .el-input--mini .el-input__inner {
  border-radius: 0px;
  border: 1px solid #d8d8d8;
  height: 32px;
  font-size: 16px;
  /* color: #999; */
}

.el-select-dropdown__item{
  font-size: 16px;
}
.el-select-dropdown__item.selected {
  color: #333;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import './assets/font/font.css'
import Meta from 'vue-meta'
// 8392d0c015e1e33a1913b3d9b1ea2b3e
Vue.use(Meta)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.prototype.fnGoodsDetails = function (idd) {
  // this.$router.push({
  //   name: 'goodDetail',
  //   params: {
  //     id: idd + '.html',
  //   },
  // })
  window.open(
    this.$router.resolve({ name: 'goodDetail', params: { id: idd + '.html' } })
      .href,
    '_blank'
  )
}
Vue.prototype.toLogin = function () {
  this.$router.push({
    name: 'Login',
    params: {
      id: '.html',
    },
  })
}
Vue.prototype.toMain = function () {
  this.$router.push({
    name: 'Main',
    params: {},
  })
}
Vue.prototype.toHome = function () {
  this.$router.push({
    name: 'home',
    params: {},
  })
}

Vue.prototype.toZx = function (idd) {
  // this.$router.push({
  //   name: 'zixun2',
  //   params: {
  //     id: idd + '.html',
  //   },
  // })
  window.open(
    this.$router.resolve({ name: 'zixun2', params: { id: idd + '.html' } })
      .href,
    '_blank'
  )
}
Vue.prototype.toZx3 = function (idd) {
  // this.$router.push({
  //   name: 'zixun2',
  //   params: {
  //     id: idd + '.html',
  //   },
  // })
  window.open(
    this.$router.resolve({ name: 'zixun3', params: { id: idd + '.html' } })
      .href,
    '_blank'
  )
}
Vue.prototype.timeMD = function (time) {
  let time1=time.split(' ');
  let time2 = time1[0].split('-');
  let time3 = time2[1]+'/'+time2[2];
  return time3;
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

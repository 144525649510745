<template>
  <div>
    <Header :num111="1" />
    <el-image
      style="width: 100%; height: 396px;margin: 0 0 50px"
      :src="allBg"
      @click="openTui"
    ></el-image>
    <div class="middle1">
      <!-- <div class="align-start" style="margin: 18px 0">
        <div class="font-777 pointer" @click="toMain()">首页</div>
        <el-image
          style="width: 20px; height: 20px; margin: 0 2px"
          :src="require('@/assets/right_arrow2.png')"
          fit="cover"
        ></el-image>
        <div class="font-777 pointer" @click="fnShua">{{ showText }}</div>
        <div v-show="keyword" class="align-center">
          <el-image
            style="width: 20px; height: 20px; margin: 0 2px"
            :src="require('@/assets/right_arrow2.png')"
            fit="cover"
          ></el-image>
          <div class="font-777">
            搜索“<span class="cor_f08300">{{ keyword }}</span
            >”
          </div>
        </div>
      </div> -->

      <div style="margin-bottom: 19px">
        <div
          class="s_cate align-between-top"
          id="firstCate"
          ref="oneCate"
          :class="{ heightauto: !arrow1, height40: arrow1 }"
          v-show="firstCate.length > 0"
        >
          <div class="flex-wrap">
            <div class="s_cate_title align-end">一级分类</div>
            <div class="align-start cate_out flex-con">
              <div
                class="font-333-14 pointer fl"
                :class="{ cor_EC7902: !first_cate_id }"
                @click="fnFirstCate('', '')"
              >
                全部
              </div>
              <div
                class="font-333-14 pointer fl"
                v-for="(item, index) in firstCate"
                :class="{
                  cor_EC7902: first_cate_id == item.gc_id,
                }"
                :key="'firstCate_' + index"
                @click="fnFirstCate(item.gc_id, item.gc_url_one)"
              >
                {{ item.gc_name || '' }}
              </div>
            </div>
          </div>

          <div
            class="align-center more pointer"
            @click="fnMore(0)"
            v-show="firstCateH > 57"
          >
            <div class="font-666-14">更多</div>
            <el-image
              style="width: 11px; height: 7px; margin-left: 5px"
              :src="
                arrow1
                  ? require('@/assets/bottom_arrow2.png')
                  : require('@/assets/top_arrow2.png')
              "
              fit="cover"
            ></el-image>
          </div>
        </div>
        <div
          class="s_cate align-between-top"
          ref="twoCate"
          id="secondCate"
          :class="{ heightauto: !arrow1, height40: arrow1 }"
          v-show="secondCate.length > 0"
        >
          <div class="flex-wrap">
            <div class="s_cate_title align-end">二级分类</div>
            <div class="align-start cate_out flex-con">
              <div
                class="font-333-14 pointer fl"
                :class="{ cor_EC7902: !secondCateId }"
                @click="fnSecondCate('', '', '', '')"
              >
                全部
              </div>
              <div
                class="font-333-14 pointer fl"
                :class="{
                  cor_EC7902: secondCateId == item.gc_id,
                }"
                v-for="(item, index) in secondCate"
                :key="'secondCate_' + index"
                @click="
                  fnSecondCate(
                    item.gc_id,
                    item.gc_pid,
                    item.gc_url_one,
                    item.gc_url_two
                  )
                "
              >
                {{ item.gc_name || '' }}
              </div>
            </div>
          </div>

          <div
            class="align-center more pointer"
            @click="fnMore(1)"
            v-show="secondCateH > 57"
          >
            <div class="font-666-14">更多</div>
            <el-image
              style="width: 11px; height: 7px; margin-left: 5px"
              :src="
                arrow1
                  ? require('@/assets/bottom_arrow2.png')
                  : require('@/assets/top_arrow2.png')
              "
              fit="cover"
            ></el-image>
          </div>
        </div>
        <div
          class="s_cate align-between-top"
          v-show="thirdCate.length > 0"
          :class="{ heightauto: !arrow2, height40: arrow2 }"
          ref="threeCate"
          id="thirdCate"
        >
          <div class="flex-wrap">
            <div class="s_cate_title align-end flex-con">三级分类</div>
            <div class="align-start cate_out">
              <div
                class="font-333-14 pointer fl"
                :class="{ cor_EC7902: !thirdCateId }"
                @click="fnThirdCate('', '', '', '', '')"
              >
                全部
              </div>
              <div
                class="font-333-14 pointer fl"
                :class="{ cor_EC7902: thirdCateId == item.gc_id }"
                v-for="(item, index) in thirdCate"
                :key="'secondCate_' + index"
                @click="
                  fnThirdCate(
                    item.gc_id,
                    item.gc_pid,
                    item.gc_url_one,
                    item.gc_url_two,
                    item.gc_url_three
                  )
                "
              >
                {{ item.gc_name || '' }}
              </div>
            </div>
          </div>

          <div
            class="align-center more pointer"
            @click="fnMore(2)"
            v-show="thirdCateH > 57"
          >
            <div class="font-666-14">更多</div>
            <el-image
              style="width: 11px; height: 7px; margin-left: 5px"
              :src="
                arrow1
                  ? require('@/assets/bottom_arrow2.png')
                  : require('@/assets/top_arrow2.png')
              "
              fit="cover"
            ></el-image>
          </div>
        </div>
      </div>
      <div>
        <div v-if="data.length > 0">
          <!-- <div
            class="two pointer"
            v-for="(item, index) in data"
            :key="'goods_' + index"
            @click="fnGoodsDetails(item.goods_id)"
          >
            <img :src="item.goods_thumb" class="twoImg" />
            <div class="twoTitle aui-ellipsis-2">{{ item.goods_name }}</div>
            <div class="li-label-out">
              <div
                class="li-label"
                v-for="(item, index) in item.goods_tag"
                :key="'goods_tag_' + index"
              >
                {{ item }}
              </div>
            </div>
            <div class="price2">
              <span class="price3">￥</span
              >{{ item.goods_floor_price > 0 ? item.goods_floor_price : "面议" }}
            </div>
            <div class="btn bg_f08300">立即抢购</div>
          </div> -->
          <div
            class="one pointer"
            v-for="(item, index) in data"
            :key="'goods_' + index"
            @click="fnGoodsDetails(item.goods_id)"
          >
            <el-image
              :src="item.goods_thumb"
              fit="cover"
              class="oneImg"
            ></el-image>
            <div style="padding: 10px 20px 0">
              <div class="twoTitle aui-ellipsis-2">
                {{ item.goods_name }}
              </div>
              <div class="price2">
                ¥{{
                  item.goods_floor_price > 0 ? item.goods_floor_price : '面议'
                }}<span
                  class="font-333-bold"
                  style="position: relative; bottom: 1px"
                  >{{ item.goods_floor_price > 0 ? item.danwei : '' }}</span
                >
                <span class="hua-xian-jia" >
                                {{
                                  item.goods_old_price > 0 ? ('¥'+item.goods_old_price) : ''
                                }}
                              </span>
              </div>
            </div>

            <div class="align-center" style="padding: 20px 15px 17px">
              <!-- <div class="collect1 align-center" @click.stop="fnCollect(item.goods_id)">
                <el-image
                  :src="
                    item.is_collect == 1
                      ? require('@/assets/collect4.png')
                      : require('@/assets/collect3.png')
                  "
                  fit="cover"
                  style="width: 17px; height: 15px"
                  class="collect2"
                ></el-image>
              </div> -->

              <div class="buy1 align-center">
                <div class="shan"></div>
                <div>立即购买</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div style="text-align: center; margin: 225px auto">
            <el-image
              style="width: 160px; height: 160px; margin-bottom: 16px"
              :src="require('@/assets/quesheng/no_content.png')"
              fit="cover"
            ></el-image>
            <div class="font-666-14">商品完善中~</div>
          </div>
        </div>

        <div class="align-center">
          <Page
            :limit="limit"
            :totalNum="total"
            @onLimit="changeLimit"
            @onPage="changePage"
          />
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'
import {
  limit,
  goodsList,
  loginAgreement,
  secondAndThirdCate,
  selectGoodsCate,
  getGoodsCateInfo,
  goodsCate,
  tdkPages,
} from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    right,
  },
  data() {
    return {
      allBg: '',
      data: [],
      page: 1,
      limit: limit,
      total: 0,
      keyword: '',
      price_sort: 0,
      lower_price: '',
      high_price: '',
      goods_clear: 1,
      goods_hot: 0,
      showText: '尾货清仓',
      firstCate: [],
      secondCate: [],
      thirdCate: [],
      first_cate_id: '',
      secondCateId: '',
      thirdCateId: '',
      arrow0: false,
      arrow1: false,
      arrow2: false,
      firstCateH: '',
      secondCateH: '',
      thirdCateH: '',
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
      gc_url_one: '',
      gc_url_two: '',
      gc_url_three: '',
      tuiUrl: '',
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  mounted() {
    // console.log(this.$route)
    this.$bus.$on('keyword', (val) => {
      this.keyword = val || ''
      this.first_cate_id = ''
      this.page = 1
      this.initCate1()
      this.$router.push({
        path: `/sale.html`,
        query: {
          keyword: this.keyword,
        },
      })
    })
    this.fnLoginAgreement(5)
    this.initCate1()
    this.fnGetTdk(2)
    this.$router.push({
      path: `/sale.html`,
    })
  },
  methods: {
    openTui() {
      if (this.tuiUrl) {
        window.open(this.tuiUrl)
      }
    },
    fnGetTdk(type) {
      let params = {
        type: type,
      }
      tdkPages(params).then((data) => {
        if (data.status == 200) {
          this.tdkTitle = data.msg.title || '本牛'
          this.tdkKeywords = data.msg.keyword || '本牛'
          this.tdkDescription = data.msg.desc || '本牛'
        }
      })
    },
    fnShua() {
      this.first_cate_id = ''
      this.secondCateId = ''
      this.thirdCateId = ''
      this.keyword = ''
      this.page = 1
      this.initCate1()
      this.$bus.$emit('keywordGet')
      this.$router.push({
        path: `/sale.html`,
      })
    },
    initData() {
      let params = {
        page: this.page,
        price_sort: this.price_sort,
        lower_price: this.lower_price,
        high_price: this.high_price,
        goods_clear: this.goods_clear,
        goods_hot: this.goods_hot,
        first_cate_id: this.first_cate_id,
        second_cate_id: this.secondCateId,
        third_cate_id: this.thirdCateId,
        keyword: this.keyword,
      }
      goodsList(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count
        }
      })
    },
    initCate1(type) {
      this.arrow0 = false
      this.arrow1 = false
      this.arrow2 = false
      //所有分类
      let params = {
        type: 2,
        keyword: this.keyword,
        first_cate_id: this.first_cate_id || '',
      }
      if (type == 1) {
        params = {
          first_cate_id: '',
          second_cate_id: this.secondCateId || '',
          keyword: this.keyword,
          type: 2,
        }
      }
      secondAndThirdCate(params).then((data) => {
        if (data.status == 200) {
          if (type == 1) {
            this.thirdCate = data.thirdCate
            this.arrow2 = false
            if (!this.$refs.threeCate) {
              return
            }
            setTimeout(() => {
              this.thirdCateH = this.$refs.threeCate.offsetHeight
              if (this.thirdCateH > 40) {
                document.getElementById('thirdCate').style.Height = '40px'
                this.arrow2 = true
              } else {
                this.arrow2 = false
              }
            }, 0)
            this.initData()
            return
          }
          if (!(this.first_cate_id && this.keyword)) {
            this.firstCate = data.firstCate
          }
          this.secondCate = data.secondCate
          this.thirdCate = data.thirdCate
          this.secondCateId = ''
          this.thirdCateId = ''
          setTimeout(() => {
            if (
              !this.$refs.oneCate ||
              !this.$refs.twoCate ||
              !this.$refs.threeCate
            ) {
              return
            }
            if (this.firstCate.length > 0) {
              this.firstCateH = this.$refs.oneCate.offsetHeight
              if (this.firstCateH > 40) {
                document.getElementById('firstCate').style.Height = '40px'
                this.arrow0 = true
              } else {
                this.arrow0 = false
              }
            }
            if (this.secondCate.length > 0) {
              this.secondCateH = this.$refs.twoCate.offsetHeight
              if (this.secondCateH > 40) {
                document.getElementById('secondCate').style.Height = '40px'
                this.arrow1 = true
              } else {
                this.arrow1 = false
              }
            }
            if (this.thirdCate.length > 0) {
              this.thirdCateH = this.$refs.threeCate.offsetHeight
              if (this.thirdCateH > 40) {
                document.getElementById('thirdCate').style.Height = '40px'
                this.arrow2 = true
              } else {
                this.arrow2 = false
              }
            }
          }, 0)
          this.initData()
        }
      })
    },
    // initCate2() {
    //   //点击二级获取此二级下所有三级
    //   let params = {
    //     second_cate_id: this.secondCateId,
    //   };
    //   selectGoodsCate(params).then((data) => {
    //     if (data.status == 200) {
    //       this.thirdCate = data.thirdCate;
    //       this.arrow2 = false;
    //       setTimeout(() => {
    //         this.thirdCateH = this.$refs.threeCate.offsetHeight;
    //         if (this.thirdCateH > 40) {
    //           document.getElementById("thirdCate").style.Height = "40px";
    //           this.arrow2 = true;
    //         } else {
    //           this.arrow2 = false;
    //         }
    //       }, 0);
    //     }
    //   });
    //   this.initData();
    // },
    fnGetCateInfo() {
      //点击级别类目id获取所属上级级信息
      let params = {
        cate_id: this.secondCateId,
      }
      getGoodsCateInfo(params).then((data) => {
        if (data.status == 200) {
          this.first_cate_id = data.msg.gc_pid
        }
      })
    },
    fnFirstCate(id, gc_url) {
      this.first_cate_id = id
      this.secondCateId = ''
      this.thirdCateId = ''
      this.page = 1
      this.initCate1()
      if (id) {
        this.gc_url_one = gc_url
        if (this.keyword) {
          this.$router.push({
            path: `/sale/${gc_url}.html`,
            query: {
              keyword: this.keyword,
            },
          })
        } else {
          this.$router.push({
            path: `/sale/${gc_url}.html`,
          })
        }
        // this.tdkTitle = gc_title || "本牛";
        // this.tdkKeywords = gc_keyword || this.keyword || "本牛";
        // this.tdkDescription = gc_desc || "本牛";
      } else {
        this.gc_url_one = ''
        if (this.keyword) {
          this.$router.push({
            path: `/sale.html`,
            query: {
              keyword: this.keyword,
            },
          })
          // this.tdkTitle = "本牛";
          // this.tdkKeywords = this.keyword || "本牛";
          // this.tdkDescription = "本牛";
        } else {
          this.$router.push({
            path: `/sale.html`,
          })
        }
      }
    },
    fnSecondCate(id, pid, gc_url1, gc_url2) {
      if (pid) {
        this.first_cate_id = pid
        this.gc_url_one = gc_url1
      }
      this.secondCateId = id
      this.thirdCateId = ''
      this.page = 1
      if (!id) {
        this.initCate1()
      } else {
        // this.initCate2();
        this.initCate1(1)
      }
      if (id) {
        this.gc_url_two = gc_url2
        //document.title = gc_title
        if (this.keyword) {
          this.$router.push({
            path: `/sale/${gc_url1}/${gc_url2}.html`,
            query: {
              keyword: this.keyword,
            },
          })
        } else {
          this.$router.push({
            path: `/sale/${gc_url1}/${gc_url2}.html`,
          })
        }
        // this.tdkTitle = gc_title || "本牛";
        // this.tdkKeywords = gc_keyword || this.keyword || "本牛";
        // this.tdkDescription = gc_desc || "本牛";
      } else {
        this.gc_url_two = ''
        if (this.keyword) {
          if (this.gc_url_one) {
            this.$router.push({
              path: `/sale/${this.gc_url_one}.html`,
              query: {
                keyword: this.keyword,
              },
            })
          } else {
            this.$router.push({
              path: `/sale.html`,
              query: {
                keyword: this.keyword,
              },
            })
            // this.tdkTitle = "本牛";
            // this.tdkKeywords = this.keyword || "本牛";
            // this.tdkDescription = "本牛";
          }
        } else {
          if (this.gc_url_one) {
            this.$router.push({
              path: `/sale/${this.gc_url_one}.html`,
            })
          } else {
            this.$router.push({
              path: `/sale.html`,
            })
          }
        }
      }
    },
    fnThirdCate(id, pid, gc_url1, gc_url2, gc_url3) {
      if (pid) {
        if (pid != this.secondCateId) {
          this.secondCateId = pid
          if (!this.first_cate_id) {
            this.fnGetCateInfo()
          }
        }
      }
      this.thirdCateId = id
      this.page = 1
      if (!id && this.secondCateId > 0) {
        // this.initCate2();
        this.initCate1()
      } else {
        this.initData()
      }
      if (id) {
        this.gc_url_one = gc_url1
        this.gc_url_two = gc_url2
        this.gc_url_three = gc_url3
        //document.title = gc_title
        if (this.keyword) {
          this.$router.push({
            path: `/sale/${gc_url1}/${gc_url2}/${gc_url3}.html`,
            query: {
              keyword: this.keyword,
            },
          })
        } else {
          this.$router.push({
            path: `/sale/${gc_url1}/${gc_url2}/${gc_url3}.html`,
          })
        }
        // this.tdkTitle = gc_title || "本牛";
        // this.tdkKeywords = gc_keyword || this.keyword || "本牛";
        // this.tdkDescription = gc_desc || "本牛";
      } else {
        this.gc_url_three = ''
        if (this.keyword) {
          if (this.gc_url_one && this.gc_url_two) {
            this.$router.push({
              path: `/sale/${this.gc_url_one}/${this.gc_url_two}.html`,
              query: {
                keyword: this.keyword,
              },
            })
          }
          if (this.gc_url_one && !this.gc_url_two) {
            this.$router.push({
              path: `/sale/${this.gc_url_one}.html`,
              query: {
                keyword: this.keyword,
              },
            })
          }
          if (!this.gc_url_one && !this.gc_url_two) {
            this.$router.push({
              path: `/sale.html`,
              query: {
                keyword: this.keyword,
              },
            })
            // this.tdkTitle = "本牛";
            // this.tdkKeywords = this.keyword || "本牛";
            // this.tdkDescription = "本牛";
          }
          // console.log(691)
        } else {
          if (this.gc_url_one && this.gc_url_two) {
            this.$router.push({
              path: `/sale/${this.gc_url_one}/${this.gc_url_two}.html`,
            })
          }
          if (this.gc_url_one && !this.gc_url_two) {
            this.$router.push({
              path: `/sale/${this.gc_url_one}.html`,
            })
          }
          // if (!this.gc_url_one && !this.gc_url_two) {
          //   this.$router.push({
          //     path: `/list`,
          //   })
          // }
        }
      }
    },
    fnLoginAgreement(type) {
      let params = {
        type: type,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          this.allBg = data.msg.content1 || ''
          this.tuiUrl = data.msg.content2 || ''
        }
      })
    },
    fnMore(num) {
      this['arrow' + num] = !this['arrow' + num]
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      this.initData()
    },
  },
}
</script>

<style scoped>
.buy1 {
  width: 219px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #ec7902;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #ec7902;
  box-sizing: border-box;
  /* padding-left: 37px; */
  line-height: 29px;
}
.buy1:hover {
  color: #ffffff;
  background: #ec7902;
}
.buy1 .shan {
  display: inline-block;
  width: 12px;
  height: 16px;
  background: url('@/assets/buy2.png') no-repeat center center;
  background-size: 100% 100%;
  margin-right: 10px;
  margin-top: 2px;
}
.buy1:hover .shan {
  background: url('@/assets/buy1.png') no-repeat center center;
  background-size: 100% 100%;
}
.btn_padding {
  padding: 4px 8px;
  background: #fff;
  border-radius: 5px;
}
.yi {
  width: 16px;
  height: 1px;
  background: #d8d8d8;
  margin: 0 4px;
}
.sure {
  width: 76px;
  height: 37px;
  background: #ec7902;
  border-radius: 5px;
  margin-left: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.s_cate_title {
  width: 119px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  box-sizing: border-box;
  padding-right: 31px;
}
.s_cate:last-child {
  border: none;
}
.s_cate {
  height: 56px;
  background: #ffffff;
  /* border-radius: 4px; */
  font-size: 12px;
  border-bottom: 1px solid #eee;
  overflow: hidden;
}
.cate_out {
  flex-flow: row wrap;
}
.cate_out div {
  line-height: 56px;
  margin-left: 19px;
}
.more {
  margin-top: 15px;
  margin-right: 14px;
  width: 82px;
  height: 27px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  box-sizing: border-box;
}
.s_tab {
  height: 50px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 14px;
  padding-right: 16px;
  box-shadow: 0px 3px 5px 0px rgba(75, 75, 75, 0.1);
}
.tab_item {
  width: 76px;
  height: 50px;
  color: #333;
}
.tab_item.active {
  width: 103px;
  height: 50px;
  background: #ec7902;
  color: #fff;
}

input[type='number'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 24px;
  min-width: 70px;
  padding: 0 12px;
  color: #333333;
  font-size: 12px;
  outline: none;
}
input[type='number']::-webkit-input-placeholder {
  color: #aaa;
}
input[type='number']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-right: none;
}

.two {
  position: relative;
  display: inline-block;
  width: calc((100% - 40px) / 5);
  height: 274px;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin-right: 10px;
  padding: 6px 8px;
  margin-bottom: 10px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: transform 0.5s, box-shadow 0.5s;
}
.two:hover {
  border: 0;
  transform: scale(1.02);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.twoImg {
  display: block;
  width: 126px;
  height: 126px;
  margin: 0 auto 1px;
}
.two:nth-child(5n + 5) {
  margin-right: 0;
}
.twoTitle {
  font-size: 16px;
  color: #333333;
  line-height: 20px;
  height: 41px;
  margin: 0 0 20px;
}
.price2 {
  font-size: 24px;
  font-weight: 500;
  color: #ec7902;
}
.price3 {
  font-size: 12px;
  font-weight: 400;
  /* color: #333333; */
}
.banner {
  height: 83px;
  width: 1080px;
  margin-top: 10px;
}
.label {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #dea422;
  border-radius: 2px;
  border: 1px solid;
  border-image: linear-gradient(
      90deg,
      rgba(239, 200, 69, 1),
      rgba(220, 160, 30, 1)
    )
    1 1;
  margin-top: 4px;
  padding: 3px 8px;
}

.btn {
  width: 100%;
  height: 30px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 5px;
}
.heightauto {
  height: auto;
}
.height40 {
  height: 56px;
}
.fl:hover {
  color: #ec7902;
}

.one {
  /* position: relative; */
  display: inline-block;
  width: calc((100% - 80px) / 5);
  /* height: 302px; */
  background: #ffffff;
  border-radius: 5px;
  margin-right: 20px;
  /* padding: 20px; */
  margin-bottom: 20px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: box-shadow 0.5s;
}
.one:hover {
  /* transform: scale(1.02); */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.one:nth-child(5n) {
  margin-right: 0;
}

.oneImg {
  display: block;
  width: 178px;
  height: 178px;
  margin: 0 auto;
}
</style>

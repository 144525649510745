<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top: 14px">
      <div>
        <MyInfo />
      </div>
      <div class="my_right flex-con">
        <div class="border_eee">
          <div class="align-between cheng_head11">
            <div class="font-333-20-bold">物流详情</div>
          </div>
          <div
            style="line-height: 52px; padding-left: 22px"
            class="font-333-16-bold"
          >
            订单编号：
            <span class="font-666-16">{{ data.order_number || '' }}</span>
          </div>
        </div>
        <div class="border_eee m_t16">
          <div class="align-between cheng_head11">
            <div class="font-333-20-bold">收货信息</div>
          </div>
          <div class="order_box">
            <div class="flex-wrap">
              <div
                class="flex-con"
                style="padding: 16px 0 0 22px; text-align: left"
              >
                <div class="font-333-16-bold flex-wrap" style="margin-bottom: 14px">
                  <div class="flex-con">
                    收件人：<span class="font-666-16">{{ data.order_name || '' }}</span>
                  </div>
                  <div class="flex-con">
                    收货电话：<span class="font-666-16">{{ data.order_phone || '' }}</span>
                  </div>
                </div>
                <div class="font-333-16-bold" style="margin-bottom: 14px">
                  收货地址：<span class="font-666-16">{{ data.order_address || '' }}</span>
                </div>
                <div class="font-333-16-bold" style="margin-bottom: 14px">
                  出发地：<span class="font-666-16">{{ data.order_go_self || '' }}</span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 目的地：<span class="font-666-16">{{ data.order_get_self || '' }}</span>
                </div>
                <div class="font-333-16-bold" style="margin-bottom: 14px">
                  物流信息：<span class="font-666-16">{{ data.order_express_self || '' }}</span>
                </div>
                <div class="font-333-16-bold" style="margin-bottom: 14px">
                  快递单号：<span class="font-666-16">{{ data.order_express || '' }}</span>
                  {{ data.order_express_no }} &nbsp;&nbsp;&nbsp;
                  <span @click="fnWl" class="pointer cor_f08300"
                    >详细信息</span
                  >
                </div>
                <div
                  class="font-333-16-bold"
                  style="margin-bottom: 14px"
                  v-if="wldata.status == 200"
                >
                  快递状态：<span class="font-666-16">{{ wldata.msg[0].status + wldata.msg[0].context }}</span>
                </div>
              </div>
            </div>
            <div style="padding: 0 20px 20px">
              <iframe
                :src="wldata.mapurl"
                height="340"
                width="100%"
                v-if="wldata.mapurl"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="dialogVisible" width="840px">
        <div class="dialog">
          <!-- <div class="align-start font-333" style="margin-bottom: 14px">
          <div class="width115">
            <a :href="orderUrl">{{ orderUrl }} 点击下载</a>
          </div>
        </div> -->
          <div v-if="wldata.status == 200">
            <!-- style="padding: 17px 20px 0; height: 180px; overflow-y: scroll" -->
            <div class="flex-wrap" v-for="(item, index) in wldata.msg">
              <img
                v-if="index == wldata.msg.length - 1"
                :src="require('@/assets/check2.png')"
                style="width: 18px; height: 18px"
                class="m-r10"
              />
              <img
                v-else
                :src="require('@/assets/checked21.png')"
                style="width: 18px; height: 18px"
                class="m-r10"
              />
              <div class="flex-con" style="position: relative">
                <div class="EFC845-14 cor_f08300" style="line-height: 18px">
                  {{ item.status }} {{ item.ftime }}
                </div>
                <div
                  style="position: relative; padding: 16px 0 20px 0"
                  class="font-777-12"
                >
                  {{ item.context }}
                  <div
                    class="wlXian"
                    v-if="index != wldata.msg.length - 1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import right from '@/components/right.vue'
import { getWlInfo } from '../api'
export default {
  components: {
    Header,
    Footer,
    MyInfo,
    right,
  },
  data() {
    return {
      order_id: '',
      data: '',
      wldata: '',
      dialogVisible: false,
    }
  },
  methods: {
    initData() {
      let params = {
        order_id: this.order_id,
      }
      getWlInfo(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.wldata = data.wldata
        }
      })
    },
    fnWl() {
      if (this.wldata.status == 200) {
        this.dialogVisible = true
      } else {
        this.$message.error('暂无快递信息')
      }
    },
  },
  mounted() {
    document.title = '本牛'
    let can = this.$route.params.id.split('.')[0]
    this.order_id = can
    this.initData()
  },
}
</script>

<style scoped>
.dialog {
  max-height: 600px;
  overflow-y: scroll;
}
.dialog::-webkit-scrollbar {
  display: none;
}
.my_order {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 26px;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}
.order_box {
  background: #ffffff;
  /* border-radius: 4px; */
  /* padding-bottom: 20px; */
}

.xian {
  width: 1px;
  height: 15px;
  background: #333333;
  border-radius: 1px;
}

.wlXian {
  width: 1px;
  height: 100%;
  background: #d8d8d8;
  position: absolute;
  left: -19px;
  top: 0;
}
</style>

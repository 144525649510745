<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top: 14px">
      <div>
        <MyInfo />
      </div>

      <div class="my_right flex-con">
        <div class="order_box" style="margin-bottom: 16px">
          <div class="align-start cheng_head11">
            <div class="font-333-20-bold">我的订单</div>
          </div>
          <div class="align-start" style="height: 56px; padding-left: 22px">
            <div class="font-333-16-bold">
              订单编号：<span class="font-666-16">{{ order_number }}</span>
            </div>
          </div>
        </div>

        <div class="order_box" style="padding-bottom: 35px">
          <div class="align-start cheng_head11" style="margin-bottom: 16px">
            <div class="font-333-20-bold">申请售后</div>
          </div>
          <div class="font-333-16-bold align-start" style="margin-bottom: 16px">
            <div class="width114">售后类型：</div>
            <el-select
              v-model="afc_name"
              placeholder="请选择售后类型"
              size="mini"
            >
              <el-option
                v-for="item in data"
                :key="item.afc_id"
                :label="item.afc_name"
                :value="item.afc_name"
              >
              </el-option>
            </el-select>
          </div>
          <div class="font-333-16-bold align-start" style="margin-bottom: 16px">
            <div class="width114">售后原因：</div>
            <input
              type="text"
              name=""
              value=""
              placeholder="请输入"
              v-model="reason"
            />
          </div>

          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            name="file"
            value=""
            id="fileImg"
            @change="uploadData"
            style="margin-bottom: 10px"
            class="input1"
          />
          <div
            v-for="(item, index) in imgSmall"
            :key="index"
            class="font-333-12"
          >
            {{ item }}
          </div>
          <div class="btn align-center pointer" @click="fnTi">提交</div>
        </div>
      </div>
    </div>
    <!-- <div class="font-333 align-top" style="margin-bottom: 14px">
        <div style="margin-right: 16px">附件上传:</div>
        <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div> -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import right from '@/components/right.vue'
import { afterSaleCate, afterSaleOrder, uploadFile } from '../api'
export default {
  components: {
    Header,
    Footer,
    MyInfo,
    right,
  },
  data() {
    return {
      data: [],
      afc_name: '',
      order_id: '',
      order_number: '',
      imgs: [],
      imgSmall: [],
      reason: '',
    }
  },
  methods: {
    initSaleCate() {
      let params = {}
      afterSaleCate(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
        }
      })
    },
    fnTi() {
      if (!this.afc_name) {
        this.$message.error('请选择售后类型')
        return
      }
      let params = {
        order_id: this.order_id,
        type: this.afc_name,
        reason: this.reason,
        imgs: this.imgs.join(','),
      }
      afterSaleOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success('申请成功')
          this.$router.go(-1)
        }
      })
    },
    uploadData(e) {
      let files = e.target.files[0]
      let formData = new FormData()
      formData.append('file', files)
      // console.log(files)
      this.imgSmall.push(files.name)
      let _self = this
      return new Promise((resolve, reject) => {
        uploadFile(formData)
          .then((response) => {
            this.imgs.push(response.msg)
            // console.log('93||' + response.msg)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
  },
  mounted() {
    document.title = '本牛'
    let can = this.$route.params.id.split('.')[0]
    let can1 = can.split('-')
    this.order_id = can1[0]
    this.order_number = can1[1]
    this.initSaleCate()
  },
}
</script>

<style scoped>
.width114 {
  /* min-width: 114px; */
  padding-left: 22px;
  text-align: right;
  margin-right: 10px;
}
.my_order {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 26px;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}
.order_box {
  background: #ffffff;
  /* border-radius: 4px; */
  /* min-height: 624px; */
  box-sizing: border-box;
  /* padding: 16px 26px; */
  text-align: left;
  /* padding-right: 32px; */

  border: 1px solid #eeeeee;
}
input[type='text'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 32px;
  width: 870px;
  padding: 0 12px;
  color: #333333;
  font-size: 16px;
  outline: none;
}
input[type='text']::-webkit-input-placeholder {
  color: #999;
}
input[type='text']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
}

.btn {
  width: 256px;
  height: 46px;
  background: #fa9c22;
  border-radius: 4px;
  font-size: 20px;
  color: #ffffff;
  margin: 30px auto 0;
}
::v-deep .el-input--mini .el-input__inner {
  border-radius: 0px;
  border: 1px solid #d8d8d8;
  height: 32px;
  font-size: 16px;
  width: 870px;
  /* color: #999; */
}

.input1::file-selector-button {
  width: 101px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 3px;
  margin-right: 18px;
  cursor: pointer;
  margin-left: 22px;
}
.input1::-ms-browse {
  width: 101px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 3px;
  cursor: pointer;
}

.input1 {
  font-size: 16px;
  font-weight: 400;
  color: #6eaaf9;
}

.el-select-dropdown__item {
  font-size: 16px;
}
.el-select-dropdown__item.selected {
  color: #333;
}
</style>

<template>
  <div>
    <Header />
    <div style="margin: 14px auto 20px; width: 1300px">
      <!-- <div class="align-start" style="margin: 14px 0">
        <div class="font-777 pointer" @click="toMain()">首页</div>
        <el-image
          style="width: 20px; height: 20px; margin: 0 2px"
          :src="require('@/assets/right_arrow2.png')"
          fit="cover"
        ></el-image>
        <div class="font-777">
          {{ type == 1 ? fwb.service_title : fwb.about_title }}
        </div>
      </div> -->
      <div class="flex-wrap" style="width: 1300px; margin: auto">
        <div class="my_menu">
          <div
            class="align-start order1 pointer"
            :class="{ order1_active: switch1 }"
            @click="fnSwitch(1)"
            @mouseenter="mouseenterOut(1)"
            @mouseleave="mouseleaveOut(1)"
          >
            <el-image
              style="width: 26px; height: 26px; margin: 0 22px 0 26px"
              :src="
                switch1 || show1
                  ? require('@/assets/about1.png')
                  : require('@/assets/about11.png')
              "
              fit="cover"
            ></el-image>
            <div class="">服务政策</div>
            <el-image
              :style="
                switch1
                  ? 'width: 11px; height: 7px; margin-left: 59px'
                  : 'width: 7px; height: 11px; margin-left: 61px'
              "
              :src="
                switch1
                  ? require('@/assets/bottom_arrow2.png')
                  : require('@/assets/arrow_right3.png')
              "
              fit="cover"
            ></el-image>
          </div>
          <div style="padding-left: 79px" class="pointer" v-if="switch1">
            <div
              class="order1_item font-666-18 align-start"
              v-for="(item, index) in data3"
              :key="'data3_' + index"
              @click="fnFw(item.service_id)"
              :class="{ FF940A: type == 1 && item.service_id == idd }"
            >
              {{ item.service_title }}
            </div>
          </div>

          <div
            class="align-start order1 pointer"
            :class="{ order1_active: switch2 }"
            @click="fnSwitch(2)"
            @mouseenter="mouseenterOut(2)"
            @mouseleave="mouseleaveOut(2)"
          >
            <el-image
              style="width: 26px; height: 26px; margin: 0 22px 0 26px"
              :src="
                switch2 || show2
                  ? require('@/assets/about2.png')
                  : require('@/assets/about22.png')
              "
              fit="cover"
            ></el-image>
            <div class="">关于我们</div>
            <el-image
              :style="
                switch2
                  ? 'width: 11px; height: 7px; margin-left: 59px'
                  : 'width: 7px; height: 11px; margin-left: 61px'
              "
              :src="
                switch2
                  ? require('@/assets/bottom_arrow2.png')
                  : require('@/assets/arrow_right3.png')
              "
              fit="cover"
            ></el-image>
          </div>
          <div style="padding-left: 79px" class="pointer" v-if="switch2">
            <div
              class="order1_item font-666-18 align-start"
              v-for="(item, index) in data1"
              :key="'data1_' + index"
              @click="fnAboutUsDetail(item.about_id)"
              :class="{ FF940A: type == 2 && item.about_id == idd }"
            >
              {{ item.about_title }}
            </div>
          </div>

          <!-- <div class="align-start" style="margin-top: 20px">
            <el-image
              style="width: 20px; height: 20px; margin-right: 7px"
              :src="require('@/assets/order_zx.png')"
              fit="cover"
            ></el-image>
            <div class="font-333">服务政策</div>
          </div>
          <div
            class="font-333 pointer"
            style="margin-top: 7px; padding-left: 27px"
            v-for="(item, index) in data3"
            :key="'data3_' + index"
            @click="fnFw(item.service_id)"
            :class="{ cor_f08300: type == 1 && item.service_id == idd }"
          >
            {{ item.service_title }}
          </div> -->

          <!-- <div class="align-start" style="margin-top: 20px">
            <el-image
              style="width: 20px; height: 20px; margin-right: 7px"
              :src="require('@/assets/aboutus.png')"
              fit="cover"
            ></el-image>
            <div class="font-333">关于我们</div>
          </div>
          <div
            class="font-333 pointer"
            style="margin-top: 7px; padding-left: 27px"
            v-for="(item, index) in data1"
            :key="'data1_' + index"
            @click="fnAboutUsDetail(item.about_id)"
            :class="{ cor_f08300: type == 2 && item.about_id == idd }"
          >
            {{ item.about_title }}
          </div> -->
        </div>
        <div class="flex-con" style="padding-left: 10px">
          <div
            style="
              background: #ffffff;
              min-height: 1155px;
              padding: 47px 30px;
              box-sizing: border-box;
            "
          >
            <div
              style="
                text-align: center;
                margin-bottom:30px;
              "
              class="font-333-24"
            >
              {{ type == 1 ? fwb.service_title : fwb.about_title }}
            </div>
            <div
              class="font-666-18 fwb"
              v-html="type == 1 ? fwb.service_content : fwb.about_content"
              style="overflow-y: scroll; max-height: 1060px ;line-height: 36px;"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import {
  aboutUs,
  servicePolicy,
  policyDetail,
  aboutUsDetail,
  tdkPages,
} from '../api'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  data() {
    return {
      type: '',
      idd: '',
      data1: [],
      data3: [],
      fwb: '',
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
      switch1: true,
      switch2: true,
      show1: false,
      show2: false,
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  mounted() {
    this.fnAboutUs()
    this.fnServicePolicy()
    let can = this.$route.params.id.split('.')[0].split('-')
    this.type = can[0]
    this.idd = can[1]
    // this.type == 1 ? (this.switch1 = true) : (this.switch1 = false)
    // this.type == 2 ? (this.switch2 = true) : (this.switch2 = false)
    if (this.type == 1) {
      this.fnFw(this.idd)
    } else {
      this.fnAboutUsDetail(this.idd)
    }
    this.$bus.$on('footer', (val, idd) => {
      this.type = val
      this.idd = idd
      if (this.type == 1) {
        this.fnFw(this.idd)
      } else {
        this.fnAboutUsDetail(this.idd)
      }
    })
  },
  methods: {
    mouseenterOut(index) {
      this['show' + index] = true
    },
    mouseleaveOut(index) {
      this['show' + index] = false
    },
    fnSwitch(num1) {
      this['switch' + num1] = !this['switch' + num1]
    },
    fnGetTdk(type) {
      let params = {
        type: type,
      }
      tdkPages(params).then((data) => {
        if (data.status == 200) {
          this.tdkTitle = data.msg.title || '本牛'
          this.tdkKeywords = data.msg.keyword || '本牛'
          this.tdkDescription = data.msg.desc || '本牛'
        }
      })
    },
    fnAboutUs() {
      let params = {}
      aboutUs(params).then((data) => {
        if (data.status == 200) {
          this.data1 = data.msg
        }
      })
    },
    fnServicePolicy() {
      let params = {}
      servicePolicy(params).then((data) => {
        if (data.status == 200) {
          this.data3 = data.msg
        }
      })
    },
    fnFw(service_id) {
      this.$router.push({
        name: 'About',
        params: {
          id: 1 + '-' + service_id + '.html',
        },
      })
      this.type = 1
      this.idd = service_id
      let params = {
        service_id: service_id,
      }
      policyDetail(params).then((data) => {
        if (data.status == 200) {
          this.fwb = data.msg
          document.documentElement.scrollTop = 0
          this.fnGetTdk(7)
        }
      })
    },
    fnAboutUsDetail(about_id) {
      this.$router.push({
        name: 'About',
        params: {
          id: 2 + '-' + about_id + '.html',
        },
      })
      this.type = 2
      this.idd = about_id
      let params = { about_id: about_id }
      aboutUsDetail(params).then((data) => {
        if (data.status == 200) {
          this.fwb = data.msg
          document.documentElement.scrollTop = 0
          this.fnGetTdk(8)
        }
      })
    },
  },
}
</script>

<style scoped>
.order1 {
  height: 48px;
  font-size: 18px;
  color: #666;
  font-weight: 500;
  border-left: 4px solid #fff;
}
.order1_active {
  border-left: 4px solid #ff940a;
  color: #ff940a;
  background-color: rgba(255, 226, 189, 0.2);
}
.order1:hover {
  border-left: 4px solid #ff940a;
  color: #ff940a;
  background-color: rgba(255, 226, 189, 0.2);
}
.order1_item {
  height: 48px;
}
.order1_item:hover {
  color: #ff940a;
}
.my_menu {
  width: 256px;
  min-width: 256px;
  height: 1155px;
  background: #ffffff;
  /* border-radius: 0px 0px 4px 4px; */
  text-align: left;
  /* padding-left: 48px; */
  box-sizing: border-box;
}
.fwb::-webkit-scrollbar {
  display: none;
}
</style>

<template>
  <div>
    <Header />
    <div class="middle1" style="margin-top: 14px">
      <div style="background: #ffffff; border-radius: 4px">
        <div
          style="padding: 20px; border-bottom: 1px solid #d8d8d8"
          class="flex-wrap"
        >
          <el-image
            style="width: 80px; height: 80px; margin-right: 20px"
            :src="store.company_logo || require('@/assets/shop_head.png')"
            fit="cover"
          ></el-image>
          <div class="flex-con">
            <div class="align-between" style="margin-bottom: 12px">
              <div class="font-333-20">{{ store.company_name || '' }}</div>
              <!-- <div class="align-center pointer">
                <div class="font-aaa">查看详情</div>
                <el-image
                  style="width: 18px; height: 18px; margin-right: 4px"
                  :src="require('@/assets/arrow2.png')"
                  fit="cover"
                ></el-image>
              </div> -->
            </div>
            <div class="font-333">
              {{ store.company_intro || '' }}
            </div>
          </div>
        </div>
        <div style="padding: 0 20px" class="font-333-16-22 align-start">
          <div
            class="shop_tab pointer"
            :class="{ active: type111 }"
            @click="fnQieHead(true)"
          >
            首页
          </div>
          <div
            class="shop_tab pointer"
            :class="{ active: !type111 }"
            @click="fnQieHead(false)"
          >
            店铺详情
          </div>
        </div>
      </div>
      <div v-show="type111">
        <div class="shop_title font-fff-16-bold">
          <div class="shop_title_bg align-center">热卖推荐专区</div>
        </div>

        <div>
          <div
            class="two pointer"
            v-for="(item, index) in dataHot"
            :key="'goodsHot_' + index"
            @click="fnGoodsDetails(item.goods_id)"
          >
            <img :src="item.goods_thumb" class="twoImg" />
            <div class="twoTitle aui-ellipsis-2">{{ item.goods_name }}</div>
            <div class="li-label-out">
              <div
                class="li-label"
                v-for="(item, index) in item.goods_tag"
                :key="'goodsHot_tag_' + index"
              >
                {{ item }}
              </div>
            </div>
            <div class="price2">
              <span class="price3">￥</span
              >{{
                item.goods_floor_price > 0 ? item.goods_floor_price : '面议'
              }}
            </div>
            <div class="btn bg_f08300">立即抢购</div>
          </div>
        </div>
        <div class="shop_title font-fff-16-bold" style="margin-top: 24px">
          <div class="shop_title_bg align-center">所有商品</div>
        </div>
        <div>
          <div
            class="s_cate align-between-top"
            :class="{ heightauto: !arrow1 }"
          >
            <div class="align-start cate_out">
              <div class="s_cate_title align-center">二级分类</div>
              <div
                class="font-333-12 pointer fl"
                :class="{ cor_f08300: !secondCateId }"
                @click="fnSecondCate('', '')"
              >
                全部
              </div>
              <div
                class="font-333-12 pointer fl"
                :class="{
                  cor_f08300: secondCateId == item.gc_id,
                }"
                v-for="(item, index) in secondCate"
                :key="'secondCate_' + index"
                @click="fnSecondCate(item.gc_id, item.gc_name)"
              >
                {{ item.gc_name || '' }}
              </div>
            </div>
            <div
              class="btn_padding align-center more pointer"
              @click="fnMore(1)"
            >
              <div class="font-777">更多</div>
              <el-image
                style="width: 20px; height: 20px; margin-left: 6px"
                :src="
                  arrow1
                    ? require('@/assets/bottom_arrow1.png')
                    : require('@/assets/top_arrow1.png')
                "
                fit="cover"
              ></el-image>
            </div>
          </div>
          <div
            class="s_cate align-between-top"
            :class="{ heightauto: !arrow2 }"
          >
            <div class="align-start cate_out">
              <div class="s_cate_title align-center">三级分类</div>
              <div
                class="font-333-12 pointer fl"
                :class="{ cor_f08300: !thirdCateId }"
                @click="fnThirdCate('', '')"
              >
                全部
              </div>
              <div
                class="font-333-12 pointer fl"
                :class="{ cor_f08300: thirdCateId == item.gc_id }"
                v-for="(item, index) in thirdCate"
                :key="'secondCate_' + index"
                @click="fnThirdCate(item.gc_id, item.gc_name)"
              >
                {{ item.gc_name || '' }}
              </div>
            </div>
            <div
              class="btn_padding align-center more pointer"
              @click="fnMore(2)"
            >
              <div class="font-777">更多</div>
              <el-image
                style="width: 20px; height: 20px; margin-left: 6px"
                :src="
                  arrow2
                    ? require('@/assets/bottom_arrow1.png')
                    : require('@/assets/top_arrow1.png')
                "
                fit="cover"
              ></el-image>
            </div>
          </div>
        </div>
        <div class="s_tab align-between">
          <div class="tab_out align-start">
            <div class="tab_item align-center active" @click="fnPrice(1)">
              默认
            </div>
            <div class="tab_item align-center pointer" @click="fnPrice">
              价格
              <el-image
                style="width: 20px; height: 20px; margin-left: 4px"
                :src="require('@/assets/shai_icon' + price_sort + '.png')"
                fit="cover"
              ></el-image>
            </div>
            <input
              type="number"
              v-model="lower_price"
              placeholder="最低价"
              style="margin-left: 16px"
            />
            <div class="yi"></div>
            <input type="number" v-model="high_price" placeholder="最高价" />
            <div
              class="font-777-12 align-center sure pointer"
              @click="fnPriceTwo"
            >
              确定
            </div>
          </div>
          <!-- <div>
                  <span class="DCA01E">{{ total || 0 }}</span
                  >种
                </div> -->
        </div>
        <div>
          <div
            class="two pointer"
            v-for="(item, index) in data"
            :key="'goods_' + index"
            @click="fnGoodsDetails(item.goods_id)"
          >
            <img :src="item.goods_thumb" class="twoImg" />
            <div class="twoTitle aui-ellipsis-2">{{ item.goods_name }}</div>
            <div class="li-label-out">
              <div
                class="li-label"
                v-for="(item, index) in item.goods_tag"
                :key="'goods_tag_' + index"
              >
                {{ item }}
              </div>
            </div>
            <div class="price2">
              <span class="price3">￥</span
              >{{
                item.goods_floor_price > 0 ? item.goods_floor_price : '面议'
              }}
            </div>

            <div class="btn">立即抢购</div>
          </div>

          <div class="align-center">
            <Page
              :limit="limit"
              :totalNum="total"
              @onLimit="changeLimit"
              @onPage="changePage"
            />
          </div>
        </div>
      </div>
      <div>
        <div
          class="swiper-container"
          style="border-radius: 0"
          id="swiper-container1"
          :class="{ height0: type111 }"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide pointer"
              v-for="(item, index) in store.company_images"
              :key="'bigBanner_' + index"
            >
              <img :src="item" />
            </div>
          </div>
          <div
            class="swiper-pagination swiper111"
            style="
              width: 100%;
              height: 30px;
              position: absolute;
              bottom: 0;
              background-color: rgba(240, 131, 0, 0.4);
              padding-top: 15px;
            "
          ></div>
        </div>
        <div
          class="fwb font-333"
          v-show="!type111"
          v-html="store.company_detail || ''"
        ></div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'
import Swiper from 'swiper/bundle'
import {
  limit,
  goodsList,
  loginAgreement,
  secondAndThirdCate,
  selectGoodsCate,
  getGoodsCateInfo,
  goodsCate,
  storeInfo,
} from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    right,
  },
  data() {
    return {
      data: [],
      dataHot: [],
      page: 1,
      limit: limit,
      total: 0,
      keyword: '',
      price_sort: 0,
      lower_price: '',
      high_price: '',
      goods_clear: 0,
      goods_hot: 0,
      secondCate: [],
      secondCateOld: [],
      thirdCate: [],
      thirdCateOld: [],
      first_cate_id: '',
      secondCateId: '',
      thirdCateId: '',
      firstCateName: '',
      secondCateName: '',
      thirdCateName: '',
      type: '', //入口  0搜索 1一级 2二级 3三级
      arrow1: true,
      arrow2: true,
      type111: true,
      store_id: this.$route.query.store_id,
      store: {},
      ossSign: '',
    }
  },
  methods: {
    fnQieHead(bool) {
      // console.log(bool)
      this.type111 = bool
    },
    initData() {
      let params = {
        page: this.page,
        price_sort: this.price_sort,
        lower_price: this.lower_price,
        high_price: this.high_price,
        goods_clear: this.goods_clear,
        goods_hot: this.goods_hot,
        first_cate_id: this.first_cate_id,
        second_cate_id: this.secondCateId,
        third_cate_id: this.thirdCateId,
        keyword: this.keyword,
        store_id: this.store_id,
        ossSign: this.ossSign,
      }
      goodsList(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count
          if (data.ossUser) {
            localStorage.setItem('allUser', JSON.stringify(data.ossUser))
            setTimeout(() => {
              this.$bus.$emit('ossSignMain')
            }, 200)
          }
        }
      })
    },
    initDataHot() {
      let params = {
        page: 1,
        goods_clear: 0,
        goods_hot: 1,
        keyword: '',
        store_id: this.store_id,
      }
      goodsList(params).then((data) => {
        if (data.status == 200) {
          this.dataHot = data.msg
        }
      })
    },
    fnStoreInfo() {
      //店铺详情
      let params = {
        store_id: this.store_id,
      }
      storeInfo(params).then((data) => {
        if (data.status == 200) {
          this.store = data.msg
        }
      })
    },
    initCate1() {
      //所有分类
      let params = {}
      secondAndThirdCate(params).then((data) => {
        if (data.status == 200) {
          this.secondCateOld = this.secondCate = data.secondCate
          this.thirdCateOld = this.thirdCate = data.thirdCate
        }
      })
    },
    initCate2() {
      //点击二级获取此二级下所有三级
      let params = {
        second_cate_id: this.secondCateId,
      }
      selectGoodsCate(params).then((data) => {
        if (data.status == 200) {
          this.thirdCate = data.thirdCate
          this.thirdCateId = this.thirdCateId || ''
        }
      })
    },
    initCate3() {
      //点击三级获取所属二级信息
      let params = {
        cate_id: this.thirdCateId,
      }
      getGoodsCateInfo(params).then((data) => {
        if (data.status == 200) {
          this.secondCateId = data.msg.gc_pid
          this.thirdCateName = data.msg.gc_name
          // this.initCate4(2, this.secondCateId)
        }
      })
    },
    fnSecondCate(id, name) {
      this.secondCateName = name
      this.secondCateId = id
      this.thirdCateId = ''
      this.thirdCateName = ''
      this.page = 1
      if (!id) {
        this.secondCate = this.secondCateOld
        this.thirdCate = this.thirdCateOld
      } else {
        this.initCate2()
      }
      this.initData()
    },
    fnThirdCate(id, name) {
      this.thirdCateId = id
      this.thirdCateName = name
      this.page = 1
      if (id) {
        this.initCate3()
      }
      this.initData()
    },
    fnMore(num) {
      this['arrow' + num] = !this['arrow' + num]
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      this.initData()
    },
    fnPrice(type) {
      if (type) {
        this.price_sort = 0
      } else {
        if (this.price_sort < 2) {
          this.price_sort++
        } else {
          this.price_sort = 0
        }
      }
      this.initData()
    },
    fnPriceTwo() {
      if (this.lower_price && this.high_price) {
        if (Number(this.high_price) < Number(this.lower_price)) {
          this.$message.error('请输入正确价格')
        } else {
          this.initData()
        }
      } else {
        this.initData()
      }
    },
  },
  mounted() {
    document.title = '本牛'
    // let geturl = window.location.href
    // // console.log(geturl)
    // let getqyinfo = geturl.split('?')[1] //qycode=1001&qyname=%E4%  截取到参数部分
    // // console.log(getqyinfo)
    // let getqys = new URLSearchParams('?' + getqyinfo) //将参数放在URLSearchParams函数中
    // this.store_id = getqys.get('store_id')
    if(this.$route.query.store_id=='jingbeijituan'){
      this.store_id = '542';
    }else{
      this.store_id = this.$route.query.store_id;
    }
    this.ossSign = this.$route.query.ossSign

    this.fnStoreInfo()
    this.initDataHot()
    this.initCate1()
    this.initData()
    // this.fnQieHead(false);
    new Swiper('#swiper-container1', {
      autoplay: true,
      observer: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })

    // let can = this.$route.params.id.split('.')[0]
    // let can1 = can.split('-')
    // this.keyword = this.$route.params.keyword
    // console.log(can1)
    this.$bus.$on('keyword', (val) => {
      this.keyword = val
      this.initData()
    })
    if (this.keyword) {
      this.$bus.$emit('keywordGet', this.keyword)
    }

    // if (this.ossClear == 'clear') {
    //   localStorage.setItem('allUser', '')
    //   setTimeout(() => {
    //     this.$bus.$emit('login', 222)
    //   }, 200)
    //   return
    // }
  },
}
</script>

<style scoped>
@import url('swiper/swiper-bundle.css');
.btn_padding {
  padding: 1px 11px;
  border: 1px solid #d8d8d8;
}
.yi {
  width: 16px;
  height: 1px;
  background: #d8d8d8;
  margin: 0 4px;
}
.sure {
  height: 24px;
  width: 48px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  margin-left: 16px;
}

.s_cate_title {
  width: 104px;
  height: 40px;
  background: #ebebeb;
  border-radius: 4px 0px 0px 4px;
  color: #777777;
}
.s_cate {
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 10px;
  overflow: hidden;
}
.cate_out {
  flex-flow: row wrap;
}
.cate_out div:not(:first-child) {
  line-height: 40px;
  margin-left: 16px;
}
.more {
  box-sizing: border-box;
  min-width: 80px;
  height: 24px;
  margin: 8px 16px 0 0;
}
.s_tab {
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 10px;
  padding-right: 16px;
}
.tab_item {
  width: 80px;
  height: 40px;
}
.tab_item.active {
  background: #f08300;
  border-radius: 4px 0px 0px 4px;
  color: #ffffff;
}

input[type='number'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 24px;
  min-width: 70px;
  padding: 0 12px;
  color: #333333;
  font-size: 12px;
  outline: none;
}
input[type='number']::-webkit-input-placeholder {
  color: #aaa;
}
input[type='number']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-right: none;
}

.two {
  position: relative;
  display: inline-block;
  width: calc((100% - 40px) / 5);
  height: 274px;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin-right: 10px;
  padding: 6px 8px;
  margin-bottom: 10px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: transform 0.5s, box-shadow 0.5s;
}
.two:hover {
  border: 0;
  transform: scale(1.02);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.twoImg {
  display: block;
  width: 126px;
  height: 126px;
  margin: 0 auto 1px;
}
.two:nth-child(5n + 5) {
  margin-right: 0;
}
.twoTitle {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  height: 40px;
}
.price2 {
  font-size: 18px;
  color: #c52b33;
  margin-top: 4px;
  line-height: 25px;
  height: 27px;
}
.price3 {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.banner {
  height: 83px;
  width: 1080px;
  margin-top: 10px;
}
.label {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #dea422;
  border-radius: 2px;
  border: 1px solid;
  border-image: linear-gradient(
      90deg,
      rgba(239, 200, 69, 1),
      rgba(220, 160, 30, 1)
    )
    1 1;
  margin-top: 4px;
  padding: 3px 8px;
}

.btn {
  width: 100%;
  height: 30px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 5px;
}
.heightauto {
  height: auto;
}
.fl:hover {
  color: #f08300;
}

.shop_tab {
  padding: 9px 22px;
}
.shop_tab.active,
.shop_tab:hover {
  font-weight: 500;
  color: #f08300;
}

.shop_title {
  height: 44px;
  background: linear-gradient(
    90deg,
    rgba(239, 200, 69, 0.2) 0%,
    rgba(220, 160, 30, 0.2) 100%
  );
  border-radius: 4px;
  margin: 34px 0 20px;
}
.shop_title_bg {
  width: 416px;
  height: 48px;
  margin: auto;
  position: relative;
  top: -14px;
  background: url('@/assets/shop_bg2.png') no-repeat center center;
  background-size: 100% 100%;
  letter-spacing: 1px;
}
.fwb {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
}

.swiper-container {
  /* width: 590px; */
  height: 400px;
  /* border-radius: 8px; */
  overflow: hidden;
  margin: 20px 0;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: 16px;
}

.swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  display: inline-block;
  margin-right: 16px;
  opacity: 1;
}

::v-deep .swiper-pagination-bullet-active {
  width: 13px;
  height: 13px;
  background: #fff;
  display: inline-block;
  border-radius: 7px;
}

.height0 {
  height: 0;
}
</style>

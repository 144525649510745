<template>
  <div>
    <Header />
    <div class="middle1" style="margin-top: 14px">
      <div style="padding: 10px; background-color: #fff">
        <div class="contain border_eee">
          <div v-show="type == 3">
            <div class="xian1">
              <div class="cheng_head11 align-start">
                <el-image
                  style="width: 24px; height: 24px; margin-right: 16px"
                  :src="require('@/assets/checked11.png')"
                  fit="cover"
                ></el-image>
                <div class="font-333-20-bold">
                  <div>订单已提交，请尽快完成支付！</div>
                </div>
              </div>
              <div class="sao_box">
                <el-image
                  style="
                    width: 136px;
                    height: 136px;
                    margin: 0 auto 20px;
                    display: block;
                  "
                  :src="url"
                  fit="cover"
                ></el-image>
                <div class="align-center">
                  <el-image
                    style="width: 24px; height: 24px; margin-right: 10px"
                    :src="require('@/assets/sao.png')"
                    fit="cover"
                  ></el-image>
                  <div class="font-333">
                    打开手机{{ payTxt }} <br />
                    扫一扫继续付款
                  </div>
                </div>
              </div>
            </div>
            <div class="btn align-center pointer" @click="toOrder">
              我已支付
            </div>
            <div
              class="align-center font-333-20"
            >
              支付成功请前往
              <span class="pointer cor_FA9C22" @click="toOrder" style="font-weight:500;"
                >&nbsp;签署&nbsp;</span
              >
              合同


              <div
                  class="kuang11 align-center pointer font-666-16"
                  @click="initDownOrder"
                  style="margin-left: 24px"
                >
                  订单导出
                </div>
            </div>
          </div>

          <div class="xian2" v-show="type == 4">
            <div
              class="p_top"
              style="border-bottom: 1px solid #eeeeee"
            >
              <div style="background: rgba(255, 226, 189,.2);padding:16px 0 25px 36px;">
                <div class="font-333-20-bold">订单提交成功，请尽快付款</div>
                <div>
                  <div class="align-start" style="margin: 22px 0 20px">
                    <span class="font-333-18">订单编号：</span>
                    <span class="font-666-18">{{ number }}</span>
                  </div>
                  <div class="align-start">
                    <span class="font-333-18">订单金额：</span>
                    <span class="font-666-18">¥</span>
                    <span class="font-FA9C22-22-bold">{{ price }}</span>
                  </div>
                </div>
              </div>
              <div style="padding:22px 36px 0;">
                <div class="font-666-18" style="margin-bottom: 22px">
                  注意事项（转账必读）
                </div>
                <div class="font-666-18" style="line-height: 28px;">
                  1.公司转账汇款时请将订单编号{{
                    number
                  }}填写至汇款单中“用途/备注/摘要”等栏；
                  2.下单后请尽快转账，务必保证转账金额与订单金额一致，请勿多转/少转或分次转账，否则影响订单对账进度；
                  <!-- 如未及时汇款订单将在下单15天后自动取消； -->
                </div>
                <div class="flex-wrap" style="margin: 40px 0 22px">
                <div class="flex-con"></div>
                <div class="align-center gou pointer" @click="toMain()">
                  继续购物
                </div>
                <div class="align-center see_order pointer" @click="fnOrderXq()">
                  查看订单
                </div>
              </div>

              </div>

              
            </div>

            <div style="padding: 0 36px 0 40px">
              <div class="font-333-18-bold" style="margin: 22px 0">
                对公转账信息
              </div>
              <div class="xian2_info">
                <div class="flex-wrap">
                  <div
                    class="align-center font-333-18"
                    style="width: 260px; background: #f4f4f4"
                  >
                    公司名称：
                  </div>
                  <div
                    class="flex-con font-666-18 align-start"
                    style="padding-left: 90px"
                  >
                    本牛(江苏)电子商务有限公司
                  </div>
                </div>
                <div class="flex-wrap">
                  <div
                    class="align-center font-333-18"
                    style="width: 260px; background: #f4f4f4"
                  >
                    银行账号：
                  </div>
                  <div
                    class="flex-con font-666-18 align-start"
                    style="padding-left: 90px"
                  >
                    1115927319000037085
                  </div>
                </div>
                <div class="flex-wrap">
                  <div
                    class="align-center font-333-18"
                    style="width: 260px; background: #f4f4f4"
                  >
                    开户银行：
                  </div>
                  <div
                    class="flex-con font-666-18 align-start"
                    style="padding-left: 90px"
                  >
                    中国工商银行股份有限公司泰兴滨江支行
                  </div>
                </div>
                <!-- <div class="flex-wrap">
                <div
                  class="align-center font-777"
                  style="width: 188px; background: #e5e5e5"
                >
                  银联号：
                </div>
                <div
                  class="flex-con font-333-16 align-start"
                  style="padding-left: 66px"
                >
                  148714874161455
                </div>
              </div> -->
                <div class="flex-wrap">
                  <div
                    class="align-center font-333-18"
                    style="width: 260px; background: #f4f4f4"
                  >
                    用途/备注/摘要：
                  </div>
                  <div
                    class="flex-con font-666-18 align-start"
                    style="padding-left: 90px"
                  >
                    {{ number }}
                  </div>
                </div>
                <div class="flex-wrap">
                  <div
                    class="align-center font-333-18"
                    style="width: 260px; background: #f4f4f4"
                  >
                    税号：
                  </div>
                  <div
                    class="flex-con font-666-18 align-start"
                    style="padding-left: 90px"
                  >
                    91321283346523060M
                  </div>
                </div>
                <div class="flex-wrap">
                  <div
                    class="align-center font-333-18"
                    style="width: 260px; background: #f4f4f4"
                  >
                    电话：
                  </div>
                  <div
                    class="flex-con font-666-18 align-start"
                    style="padding-left: 90px"
                  >
                    15067554231
                  </div>
                </div>
                <div class="flex-wrap">
                  <div
                    class="align-center font-333-18"
                    style="width: 260px; background: #f4f4f4"
                  >
                    地址：
                  </div>
                  <div
                    class="flex-con font-666-18 align-start"
                    style="padding-left: 90px"
                  >
                    江苏省南京市江宁区金源路8号绿地之窗商务广场H1幢
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
// 支付宝直接跳转，微信反二维码，银联7月份，线下直接支付成功
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { orderDownload } from '../api'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  data() {
    return {
      url: this.$route.params.url || '',
      payTxt: this.$route.params.type == 3 ? '微信' : '',
      type: this.$route.params.type,
      number: this.$route.params.number || '',
      price: this.$route.params.price || '',
      order_id: this.$route.params.order_id || '',
      orderUrl:''
    }
  },
  methods: {
    initDownOrder() {
      let params = {
        ids: this.order_id
      }
      orderDownload(params).then((data) => {
        if (data.status == 200) {
          // window.location.href = data.msg
          // this.data = data.msg
          this.orderUrl = data.msg2
          window.location.href = this.orderUrl
          // this.dialogVisible = true
        }
      })
    },
    fnOrderXq() {
      this.$router.push({
        name: 'OrderXq',
        params: {
          id: this.order_id + '.html',
        },
      })
    },
    toOrder(type) {
      if (type) {
        this.$router.push({
          name: 'OrderZx',
          params: {
            id: 0 + '.html',
          },
        })
        return
      }
      this.$router.push({
        name: 'OrderZx',
        params: {
          id: 2 + '.html',
        },
      })
    },
    toBuy() {
      this.$router.go(-2)
    },
  },
  mounted() {
    document.title = '本牛'
    if (!this.type) {
      this.$router.go(-2)
    } 
  },
}
</script> 

<style scoped>
.kuang11 {
  width: 103px;
  height: 27px;
  border: 1px solid #d8d8d8;
}
.kuang11:hover{
  border: 1px solid #fa9c22;
  color: #fa9c22;
}
.btn_padding {
  padding: 1px 11px;
  margin-left: 10px;
  border: 1px solid #d8d8d8;
}
.btn {
  width: 256px;
height: 46px;
background: #FA9C22;
border-radius: 4px;
  font-size: 20px;
  color: #ffffff;
  margin: 30px auto 22px;
}
.xian2_info {
}
.xian2_info > div {
  border: 1px solid #d8d8d8;
  /* border-bottom: 2px solid #d8d8d8; */
  height: 44px;
}
.xian2_info > div:last-child {
  /* border-bottom: none; */
}
.see_order {
  width: 256px;
  height: 46px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #fa9c22;
  box-sizing: border-box;
  margin-left: 30px;
  color: #fa9c22;
  font-size: 20px;
}
.gou {
  width: 256px;
  height: 46px;
  background: #fa9c22;
  border-radius: 4px;
  font-size: 20px;
  color: #ffffff;
}
.sao_box {
  width: 222px;
  height: 222px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 30px auto 0;
  padding: 20px 0 6px;
  box-sizing: border-box;
}
.contain {
  background: #ffffff;
  /* border-radius: 4px; */
  /* border: 1px solid;
  border-image: linear-gradient(90deg, #f08300, #f08300) 1 1; */
  /* margin-top: 20px; */
  padding-bottom: 35px;
}
.p_top {
  /* background: linear-gradient(
    90deg,
    rgba(239, 200, 69, 0.2) 0%,
    rgba(220, 160, 30, 0.2) 100%
  );
  border-radius: 4px;
  padding: 25px; */
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  background: url('@/assets/check2.png') no-repeat center center;
  background-size: 100% 100%;
}
input[type='radio']:checked {
  background: url('@/assets/checked21.png') no-repeat center center;
  background-size: 100% 100%;
}
</style>

<template>
  <div>
    <Header />
    <div class="middle1">
      <div class="align-start" style="margin: 20px 0 17px">
        <div class="font-333-16-16 pointer" @click="toMain()">首页</div>
        <div v-show="firstCateId" class="align-center">
          <el-image
            style="width: 7px; height: 11px; margin: 0 5px"
            :src="require('@/assets/arrow_right3.png')"
            fit="cover"
          ></el-image>
          <div
            class="btn_padding font-333-16-16 pointer"
            @click="fnHeaderQie(1, firstCateId)"
          >
            {{ firstCateName || '' }}
          </div>
        </div>
        <div v-show="secondCateId" class="align-center">
          <el-image
            style="width: 20px; height: 20px; margin: 0 2px"
            :src="require('@/assets/right_arrow2.png')"
            fit="cover"
          ></el-image>
          <div
            class="btn_padding font-333-16-16 pointer"
            @click="fnHeaderQie(2, secondCateId)"
          >
            {{ secondCateName || '' }}
          </div>
        </div>
        <div v-show="thirdCateId" class="align-center">
          <el-image
            style="width: 20px; height: 20px; margin: 0 2px"
            :src="require('@/assets/right_arrow2.png')"
            fit="cover"
          ></el-image>
          <div class="btn_padding font-333-16-16">
            {{ thirdCateName || '' }}
          </div>
        </div>
        <div v-show="keyword" class="align-center">
          <el-image
            style="width: 20px; height: 20px; margin: 0 2px"
            :src="require('@/assets/right_arrow2.png')"
            fit="cover"
          ></el-image>
          <div class="font-333-16-16">
            搜索“<span class="cor_EC7902">{{ keyword }}</span
            >”
          </div>
        </div>
      </div>
      <div style="margin-bottom: 12px">
        <div
          class="s_cate align-between-top"
          id="firstCate"
          ref="oneCate"
          :class="{ heightauto: !arrow1, height40: arrow1 }"
          v-show="firstCate.length > 0"
        >
          <div class="flex-wrap">
            <div class="s_cate_title align-end">一级分类:</div>

            <div class="align-start cate_out flex-con">
              <div
                class="font-333-14 pointer fl"
                :class="{ cor_EC7902: !firstCateId }"
                @click="fnFirstCate('', '', '', '')"
                v-show="keyword"
              >
                全部
              </div>
              <div
                class="font-333-14 pointer fl"
                :class="{
                  cor_EC7902: firstCateId == item.gc_id,
                }"
                v-for="(item, index) in firstCate"
                :key="'firstCate_' + index"
                @click="
                  fnFirstCate(
                    item.gc_id,
                    item.gc_name,
                    item.gc_url_one,
                    item.gc_title,
                    item.gc_keyword,
                    item.gc_desc
                  )
                "
              >
                {{ item.gc_name || '' }}
              </div>
            </div>
          </div>
          <div
            class="align-center more pointer"
            @click="fnMore(0)"
            v-show="firstCateH > 57"
          >
            <div class="font-666-14">更多</div>
            <el-image
              style="width: 11px; height: 7px; margin-left: 5px"
              :src="
                arrow1
                  ? require('@/assets/bottom_arrow2.png')
                  : require('@/assets/top_arrow2.png')
              "
              fit="cover"
            ></el-image>
          </div>
        </div>
        <div
          class="s_cate align-between-top"
          ref="twoCate"
          id="secondCate"
          :class="{ heightauto: !arrow1, height40: arrow1 }"
          v-show="secondCate.length > 0"
        >
          <div class="flex-wrap">
            <div class="s_cate_title align-end">二级分类:</div>

            <div class="align-start cate_out flex-con">
              <div
                class="font-333-14 pointer fl"
                :class="{ cor_EC7902: !secondCateId }"
                @click="fnSecondCate('', '', '', '')"
              >
                全部
              </div>
              <div
                class="font-333-14 pointer fl"
                :class="{
                  cor_EC7902: secondCateId == item.gc_id,
                }"
                v-for="(item, index) in secondCate"
                :key="'secondCate_' + index"
                @click="
                  fnSecondCate(
                    item.gc_id,
                    item.gc_name,
                    item.gc_pid,
                    item.gc_url_one,
                    item.gc_url_two,
                    item.gc_title,
                    item.gc_keyword,
                    item.gc_desc
                  )
                "
              >
                {{ item.gc_name || '' }}
              </div>
            </div>
          </div>
          <div
            class="align-center more pointer"
            @click="fnMore(1)"
            v-show="secondCateH > 57"
          >
            <div class="font-666-14">更多</div>
            <el-image
              style="width: 11px; height: 7px; margin-left: 5px"
              :src="
                arrow1
                  ? require('@/assets/bottom_arrow2.png')
                  : require('@/assets/top_arrow2.png')
              "
              fit="cover"
            ></el-image>
          </div>
        </div>
        <div
          class="s_cate align-between-top"
          v-show="thirdCate.length > 0"
          :class="{ heightauto: !arrow2, height40: arrow2 }"
          ref="threeCate"
          id="thirdCate"
        >
          <div class="flex-wrap">
            <div class="s_cate_title align-end">三级分类:</div>
            <div class="align-start cate_out flex-con">
              <div
                class="font-333-14 pointer fl"
                :class="{ cor_EC7902: !thirdCateId }"
                @click="fnThirdCate('', '', '', '')"
              >
                全部
              </div>
              <div
                class="font-333-14 pointer fl"
                :class="{ cor_EC7902: thirdCateId == item.gc_id }"
                v-for="(item, index) in thirdCate"
                :key="'secondCate_' + index"
                @click="
                  fnThirdCate(
                    item.gc_id,
                    item.gc_name,
                    item.gc_pid,
                    item.gc_url_one,
                    item.gc_url_two,
                    item.gc_url_three,
                    item.gc_title,
                    item.gc_keyword,
                    item.gc_desc
                  )
                "
              >
                {{ item.gc_name || '' }}
              </div>
            </div>
          </div>
          <div
            class="align-center more pointer"
            @click="fnMore(2)"
            v-show="thirdCateH > 57"
          >
            <div class="font-666-14">更多</div>
            <el-image
              style="width: 11px; height: 7px; margin-left: 5px"
              :src="
                arrow1
                  ? require('@/assets/bottom_arrow2.png')
                  : require('@/assets/top_arrow2.png')
              "
              fit="cover"
            ></el-image>
          </div>
        </div>
      </div>
      <div class="s_tab align-between">
        <div class="tab_out align-start">
          <div class="tab_item align-center active">默认</div>
          <div class="tab_item align-end pointer" @click="fnPrice">
            价格
            <el-image
              style="width: 20px; height: 15px; margin-left: 2px"
              :src="require('@/assets/shai_icon' + price_sort + '.png')"
              fit=""
            ></el-image>
          </div>
          <input
            type="number"
            v-model="lower_price"
            placeholder="最低价"
            style="margin-left: 16px"
          />
          <div class="yi"></div>
          <input type="number" v-model="high_price" placeholder="最高价" />
          <div
            class="font-777-12 align-center sure pointer"
            @click="fnPriceTwo"
          >
            确定
          </div>
        </div>
        <!-- <div>
        <span class="DCA01E">{{ total || 0 }}</span
        >种
      </div> -->
      </div>
      <div>
        <div v-if="data.length > 0">
          <div
            class="one pointer"
            v-for="(item, index) in data"
            :key="'goods_' + index"
            @click="fnGoodsDetails(item.goods_id)"
          >
            <el-image
              :src="item.goods_thumb"
              fit="cover"
              class="oneImg"
            ></el-image>
            <div style="padding: 10px 20px 0">
              <div class="twoTitle aui-ellipsis-2">
                {{ item.goods_name }}
              </div>
              <div class="price2">
                ¥{{
                  item.goods_floor_price > 0 ? item.goods_floor_price : '面议'
                }}<span
                  class="font-333-bold"
                  style="position: relative; bottom: 1px"
                  >{{ item.goods_floor_price > 0 ? item.danwei : '' }}</span
                >
                <span class="hua-xian-jia" >
                                {{
                                  item.goods_old_price > 0 ? ('¥'+item.goods_old_price) : ''
                                }}
                              </span>
              </div>
            </div>
            <div class="align-between" style="padding: 20px 15px 17px">
              <div
                class="collect1 align-center"
                @click.stop="fnCollect(item.goods_id)"
              >
                <el-image
                  :src="
                    item.is_collect == 1
                      ? require('@/assets/collect4.png')
                      : require('@/assets/collect3.png')
                  "
                  fit="cover"
                  style="width: 17px; height: 15px"
                  class="collect2"
                ></el-image>
              </div>

              <div class="buy1 align-start">
                <div class="shan"></div>
                <div>立即购买</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div style="text-align: center; margin: 225px auto">
            <el-image
              style="width: 160px; height: 160px; margin-bottom: 16px"
              :src="require('@/assets/quesheng/no_content.png')"
              fit="cover"
            ></el-image>
            <div class="font-666-14">商品完善中~</div>
          </div>
        </div>
        <div class="align-center">
          <Page
            :limit="limit"
            :totalNum="total"
            @onLimit="changeLimit"
            @onPage="changePage"
          />
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'
import {
  limit,
  goodsList,
  loginAgreement,
  secondAndThirdCate,
  selectGoodsCate,
  getGoodsCateInfo,
  goodsCate,
  getCateInfoByUrl,
  doCollect,
} from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    right,
  },
  data() {
    return {
      data: [],
      page: 1,
      limit: limit,
      total: 0,
      keyword: '',
      price_sort: 0,
      lower_price: '',
      high_price: '',
      goods_clear: 0,
      goods_hot: 0,
      firstCate: [],
      firstCateOld: [],
      secondCate: [],
      secondCateOld: [],
      thirdCate: [],
      thirdCateOld: [],
      firstCateId: '',
      secondCateId: '',
      thirdCateId: '',
      firstCateName: '',
      secondCateName: '',
      thirdCateName: '',
      type: '', //入口  0搜索 1一级 2二级 3三级
      arrow0: false,
      arrow1: false,
      arrow2: false,
      firstCateH: '',
      secondCateH: '',
      thirdCateH: '',
      gc_url_one: this.$route.params.one || '',
      gc_url_two: this.$route.params.two || '',
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    fnCollect(goods_id) {
      if (!localStorage.getItem('allUser')) {
        this.$bus.$emit('login', 1)
        return
      }
      // if (num == 2) {
      //   this.dialogVisible111 = true
      //   return
      // }
      let params = {
        goods_id: goods_id,
      }
      doCollect(params).then((data) => {
        if (data.status == 200) {
          this.initData()
          // this.data.is_collect = num == 1 ? 2 : 1
        }
      })
    },
    initData() {
      let params = {
        page: this.page,
        price_sort: this.price_sort,
        lower_price: this.lower_price,
        high_price: this.high_price,
        goods_clear: this.goods_clear,
        goods_hot: this.goods_hot,
        first_cate_id: this.firstCateId,
        second_cate_id: this.secondCateId,
        third_cate_id: this.thirdCateId,
        keyword: this.keyword,
      }
      goodsList(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count
        }
      })
    },
    initCate1(type) {
      this.arrow0 = false
      this.arrow1 = false
      this.arrow2 = false
      //所有分类
      let params = {
        first_cate_id: this.firstCateId || '',
        type: 1,
        keyword: this.keyword,
      }
      if (type == 1) {
        params = {
          first_cate_id: '',
          second_cate_id: this.secondCateId || '',
          type: 1,
          keyword: this.keyword,
        }
      }
      secondAndThirdCate(params).then((data) => {
        if (data.status == 200) {
          if (type == 1) {
            this.thirdCate = data.thirdCate
            this.arrow2 = false
            if (!this.$refs.threeCate) {
              return
            }
            setTimeout(() => {
              this.thirdCateH = this.$refs.threeCate.offsetHeight
              if (this.thirdCateH > 40) {
                document.getElementById('thirdCate').style.Height = '40px'
                this.arrow2 = true
              } else {
                this.arrow2 = false
              }
            }, 0)
            this.initData()
            return
          }
          if (type == 2) {
            this.firstCate = data.firstCate
            this.secondCateOld = this.secondCate = data.secondCate
            if (!this.$refs.oneCate || !this.$refs.twoCate) {
              return
            }
            this.firstCateH = this.$refs.oneCate.offsetHeight
            this.secondCateH = this.$refs.twoCate.offsetHeight
            if (this.firstCateH > 40) {
              document.getElementById('firstCate').style.Height = '40px'
              this.arrow0 = true
            } else {
              this.arrow0 = false
            }
            if (this.secondCateH > 40) {
              document.getElementById('secondCate').style.Height = '40px'
              this.arrow1 = true
            } else {
              this.arrow1 = false
            }
            this.initCate1(1)
            return
          }
          if (!(this.firstCateId && this.keyword)) {
            this.firstCate = data.firstCate
          }
          this.secondCateId = ''
          this.thirdCateId = ''
          this.secondCateOld = this.secondCate = data.secondCate
          this.thirdCateOld = this.thirdCate = data.thirdCate
          setTimeout(() => {
            if (
              !this.$refs.oneCate ||
              !this.$refs.twoCate ||
              !this.$refs.threeCate
            ) {
              return
            }
            this.firstCateH = this.$refs.oneCate.offsetHeight
            this.secondCateH = this.$refs.twoCate.offsetHeight
            this.thirdCateH = this.$refs.threeCate.offsetHeight
            if (this.firstCateH > 40) {
              document.getElementById('firstCate').style.Height = '40px'
              this.arrow0 = true
            } else {
              this.arrow0 = false
            }
            if (this.secondCateH > 40) {
              document.getElementById('secondCate').style.Height = '40px'
              this.arrow1 = true
            } else {
              this.arrow1 = false
            }
            if (this.thirdCateH > 40) {
              document.getElementById('thirdCate').style.Height = '40px'
              this.arrow2 = true
            } else {
              this.arrow2 = false
            }
          }, 0)
          this.initData()
        }
      })
    },
    // initCate2() {
    //   //点击二级获取此二级下所有三级
    //   let params = {
    //     second_cate_id: this.secondCateId,
    //   }
    //   selectGoodsCate(params).then((data) => {
    //     if (data.status == 200) {
    //       this.thirdCate = data.thirdCate
    //       this.arrow2 = false
    //       setTimeout(() => {
    //         this.thirdCateH = this.$refs.threeCate.offsetHeight
    //         if (this.thirdCateH > 40) {
    //           document.getElementById('thirdCate').style.Height = '40px'
    //           this.arrow2 = true
    //         } else {
    //           this.arrow2 = false
    //         }
    //       }, 0)
    //       this.initData()
    //     }
    //   })
    // },
    initCate4(type, idd) {
      //获取对应id的name
      let params = {
        cate_id: idd,
      }
      getGoodsCateInfo(params).then((data) => {
        if (data.status == 200) {
          if (type == 1) {
            this.firstCateName = data.msg.gc_name
          }
          if (type == 2) {
            if (!data.msg) {
              this.secondCateName = ''
              this.secondCateId = ''
            } else {
              this.secondCateName = data.msg.gc_name
            }
          }
          if (type == 3) {
            this.thirdCateName = data.msg.gc_name
          }
        }
      })
    },
    fnFirstCate(id, name, gc_url, gc_title, gc_keyword, gc_desc) {
      this.gc_url_two = ''
      this.firstCateName = name
      this.firstCateId = id
      this.secondCateName = ''
      this.secondCateId = ''
      this.thirdCateId = ''
      this.thirdCateName = ''
      this.page = 1
      this.initCate1()
      if (id) {
        this.gc_url_one = gc_url
        if (this.keyword) {
          this.$router.push({
            path: `/search/${gc_url}`,
            query: {
              keyword: this.keyword,
            },
          })
        } else {
          this.$router.push({
            path: `/${gc_url}`,
          })
        }
        this.tdkTitle = gc_title || '本牛'
        this.tdkKeywords = gc_keyword || this.keyword || '本牛'
        this.tdkDescription = gc_desc || '本牛'
      } else {
        this.gc_url_one = ''
        if (this.keyword) {
          this.$router.push({
            path: `/search`,
            query: {
              keyword: this.keyword,
            },
          })
          this.tdkTitle = '本牛'
          this.tdkKeywords = this.keyword || '本牛'
          this.tdkDescription = '本牛'
        } else {
          // this.$router.push({
          //   path: `/list`,
          // })
        }
      }
    },
    fnSecondCate(
      id,
      name,
      pid,
      gc_url1,
      gc_url2,
      gc_title,
      gc_keyword,
      gc_desc
    ) {
      if (pid) {
        this.gc_url_one = gc_url1
        this.firstCateId = pid
        this.initCate4(1, this.firstCateId)
      }
      this.secondCateName = name
      this.secondCateId = id
      this.thirdCateId = ''
      this.thirdCateName = ''
      this.page = 1
      if (!id) {
        this.secondCate = this.secondCateOld
        this.thirdCate = this.thirdCateOld
        this.arrow1 = false
        this.arrow2 = false
        setTimeout(() => {
          this.secondCateH = this.$refs.twoCate.offsetHeight
          this.thirdCateH = this.$refs.threeCate.offsetHeight
          if (this.secondCateH > 40) {
            document.getElementById('secondCate').style.Height = '40px'
            this.arrow1 = true
          } else {
            this.arrow1 = false
          }
          if (this.thirdCateH > 40) {
            document.getElementById('thirdCate').style.Height = '40px'
            this.arrow2 = true
          } else {
            this.arrow2 = false
          }
        }, 0)
        this.initData()
      } else {
        this.initCate1(1)
      }
      if (id) {
        this.gc_url_two = gc_url2
        //document.title = gc_title
        if (this.keyword) {
          this.$router.push({
            path: `/search/${gc_url1}/${gc_url2}`,
            query: {
              keyword: this.keyword,
            },
          })
        } else {
          this.$router.push({
            path: `/${gc_url1}/${gc_url2}`,
          })
        }
        this.tdkTitle = gc_title || '本牛'
        this.tdkKeywords = gc_keyword || this.keyword || '本牛'
        this.tdkDescription = gc_desc || '本牛'
      } else {
        this.gc_url_two = ''
        if (this.keyword) {
          if (this.gc_url_one) {
            this.$router.push({
              path: `/search/${this.gc_url_one}`,
              query: {
                keyword: this.keyword,
              },
            })
            this.getCateIdOneOrTwo(this.gc_url_one)
          } else {
            this.$router.push({
              path: `/search`,
              query: {
                keyword: this.keyword,
              },
            })
            this.tdkTitle = '本牛'
            this.tdkKeywords = this.keyword || '本牛'
            this.tdkDescription = '本牛'
          }
        } else {
          if (this.gc_url_one) {
            this.$router.push({
              path: `/${this.gc_url_one}`,
            })
            this.getCateIdOneOrTwo(this.gc_url_one)
          } else {
            // this.$router.push({
            //   path: `/list`,
            // })
          }
        }
      }
    },
    fnGetCateInfo() {
      //点击级别类目id获取所属上级级信息
      let params = {
        cate_id: this.secondCateId,
      }
      getGoodsCateInfo(params).then((data) => {
        if (data.status == 200) {
          this.firstCateId = data.msg.gc_pid
          this.initCate4(1, this.firstCateId)
        }
      })
    },
    fnThirdCate(
      id,
      name,
      pid,
      gc_url1,
      gc_url2,
      gc_url3,
      gc_title,
      gc_keyword,
      gc_desc
    ) {
      if (pid) {
        if (pid != this.secondCateId) {
          this.secondCateId = pid
          this.initCate4(2, this.secondCateId)
          if (!this.firstCateId) {
            this.fnGetCateInfo()
          }
        }
      }
      this.thirdCateId = id
      this.thirdCateName = name
      this.page = 1
      if (!id && this.secondCateId > 0) {
        this.initCate1(1)
      } else {
        this.initData()
      }
      if (id) {
        this.gc_url_one = gc_url1
        this.gc_url_two = gc_url2
        this.gc_url_three = gc_url3
        //document.title = gc_title
        if (this.keyword) {
          this.$router.push({
            path: `/search/${gc_url1}/${gc_url2}/${gc_url3}`,
            query: {
              keyword: this.keyword,
            },
          })
        } else {
          this.$router.push({
            path: `/${gc_url1}/${gc_url2}/${gc_url3}`,
          })
        }
        this.tdkTitle = gc_title || '本牛'
        this.tdkKeywords = gc_keyword || this.keyword || '本牛'
        this.tdkDescription = gc_desc || '本牛'
      } else {
        this.gc_url_three = ''
        if (this.keyword) {
          if (this.gc_url_one && this.gc_url_two) {
            this.$router.push({
              path: `/search/${this.gc_url_one}/${this.gc_url_two}`,
              query: {
                keyword: this.keyword,
              },
            })
            this.getCateIdOneOrTwo('', this.gc_url_two)
          }
          if (this.gc_url_one && !this.gc_url_two) {
            this.$router.push({
              path: `/search/${this.gc_url_one}`,
              query: {
                keyword: this.keyword,
              },
            })
            this.getCateIdOneOrTwo(this.gc_url_one)
          }
          if (!this.gc_url_one && !this.gc_url_two) {
            this.$router.push({
              path: `/search`,
              query: {
                keyword: this.keyword,
              },
            })
            this.tdkTitle = '本牛'
            this.tdkKeywords = this.keyword || '本牛'
            this.tdkDescription = '本牛'
          }
          // console.log(691)
        } else {
          if (this.gc_url_one && this.gc_url_two) {
            this.$router.push({
              path: `/${this.gc_url_one}/${this.gc_url_two}`,
            })
            this.getCateIdOneOrTwo('', this.gc_url_two)
          }
          if (this.gc_url_one && !this.gc_url_two) {
            this.$router.push({
              path: `/${this.gc_url_one}`,
            })
            this.getCateIdOneOrTwo(this.gc_url_one)
          }
          // if (!this.gc_url_one && !this.gc_url_two) {
          //   this.$router.push({
          //     path: `/list`,
          //   })
          // }
        }
      }
    },
    fnHeaderQie(type, idd) {
      if (type == 1) {
        this.secondCateName = ''
        this.secondCateId = ''
        this.thirdCateId = ''
        this.thirdCateName = ''
        if (this.keyword) {
          this.secondCate = this.secondCateOld
          this.thirdCate = this.thirdCateOld
          this.initData()
          this.$router.push({
            path: `/search/${this.gc_url_one}`,
            query: {
              keyword: this.keyword,
            },
          })
          this.getCateIdOneOrTwo(this.gc_url_one)
          return
        }
        this.initCate1()
        this.$router.push({
          path: `/${this.gc_url_one}`,
        })
        this.getCateIdOneOrTwo(this.gc_url_one)
      } else if (type == 2) {
        this.thirdCateId = ''
        this.thirdCateName = ''
        this.initCate1(1)
        if (this.keyword) {
          this.$router.push({
            path: `/search/${this.gc_url_one}/${this.gc_url_two}`,
            query: {
              keyword: this.keyword,
            },
          })
        } else {
          this.$router.push({
            path: `/${this.gc_url_one}/${this.gc_url_two}`,
          })
        }
        this.getCateIdOneOrTwo('', this.gc_url_two)
      }
    },
    fnPrice() {
      if (this.price_sort < 2) {
        this.price_sort++
      } else {
        this.price_sort = 0
      }
      this.initData()
    },
    fnPriceTwo() {
      if (this.lower_price && this.high_price) {
        if (Number(this.high_price) < Number(this.lower_price)) {
          this.$message.error('请输入正确价格')
        } else {
          this.initData()
        }
      } else {
        this.initData()
      }
    },
    fnMore(num) {
      this['arrow' + num] = !this['arrow' + num]
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      this.initData()
    },
    getCateId() {
      //获取分类id和name
      let params = {
        one: this.$route.params.one || '',
        two: this.$route.params.two || '',
        three: this.$route.params.three || '',
      }
      getCateInfoByUrl(params).then((data) => {
        if (data.status == 200) {
          this.firstCateId = data.msg.one ? data.msg.one.gc_id : ''
          this.firstCateName = data.msg.one ? data.msg.one.gc_name : ''
          this.secondCateId = data.msg.two ? data.msg.two.gc_id : ''
          this.secondCateName = data.msg.two ? data.msg.two.gc_name : ''
          this.thirdCateId = data.msg.three ? data.msg.three.gc_id : ''
          this.thirdCateName = data.msg.three ? data.msg.three.gc_name : ''
          if (this.firstCateId && this.secondCateId) {
            this.initCate1(2)
          } else {
            this.initCate1()
          }

          if (this.thirdCateId) {
            this.tdkTitle = data.msg.three.gc_title || '本牛'
            this.tdkKeywords = data.msg.three.gc_keyword || '本牛'
            this.tdkDescription = data.msg.three.gc_desc || '本牛'
          } else {
            if (this.secondCateId) {
              this.tdkTitle = data.msg.two.gc_title || '本牛'
              this.tdkKeywords = data.msg.two.gc_keyword || '本牛'
              this.tdkDescription = data.msg.two.gc_desc || '本牛'
            } else {
              this.tdkTitle = data.msg.one.gc_title || '本牛'
              this.tdkKeywords = data.msg.one.gc_keyword || '本牛'
              this.tdkDescription = data.msg.one.gc_desc || '本牛'
            }
          }
        }
      })
    },

    getCateIdOneOrTwo(one, two) {
      //获取分类id和name
      let params = {
        one: one || '',
        two: two || '',
      }
      getCateInfoByUrl(params).then((data) => {
        if (data.status == 200) {
          if (one) {
            this.tdkTitle = data.msg.one.gc_title || '本牛'
            this.tdkKeywords = data.msg.one.gc_keyword || '本牛'
            this.tdkDescription = data.msg.one.gc_desc || '本牛'
          }
          if (two) {
            this.tdkTitle = data.msg.two.gc_title || '本牛'
            this.tdkKeywords = data.msg.two.gc_keyword || '本牛'
            this.tdkDescription = data.msg.two.gc_desc || '本牛'
          }
        }
      })
    },
  },
  mounted() {
    // console.log(this.$route)
    if (this.$route.path == '/search') {
      this.keyword = this.$route.query.keyword
      if (this.keyword) {
        this.$bus.$emit('keywordGet', this.keyword)
      }
      this.tdkTitle = '本牛'
      this.tdkKeywords = this.keyword || '本牛'
      this.tdkDescription = '本牛'
      this.initCate1()
    } else {
      this.getCateId()
    }
    this.$bus.$on('keyword', (val) => {
      this.keyword = val
      this.firstCateId = ''
      this.secondCateId = ''
      this.thirdCateId = ''
      this.tdkTitle = '本牛'
      this.tdkKeywords = this.keyword || '本牛'
      this.tdkDescription = '本牛'
      this.initCate1()
      this.$router.push({
        path: `/search`,
        query: {
          keyword: this.keyword,
        },
      })
    })
    this.$bus.$on('cate_id', (idd) => {
      this.keyword = ''
      this.$bus.$emit('keywordGet', this.keyword)
      this.getCateId()
    })
  },
}
</script>

<style scoped>
.buy1 {
  width: 168px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #ec7902;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #ec7902;
  box-sizing: border-box;
  padding-left: 37px;
  line-height: 29px;
}
.buy1:hover {
  color: #ffffff;
  background: #ec7902;
}
.buy1 .shan {
  display: inline-block;
  width: 12px;
  height: 16px;
  background: url('@/assets/buy2.png') no-repeat center center;
  background-size: 100% 100%;
  margin-right: 10px;
  margin-top: 2px;
}
.buy1:hover .shan {
  background: url('@/assets/buy1.png') no-repeat center center;
  background-size: 100% 100%;
}
.collect1 {
  width: 37px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #ec7902;
  border-radius: 5px;
  box-sizing: border-box;
}
.collect1:hover {
  background: url('@/assets/collect3.png') no-repeat center center;
  background-size: 17px 15px;
}
.collect1:hover .collect2 {
  display: none;
}
.btn_padding {
  padding: 4px 8px;
  background: #fff;
  border-radius: 5px;
}
.yi {
  width: 16px;
  height: 1px;
  background: #d8d8d8;
  margin: 0 4px;
}
.sure {
  /* height: 24px;
  width: 48px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  margin-left: 16px; */

  width: 76px;
  height: 37px;
  background: #ec7902;
  border-radius: 5px;
  margin-left: 19px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

/* .sure:hover {
  height: 24px;
  width: 48px;
  border: none;
  box-sizing: border-box;
  margin-left: 16px;
  color: #fff;
  background-color: #f08300;
} */

.s_cate_title {
  width: 119px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  box-sizing: border-box;
  padding-right: 31px;
  /* text-align: right; */
}
.s_cate:last-child {
  border: none;
}
.s_cate {
  height: 56px;
  background: #ffffff;
  /* border-radius: 4px; */
  font-size: 12px;
  border-bottom: 1px solid #eee;
  overflow: hidden;
}
.cate_out {
  flex-flow: row wrap;
}
.cate_out div {
  line-height: 56px;
  margin-left: 19px;
}
.more {
  margin-top: 15px;
  margin-right: 14px;
  width: 82px;
  height: 27px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  box-sizing: border-box;
}
.s_tab {
  height: 50px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 14px;
  padding-right: 16px;
  box-shadow: 0px 3px 5px 0px rgba(75, 75, 75, 0.1);
}
.tab_item {
  width: 76px;
  height: 50px;
  color: #333;
}
.tab_item.active {
  width: 103px;
  height: 50px;
  background: #ec7902;
  color: #fff;
}

input[type='number'] {
  box-sizing: border-box;
  padding: 0 12px;
  color: #333333;
  font-size: 14px;
  outline: none;
  text-align: center;
  width: 300px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
}
input[type='number']::-webkit-input-placeholder {
  color: #999;
}
input[type='number']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-right: none;
}

.two {
  position: relative;
  display: inline-block;
  width: calc((100% - 40px) / 5);
  height: 274px;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin-right: 10px;
  padding: 6px 8px;
  margin-bottom: 10px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: transform 0.5s, box-shadow 0.5s;
}
.two:hover {
  border: 0;
  transform: scale(1.02);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.twoImg {
  display: block;
  width: 126px;
  height: 126px;
  margin: 0 auto 1px;
}
.two:nth-child(5n + 5) {
  margin-right: 0;
}
.twoTitle {
  font-size: 16px;
  color: #333333;
  line-height: 20px;
  height: 41px;
  margin: 0 0 20px;
}
.price2 {
  /* font-size: 18px;
  color: #c52b33;
  margin-top: 4px;
  line-height: 25px;
  height: 27px; */

  font-size: 24px;
  font-weight: 500;
  color: #ec7902;
}
.price3 {
  font-size: 12px;
  font-weight: 400;
  /* color: #333333; */
}
.banner {
  height: 83px;
  width: 1080px;
  margin-top: 10px;
}
.label {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #dea422;
  border-radius: 2px;
  border: 1px solid;
  border-image: linear-gradient(
      90deg,
      rgba(239, 200, 69, 1),
      rgba(220, 160, 30, 1)
    )
    1 1;
  margin-top: 4px;
  padding: 3px 8px;
}

.btn {
  width: 100%;
  height: 30px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 5px;
}
.heightauto {
  height: auto;
}
.height40 {
  height: 56px;
}
.fl:hover {
  color: #ec7902;
}

.one {
  /* position: relative; */
  display: inline-block;
  width: calc((100% - 80px) / 5);
  /* height: 302px; */
  background: #ffffff;
  border-radius: 5px;
  margin-right: 20px;
  /* padding: 20px; */
  margin-bottom: 20px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: box-shadow 0.5s;
}
.one:hover {
  /* transform: scale(1.02); */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.one:nth-child(5n) {
  margin-right: 0;
}

.oneImg {
  display: block;
  width: 178px;
  height: 178px;
  margin: 0 auto;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header',{attrs:{"num111":5}}),_c('div',{staticClass:"middle1"},[_vm._l((_vm.cate),function(item,index){return (item.ic_id != 2)?_c('div',{key:'cate_' + index},[_c('div',{staticClass:"font-333-30",staticStyle:{"margin":"70px 0 47px"}},[_vm._v(" "+_vm._s(item.ic_name || '')+" ")]),_c('div',{staticClass:"flex-wrap"},[_vm._l((item.info),function(item,index){return (index == 0)?_c('div',{key:'infoList_' + item.info_id + '_' + index,staticClass:"bg_fff pointer zixun1",staticStyle:{"height":"478px","width":"616px","box-sizing":"border-box","margin-right":"19px"},on:{"click":function($event){return _vm.toZx(item.info_id)},"mouseenter":function($event){return _vm.mouseenterOutZx1(item.ic_id)},"mouseleave":function($event){return _vm.mouseleaveOutZx1()}}},[_c('div',{staticClass:"img2_out"},[_c('el-image',{staticClass:"img2",staticStyle:{"width":"616px","height":"318px","display":"block"},attrs:{"src":item.info_thumb,"fit":"cover"}})],1),_c('div',{staticStyle:{"padding":"17px 72px 0"}},[_c('div',{staticClass:"aui-ellipsis-2 left_title2"},[_vm._v(" "+_vm._s(item.info_title || '')+" ")]),_c('div',{staticClass:"aui-ellipsis-2 left_info2"},[_vm._v(" "+_vm._s(item.info_intro || '')+" ")]),_c('el-image',{staticClass:"left_jt1",attrs:{"src":_vm.showHoverZx1 && _vm.ic_id == item.ic_id
                  ? require('@/assets/arrow_right2.png')
                  : require('@/assets/arrow_right1.png'),"fit":"cover"}})],1)]):_vm._e()}),_c('div',{staticClass:"flex-con"},_vm._l((item.info),function(item,index){return (index > 0 && index < 4)?_c('div',{key:'infoList_' + item.info_id + '_' + index,staticClass:"bg_fff three_right pointer flex-wrap",staticStyle:{"height":"146px","padding":"0 0 0 40px","position":"relative","box-sizing":"border-box"},style:({ 'margin-bottom': index == 3 ? '0' : '19px' }),on:{"click":function($event){return _vm.toZx(item.info_id)},"mouseenter":function($event){return _vm.mouseenterOutZx2(index,item.ic_id)},"mouseleave":function($event){return _vm.mouseleaveOutZx2()}}},[_c('div',{staticClass:"flex-con",staticStyle:{"padding-top":"40px"}},[_c('div',{staticClass:"aui-ellipsis-1 left_title2",staticStyle:{"height":"18px","line-height":"18px","margin-bottom":"15px"}},[_vm._v(" "+_vm._s(item.info_title || '')+" ")]),_c('div',{staticClass:"aui-ellipsis-2 left_info2",staticStyle:{"height":"48px"}},[_vm._v(" "+_vm._s(item.info_intro || '')+" ")])]),_c('div',{staticClass:"img3_out align-start"},[_c('el-image',{staticClass:"img3 left_jt1",attrs:{"src":_vm.showHoverZx2 && _vm.ic_id == item.ic_id && index == _vm.indexZx2
                  ? require('@/assets/arrow_right2.png')
                  : require('@/assets/arrow_right1.png'),"fit":"cover"}})],1)]):_vm._e()}),0)],2),_c('div',{staticClass:"align-end"},[_c('div',{staticClass:"zx_more pointer",on:{"click":_vm.fnMore}},[_vm._v(" 了解更多 "),_c('div')])])]):_vm._e()}),_vm._l((_vm.cate),function(item,index){return _c('div',{key:'info2_' + index},[(item.ic_id == 2)?_c('div',[_c('div',{staticClass:"font-333-30",staticStyle:{"margin":"70px 0 47px"}},[_vm._v("行业资讯")]),_c('div',{staticClass:"align-between"},_vm._l((item.info),function(item,index){return (index < 3)?_c('div',{key:'info3_' + index,staticClass:"pointer hy_item",on:{"click":function($event){return _vm.toZx(item.info_id)},"mouseenter":function($event){return _vm.mouseenterOutZx(index)},"mouseleave":function($event){return _vm.mouseleaveOutZx()}}},[_c('div',{staticStyle:{"overflow":"hidden"}},[_c('el-image',{staticClass:"left_img1",attrs:{"src":item.info_thumb,"fit":"cover"}})],1),_c('div',{staticStyle:{"padding":"0 26px"}},[_c('div',{staticClass:"font-333-26-bold info1_time",staticStyle:{"text-align":"right","margin-top":"12px"}},[_vm._v(" "+_vm._s(_vm.timeMD(item.info_time))+" ")]),_c('div',{staticClass:"align-start"},[_c('div',{staticClass:"left_title1 aui-ellipsis-2"},[_vm._v(" "+_vm._s(item.info_title || '')+" ")])]),_c('div',{staticClass:"left_info1 aui-ellipsis-2"},[_vm._v(" "+_vm._s(item.info_intro || '')+" ")]),_c('el-image',{staticClass:"left_jt1",attrs:{"src":_vm.showHoverZx && _vm.indexZx == index
                    ? require('@/assets/arrow_right2.png')
                    : require('@/assets/arrow_right1.png'),"fit":"cover"}})],1)]):_vm._e()}),0),_c('div',{staticClass:"align-end"},[_c('div',{staticClass:"zx_more pointer",on:{"click":_vm.fnMore}},[_vm._v(" 了解更多 "),_c('div')])])]):_vm._e()])}),_vm._m(0),_c('div',{staticClass:"align-between"},_vm._l((_vm.data1),function(item,index){return (index < 3)?_c('div',{key:'data1_' + index,staticClass:"bg_fff pointer active1",on:{"click":function($event){return _vm.toZx3(item.activity_id)}}},[_c('div',{staticClass:"img_out1"},[_c('el-image',{staticClass:"img1",staticStyle:{"min-width":"420px","width":"420px","height":"205px","display":"block"},attrs:{"fit":"cover","src":item.activity_thumb}})],1),_c('div',{staticStyle:{"padding":"35px 25px"}},[_c('div',{staticClass:"aui-ellipsis-1 font-333-18-bold",staticStyle:{"margin-bottom":"9px"}},[_vm._v(" "+_vm._s(item.activity_title || '')+" ")]),_c('div',{staticClass:"align-between"},[_c('div',{staticClass:"font-666-18"},[_vm._v(_vm._s(item.activity_time || ''))]),_c('div',{staticClass:"btn1 font-fff-18 align-center"},[_vm._v("立即参与")])])])]):_vm._e()}),0),_c('div',{staticClass:"align-end"},[_c('div',{staticClass:"zx_more pointer",on:{"click":function($event){return _vm.fnMore('active')}}},[_vm._v(" 了解更多 "),_c('div')])])],2),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listTitle1 flex-wrap",staticStyle:{"margin":"88px 0 37px"}},[_c('div',[_vm._v("本牛活动")])])
}]

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticStyle:{"margin":"14px auto 20px","width":"1300px"}},[_c('div',{staticClass:"flex-wrap",staticStyle:{"width":"1300px","margin":"auto"}},[_c('div',{staticClass:"my_menu"},[_c('div',{staticClass:"align-start order1 pointer",class:{ order1_active: _vm.switch1 },on:{"click":function($event){return _vm.fnSwitch(1)},"mouseenter":function($event){return _vm.mouseenterOut(1)},"mouseleave":function($event){return _vm.mouseleaveOut(1)}}},[_c('el-image',{staticStyle:{"width":"26px","height":"26px","margin":"0 22px 0 26px"},attrs:{"src":_vm.switch1 || _vm.show1
                ? require('@/assets/about1.png')
                : require('@/assets/about11.png'),"fit":"cover"}}),_c('div',{},[_vm._v("服务政策")]),_c('el-image',{style:(_vm.switch1
                ? 'width: 11px; height: 7px; margin-left: 59px'
                : 'width: 7px; height: 11px; margin-left: 61px'),attrs:{"src":_vm.switch1
                ? require('@/assets/bottom_arrow2.png')
                : require('@/assets/arrow_right3.png'),"fit":"cover"}})],1),(_vm.switch1)?_c('div',{staticClass:"pointer",staticStyle:{"padding-left":"79px"}},_vm._l((_vm.data3),function(item,index){return _c('div',{key:'data3_' + index,staticClass:"order1_item font-666-18 align-start",class:{ FF940A: _vm.type == 1 && item.service_id == _vm.idd },on:{"click":function($event){return _vm.fnFw(item.service_id)}}},[_vm._v(" "+_vm._s(item.service_title)+" ")])}),0):_vm._e(),_c('div',{staticClass:"align-start order1 pointer",class:{ order1_active: _vm.switch2 },on:{"click":function($event){return _vm.fnSwitch(2)},"mouseenter":function($event){return _vm.mouseenterOut(2)},"mouseleave":function($event){return _vm.mouseleaveOut(2)}}},[_c('el-image',{staticStyle:{"width":"26px","height":"26px","margin":"0 22px 0 26px"},attrs:{"src":_vm.switch2 || _vm.show2
                ? require('@/assets/about2.png')
                : require('@/assets/about22.png'),"fit":"cover"}}),_c('div',{},[_vm._v("关于我们")]),_c('el-image',{style:(_vm.switch2
                ? 'width: 11px; height: 7px; margin-left: 59px'
                : 'width: 7px; height: 11px; margin-left: 61px'),attrs:{"src":_vm.switch2
                ? require('@/assets/bottom_arrow2.png')
                : require('@/assets/arrow_right3.png'),"fit":"cover"}})],1),(_vm.switch2)?_c('div',{staticClass:"pointer",staticStyle:{"padding-left":"79px"}},_vm._l((_vm.data1),function(item,index){return _c('div',{key:'data1_' + index,staticClass:"order1_item font-666-18 align-start",class:{ FF940A: _vm.type == 2 && item.about_id == _vm.idd },on:{"click":function($event){return _vm.fnAboutUsDetail(item.about_id)}}},[_vm._v(" "+_vm._s(item.about_title)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"flex-con",staticStyle:{"padding-left":"10px"}},[_c('div',{staticStyle:{"background":"#ffffff","min-height":"1155px","padding":"47px 30px","box-sizing":"border-box"}},[_c('div',{staticClass:"font-333-24",staticStyle:{"text-align":"center","margin-bottom":"30px"}},[_vm._v(" "+_vm._s(_vm.type == 1 ? _vm.fwb.service_title : _vm.fwb.about_title)+" ")]),_c('div',{staticClass:"font-666-18 fwb",staticStyle:{"overflow-y":"scroll","max-height":"1060px","line-height":"36px"},domProps:{"innerHTML":_vm._s(_vm.type == 1 ? _vm.fwb.service_content : _vm.fwb.about_content)}})])])])]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
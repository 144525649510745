<template>
  <div class="my_left">
    <div class="my_zx font-fff-20-bold align-center">个人中心</div>
    <div class="my_menu">
      <div
        class="align-start order1 pointer"
        :class="{ order1_active: switch1 }"
        @click="fnSwitch(1)"
        @mouseenter="mouseenterOut(1)"
        @mouseleave="mouseleaveOut(1)"
      >
        <el-image
          style="width: 26px; height: 26px; margin: 0 22px 0 26px"
          :src="
            switch1||show1
              ? require('@/assets/grzx/order1.png')
              : require('@/assets/grzx/order11.png')
          "
          fit="cover"
        ></el-image>
        <div class="">订单中心</div>
        <!-- font-666-18-bold -->
        <el-image
          :style="
            switch1
              ? 'width: 11px; height: 7px; margin-left: 59px'
              : 'width: 7px; height: 11px; margin-left: 61px'
          "
          :src="
            switch1
              ? require('@/assets/bottom_arrow2.png')
              : require('@/assets/arrow_right3.png')
          "
          fit="cover"
        ></el-image>
      </div>
      <div style="padding-left: 79px" class="pointer" v-if="switch1">
        <div
          class="order1_item font-666-18 align-start"
          @click="fnOrderZx(0)"
          :class="{ FF940A: num == 0 }"
        >
          全部订单
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnOrderZx(1)"
          :class="{ FF940A: num == 1 }"
        >
          待付款
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnOrderZx(2)"
          :class="{ FF940A: num == 2 }"
        >
          待发货
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnOrderZx(3)"
          :class="{ FF940A: num == 3 }"
        >
          待收货
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnOrderZx(4)"
          :class="{ FF940A: num == 4 }"
        >
          已完成
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnOrderZx(5)"
          :class="{ FF940A: num == 5 }"
        >
          售后
        </div>
      </div>

      <div
        class="align-start order1 pointer"
        :class="{ order1_active: switch2 }"
        @click="fnSwitch(2)"
        @mouseenter="mouseenterOut(2)"
        @mouseleave="mouseleaveOut(2)"
      >
        <el-image
          style="width: 26px; height: 26px; margin: 0 22px 0 26px"
          :src="
            switch2||show2
              ? require('@/assets/grzx/order2.png')
              : require('@/assets/grzx/order22.png')
          "
          fit="cover"
        ></el-image>
        <div class="">企业管理</div>
        <!-- font-666-18-bold -->
        <el-image
          :style="
            switch2
              ? 'width: 11px; height: 7px; margin-left: 59px'
              : 'width: 7px; height: 11px; margin-left: 61px'
          "
          :src="
            switch2
              ? require('@/assets/bottom_arrow2.png')
              : require('@/assets/arrow_right3.png')
          "
          fit="cover"
        ></el-image>
      </div>
      <div style="padding-left: 79px" class="pointer" v-if="switch2">
        <div
          class="order1_item font-666-18 align-start"
          @click="fnRzzx"
          :class="{ FF940A: num == 6 }"
        >
          认证中心
        </div>
      </div>

      <div
        class="align-start order1 pointer"
        :class="{ order1_active: switch3 }"
        @click="fnSwitch(3)"
        @mouseenter="mouseenterOut(3)"
        @mouseleave="mouseleaveOut(3)"
      >
        <el-image
          style="width: 26px; height: 26px; margin: 0 22px 0 26px"
          :src="
            switch3||show3
              ? require('@/assets/grzx/order3.png')
              : require('@/assets/grzx/order33.png')
          "
          fit="cover"
        ></el-image>
        <div class="">账号中心</div>
        <!-- font-666-18-bold -->
        <el-image
          :style="
            switch3
              ? 'width: 11px; height: 7px; margin-left: 59px'
              : 'width: 7px; height: 11px; margin-left: 61px'
          "
          :src="
            switch3
              ? require('@/assets/bottom_arrow2.png')
              : require('@/assets/arrow_right3.png')
          "
          fit="cover"
        ></el-image>
      </div>
      <div style="padding-left: 79px" class="pointer" v-if="switch3">
        <div
          class="order1_item font-666-18 align-start"
          @click="fnJbzl"
          :class="{ FF940A: num == 7 }"
        >
          基本资料
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnActive"
          :class="{ FF940A: num == 8 }"
        >
          我的活动
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnMyAddress"
          :class="{ FF940A: num == 9 }"
        >
          通用地址管理
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnFp"
          :class="{ FF940A: num == 10 }"
        >
          发票抬头
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnCollect"
          :class="{ FF940A: num == 11 }"
        >
          我的收藏
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnHistory"
          :class="{ FF940A: num == 12 }"
        >
          浏览历史
        </div>
      </div>

      <div
        class="align-start order1 pointer"
        :class="{ order1_active: switch4 }"
        @click="fnSwitch(4)"
        @mouseenter="mouseenterOut(4)"
        @mouseleave="mouseleaveOut(4)"
      >
        <el-image
          style="width: 26px; height: 26px; margin: 0 22px 0 26px"
          :src="
            switch4||show4
              ? require('@/assets/grzx/order4.png')
              : require('@/assets/grzx/order44.png')
          "
          fit="cover"
        ></el-image>
        <div class="">分销中心</div>
        <!-- font-666-18-bold -->
        <el-image
          :style="
            switch4
              ? 'width: 11px; height: 7px; margin-left: 59px'
              : 'width: 7px; height: 11px; margin-left: 61px'
          "
          :src="
            switch4
              ? require('@/assets/bottom_arrow2.png')
              : require('@/assets/arrow_right3.png')
          "
          fit="cover"
        ></el-image>
      </div>
      <div style="padding-left: 79px" class="pointer" v-if="switch4">
        <div
          class="order1_item font-666-18 align-start"
          @click="fnTui"
          :class="{ FF940A: num == 13 }"
        >
          推广用户
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnIncome"
          :class="{ FF940A: num == 14 }"
        >
          收益明细
        </div>
        <div
          class="order1_item font-666-18 align-start"
          @click="fnXy"
          :class="{ FF940A: num == 15 }"
          v-if="user_business == 2"
        >
          分销协议
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authStatus } from '../api'
export default {
  // props:['num'],
  props: {
    num111: {
      Type: Number,
      default: 999,
    },
  },
  data() {
    return {
      value1: '',
      num: 0,
      user_business:
        JSON.parse(localStorage.getItem('allUser')).user_business || '',
      switch1: true,
      switch2: true,
      switch3: true,
      switch4: true,
      show1:false,
      show2:false,
      show3:false,
      show4:false,
    }
  },
  methods: {
    mouseenterOut(index) {
      this['show' + index]=true
    },
    mouseleaveOut(index) {
      this['show' + index]=false
    },
    fnSwitch(num1) {
      this['switch' + num1] = !this['switch' + num1]
    },
    fnOrderZx(type) {
      this.num = type
      this.$router.push({
        name: 'OrderZx',
        params: {
          id: type + '.html',
        },
      })
      this.$emit('update', type)
    },
    fnRzzx() {
      this.num = 6
      this.$router.push({
        name: 'Rzzx',
        params: {
          id: '.html',
        },
      })
      return
      let params = {}
      authStatus(params).then((data) => {
        if (data.status == 200) {
          if (data.msg == 1) {
            this.$message.error('审核中')
          } else {
            this.$router.push({
              name: 'Rzzx',
              params: {
                id: '.html',
              },
            })
          }
        }
      })
    },
    fnJbzl() {
      this.num = 7
      this.$router.push({
        name: 'Jbzl',
        params: {
          id: '.html',
        },
      })
    },
    fnActive() {
      this.num = 8
      this.$router.push({
        name: 'MyActive',
        params: { id: '.html' },
      })
    },
    fnMyAddress() {
      this.num = 9
      this.$router.push({
        name: 'MyAddress',
        params: {
          id: '.html',
        },
      })
    },
    fnFp() {
      this.num = 10
      this.$router.push({
        name: 'FaPiao',
        params: { id: '.html' },
      })
    },
    fnCollect() {
      this.num = 11
      this.$router.push({
        name: 'MyCollect',
        params: { id: '.html' },
      })
    },
    fnHistory() {
      this.num = 12
      this.$router.push({
        name: 'MyHistory',
        params: { id: '.html' },
      })
    },
    fnIncome() {
      this.num = 14
      this.$router.push({
        name: 'Income',
        params: { id: '.html' },
      })
    },
    fnTui() {
      this.num = 13
      this.$router.push({
        name: 'Spread',
        params: { id: '.html' },
      })
    },
    fnXy() {
      this.num = 15
      this.$router.push({
        name: 'XieYi',
        params: { id: '.html' },
      })
    },
  },
  mounted() {
    // console.log(this.$router.history.current.name);
    if (this.num111 != 999) {
      this.num = this.num111
    } else {
      if (this.$router.history.current.name == 'OrderXq') {
        this.num = 999
        return
      }
      let can = this.$route.params.id.split('.')[0]
      this.num = can
    }
  },
}
</script>

<style scoped>
.order1 {
  height: 48px;
  font-size: 18px;
  color: #666;
  font-weight: 500;
  border-left: 4px solid #fff;
}
.order1_active {
  border-left: 4px solid #ff940a;
  color: #ff940a;
  background-color: rgba(255, 226, 189, 0.2);
}
.order1:hover {
  border-left: 4px solid #ff940a;
  color: #ff940a;
  background-color: rgba(255, 226, 189, 0.2);
}
.order1_item {
  height: 48px;
}
.my_left {
  width: 256px;
  height: 1218px;
  background: #ffffff;
  /* border-radius: 0px 0px 4px 4px; */
  text-align: left;
  margin-right: 16px;
}
.my_zx {
  height: 63px;

  background: #fa9c22;
  margin-bottom: 20px;
}
.my_menu {
  /* padding: 0 0 0 60px; */
}
.my_right {
  padding-left: 10px;
}
.myTab:hover {
  color: #ff940a;
}
.order1_item:hover {
  color: #ff940a;
}
</style>

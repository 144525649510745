<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top: 14px;">
      <div>
        <MyInfo :num111="8" />
      </div>
      <div class="my_right flex-con">
        <div v-if="data.length > 0">
          <div class="border_eee">
          <div class="my_order">
            <div class="align-start cheng_head11">
            <div class="font-333-20-bold">我的活动</div>
          </div>
            <div class="flex-wrap font-333-16-bold" style="line-height: 56px;border-bottom: 1px solid #EEEEEE;padding-left:22px;">
              <div class="flex-con" style="text-align: left;">活动名称</div>
              <div style="min-width: 80px">姓名</div>
              <div style="min-width: 100px">手机号</div>
              <div style="min-width: 135px">公司名称</div>
              <div style="min-width: 135px">备注</div>
              <div style="width: 180px">报名时间</div>
              <div style="width: 70px">活动状态</div>
              <div style="width: 110px">操作</div>
            </div>
          </div>
          <div class="my_order" style="padding-left:22px;">
            <div
              class="flex-wrap font-333-16-22"
              style="margin-bottom: 22px"
              v-for="(item, index) in data"
              :key="'active_' + item.aa_id + '_' + index"
            >
              <div class="flex-con" style="text-align: left">
                {{ item.activity_title || '' }}
              </div>
              <!-- <div style="min-width: 135px">05.01-05.20</div> -->
              <div style="width: 80px">{{ item.aa_username || '' }}</div>
              <div style="width: 100px">{{ item.aa_phone || '' }}</div>
              <div style="width: 135px">{{ item.aa_company || '' }}</div>
              <div style="width: 135px">{{ item.aa_remark || '' }}</div>
              <div style="width: 180px" class="font-666-16-bold">{{ item.aa_time || '' }}</div>
              <div style="width: 70px" class="font-666-16-bold cor_FA9C22">{{item.activity_status==1?'进行中':'已结束'}}</div>
              <div
                style="width: 110px"
                class="pointer font-777-16"
                @click="toZx3(item.activity_id)"
              >
                查看
              </div>
            </div>
          </div>
        </div>
      </div>
        <div
          style="text-align: center; margin: 240px auto 0"
          v-else
        >
          <el-image
            style="width: 160px; height: 160px; margin-bottom: 16px"
            :src="require('@/assets/quesheng/no_content.png')"
            fit="cover"
          ></el-image>
          <div class="font-666-14">暂无内容~</div>
        </div>

        <div class="align-end" v-if="total > 0" style="margin-top: 20px;">
          <Page
            :limit="limit"
            :totalNum="total"
            @onLimit="changeLimit"
            @onPage="changePage"
          />
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->

    <!-- <div class="flex-wrap font-333" style="margin-bottom: 20px">
        <div class="flex-con" style="text-align: left">
          本牛某一限定活动标题
        </div>
        <div style="min-width: 135px">05.01-05.20</div>
        <div style="width: 135px">05.01</div>
        <div style="width: 135px" class="C52B33">进行中</div>
        <div style="width: 108px">查看</div>
      </div> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'
import { limit, myActivity } from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    MyInfo,
    right,
  },
  data() {
    return {
      data: [],
      page: 1,
      limit: limit,
      total: 0,
    }
  },
  methods: {
    initData() {
      let params = {
        page: this.page,
      }
      myActivity(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count
        }
      })
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      this.initData()
    },
    fnXq(idd) {
      this.$router.push({
        name: 'zixun3',
        params: {
          id: idd + '.html',
        },
      })
    },
  },
  mounted() {
    document.title = '本牛'
    this.initData()
  },
}
</script>

<style scoped>
.my_order {
  /* background: #ffffff;
  border-radius: 4px;
  padding: 20px 26px;
  font-size: 14px;*/
  margin-bottom: 22px; 
  text-align: center;
  box-sizing: border-box;
}
</style>

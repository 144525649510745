<template>
  <div>
    <Header :num111="4" />
    <div style="position: relative">
      <img :src="headImg" class="oneImg" @click="openTui" />
      <div style="position: absolute; width: 100%; height: 396px; top: 0">
        <div class="box-txt">
          <div class="font-fff-30-bold m_b20">定制服务</div>
          <div class="font-fff-18" style="line-height: 32px">
            满足您的多种需求， <br />
            多年生产经验，让您轻松放心
          </div>
        </div>
      </div>
    </div>
    <div class="kuang">
      <div class="title1 font-333-36-bold">填写表单信息</div>
      <div class="mb align-start">
        <div class="mb1 align-start">
          <div class="info"><span class="FF940A m_r5 xing">*</span>姓名:</div>
          <input
            type="text"
            placeholder="长度不超过20个字符"
            v-model="name"
            style="width: 402px"
          />
        </div>
        <div class="mb1 align-start">
          <div class="info" style="width: 242px">
            <span class="FF940A m_r5 xing">*</span>联系电话:
          </div>
          <input
            type="tel"
            placeholder="请输入您的联系方式"
            v-model="phone"
            style="width: 402px"
          />
        </div>
      </div>
      <div class="mb mb1 align-start">
        <div class="info">
          <span style="color: rgba(0, 0, 0, 0)">*</span>产品:
        </div>
        <input
          type="text"
          class=""
          placeholder=""
          v-model="product"
          style="width: 1045px"
        />
      </div>
      <div class="mb mb2 flex-wrap">
        <div class="info">
          <span style="color: rgba(0, 0, 0, 0)">*</span>规格:
        </div>
        <textarea class="flex-con" placeholder="" v-model="spec"></textarea>
      </div>
      <div style="margin: 37px 0 0 185px">
        <div class="blue align-start">
          <div class="align-start" style="margin-right:133px;">
            <img
              :src="require('@/assets/download.png')"
              alt=""
              style="width: 18px; height: 24px; margin-right: 9px"
            />
            <a :href="demo" class="pointer down1">下载模板</a>
          </div>

          <input
            type="file"
            accept="application/msexcel,text/plain,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            name="file"
            value="附件上传您的需求"
            id="fileImg"
            @change="uploadData"
            class="pointer input1"
          />
          <!-- accept="text/plain,application/pdf,application/msword" -->
        </div>
        <div class="tips">
          提示：收集电话只为方便联系您，您所需要的产品规格尽量填写清晰，方便后续平台联系您
        </div>
        <div class="btn pointer" @click="fnTi">提交表单</div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { loginAgreement, brandOrder, uploadFile, tdkPages } from '../api'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  data() {
    return {
      headImg: '',
      demo: '',
      name: '',
      phone: '',
      product: '',
      spec: '',
      file: '',
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
      tuiUrl: '',
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    openTui() {
      if (this.tuiUrl) {
        window.open(this.tuiUrl)
      }
    },
    fnGetTdk(type) {
      let params = {
        type: type,
      }
      tdkPages(params).then((data) => {
        if (data.status == 200) {
          this.tdkTitle = data.msg.title || '本牛'
          this.tdkKeywords = data.msg.keyword || '本牛'
          this.tdkDescription = data.msg.desc || '本牛'
        }
      })
    },
    fnAgreement(type) {
      let params = {
        type: type,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          if (type == 7) {
            this.headImg = data.msg.content1 || ''
            this.tuiUrl = data.msg.content2 || ''
          } else {
            this.demo = data.msg.content1 || ''
          }
        }
      })
    },
    fnTi() {
      if (!localStorage.getItem('allUser')) {
        this.$bus.$emit('login', 1)
        return
      }
      if (!this.name) {
        this.$message.error('请输入姓名')
        return
      }
      if (!this.phone) {
        this.$message.error('请输入联系电话')
        return
      }
      let params = {
        name: this.name,
        phone: this.phone,
        product: this.product,
        spec: this.spec,
        file: this.file,
      }
      brandOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success('提交成功')
        }
      })
    },
    uploadData(e) {
      let files = e.target.files[0]
      let formData = new FormData()
      formData.append('file', files)
      // console.log(files)
      let _self = this
      return new Promise((resolve, reject) => {
        uploadFile(formData)
          .then((response) => {
            this.file = response.msg
            console.log('93||' + response.msg)
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
  mounted() {
    this.fnAgreement(7)
    this.fnAgreement(9)
    this.fnGetTdk(5)
  },
}
</script>
<style scoped>
.xing{
  display:inline-block;font-size: 30px;line-height:15px;font-weight: 500;    position: relative;
    top: 10px;
}
.input1::file-selector-button {
  width: 101px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 3px;
  margin-right: 18px;
  cursor: pointer;
}
.input1::-ms-browse {
  width: 101px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 3px;
  cursor: pointer;
}

.input1 {
  font-size: 18px;
  font-weight: 400;
  color: #6eaaf9;
}
.down1 {
  height: 18px;
  font-size: 18px;
  font-weight: 400;
  color: #6eaaf9;
  /* line-height: 18px ; */
  text-decoration: none;
}
.box-txt {
  width: 1300px;
  margin: auto;
  box-sizing: border-box;
  padding: 48px 0 0 52px;
}
.oneImg {
  position: relative;
  width: 100%;
  height: 396px;
}

.fourAll {
  padding: 0 208px;
  margin-top: -20px;
}

.four {
  width: 173px;
  height: 173px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 40px 0;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 40px;
}

.fourImg {
  width: 60px;
  height: 60px;
}

.title {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.kuang {
  z-index: 1;
  position: relative;
  margin: -200px auto 63px;
  width: 1300px;
  min-height:750px;
  background: #ffffff;
  border-radius: 10px;
  padding: 67px 0 0;
  box-sizing: border-box;
}

.title1 {
  /* font-size: 28px;
  font-weight: 500;
  color: #333333; */
  margin-bottom: 68px;
  text-align: center;
}

.info {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  width: 149px;
  box-sizing: border-box;
  padding-right: 19px;
  text-align: right;
  /* margin-right: 19px; */
}

.mb {
  margin-bottom: 32px;
}

.mb1 {
  align-items: center;
}

.mb2 {
  align-items: baseline;
}

input[type='tel'],
input[type='text'],
input[type='password'] {
  height: 46px;
  margin: 0;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 400;
  color: #333;
  line-height: 46px;
  background: transparent;
  outline: none;
  border: 1px solid #dcdcdc;
}
input[type='tel']:focus,
input[type='text']:focus,
input[type='password']:focus {
  border: 1px solid #dcdcdc;
}

textarea {
  max-width: 1045px;
  height: 112px;
  border: 1px solid #dcdcdc;
  padding: 10px;
  font-size: 18px;
  color: #333;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0);
  outline: none;
  box-sizing: border-box;
  font-family: '微软雅黑';
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: 16px;
  color: #999999;
}
.blue {
  font-size: 12px;
  font-weight: 400;
  color: #3b9bff;
  text-align: right;
  margin-top: 17px;
}
.tips {
  margin-top: 30px;

  font-size: 18px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
}
.btn {
  width: 187px;
  height: 64px;
  background: #ff940a;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 64px;
  text-align: center;
  margin: 39px 0 0 376px;
  letter-spacing: 2px;
}
</style>

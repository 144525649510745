<template>
  <div>
    <div class="l_head align-center">
      <div class="l_head_in align-between">
        <div class="align-center">
          <router-link to="/">
            <el-image
              style="
                width: 208px;
                height: 72px;
                margin-right: 20px;
                cursor: pointer;
              "
              :src="require('@/assets/logo.png')"
              fit="cover"
            ></el-image>
          </router-link>
          <div>欢迎登录</div>
        </div>
        <div class="align-center">
          <el-image
            style="width: 24px; height: 24px; margin: 0 6px 0 30px"
            :src="require('@/assets/phone1.png')"
            fit="cover"
          ></el-image>
          <span style="text-decoration: underline">{{ phoneHeader }}</span>
        </div>
      </div>
    </div>
    <div class="meng_out">
      <div class="meng">
        <div class="meng_in align-between">
          <div class="l_left">
            <!-- <div style="font-size: 40px; line-height: 56px; margin-top: 126px">
              全球保温节能电子商务平台
            </div>
            <div
              style="
                font-size: 50px;
                margin: 40px 0;
                text-align: right;
                font-weight: 700;
              "
            >
              <span
                style="
                  width: 80px;
                  margin-right: 2px;
                  background: #fff;
                  height: 3px;
                  display: inline-block;
                  position: relative;
                  top: -14px;
                "
              ></span>
              本牛
            </div>
            <div style="font-size: 20px; text-align: center">
              厂家直供/品类齐全/价格最低/品质保障
            </div> -->
          </div>
          <div class="l_out">
            <div class="l_tab align-around">
              <div class="color333 pointer" @click="fnTab(true)">立即登录</div>
              <!-- <div class="xian"></div> -->
            </div>
            <div class="input_item flex-wrap">
              <div class="input_left">
                <el-image
                  style="width: 26px; height: 26px; margin: 12px"
                  :src="require('@/assets/login_phone.png')"
                  fit="cover"
                ></el-image>
              </div>
              <input
                type="tel"
                name=""
                value=""
                v-model="phone"
                placeholder="请输入您的手机号"
              />
            </div>
            <div class="flex-wrap" v-if="loginType">
              <div class="input_item" style="max-width: 182px">
                <input
                  type="tel"
                  name=""
                  value=""
                  v-model="code"
                  placeholder="请输入验证码"
                  style="border-radius: 4px; max-width: 182px"
                  @keyup.enter="fnLogin"
                />
              </div>
              <div class="flex-con"></div>
              <div
                class="input_item align-center pointer DEA422 cor_f08300"
                style="min-width: 102px; font-size: 14px"
                @click="need_ma"
              >
                {{ codeTxt }}
              </div>
            </div>
            <div class="input_item flex-wrap" v-else>
              <div class="input_left">
                <el-image
                  style="width: 26px; height: 26px; margin: 12px"
                  :src="require('@/assets/login_pwd.png')"
                  fit="cover"
                ></el-image>
              </div>
              <input
                type="password"
                name=""
                value=""
                v-model="password"
                placeholder="请输入您的密码"
                @keyup.enter="fnLogin"
              />
            </div>
            <div class="align-start font-333-12 xyTxt">
              <input type="checkbox" name="" value="" v-model="checked" />
              我已阅读并同意<span
                class="pointer DEA422 cor_f08300"
                @click="fnXy(3)"
                >《本牛网站服务协议》</span
              ><span class="pointer DEA422 cor_f08300" @click="fnXy(4)"
                >《隐私政策》</span
              >
            </div>
            <div class="btn align-center pointer bg_f08300" @click="fnLogin">
              登录/注册
            </div>
            <div class="align-between">
              <!-- <div></div> -->
              <!-- <div class="align-center">
                <el-image
                  style="width: 17px; height: 17px; margin-right: 4px"
                  :src="require('@/assets/to_register.png')"
                  fit="cover"
                ></el-image>
                <div class="to_register" @click="fnRegister">立即注册</div>
              </div> -->

              <div
                class="forget_pwd pointer"
                @click="fnTab(false)"
                v-show="loginType"
              >
                密码登录
              </div>
              <div
                class="forget_pwd pointer"
                @click="fnTab(true)"
                v-show="!loginType"
              >
                验证码登录
              </div>
              <div
                class="forget_pwd pointer"
                v-show="!loginType"
                @click="fnForget"
              >
                忘记密码？
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="1200px">
      <div class="dialog">
        <div
          style="
            background: #ffffff;
            border-radius: 4px;
            height: 635px;
            padding: 0 20px;
            overflow-y: scroll;
          "
          class="scrollbar"
        >
          <div
            style="
              font-size: 16px;
              text-align: center;
              font-weight: 500;
              color: #333333;
              line-height: 57px;
            "
          >
            {{ type == 3 ? '《本牛网站服务协议》' : '《隐私政策》' }}
          </div>
          <div
            class="font-fff-333"
            style="line-height: 20px"
            v-html="type == 3 ? xyServe : xyYs"
          ></div>
        </div>
      </div>
    </el-dialog>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { passwordLogin, codeLogin, sendCode, loginAgreement } from '../api'
export default {
  components: {
    Footer,
  },
  data() {
    return {
      loginType: true, //true是验证码登录
      phone: '',
      code: '',
      password: '',
      isOnclick: true,
      onClickTime: '',
      isinerval: '',
      onClickTime1: '',
      codeTxt: '获取验证码',
      phoneHeader: '',
      checked: true,
      dialogVisible: false,
      xyServe: '',
      xyYs: '',
      type: '',
    }
  },
  methods: {
    fnTab(boole) {
      clearInterval(this.isinerval)
      this.codeTxt = '获取验证码'
      this.code = ''
      this.phone = ''
      this.loginType = boole
    },
    fnRegister() {
      this.$router.push({
        name: 'Register',
        params: {},
      })
    },
    fnForget() {
      this.$router.push({
        name: 'FrogetPwd',
        params: {},
      })
    },
    fnLogin() {
      this.loginType ? this.fnLoginCode() : this.fnLoginPwd()
    },
    fnLoginCode() {
      if (!this.phone) {
        this.$message.error('请输入您的手机号')
        return
      }
      if (!this.code) {
        this.$message.error('请输入验证码')
        return
      }
      if (!this.checked) {
        this.$message.error('请勾选服务协议与隐私政策')
        return
      }

      let params = {
        phone: this.phone,
        code: this.code,
        type: 1,
        from_source: localStorage.getItem('from_source') || '',
      }
      codeLogin(params).then((data) => {
        if (data.status == 200) {
          localStorage.setItem('allUser', JSON.stringify(data.msg))
          clearInterval(this.isinerval)
          this.$router.push({ path: '/' })
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    fnLoginPwd() {
      if (!this.phone) {
        this.$message.error('请输入您的手机号')
        return
      }
      if (!this.password) {
        this.$message.error('请输入您的密码')
        return
      }
      if (!this.checked) {
        this.$message.error('请勾选服务协议与隐私政策')
        return
      }
      let params = {
        phone: this.phone,
        password: this.password,
      }
      passwordLogin(params).then((data) => {
        if (data.status == 200) {
          localStorage.setItem('allUser', JSON.stringify(data.msg))
          clearInterval(this.isinerval)
          this.$router.push({ path: '/' })
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    ///验证手机号
    need_ma() {
      let regBox = {
        regMobile: /^1[3456789]\d{9}$/, //手机
      }
      let rPhone = regBox.regMobile.test(this.phone)
      if (!(rPhone && this.phone)) {
        this.$message.error('请输入正确的手机号')
        return
      }
      if (this.isOnclick) {
        //调用接口判断
        this.sendSMS()
      }
    },
    //获取验证码

    sendSMS() {
      let params = {
        phone: this.phone,
      }
      sendCode(params).then((data) => {
        if (data.status == 200) {
          if (this.isOnclick) {
            this.onClickTime = Math.floor(new Date().getTime() / 1000)
            this.isinerval = setInterval(this.CountDown, 1000)
            this.isOnclick = false
          }
        }
        // console.log('|||||'+localStorage.getItem('allUser'));
      })
    },
    fnXy(type) {
      this.dialogVisible = true
      this.type = type
      this.fnAgreement(this.type)
    },
    fnAgreement(type) {
      let params = {
        type: type,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          // 3 本牛网站服务协议   4 本牛隐私协议
          if (type == 10) {
            this.phoneHeader = data.msg.content1 || ''
            localStorage.setItem('phoneHeader', data.msg.content1)
          }
          if (type == 3) {
            this.xyServe = data.msg.content1 || ''
          }
          if (type == 4) {
            this.xyYs = data.msg.content1 || ''
          }
        }
      })
    },
    //验证码60秒倒数
    CountDown() {
      this.onClickTime1 =
        60 - (Math.floor(new Date().getTime() / 1000) - this.onClickTime)
      if (this.onClickTime1 < 1) {
        this.codeTxt = '重新获取'
        clearInterval(this.isinerval)
        this.isOnclick = true
        return
      }
      this.codeTxt = this.onClickTime1 + 's'
    },
  },
  mounted() {
    document.title = '本牛'
    this.fnAgreement(10)
  },
}
</script>

<style scoped>
.forget_pwd {
  font-size: 12px;
  color: #aaaaaa;
  line-height: 17px;
}
.to_register {
  font-size: 12px;
  color: #efc845;
  line-height: 17px;
}
.btn {
  height: 40px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 4px;
  margin: 20px 0 10px;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}
input[type='text'],
input[type='password'],
input[type='tel'] {
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  height: 50px;
  /* width: 125px; */
  padding: 0 12px;
  color: #333333;
  font-size: 14px;
  outline: none;
}
input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder {
  color: #aaa;
}
input[type='text']:active,
input[type='password']:active,
input[type='tel']:active {
  box-sizing: border-box;
  border: none;
  border-right: none;
}
.input_left {
  width: 50px;
  height: 50px;
  background: #ebebeb;
  border-radius: 4px 0px 0px 4px;
}
.input_item {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  margin-top: 30px;
}
.xian {
  width: 1px;
  height: 28px;
  background: #d8d8d8;
}
.color333 {
  color: #333;
}
.l_tab {
  font-size: 20px;
  color: #777777;
}
.l_out {
  width: 385px;
  height: 400px;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  top: 80px;
  padding: 50px 42px 0;
  box-sizing: border-box;
}
.l_head {
  height: 110px;
  background: linear-gradient(180deg, #9c9b96 0%, #676664 100%);
  width: 100%;
}
.l_head_in {
  width: 1080px;
  margin: auto;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}
.meng_out {
  height: 560px;
  position: relative;
  background: url('@/assets/static/login_bg.png') no-repeat center center;
  background-size: cover;
}
.meng {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
}
.l_left {
  color: #ffffff;
  width: 540px;
}
.meng_in {
  width: 1080px;
  margin: auto;
}
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background: url('@/assets/check2.png') no-repeat center center;
  background-size: 100% 100%;
}
input[type='checkbox']:checked {
  background: url('@/assets/checked21.png') no-repeat center center;
  background-size: 100% 100%;
}
/* input[type='checkbox'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  margin-right: 5px;
}
input[type='checkbox']:checked {
  border: 1px solid #333;
  position: relative;
}
input[type='checkbox']:checked:after {
  position: absolute;
  width: 8px;
  height: 5px;
  top: 50%;
  left: 50%;
  margin-left: -5px;
  margin-top: -5px;
  content: '';
  color: #000;
  display: inline-block;
  visibility: visible;
  border: 2px solid #333;
  border-top: none;
  border-right: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
} */
.xyTxt {
  line-height: 17px;
  margin: 20px 0;
}
::v-deep .el-dialog__body {
  padding-top: 0;
}
</style>

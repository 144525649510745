<template>
  <div id="main">
    <div ref="top111">
      <HeaderOld :num111="0" />
      <div style="background: #fff; padding-bottom: 40px">
        <div style="width: 1200px; margin: auto">
          <div class="flex-wrap">
            <div style="width: 230px"></div>
            <div style="margin: 10px 10px 0; width: 724px">
              <div
                class="swiper-container"
                style="width: 724px; border-radius: 0"
                id="swiper-container1"
              >
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide pointer"
                    v-for="(item, index) in data.bigBanner"
                    :key="'bigBanner_' + index"
                    @click="fnLb(item.banner_id)"
                  >
                    <img :src="item.banner_image" />
                  </div>
                </div>
                <div class="swiper-pagination swiper111" style=""></div>
              </div>
            </div>
            <div>
              <div class="top">
                <div class="flex-wrap" style="align-items: center">
                  <el-image
                    :src="require('@/assets/logo.png')"
                    fit="cover"
                    class="avatar"
                  ></el-image>
                  <div>
                    <div class="hao">您好，{{ userName }}</div>
                    <div>欢迎来到本牛</div>
                  </div>
                </div>
                <div class="button align-center" v-if="!allUser">
                  <div class="btn1 pointer" @click="toLogin()">注册/登录</div>
                  <!-- <div class="flex-con"></div> -->
                  <!-- <div class="btn2 pointer" @click="toLogin()">登录</div> -->
                </div>
              </div>
              <div class="bottom">
                <div class="title">平台优势</div>
                <div class="flex-wrap1">
                  <div
                    class="four"
                    @mouseenter="mouseenter4(index, item)"
                    @mouseleave="mouseleave4(index, item)"
                    v-for="(item, index) in data.fourAdvantage"
                    :key="'fourAdvantage_' + index"
                  >
                    <img
                      :src="
                        isHover4 == index ? item.advantage_icon1 : item.advantage_icon
                      "
                    />
                    <div>{{ item.advantage_name || "" }}</div>
                  </div>
                </div>

                <div class="bottomImg">
                  <div class="swiper-container swiper-container5" id="swiper-container5">
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="(item, index) in data.smallBanner"
                        :key="'smallBanner_' + index"
                      >
                        <img :src="item.banner_image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="notice flex-wrap">
            <img src="../assets/main1_55.png" class="noticeImg" />
            <div class="notice1">公告</div>
            <div class="flex-con">
              <div class="swiper-container swiper-container1" id="swiper-container2">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide pointer"
                    v-for="(item, index) in data.notice"
                    :key="'notice_' + index"
                    @click="fnNotice(index)"
                  >
                    <div class="swiper-content aui-ellipsis-1">
                      {{ item.notice_title || "" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="padding-bottom: 40px">
        <div style="width: 1200px; margin: auto">
          <div class="listTitle flex-wrap">
            <div class="xian"></div>
            <div>应用领域</div>
          </div>
          <div class="swiper-container swiper-container2" id="swiper-container3">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide swiper-slide1 pointer"
                v-for="(item, index) in appList"
                :key="'appList_' + index"
                @click="fnApp(item.app_id)"
              >
                <img :src="item.app_thumb" class="lyu" />
                <div class="half">
                  <img :src="item.app_icon1" class="lyu1" />
                  <div class="all_title">{{ item.app_title }}</div>
                </div>
                <div class="all">
                  <img :src="item.app_icon2" class="lyu2" />
                  <div class="all_title">{{ item.app_title }}</div>
                  <div class="all_info">
                    {{ item.app_intro }}
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next pointer next1"></div>
            <div class="swiper-button-prev pointer prev1"></div>
          </div>
        </div>
      </div>
    </div>

    <div style="background: #fff">
      <div style="width: 1200px; margin: auto">
        <div class="recommend flex-wrap" id="recommendId">
          <div class="flex-con"></div>
          <div class="xian1"></div>
          <div class="recommend_title align-center">
            <img src="../assets/main1_166.png" alt="" />
            <div>为你推荐</div>
          </div>
          <div class="xian1"></div>
          <div class="flex-con"></div>
        </div>
        <div class="box">
          <el-image :src="tuiBg" fit="cover" class="topImg"></el-image>
          <div
            class="one pointer"
            v-for="(item, index) in data.niceGoods"
            :key="'niceGoods_' + index"
            @click="fnGoodsDetails(item.goods_id)"
          >
            <el-image :src="item.goods_thumb" fit="cover" class="oneImg"></el-image>
            <div class="oneTitle">{{ item.goods_name }}</div>
            <div class="li-label-out">
              <div
                class="li-label"
                v-for="(item, index) in item.goods_tag"
                :key="'goods_tag_' + index"
              >
                {{ item }}
              </div>
            </div>

            <div class="oneBottom">
              <div class="priceAll flex-wrap">
                <div class="flex-con">已售{{ item.goods_saled || 0 }}</div>
                <div class="price">
                  <span class="price1">￥</span
                  >{{ item.goods_floor_price > 0 ? item.goods_floor_price : "面议" }}
                </div>
              </div>
              <div class="btn">立即抢购</div>
            </div>
          </div>
          <div class="one pointer one1" @click="fnRecommend">
            <el-image
              :src="require('@/assets/static/image.png')"
              fit="contain"
              class="oneImg"
            ></el-image>
            <div class="more_meng">
              <div
                style="
                  background: rgba(0, 0, 0, 0.4);
                  border-radius: 4px;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                "
              ></div>
              <div class="more">更多产品</div>
            </div>

            <div class="oneBottom">
              <div class="btn">更多产品</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="recommend flex-wrap" style="padding-bottom: 0">
      <div class="flex-con"></div>
      <div class="xian1"></div>
      <div class="recommend_title align-center">
        <div>精选品类</div>
      </div>
      <div class="xian1"></div>
      <div class="flex-con"></div>
    </div>

    <div
      v-for="(item, index1) in data.goodsCate"
      :key="'goodsCate_' + index1"
      :class="{ bg_fff: index1 % 2 }"
      :id="'xl_' + index1"
      style="padding: 40px 0"
    >
      <div style="width: 1200px; margin: auto">
        <div class="flex-wrap">
          <div class="xl_left flex-wrap flex-vertical">
            <div class="flex-con xl_scroll">
              <div class="xl_title">{{ item.gc_name }}</div>
              <div
                class="xl_item_title pointer"
                v-for="(item, index) in item.secondCate"
                :key="'secondCate_' + index"
                :class="{ active: numAll[index1] == index }"
                @click="fnSecond(index1, index)"
              >
                {{ item.gc_name || "" }}
              </div>
            </div>
            <div v-if="item.gc_images">
              <el-image
                fit="cover"
                :src="item.gc_images.split(',')[0]"
                style="min-height: 200px; width: 200px; height: 200px"
              ></el-image>
            </div>
          </div>
          <div class="flex-con">
            <div v-if="item.secondCate[numAll[index1]].goods.length > 0">
              <div
                class="two pointer"
                v-for="(item, index) in item.secondCate[numAll[index1]].goods"
                :key="'goods_' + item.gc_id + '_' + index"
                @click="fnGoodsDetails(item.goods_id)"
                v-if="index < 8"
              >
                <el-image fit="cover" :src="item.goods_thumb" class="twoImg"></el-image>
                <div class="twoTitle aui-ellipsis-3">
                  {{ item.goods_name || "" }}
                </div>
                <div class="price2">
                  <span class="price3">￥</span
                  >{{ item.goods_floor_price > 0 ? item.goods_floor_price : "面议" }}
                </div>
              </div>
            </div>
            <div v-else>
              <div style="text-align: center; margin: 225px auto">
                <el-image
                  style="width: 160px; height: 160px; margin-bottom: 16px"
                  :src="require('@/assets/quesheng/no_content.png')"
                  fit="cover"
                ></el-image>
                <div class="font-666-14">商品完善中~</div>
              </div>
            </div>
          </div>
        </div>
        <!-- (item, index) in item.secondCate.length > 0
                ? item.secondCate[numAll[index1]].goods.length > 0
                  ? item.secondCate[numAll[index1]].goods
                  : item.goods
                : item.goods -->
        <el-image
          fit="cover"
          style="margin: 20px 0 0; width: 100%"
          v-if="data.advert.length > index1"
          :src="data.advert[index1].advert_thumb"
        ></el-image>
      </div>
    </div>
    <div style="background: #fff; padding-bottom: 40px">
      <div style="width: 1200px; margin: auto">
        <div class="recommend flex-wrap">
          <div class="flex-con"></div>
          <div class="xian1"></div>
          <div class="recommend_title align-center">
            <div>平台优势</div>
          </div>
          <div class="xian1"></div>
          <div class="flex-con"></div>
        </div>
        <div class="fourAll align-around">
          <div
            class="fourrr"
            @mouseenter="mouseenter(index, item)"
            @mouseleave="mouseleave(index, item)"
            v-for="(item, index) in data.allAdvantage"
            :key="'allAdvantage_' + index"
          >
            <img
              class="fourrrImg"
              :src="isHover == index ? item.advantage_icon1 : item.advantage_icon"
              alt=""
            />
            <div class="fourrrInfo">{{ item.advantage_name || "" }}</div>
            <div class="fourCard">
              {{ item.advantage_intro || "" }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="padding-bottom: 40px">
      <div style="width: 1200px; margin: auto">
        <div class="recommend flex-wrap" style="padding-bottom: 40px">
          <div class="flex-con"></div>
          <div class="xian1"></div>
          <div class="recommend_title align-center">
            <div>行业动态</div>
          </div>
          <div class="xian1"></div>
          <div class="flex-con"></div>
        </div>
        <div class="flex-wrap">
          <div style="width: 782px" class="align-start">
            <div
              class="pointer"
              v-if="index < 2"
              v-for="(item, index) in data.info"
              :key="'info1_' + index"
              @click="toZx(item.info_id)"
            >
              <el-image :src="item.info_thumb" fit="cover" class="left_img1"></el-image>
              <div style="padding-right: 26px">
                <div class="align-start" style="margin-bottom: 10px">
                  <div class="dian"></div>
                  <div class="left_title1 aui-ellipsis-1">
                    {{ item.info_title || "" }}
                  </div>
                </div>
                <div class="left_info1 aui-ellipsis-2">
                  {{ item.info_intro || "" }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex-con">
            <div
              class="rightOne flex-wrap pointer"
              v-if="index > 1"
              v-for="(item, index) in data.info"
              :key="'info2_' + index"
              @click="toZx(item.info_id)"
            >
              <div class="dian1"></div>
              <div class="xian4"></div>
              <div class="flex-con aui-ellipsis-1">
                {{ item.info_title || "" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="background: #fff; padding-bottom: 40px">
      <div style="width: 1200px; margin: auto;display:none;">
        <div class="recommend flex-wrap" style="padding-bottom: 40px">
          <div class="flex-con"></div>
          <div class="xian1"></div>
          <div class="recommend_title align-center">
            <div>合作伙伴</div>
          </div>
          <div class="xian1"></div>
          <div class="flex-con"></div>
        </div>
        <div class="swiper-container swiper-container3" id="swiper-container4">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in data.partner"
              :key="'partner_' + index"
            >
              <img :src="item.partner_icon" class="parther" />
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
      <div class="fixleft pointer" v-if="showFixed">
        <div class="fixedHover" @click="toHere('tui')">
          <a style="text-decoration: none">为你推荐</a>
        </div>
        <div
          class="label"
          v-for="(item, index) in data.goodsCate"
          :key="'xilie_' + index"
          @click="toHere(index)"
        >
          <a style="text-decoration: none">{{ item.gc_name || "" }}</a>
        </div>
      </div>
    </div>

    <FooterOld />
    <rightOld />
  </div>
</template>
<script>
import HeaderOld from "@/components/HeaderOld.vue";
import FooterOld from "@/components/FooterOld.vue";
import rightOld from "@/components/rightOld.vue";
import Swiper from "swiper/bundle";
import { indexData, loginAgreement, tdkPages } from "../api";
export default {
  components: {
    HeaderOld,
    FooterOld,
    rightOld,
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: "keywords", content: this.tdkKeywords },
        { name: "description", content: this.tdkDescription },
      ],
    };
  },
  data() {
    return {
      data: {},
      appList: [],
      isHover: -1,
      isHover4: -1,
      numAll: [],
      tuiBg: "",
      info: [],
      allUser: localStorage.getItem("allUser")
        ? JSON.parse(localStorage.getItem("allUser")).user_id
        : "",
      scroll: "",
      showFixed: false,
      userName: localStorage.getItem("allUser")
        ? JSON.parse(localStorage.getItem("allUser")).user_username
          ? JSON.parse(localStorage.getItem("allUser")).user_username
          : JSON.parse(localStorage.getItem("allUser")).user_phone
        : "",
      tdkTitle: "",
      tdkKeywords: "",
      tdkDescription: "",
    };
  },
  methods: {
    fnGetTdk(type) {
      let params = {
        type: type,
      };
      tdkPages(params).then((data) => {
        if (data.status == 200) {
          this.tdkTitle = data.msg.title || "本牛";
          this.tdkKeywords = data.msg.keyword || "本牛";
          this.tdkDescription = data.msg.desc || "本牛";
        }
      });
    },
    initData() {
      // console.log(JSON.parse(localStorage.getItem('allUser')));
      // console.log(JSON.parse(localStorage.getItem('allUser')).user_id)
      let params = {};
      indexData(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg;
          this.info = data.msg.info;
          this.appList = data.msg.app;
          localStorage.setItem("goodsCate", JSON.stringify(data.msg.allFirstCate));
          localStorage.setItem("hotSearch", JSON.stringify(data.msg.hotSearch));
          localStorage.setItem("shopCarNumber", data.msg.shopCarNumber);
          this.numAll = [];
          if (data.msg.goodsCate.length > 0) {
            data.msg.goodsCate.forEach((element) => {
              this.numAll.push(0);
            });
          }
          // data.msg.goodsCate.forEach((element) => {
          //   this.numAll.push(0)
          // })
        }
      });
    },
    fnMore(gc_id, gc_name) {
      this.$router.push({
        name: "Search",
        params: {
          num: 3,
          type: 4,
          cateId0: gc_id, //一级分类id
          cateName0: gc_name,
          keyword: "new",
          // index1: decodeURIComponent(''),
          // index2: decodeURIComponent(''),
          // cateId1: decodeURIComponent(''),
          // cateName1: decodeURIComponent(''),
          // cateId2: decodeURIComponent(''),
          // cateName2: decodeURIComponent(''),
        },
      });
    },
    fnLb(banner_id) {
      // return
      this.$router.push({
        name: "lunboXq",
        params: {
          id: banner_id + ".html",
        },
      });
    },
    fnSecond(index1, index) {
      this.$set(this.numAll, index1, index);
    },
    // fnAppList() {
    //   //应用领域
    //   let params = {}
    //   appList(params).then((data) => {
    //     if (data.status == 200) {
    //       this.appList = data.msg
    //     }
    //   })
    // },
    fnApp(idd) {
      this.$router.push({
        name: "Hyyx",
        params: {
          id: idd + ".html",
        },
      });
    },
    fnLoginAgreement() {
      let params = {
        type: 8,
      };
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          this.tuiBg = data.msg.content1;
          localStorage.setItem("tuiBg", this.tuiBg);
          // console.log('396||'+localStorage.getItem('tuiBg'));
        }
      });
    },
    mouseenter(index, item) {
      this.isHover = index;
    },
    mouseleave(index, item) {
      this.isHover = -1;
    },
    mouseenter4(index, item) {
      this.isHover4 = index;
    },
    mouseleave4(index, item) {
      this.isHover4 = -1;
    },
    fnNotice(index) {
      this.$router.push({
        name: "notice",
        params: {
          id: this.data.notice[index].notice_id + ".html",
        },
      });
    },
    fnRecommend() {
      this.$router.push({
        name: "Recommend",
        params: {
          id: ".html",
        },
      });
    },
    handleScroll() {
      // console.log(document.getElementById("main").offsetHeight)
      //或者使用document.querySelector('.class或者#id').scrollTop
      let top111 = this.$refs.top111.offsetHeight;
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (
        this.scroll + document.body.clientHeight > top111 &&
        document.body.scrollHeight - document.body.clientHeight - 286 > this.scroll
      ) {
        this.showFixed = true;
      } else {
        this.showFixed = false;
      }
    },
    toHere(index) {
      if (index == "tui") {
        document.getElementById("recommendId").scrollIntoView(true);
        return;
      }
      document.getElementById("xl_" + index).scrollIntoView(true);
    },
    isPC() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      if (isPhone) {
        window.location.href = "https://m.niutop.com";
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.isPC();
  },
  mounted() {
    this.fnGetTdk(1);
    window.addEventListener("scroll", this.handleScroll);
    localStorage.setItem("addressObj1", "");
    localStorage.setItem("addressObj2", "");
    localStorage.setItem("addressObj3", "");
    this.initData();
    this.fnLoginAgreement();
    new Swiper("#swiper-container1", {
      autoplay: true,
      observer: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    new Swiper("#swiper-container5", {
      autoplay: true,
      observer: true,
    });
    new Swiper("#swiper-container2", {
      autoplay: true,
      observer: true,
      direction: "vertical",
      watchOverflow: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
    new Swiper("#swiper-container3", {
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 26,
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      observer: true,
    });
    // new Swiper("#swiper-container4", {
    //   breakpoints: {
    //     640: {
    //       slidesPerView: 2,
    //       spaceBetween: 0,
    //     },
    //     768: {
    //       slidesPerView: 4,
    //       spaceBetween: 0,
    //     },
    //     1024: {
    //       slidesPerView: 8,
    //       spaceBetween: 0,
    //     },
    //   },
    //   navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    //   },
    //   observer: true,
    // });
  },
};
</script>

<style scoped>
.xl_left {
  width: 240px;
  height: 650px;
  background: url("@/assets/xl_bg.png") no-repeat center center;
  background-size: 100% 100%;
  padding: 46px 20px;
  box-sizing: border-box;
}

.xl_title {
  font-size: 26px;
  font-weight: 500;
  color: #333333;
  line-height: 37px;
  margin-bottom: 14px;
}

.xl_item_title {
  font-size: 16px;
  font-weight: 400;
  color: #777777;
  line-height: 22px;
  margin-bottom: 12px;
}

.xl_item_title.active {
  font-weight: 500;
  /* color: #dca01e; */
  color: #f08300;
}
.xl_item_title:hover {
  font-weight: 500;
  /* color: #dca01e; */
  color: #f08300;
}
.xl_scroll {
  overflow-x: scroll;
}
.xl_scroll::-webkit-scrollbar {
  display: none;
}

@import url("swiper/swiper-bundle.css");
.swiper-container {
  width: 590px;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: 16px;
}

.top {
  /* width: 265px;
  height: 108px; */
  width: 225px;
  height: 107px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  padding: 12px 18px;
  margin-bottom: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
}

.hao {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  text-align: left;
  margin-bottom: 5px;
  line-height: 12px;
}

.button {
  margin-top: 10px;
}

.btn1 {
  width: 100px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  background: #f08300;
}

.btn2 {
  width: 58px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid #203398;
  font-size: 16px;
  font-weight: 500;
  color: #203398;
  line-height: 28px;
  text-align: center;
}
.bottom {
  width: 225px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  padding: 15px 18px;
  text-align: left;
}

.four {
  /* width: 50%; */
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  width: calc(100% / 2);
  min-width: calc(100% / 2);
  max-width: calc(100% / 2);
  margin-top: 5px;
  transform: scale(1);
}

.four:hover {
  transform: scale(1.2);
  color: #f08300;
}

.goods_item {
  flex: 1;
  margin: 10px 5px 0 5px;

  padding-bottom: 10px;
}

.four img {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 auto 4px;
}

.four div {
  margin-bottom: 6px;
  text-align: center;
}

.bottomImg {
  width: 100%;
  height: 125px;
  margin-top: 10px;
}

.notice {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;
  margin-top: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.noticeImg {
  width: 20px;
  height: 20px;
}

.notice1 {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  padding-left: 6px;
  padding-right: 30px;
}
.swiper-container1 {
  width: auto;
  height: 40px;
}
.swiper-content {
  height: 40px;
  line-height: 40px;
}
.listTitle {
  margin: 40px 0 30px;
  font-size: 28px;
  font-weight: 550;
  color: #333333;
  text-align: left;
  align-items: center;
}
.xian {
  width: 4px;
  height: 30px;
  background: #f08300;
  border-radius: 2px;
  margin-right: 10px;
}
.swiper-container2 {
  width: 1200px;
  height: 248px;
}
.swiper-container2 .swiper-slide {
  position: relative;
  height: 248px;
  border-radius: 4px;
}
.swiper-container2 .swiper-slide img {
  border-radius: 4px;
}

.swiper-container5 {
  width: 225px;
  height: 125px;
  border-radius: 4px;
}
.swiper-container5 .swiper-slide {
  position: relative;
  height: 125px;
}
.lyu {
  height: 248px;
  object-fit: cover;
  transition: transform 1s;
}
.swiper-button-prev:after {
  display: none;
}
.swiper-button-next:after {
  display: none;
}
.swiper-button-prev {
  width: 30px;
  height: 60px;
  background: url("../assets/main1_7.png") no-repeat center center;
  background-size: 30px 60px;
  left: 0;
  top: 53px;
}
.swiper-button-next {
  width: 30px;
  height: 60px;
  background: url("../assets/main1_6.png") no-repeat center center;
  background-size: 30px 60px;
  right: 0;
  top: 53px;
}

.swiper-button-next.next1 {
  top: 118px;
}
.swiper-button-prev.prev1 {
  top: 118px;
}
.half {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 71px;
  /* background: linear-gradient(180deg, #efc845 0%, #dca01e 100%); */
  background: #f08300;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.half .lyu1 {
  display: block;
  width: 40px;
  height: 40px;
  margin: -20px auto 0;
}
.all {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 248px;
  padding: 38px 20px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
.all .lyu2 {
  width: 40px;
  height: 40px;
  margin: auto;
  display: block;
}
.all_title {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin: 12px 0;
  text-align: center;
}
.all_info {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.swiper-slide1 {
  overflow: hidden;

  /* transition: transform 0.5s, box-shadow 0.5s; */
}
.swiper-slide1 .half {
  display: block;
}
.swiper-slide1 .all {
  display: none;
}
.swiper-slide1:hover .half {
  display: none;
}
.swiper-slide1:hover .all {
  display: block;
}
.swiper-slide1:hover .lyu {
  transform: scale(1.1);
  /* transition-duration: 1s;
  transition-timing-function:ease-out; */
}
.recommend {
  padding: 40px 0 20px;
  font-size: 28px;
  font-weight: 550;
  color: #333333;
  align-items: center;
}
.recommend_title {
  width: 270px;
}
.recommend img {
  width: 40px;
  height: 40px;
  margin-right: 11px;
}
.xian1 {
  width: 240px;
  height: 2px;
  /* background: #efc845; */
  background: #f08300;
  border-radius: 5px;
  /* margin: 0 20px; */
}
.box {
  position: relative;
  width: 1200px;
  padding: 40px 10px 20px 20px;
}
.topImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1200px;
  height: 206px;
  border-radius: 4px;
}
.one {
  position: relative;
  display: inline-block;
  width: calc((100% - 100px) / 5);
  height: 344px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-right: 15px;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: transform 0.5s, box-shadow 0.5s;
}
.one:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.oneImg {
  display: block;
  width: 140px;
  height: 140px;
  margin: 0 auto 10px;
}
.oneTitle {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}

.oneBottom {
  position: absolute;
  width: 100%;
  padding: 0 20px;
  bottom: 20px;
  left: 0;
  box-sizing: border-box;
}
.priceAll {
  align-items: end;
  font-size: 12px;
  font-weight: 400;
  color: #777777;
}
.price {
  font-size: 20px;
  font-weight: 500;
  color: #c93a42;
}
.price1 {
  font-size: 12px;
  font-weight: 400;
}
.btn {
  width: 100%;
  height: 30px;
  background: #f08300;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 10px;
}

.more {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 126px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 35px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  text-align: center;
}
.one1 {
  padding: 0;
  /* box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
border-radius: 4px; */
}
.seriesTitle {
  width: 1200px;
  height: 98px;
  background: #ffffff;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 30px;
  box-sizing: border-box;
}
.xian2 {
  width: 4px;
  height: 30px;
  background: #f08300;
  border-radius: 2px;
  margin-right: 10px;
}
.seriesTitle1 {
  font-size: 28px;
  font-weight: 550;
  color: #333333;
  align-items: center;
  margin-bottom: 10px;
}
.arrow {
  height: 35px;
  background: #f08300;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  padding: 0 18px;
  align-items: center;
}
.arrow img {
  width: 18px;
  height: 18px;
  margin-left: 6px;
}
.secondAll {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  height: 52px;
  padding: 10px 0;
}
.second {
  width: max-content;
  padding: 0 10px;
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  align-items: center;
}
.second div {
  display: inline-block;
}
.second > div:last-child .xian3 {
  display: none;
}
.second_active {
  color: #dca01e;
}
.xian3 {
  width: 1px;
  height: 20px;
  border-left: 1px solid #aeaeae;
  margin: 0 14px;
  position: relative;
  top: 6px;
}
/* .two {
  position: relative;
  display: inline-block;
  width: calc((100% - 40px) / 5);
  height: 208px;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin-right: 10px;
  padding: 6px 8px;
  margin-bottom: 10px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: transform 0.5s, box-shadow 0.5s;
} */
.two {
  position: relative;
  display: inline-block;
  width: calc(960px / 4);
  height: 325px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 20px;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: transform 0.5s, box-shadow 0.5s;
}

.two:hover {
  border: none;
  transform: scale(1.02);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.twoImg {
  display: block;
  width: 190px;
  height: 190px;
  margin: auto;
}

.twoTitle {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  height: 60px;
  margin: 4px 0 12px;
}
.price2 {
  font-size: 20px;
  color: #c93a42;
  /* margin-top: 28px; */
  line-height: 28px;
}
.price3 {
  font-size: 12px;
  font-weight: 400;
  color: #c93a42;
}
.banner {
  height: 83px;
  width: 1200px;
  margin: 10px 0 0;
}
.listTitle1 {
  margin: 30px 0 20px;
  font-size: 28px;
  font-weight: 550;
  color: #333333;
  text-align: left;
  align-items: center;
}
.fourAll {
  padding: 20px 20px 0;
}
.fourrr {
  position: relative;
  width: 140px;
  height: 140px;
  background: #ffffff;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  padding: 16px 0;
  box-sizing: border-box;
  text-align: center;
}
.fourrr:hover {
  /* color: #efc845; */
  color: #f08300;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.fourCard {
  text-align: left;
  display: none;
  position: absolute;
  right: -50%;
  bottom: 140px;
  width: 207px;
  height: auto;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  padding: 33px 35px 45px;
  background: url("@/assets/bg.png") no-repeat center center;
  background-size: 100% 100%;
}
.fourrr:hover .fourCard {
  display: block;
}
.fourrrImg {
  width: 66px;
  height: 66px;
  margin: 0 auto 10px;
}
.left {
  width: 623px;
}
.left_top1 {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 16px;
}
.left_img1 {
  width: 365px;
  height: 247px;
  border-radius: 4px;
  margin: 0 26px 20px 0;
}
.dian {
  min-width: 12px;
  width: 12px;
  height: 12px;
  background: #f08300;
  margin: 0 8px 0 10px;
  border-radius: 50%;
}
.left_title1 {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
}
.left_info1 {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
}
.left_img2 {
  width: 133px;
  height: 72px;
  border-radius: 4px;
}
.right {
  /* padding: 12px 24px 2px 12px; */
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #333333;
}
.rightOne {
  align-items: center;
  margin-bottom: 16px;
}
.dian1 {
  width: 12px;
  height: 12px;
  background: linear-gradient(90deg, #e5e5e5 0%, #cacaca 100%);
  border-radius: 50%;
}
.xian4 {
  width: 2px;
  height: 22px;
  border-left: 1px solid #aeaeae;
  margin: 0 10px;
}
.swiper-container3 {
  height: 122px;
  width: 1200px;
  position: relative;
  /* margin-bottom: 40px; */
}
.parther {
  width: 128px;
  height: 104px;
}
.fixleft {
  position: fixed;
  top: 240px;
  left: 5px;
  width: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
.fixleft a {
  color: #333;
}
.fixleft div {
  padding: 12px 10px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #d3d3d3;
}

.fixedHover:hover,
.label:hover {
  /* background: #efc845; */
  background: #f08300;
}
.fixedHover:hover a,
.label:hover a {
  color: #fff;
}
.second1:hover {
  /* color: #dca01e; */
  color: #f08300;
}
</style>
<style>
.swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 7px;
  display: inline-block;
  margin-right: 16px;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 47px;
  height: 13px;
  /* background: rgba(239, 200, 69, 0.8); */
  background: #f08300;
  display: inline-block;
  border-radius: 7px;
}

::v-deep .swiper-pagination.swiper111 {
  width: 100%;
  height: 45px;
  position: absolute;
  bottom: 0;
  background-color: rgba(238, 198, 68, 0.8);
  padding-top: 15px;
}

.more_meng {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
.one1:hover .more_meng {
  display: block;
}
.one1:hover .btn {
  display: none;
}

.bg_fff {
  background: #fff;
}
</style>

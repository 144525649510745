var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header',{attrs:{"num111":1}}),_c('el-image',{staticStyle:{"width":"100%","height":"396px","margin":"0 0 50px"},attrs:{"src":_vm.allBg},on:{"click":_vm.openTui}}),_c('div',{staticClass:"middle1"},[_c('div',{staticStyle:{"margin-bottom":"19px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.firstCate.length > 0),expression:"firstCate.length > 0"}],ref:"oneCate",staticClass:"s_cate align-between-top",class:{ heightauto: !_vm.arrow1, height40: _vm.arrow1 },attrs:{"id":"firstCate"}},[_c('div',{staticClass:"flex-wrap"},[_c('div',{staticClass:"s_cate_title align-end"},[_vm._v("一级分类")]),_c('div',{staticClass:"align-start cate_out flex-con"},[_c('div',{staticClass:"font-333-14 pointer fl",class:{ cor_EC7902: !_vm.first_cate_id },on:{"click":function($event){return _vm.fnFirstCate('', '')}}},[_vm._v(" 全部 ")]),_vm._l((_vm.firstCate),function(item,index){return _c('div',{key:'firstCate_' + index,staticClass:"font-333-14 pointer fl",class:{
                cor_EC7902: _vm.first_cate_id == item.gc_id,
              },on:{"click":function($event){return _vm.fnFirstCate(item.gc_id, item.gc_url_one)}}},[_vm._v(" "+_vm._s(item.gc_name || '')+" ")])})],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.firstCateH > 57),expression:"firstCateH > 57"}],staticClass:"align-center more pointer",on:{"click":function($event){return _vm.fnMore(0)}}},[_c('div',{staticClass:"font-666-14"},[_vm._v("更多")]),_c('el-image',{staticStyle:{"width":"11px","height":"7px","margin-left":"5px"},attrs:{"src":_vm.arrow1
                ? require('@/assets/bottom_arrow2.png')
                : require('@/assets/top_arrow2.png'),"fit":"cover"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.secondCate.length > 0),expression:"secondCate.length > 0"}],ref:"twoCate",staticClass:"s_cate align-between-top",class:{ heightauto: !_vm.arrow1, height40: _vm.arrow1 },attrs:{"id":"secondCate"}},[_c('div',{staticClass:"flex-wrap"},[_c('div',{staticClass:"s_cate_title align-end"},[_vm._v("二级分类")]),_c('div',{staticClass:"align-start cate_out flex-con"},[_c('div',{staticClass:"font-333-14 pointer fl",class:{ cor_EC7902: !_vm.secondCateId },on:{"click":function($event){return _vm.fnSecondCate('', '', '', '')}}},[_vm._v(" 全部 ")]),_vm._l((_vm.secondCate),function(item,index){return _c('div',{key:'secondCate_' + index,staticClass:"font-333-14 pointer fl",class:{
                cor_EC7902: _vm.secondCateId == item.gc_id,
              },on:{"click":function($event){return _vm.fnSecondCate(
                  item.gc_id,
                  item.gc_pid,
                  item.gc_url_one,
                  item.gc_url_two
                )}}},[_vm._v(" "+_vm._s(item.gc_name || '')+" ")])})],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.secondCateH > 57),expression:"secondCateH > 57"}],staticClass:"align-center more pointer",on:{"click":function($event){return _vm.fnMore(1)}}},[_c('div',{staticClass:"font-666-14"},[_vm._v("更多")]),_c('el-image',{staticStyle:{"width":"11px","height":"7px","margin-left":"5px"},attrs:{"src":_vm.arrow1
                ? require('@/assets/bottom_arrow2.png')
                : require('@/assets/top_arrow2.png'),"fit":"cover"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.thirdCate.length > 0),expression:"thirdCate.length > 0"}],ref:"threeCate",staticClass:"s_cate align-between-top",class:{ heightauto: !_vm.arrow2, height40: _vm.arrow2 },attrs:{"id":"thirdCate"}},[_c('div',{staticClass:"flex-wrap"},[_c('div',{staticClass:"s_cate_title align-end flex-con"},[_vm._v("三级分类")]),_c('div',{staticClass:"align-start cate_out"},[_c('div',{staticClass:"font-333-14 pointer fl",class:{ cor_EC7902: !_vm.thirdCateId },on:{"click":function($event){return _vm.fnThirdCate('', '', '', '', '')}}},[_vm._v(" 全部 ")]),_vm._l((_vm.thirdCate),function(item,index){return _c('div',{key:'secondCate_' + index,staticClass:"font-333-14 pointer fl",class:{ cor_EC7902: _vm.thirdCateId == item.gc_id },on:{"click":function($event){return _vm.fnThirdCate(
                  item.gc_id,
                  item.gc_pid,
                  item.gc_url_one,
                  item.gc_url_two,
                  item.gc_url_three
                )}}},[_vm._v(" "+_vm._s(item.gc_name || '')+" ")])})],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.thirdCateH > 57),expression:"thirdCateH > 57"}],staticClass:"align-center more pointer",on:{"click":function($event){return _vm.fnMore(2)}}},[_c('div',{staticClass:"font-666-14"},[_vm._v("更多")]),_c('el-image',{staticStyle:{"width":"11px","height":"7px","margin-left":"5px"},attrs:{"src":_vm.arrow1
                ? require('@/assets/bottom_arrow2.png')
                : require('@/assets/top_arrow2.png'),"fit":"cover"}})],1)])]),_c('div',[(_vm.data.length > 0)?_c('div',_vm._l((_vm.data),function(item,index){return _c('div',{key:'goods_' + index,staticClass:"one pointer",on:{"click":function($event){return _vm.fnGoodsDetails(item.goods_id)}}},[_c('el-image',{staticClass:"oneImg",attrs:{"src":item.goods_thumb,"fit":"cover"}}),_c('div',{staticStyle:{"padding":"10px 20px 0"}},[_c('div',{staticClass:"twoTitle aui-ellipsis-2"},[_vm._v(" "+_vm._s(item.goods_name)+" ")]),_c('div',{staticClass:"price2"},[_vm._v(" ¥"+_vm._s(item.goods_floor_price > 0 ? item.goods_floor_price : '面议')),_c('span',{staticClass:"font-333-bold",staticStyle:{"position":"relative","bottom":"1px"}},[_vm._v(_vm._s(item.goods_floor_price > 0 ? item.danwei : ''))]),_c('span',{staticClass:"hua-xian-jia"},[_vm._v(" "+_vm._s(item.goods_old_price > 0 ? ('¥'+item.goods_old_price) : '')+" ")])])]),_vm._m(0,true)],1)}),0):_c('div',[_c('div',{staticStyle:{"text-align":"center","margin":"225px auto"}},[_c('el-image',{staticStyle:{"width":"160px","height":"160px","margin-bottom":"16px"},attrs:{"src":require('@/assets/quesheng/no_content.png'),"fit":"cover"}}),_c('div',{staticClass:"font-666-14"},[_vm._v("商品完善中~")])],1)]),_c('div',{staticClass:"align-center"},[_c('Page',{attrs:{"limit":_vm.limit,"totalNum":_vm.total},on:{"onLimit":_vm.changeLimit,"onPage":_vm.changePage}})],1)])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"align-center",staticStyle:{"padding":"20px 15px 17px"}},[_c('div',{staticClass:"buy1 align-center"},[_c('div',{staticClass:"shan"}),_c('div',[_vm._v("立即购买")])])])
}]

export { render, staticRenderFns }
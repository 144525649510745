<template>
  <div id="main">
    <div ref="top111">
      <Header :num111="0" />
      <div style="background: #eff1f4; padding: 14px 0 40px">
        <div style="width: 1300px; margin: auto">
          <div class="flex-wrap">
            <div style="width: 213px">
              <div class="tab1 align-center">
                <img style="width: 22px; height: 16px; margin-right: 10px" :src="require('@/assets/cpfl1.png')" fit="cover" alt="" />
                <span class="font-fff-20-bold">产品分类</span>
                <div class="tab1_menu_out" @mouseenter="mouseenterOut()" @mouseleave="mouseleaveOut()">
                  <div class="tab1_menu">
                    <div class="menu_item pointer" @mouseenter="mouseenterFL(index, item)" v-for="(item, index) in liGoodsCate" :key="'first_' + index" @click="fnSearch(1, item.gc_url_one)">
                      <div class="align-start" style="height: 34px">
                        <span style="font-size: 18px">{{
                          item.gc_name || ''
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- v-show="showHover" -->
                  <div class="item_hover" v-show="showHover">
                    <div style="padding: 0 52px">
                      <div class="item_hover_top flex-wrap2">
                        <div class="left1" v-for="(item, index) in dataTwo.goodsCate" :key="'second_all_' + index" @click="fnSearch(2, item.gc_url_one, item.gc_url_two)" @mouseenter="mouseenterImg(item)">
                          <div class="xian222"></div>
                          <div class="font-333-18-bold pointer aui-ellipsis-1" style="
                              line-height: 30px;
                              height: 32px;
                              box-sizing: border-box;
                            " :style="
                              (index + 1) % 3 == 2
                                ? 'text-align:center;'
                                : (index + 1) % 3 == 1
                                ? 'padding-left: 33px;'
                                : 'text-align:right;padding-right: 33px'
                            ">
                            {{ item.gc_name || '' }}
                          </div>
                          <div class="font_small1" :style="
                              (index + 1) % 3 == 2
                                ? 'text-align:center;'
                                : (index + 1) % 3 == 1
                                ? 'padding-left: 33px;'
                                : 'text-align:right;padding-right: 33px'
                            ">
                            <div v-for="(item, index) in item.thirdCate" :key="'third_' + index" class="pointer" @click.stop="
                                fnSearch(
                                  3,
                                  item.gc_url_one,
                                  item.gc_url_two,
                                  item.gc_url_three
                                )
                              ">
                              {{ item.gc_name || '' }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="" style="
                        width: 100%;
                        height: 145px;
                        position: relation;
                        text-align: center;
                      ">
                      <el-image v-if="twoImg" style="
                          position: absolute;
                          width: 100%;
                          height: 145px;
                          display: block;
                        " :src="twoImg" fit="cover"></el-image>
                      <div v-else style="
                          width: 100%;
                          height: 145px;
                          background-color: #eff4fd;
                        " class="align-center">
                        <div class="font-999-14">暂无内容</div>
                      </div>
                      <!-- <div style="position: absolute; width: 100%">
                        <div
                          class="font-ed9d3b-37-bold"
                          style="letter-spacing: 3px"
                        >
                          本牛产品硅酸铝
                        </div>
                        <div class="font-55595C-24-bold">
                          ——
                          <span style="letter-spacing: 1px">次日发货</span> ——
                        </div>
                        <div
                          class="look_btn pointer font-333-16-22 align-center"
                        >
                          查看详情
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin: 0 15px; width: 820px">
              <div class="swiper-container" style="width: 820px; border-radius: 0" id="swiper-container1">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in data.bigBanner" :key="'bigBanner_' + index" @click="fnLb(item.banner_id,item.banner_href,item.banner_content)" :class="{ pointer: item.banner_href }">
                    <img :src="item.banner_image" />
                  </div>
                </div>
                <div class="swiper-pagination swiper111" style=""></div>
              </div>
            </div>
            <div>
              <div class="top">
                <div class="flex-wrap" style="align-items: center">
                  <el-image :src="
                      allUser
                        ? require('@/assets/hello.png')
                        : require('@/assets/tou.png')
                    " fit="cover" class="avatar"></el-image>
                  <div>
                    <div class="hao aui-ellipsis-1" v-show="allUser">
                      您好，{{ userName }}
                    </div>
                    <div>欢迎来到本牛</div>
                  </div>
                </div>
                <div class="button align-center" v-if="!allUser">
                  <div class="btn1 pointer" @click="toLogin()">注册/登录</div>
                  <!-- <div class="flex-con"></div> -->
                  <!-- <div class="btn2 pointer" @click="toLogin()">登录</div> -->
                </div>
                <div class="align-between" v-else>
                  <div style="text-align: center" @click="fnOrder(1)" class="pointer">
                    <div class="font-DE7E2F-20-bold" style="margin: 15px auto 9px">
                      {{ pay.unpay || 0 }}
                    </div>
                    <div class="font-333-16-22">待付款</div>
                  </div>
                  <div style="text-align: center" @click="fnOrder(2)" class="pointer">
                    <div class="font-DE7E2F-20-bold" style="margin: 15px auto 9px">
                      {{ pay.unsend || 0 }}
                    </div>
                    <div class="font-333-16-22">待发货</div>
                  </div>
                  <div style="text-align: center" @click="fnOrder(3)" class="pointer">
                    <div class="font-DE7E2F-20-bold" style="margin: 15px auto 9px">
                      {{ pay.send || 0 }}
                    </div>
                    <div class="font-333-16-22">待收货</div>
                  </div>
                </div>
              </div>
              <div class="bottom">
                <div class="title">平台优势</div>
                <div class="flex-wrap1" style="padding: 0 18px">
                  <div class="four" @mouseenter="mouseenter4(index, item)" @mouseleave="mouseleave4(index, item)" v-for="(item, index) in data.allAdvantage" :key="'fourAdvantage_' + index">
                    <img :src="item.advantage_icon" />
                    <!-- isHover4 == index
                          ? item.advantage_icon1
                          : item.advantage_icon -->
                    <div>{{ item.advantage_name || '' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="align-between m_t14 img_tab">
            <img :src="require('@/assets/static/yyly.png')" alt="" class="pointer" @click="fnTab2(3)" />
            <img :src="require('@/assets/static/ppdz.png')" alt="" class="pointer" @click="fnTab2(0)" />
            <img :src="require('@/assets/static/nrzx.png')" alt="" class="pointer" @click="fnTab2(1)" />
            <img :src="require('@/assets/static/whqc.png')" alt="" class="pointer" @click="fnTab2(2)" />
          </div>

          <div class="notice flex-wrap align-start">
            <!-- <img src="../assets/main1_55.png" class="noticeImg" /> -->
            <div class="notice1">公告</div>
            <div class="flex-con">
              <div class="swiper-container swiper-container1" id="swiper-container2">
                <div class="swiper-wrapper">
                  <div class="swiper-slide pointer" v-for="(item, index) in data.notice" :key="'notice_' + index" @click="fnNotice(index)">
                    <div class="swiper-content aui-ellipsis-1">
                      <div style="display: inline-block">
                        <div style="display: inline-block" :class="{ animation1: data.notice.length == 1 }">
                          {{ item.notice_title || '' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="">
            <div class="font-333-30 m_t53" style="margin-bottom: 6px" id="fixed_0">
              精选品类
            </div>
            <div class="flex-wrap1">
              <div v-for="(item, index1) in data.goodsCate" :key="'goodsCate_' + index1" :id="'xl_' + index1" style="margin-top: 16px; width: calc(50% - 6px)" :style="(index1 + 1) % 2 == 1 ? 'margin-right:12px;' : ''">
                <div>
                  <div class="flex-wrap">
                    <div class="xl_left flex-wrap flex-vertical">
                      <div class="flex-con xl_scroll">
                        <div class="xl_title aui-ellipsis-1">
                          {{ item.gc_name }}
                        </div>
                        <div class="xl_item_title pointer aui-ellipsis-1" v-for="(item, index) in item.secondCate" :key="'secondCate_' + index" :class="{ active: numAll[index1] == index }" @click="fnSecond(index1, index)">
                          {{ item.gc_name || '' }}
                        </div>
                      </div>
                    </div>
                    <div class="flex-con">
                      <div v-if="item.secondCate[numAll[index1]].goods.length > 0" class="four_two">
                        <div class="two pointer" v-for="(item, index) in item.secondCate[
                            numAll[index1]
                          ].goods" :key="'goods_' + item.gc_id + '_' + index" @click="fnGoodsDetails(item.goods_id)" v-if="index < 4">
                          <el-image fit="cover" :src="item.goods_thumb" class="twoImg"></el-image>
                          <div style="padding: 0 12px">
                            <div class="twoTitle aui-ellipsis-2">
                              {{ item.goods_name || '' }}
                            </div>
                            <div class="price2">
                              ¥{{
                                item.goods_floor_price > 0
                                  ? item.goods_floor_price
                                  : '面议'
                              }}<span class="font-333-bold" style="position: relative; bottom: 1px">{{
                                  item.goods_floor_price > 0 ? item.gu_name : ''
                                }}</span>
                              <span class="hua-xian-jia">
                                {{
                                  item.goods_old_price > 0 ? ('¥'+item.goods_old_price) : ''
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div style="text-align: center; margin: 195px auto 0">
                          <el-image style="
                              width: 160px;
                              height: 160px;
                              margin-bottom: 16px;
                            " :src="require('@/assets/quesheng/no_content.png')" fit="cover"></el-image>
                          <div class="font-666-14">商品完善中~</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-image fit="cover" style="margin: 30px 0 0; width: 100%; height: auto" :src="jxBg" @click="fnJx"></el-image>
            <!-- require('@/assets/static/ad3.png') -->
            <!-- <img style="margin: 30px 0 0; width: 100%;height:auto;"
              :src="data.advert[0].advert_thumb" alt=""> -->

            <div class="font-333-30" style="margin: 31px 0 29px" id="fixed_1">
              为你推荐
            </div>

            <div class="align-center tui0">
              <div class="tui1_out align-center">
                <div class="tui1 font-333-16-bold align-center pointer hvr-fade" :class="{ active: indexTj == 0 }" @click="fnTjTab(0)">
                  推荐商品
                </div>
              </div>
              <div class="align-center" v-for="(item, index) in data.fourCate" :key="'fourCate_' + index">
                <div style="width: 1px; height: 32px; background: #e5e5e5"></div>
                <div class="tui1_out align-center">
                  <div class="tui1 font-333-16-bold align-center pointer hvr-fade" :class="{ active: indexTj == index + 1 }" @click="fnTjTab(index + 1)">
                    {{ item.gc_name || '' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="one pointer" v-for="(item, index) in indexTj > 0
                  ? data.fourCate[indexTj - 1].goods
                  : data.niceGoods" :key="'niceGoods_' + index" @click="fnGoodsDetails(item.goods_id)">
                <el-image :src="item.goods_thumb" fit="cover" class="oneImg"></el-image>
                <div style="padding: 10px 20px 0">
                  <div class="twoTitle aui-ellipsis-2">
                    {{ item.goods_name }}
                  </div>
                  <div class="price2">
                    ¥{{
                      item.goods_floor_price > 0
                        ? item.goods_floor_price
                        : '面议'
                    }}<span class="font-333-bold" style="position: relative; bottom: 1px">{{
                        item.goods_floor_price > 0 ? item.gu_name : ''
                      }}</span>
                    <span class="hua-xian-jia">
                      {{
                                  item.goods_old_price > 0 ? ('¥'+item.goods_old_price) : ''
                                }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <el-image fit="cover" style="margin: 8px 0 0; width: 100%" :src="tuiBg" @click="openTui"></el-image>
            <!-- height: 180px -->
          </div>

          <div class="swiper-container swiper-container2" id="swiper-container3">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slide1 pointer" v-for="(item, index) in appList" :key="'appList_' + index" @click="fnApp(item.app_id)">
                <img :src="item.app_thumb" class="lyu" />
                <div class="half">
                  <div class="all_title font-fff-18 align-start">
                    {{ item.app_title }}
                  </div>
                </div>
                <div class="all">
                  <div class="align-center" style="margin: auto">
                    <div>
                      <div class="font-fff-24 all_title1" style="text-align: center; margin-bottom: 30px">
                        {{ item.app_title }}
                      </div>
                      <div class="all_info aui-ellipsis-3">
                        {{ item.app_intro }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="align-end" style="
                width: 100%;
                position: absolute;
                top: 0;
                right: 0;
                height: 48px;
              ">
              <div class="font-333-30" style="position: absolute; left: 0" id="fixed_2">
                应用领域
              </div>
              <div class="swiper-pagination swiper-pagination1 align-center"></div>
              <div class="swiper-button-next pointer next1"></div>
            </div>
            <div class="swiper-button-prev pointer prev1" :style="
                'right:' + (Math.ceil(appList.length / 4) * 20 + 75) + 'px'
              "></div>
          </div>

          <div class="font-333-30" style="margin: 70px 0 47px" id="fixed_3">
            行业资讯
          </div>
          <div class="align-between">
            <div class="pointer hy_item" v-if="index < 3" v-for="(item, index) in data.info" :key="'info1_' + index" @click="toZx(item.info_id)" @mouseenter="mouseenterOutZx(index)" @mouseleave="mouseleaveOutZx()">
              <div class="" style="overflow: hidden">
                <el-image :src="item.info_thumb" fit="cover" class="left_img1"></el-image>
              </div>

              <div style="padding: 0 26px">
                <div class="font-333-26-bold info1_time" style="text-align: right; margin-top: 12px">
                  {{ timeMD(item.info_time) }}
                </div>
                <div class="align-start">
                  <div class="left_title1 aui-ellipsis-2">
                    {{ item.info_title || '' }}
                  </div>
                </div>
                <div class="left_info1 aui-ellipsis-2">
                  {{ item.info_intro || '' }}
                </div>
                <el-image :src="
                    showHoverZx && indexZx == index
                      ? require('@/assets/arrow_right2.png')
                      : require('@/assets/arrow_right1.png')
                  " fit="cover" class="left_jt1"></el-image>
              </div>
            </div>
          </div>
          <div class="align-end">
            <div class="zx_more pointer" @click="fnMoreZx">
              了解更多
              <div></div>
            </div>
          </div>

          <div class="font-333-30" style="margin: 90px 0 40px" id="fixed_4">
            合作伙伴
          </div>
          <div class="swiper-container swiper-container3" id="swiper-container4">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in partner" :key="'partner_' + index">
                <!-- @mouseenter="mouseenterOutHb(index)"
                @mouseleave="mouseleaveOutHb()" -->
                <img :src="item.partner_icon1" class="parther" />
              </div>
            </div>
            <!-- <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="fixleft pointer" v-if="showFixed">
      <div class="label align-center" v-for="(item, index) in fixedItem" :key="'fixed_' + index" @click="toHere(index)">
        {{ item.title || '' }}
      </div>
    </div>

    <Footer />
    <!-- <right /> -->
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import Swiper from 'swiper/bundle'
import { indexData, loginAgreement, tdkPages, goodsCate } from '../api'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  data() {
    return {
      data: {},
      appList: [],
      isHover: -1,
      isHover4: -1,
      numAll: [],
      tuiBg: '',
      jxBg: '',
      info: [],
      allUser: localStorage.getItem('allUser')
        ? JSON.parse(localStorage.getItem('allUser')).user_id
        : '',
      scroll: '',
      showFixed: false,
      userName: localStorage.getItem('allUser')
        ? JSON.parse(localStorage.getItem('allUser')).user_username
          ? JSON.parse(localStorage.getItem('allUser')).user_username
          : JSON.parse(localStorage.getItem('allUser')).user_phone
        : '',
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
      liGoodsCate: '',
      showHover: false,
      showHoverZx: false,
      indexZx: '',
      // showHoverHb: false,
      // indexHb: '',
      first_cate_id: '',
      dataTwo: [],
      fixedItem: [
        { title: '精选品类' },
        { title: '为你推荐' },
        { title: '应用领域' },
        { title: '行业资讯' },
        { title: '合作伙伴' },
      ],
      indexTj: 0,
      pay: {},
      twoImg: '',
      tuiUrl: '',
      partner: [],
    }
  },
  methods: {
    fnMoreZx() {
      this.$router.push({
        name: 'news',
        params: {
          id: '.html',
          // index: index,
        },
      })
    },
    openTui() {
      if (this.tuiUrl) {
        window.open(this.tuiUrl)
      }
    },
    fnJx() {
      if (this.data.advert[0].advert_content) {
        window.open(this.data.advert[0].advert_content)
      }
    },
    fnOrder(index) {
      this.$router.push({
        name: 'OrderZx',
        params: {
          id: index + '.html',
        },
      })
    },
    fnTjTab(index) {
      this.indexTj = index
      // console.log(index);
    },
    fnTab2(index) {
      let tab2Url = ['Brand', 'zixun', 'Whqc', 'Selection']
      this.$router.push({
        name: tab2Url[index],
        params: {
          id: '.html',
        },
      })
    },
    mouseenterImg(item) {
      // console.log(item);
      this.twoImg = item.gc_images
    },

    mouseenterFL(index, item) {
      this.first_cate_id = item.gc_id
      this.initDataFirstId()
      // console.log(index + '||' + JSON.stringify(item))
    },
    initDataFirstId() {
      let params = {
        first_cate_id: this.first_cate_id,
      }
      goodsCate(params).then((data) => {
        if (data.status == 200) {
          this.dataTwo = data.msg
          // console.log(697+'||'+JSON.stringify(this.dataTwo.goodsCate[0].gc_images));
          this.twoImg = this.dataTwo.goodsCate[0].gc_images
        }
      })
    },
    mouseenterOut() {
      this.showHover = true
    },
    mouseleaveOut() {
      this.showHover = false
    },
    mouseenterOutZx(index) {
      this.showHoverZx = true
      this.indexZx = index
    },
    mouseleaveOutZx() {
      this.showHoverZx = false
    },
    // mouseenterOutHb(index) {
    //   this.showHoverHb = true
    //   this.indexHb = index
    // },
    // mouseleaveOutHb() {
    //   this.showHoverHb = false
    // },
    fnGetTdk(type) {
      let params = {
        type: type,
      }
      tdkPages(params).then((data) => {
        if (data.status == 200) {
          this.tdkTitle = data.msg.title || '本牛'
          this.tdkKeywords = data.msg.keyword || '本牛'
          this.tdkDescription = data.msg.desc || '本牛'
        }
      })
    },
    initData() {
      let params = {
        ossSign: this.$route.query.ossSign || '',
      }
      indexData(params).then((data) => {
        if (data.status == 200) {
          if (data.ossUser) {
            localStorage.setItem('allUser', JSON.stringify(data.ossUser))
            this.allUser = localStorage.getItem('allUser')
              ? JSON.parse(localStorage.getItem('allUser')).user_id
              : ''
            this.userName = localStorage.getItem('allUser')
              ? JSON.parse(localStorage.getItem('allUser')).user_username
                ? JSON.parse(localStorage.getItem('allUser')).user_username
                : JSON.parse(localStorage.getItem('allUser')).user_phone
              : ''
            setTimeout(() => {
              this.$bus.$emit('ossSignMain')
            }, 200)
          }
          this.data = data.msg
          this.info = data.msg.info
          this.appList = data.msg.app
          this.partner = data.msg.partner
          this.pay = data.msg.pay
          this.jxBg = this.data.advert[0].advert_thumb
          this.liGoodsCate = data.msg.allFirstCate
          // console.log(this.data.fourCate)
          localStorage.setItem(
            'goodsCate',
            JSON.stringify(data.msg.allFirstCate)
          )
          localStorage.setItem('hotSearch', JSON.stringify(data.msg.hotSearch))
          localStorage.setItem('shopCarNumber', data.msg.shopCarNumber)
          localStorage.setItem('bnPtys', JSON.stringify(data.msg.allAdvantage))
          this.numAll = []
          if (data.msg.goodsCate.length > 0) {
            data.msg.goodsCate.forEach((element) => {
              this.numAll.push(0)
            })
          }
          // data.msg.goodsCate.forEach((element) => {
          //   this.numAll.push(0)
          // })
        }
      })
    },
    fnMore(gc_id, gc_name) {
      this.$router.push({
        name: 'Search',
        params: {
          num: 3,
          type: 4,
          cateId0: gc_id, //一级分类id
          cateName0: gc_name,
          keyword: 'new',
          // index1: decodeURIComponent(''),
          // index2: decodeURIComponent(''),
          // cateId1: decodeURIComponent(''),
          // cateName1: decodeURIComponent(''),
          // cateId2: decodeURIComponent(''),
          // cateName2: decodeURIComponent(''),
        },
      })
    },
    fnLb(banner_id,banner_href,content) {
      if (banner_href) {
        window.open(banner_href)
      }else{
        if(content){
          // 首页banner图新窗口打开 端总要求
          this.$router.push({
            name: 'lunboXq',
            params: {
              id: banner_id + '.html',
            },
          })
        }else{
          return;
        }
      }
      return
      
    },
    fnSecond(index1, index) {
      this.$set(this.numAll, index1, index)
    },
    // fnAppList() {
    //   //应用领域
    //   let params = {}
    //   appList(params).then((data) => {
    //     if (data.status == 200) {
    //       this.appList = data.msg
    //     }
    //   })
    // },
    fnApp(idd) {
      this.$router.push({
        name: 'Hyyx',
        params: {
          id: idd + '.html',
        },
      })
    },
    fnLoginAgreement() {
      let params = {
        type: 8,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          this.tuiBg = data.msg.content1
          localStorage.setItem('tuiBg', this.tuiBg)
          this.tuiUrl = data.msg.content2 || ''
          // console.log('396||'+localStorage.getItem('tuiBg'));
        }
      })
    },
    mouseenter(index, item) {
      this.isHover = index
    },
    mouseleave(index, item) {
      this.isHover = -1
    },
    mouseenter4(index, item) {
      this.isHover4 = index
    },
    mouseleave4(index, item) {
      this.isHover4 = -1
    },
    fnNotice(index) {
      this.$router.push({
        name: 'notice',
        params: {
          id: this.data.notice[index].notice_id + '.html',
        },
      })
    },
    fnRecommend() {
      this.$router.push({
        name: 'Recommend',
        params: {
          id: '.html',
        },
      })
    },
    handleScroll() {
      // console.log(document.getElementById("main").offsetHeight)
      //或者使用document.querySelector('.class或者#id').scrollTop
      let top111 = this.$refs.top111.offsetHeight
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop
      if (
        this.scroll + document.body.clientHeight > top111 ||
        this.scroll < 72
      ) {
        this.showFixed = false
      } else {
        this.showFixed = true
      }
    },
    toHere(index) {
      document.getElementById('fixed_' + index).scrollIntoView({
        behavior: 'smooth', //顺滑的滚动
        block: 'center', //容器上下的中间
      })
    },

    fnSearch(type, one, two, three) {
      if (type == 1) {
        this.$router.push({
          path: `/${one}`,
        })
      }
      if (type == 2) {
        this.$router.push({
          path: `/${one}/${two}`,
        })
      }
      if (type == 3) {
        this.$router.push({
          path: `/${one}/${two}/${three}`,
        })
      }
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.fnGetTdk(1)
    window.addEventListener('scroll', this.handleScroll)
    localStorage.setItem('addressObj1', '')
    localStorage.setItem('addressObj2', '')
    localStorage.setItem('addressObj3', '')
    this.initData()
    this.fnLoginAgreement()
    new Swiper('#swiper-container1', {
      autoplay: true,
      observer: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
    new Swiper('#swiper-container5', {
      autoplay: true,
      observer: true,
    })
    new Swiper('#swiper-container2', {
      autoplay: true,
      observer: true,
      direction: 'vertical',
      watchOverflow: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
    new Swiper('#swiper-container3', {
      // breakpoints: {
      //   640: {
      //     slidesPerView: 2,
      //     spaceBetween: 50,
      //   },
      //   768: {
      //     slidesPerView: 3,
      //     spaceBetween: 40,
      //   },
      //   1024: {
      //     slidesPerView: 4,
      //     spaceBetween: 26,
      //   },
      // },
      slidesPerView: 4,
      spaceBetween: 18,
      slidesPerGroup: 4,
      pagination: {
        el: '.swiper-pagination1',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      observer: true,
    })
  },
  watch: {
    partner: {
      handler: function (newVal, oldVal) {
        this.$nextTick(() => {
          new Swiper('#swiper-container4', {
            loop: true,
            loopAdditionalSlides: 7,
            autoplay: {
              delay: 1000, // 轮播间隔时间
              stopOnLastSlide: false, // 循环播放
              disableOnInteraction: false, // 用户操作后轮播仍然继续
            },
            speed: 500,
            spaceBetween: 6,
            slidesPerView: 5,
            observer: true,
          })
        })
      },
    },
  },
}
</script>

<style scoped>
@keyframes notice {
  0% {
    left: 1194px;
  }
  100% {
    left: -100%;
  }
}
@-webkit-keyframes notice {
  0% {
    left: 1194px;
  }
  100% {
    left: -100%;
  }
}
@-moz-keyframes notice {
  0% {
    left: 1194px;
  }
  100% {
    left: -100%;
  }
}
@-o-keyframes notice {
  0% {
    left: 1194px;
  }
  100% {
    left: -100%;
  }
}
.animation1 {
  position: relative;
  animation: notice 10s linear infinite both;
  -webkit-animation: notice 10s linear infinite both;
  -moz-animation: notice 10s linear infinite both;
  -o-animation: notice 10s linear infinite both;
}
.animation1:hover {
  animation-play-state: paused;
}

.zx_more {
  text-align: right;
  margin: 20px 0 0;
  position: relative;
  font-size: 23px;
  font-weight: 400;
  font-style: italic;
  color: #999;
  line-height: 24px;
}
.zx_more:hover {
  color: #de7e2f;
}

.zx_more:hover div {
  width: 100%;
  position: absolute;
  bottom: -13px;
}
.zx_more div {
  width: 0px;
  height: 6px;
  position: absolute;
  background-color: #de7e2f;
  transition: all 0.3s;
  bottom: -13px;
  border-radius: 3px;
}
.xian222 {
  height: 2px;
  background-color: #ed9d3b;
  width: 0;
  transition: width 0.5s;
  position: absolute;
  top: 30px;
}
.left1:hover .xian222 {
  width: 100%;
}

.left_jt1 {
  width: 32px;
  height: 16px;
  position: relative;
  left: 0;
  transition: all 0.5s;
}
.hy_item {
  width: 420px;
  height: 405px;
  background-color: #fff;
  transition: all 0.5s;
}
.hy_item:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.hy_item:hover .left_img1 {
  scale: 1.2;
}

.info1_time {
  transition: all 0.5s;
}

.hy_item:hover .info1_time {
  color: #e28a4d;
}
.hy_item:hover .left_jt1 {
  position: relative;
  left: calc(100% - 32px);
}
.tui0 {
  width: 100%;
  height: 66px;
  background: #ffffff;
  margin-bottom: 24px;
}
.tui1_out {
  padding: 0 34px;
}
.tui1 {
  height: 30px;
  padding: 0 17px;
  border-radius: 15px;
  line-height: 30px;
}

.tui1:hover,
.tui1.active {
  color: #ffffff;
  background: #e28a4d;
}
.img_tab img {
  width: 314px;
  height: 160px;
}
.look_btn {
  width: 117px;
  height: 31px;
  background: #f7cf47;
  border-radius: 16px;
  margin: 9px auto 0;
  letter-spacing: 1px;
}
.font_small1 {
  height: 122px;
  overflow-y: scroll;
  width: 246px;
  box-sizing: border-box;
}
.font_small1 div {
  line-height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
.font_small1 div:hover {
  color: #ea8f4b;
}
.left1 {
  width: 246px;
  margin-top: 15px;
  position: relative;
}
.item_hover_top::-webkit-scrollbar {
  width: 6px;
  height: 100%;
}

.item_hover_top::-webkit-scrollbar-thumb {
  display: block;
  width: 6px;
  height: 50px;
  margin: 0 auto;
  border-radius: 10px;
  background: #999; /*内层轨道*/
}
.font_small1::-webkit-scrollbar {
  display: none;
}
.item_hover_top {
  overflow-y: scroll;
  height: 355px;
  padding-top: 15px;
  box-sizing: border-box;
}
.item_hover {
  height: 500px;
  width: 848px;
  background: #ffffff;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: -2px -2px 6px 0px rgba(182, 184, 188, 0.34),
    3px 4px 8px 0px rgba(182, 184, 188, 0.34);
  box-sizing: border-box;
  position: absolute;
  left: 200px;
  top: -40px;
  z-index: 99;
}
.menu_item {
  padding-left: 52px;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  margin: 1px 0;
}
.menu_item:hover {
  color: #fff;
  background: #e5974a;
}
.tab1_menu {
  position: absolute;
  top: 0px;
  width: 213px;
  height: 460px;
  background: #fff;
  box-sizing: border-box;
  padding: 2px 0 0;
  z-index: 99;
  /* overflow-y: scroll; */
  overflow-y: hidden;
}
.tab1_menu::-webkit-scrollbar {
  display: none;
}
.tab1_menu_out {
  position: absolute;
  top: 40px;
  width: 213px;
  height: 460px;
}
.tab1 {
  width: 213px;
  height: 40px;
  background: #e5974a;
  position: relative;
}
.xl_left {
  width: 188px;
  height: 570px;
  background: url("@/assets/static/jxpl.png") no-repeat center center;
  background-size: 188px 570px;
  padding: 33px 0 0 23px;
  box-sizing: border-box;
}

.xl_title {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
}

.xl_item_title {
  font-size: 16px;
  color: #333333;
  line-height: 24px;
  margin-top: 42px;
}

.xl_item_title.active,
.xl_item_title:hover {
  color: #de7e2f;
}

.xl_scroll {
  overflow-y: scroll;
}
.xl_scroll::-webkit-scrollbar {
  display: none;
}

@import url("swiper/swiper-bundle.css");
.swiper-container {
  width: 820px;
  height: 500px;
  overflow: hidden;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: 42px;
  text-align: right;
  padding-right: 60px;
  box-sizing: border-box;
}

.top {
  /* width: 265px;
  height: 108px; */
  width: 238px;
  height: 157px;
  background: #ffffff;
  /* border-radius: 0px 0px 4px 4px; */
  font-size: 16px;
  font-weight: 400;
  color: #000;
  padding: 25px 18px 0;
  margin-bottom: 16px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
}

.avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-right: 30px;
}

.hao {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  text-align: left;
  margin-bottom: 15px;
  /* line-height: 12px; */
}

.button {
  margin-top: 28px;
}

.btn1 {
  line-height: 31px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  width: 201px;
  height: 31px;
  background: #edb380;
  border-radius: 5px;
}

.btn2 {
  width: 58px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid #203398;
  font-size: 16px;
  font-weight: 500;
  color: #203398;
  line-height: 28px;
  text-align: center;
}
.bottom {
  width: 238px;
  height: 328px;
  background: #ffffff;
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  padding: 16px 18px 0;
  text-align: left;
  line-height: 18px;
}

.four {
  /* width: 50%; */
  font-size: 16px;
  font-weight: 400;
  color: #666;
  width: calc(100% / 2);
  min-width: calc(100% / 2);
  max-width: calc(100% / 2);
  transform: scale(1);
}

.four:hover {
  /* transform: scale(1.2);
  color: #f08300; */
}

.goods_item {
  flex: 1;
  margin: 10px 5px 0 5px;

  padding-bottom: 10px;
}

.four img {
  display: block;
  width: 26px;
  height: 26px;
  margin: 20px auto;
}

.four div {
  margin-bottom: 15px;
  text-align: center;
}

.bottomImg {
  width: 100%;
  height: 125px;
  margin-top: 10px;
}

.notice {
  width: 100%;
  height: 52px;
  /* border-radius: 4px; */
  box-sizing: border-box;
  margin-top: 13px;
  background: #dfe0e5;
  box-shadow: 0px 5px 6px 0px rgba(195, 197, 204, 0.07),
    0px 5px 8px 0px rgba(151, 151, 153, 0.07);
  background: linear-gradient(
    to bottom,
    rgba(195, 197, 204, 0.07) 0%,
    #dfe0e5 50%,
    rgba(195, 197, 204, 0.07) 100%
  );
  background: linear-gradient(
    to right,
    rgba(195, 197, 204, 0.07) 0%,
    #dfe0e5 50%,
    rgba(195, 197, 204, 0.07) 100%
  );
}

.noticeImg {
  width: 20px;
  height: 20px;
}

.notice1 {
  font-size: 24px;
  color: #e78b37;
  padding-left: 8px;
  padding-right: 40px;
  height: 38px;
  line-height: 38px;
  border-left: 10px solid #e9a065;
}
.swiper-container1 {
  width: auto;
  height: 52px;
}
.swiper-content {
  height: 52px;
  line-height: 52px;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
}
.listTitle {
  margin: 40px 0 30px;
  font-size: 28px;
  font-weight: 550;
  color: #333333;
  text-align: left;
  align-items: center;
}
.xian {
  width: 4px;
  height: 30px;
  background: #f08300;
  border-radius: 2px;
  margin-right: 10px;
}
.swiper-container2 {
  width: 1300px;
  height: 248px;
  padding-top: 68px;
  margin-top: 53px;
}
.swiper-container2 .swiper-slide {
  position: relative;
  height: 248px;
  /* border-radius: 4px; */
}
.swiper-container2 .swiper-slide img {
  /* border-radius: 4px; */
}

.swiper-container5 {
  width: 225px;
  height: 125px;
  border-radius: 4px;
}
.swiper-container5 .swiper-slide {
  position: relative;
  height: 125px;
}
.lyu {
  height: 248px;
  object-fit: cover;
  transition: transform 1s;
}
.swiper-button-prev:after {
  display: none;
}
.swiper-button-next:after {
  display: none;
}
.swiper-button-prev {
  width: 30px;
  height: 60px;
  background: url("../assets/main1_7.png") no-repeat center center;
  background-size: 30px 60px;
  left: 0;
  top: 53px;
}
.swiper-button-next {
  width: 30px;
  height: 60px;
  background: url("../assets/main1_6.png") no-repeat center center;
  background-size: 30px 60px;
  right: 0;
  top: 53px;
}

.half {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 58px;
  background: rgb(27, 27, 27, 0.6);
}

.all_title {
  padding-left: 18px;
  height: 58px;
}
.all_title1 {
  position: relative;
  top: -80px;
  transition: all 0.5s;
}
.all_info {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  width: 185px;
  position: relative;
  bottom: -100px;
  transition: all 0.5s;
  margin: auto;
}
.swiper-slide1 {
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  /* transition: transform 0.5s, box-shadow 0.5s; */
}
.all {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* padding: 0 50px; */
  box-sizing: border-box;
  background: rgba(27, 27, 27, 0.72);

  border: 3px solid #de7e2f;
  margin: 0 auto;
}
.swiper-slide1 .half {
  display: block;
}
.swiper-slide1 .all {
  display: flex;
  opacity: 0;
  transition: all 0.5s;
}
.swiper-slide1:hover .half {
  display: none;
}
.swiper-slide1:hover .all_title1 {
  top: 0;
}
.swiper-slide1:hover .all_info {
  bottom: 0;
}
.swiper-slide1:hover .all {
  /* display: flex; */
  opacity: 1;
  margin: 16px auto 0;
  width: 286px;
  height: 215px;
}
.swiper-slide1:hover .lyu {
  transform: scale(1.1);
  /* transition-duration: 1s;
  transition-timing-function:ease-out; */
}
.recommend {
  padding: 40px 0 20px;
  font-size: 28px;
  font-weight: 550;
  color: #333333;
  align-items: center;
}
.recommend_title {
  width: 270px;
}
.recommend img {
  width: 40px;
  height: 40px;
  margin-right: 11px;
}
.xian1 {
  width: 240px;
  height: 2px;
  /* background: #efc845; */
  background: #f08300;
  border-radius: 5px;
  /* margin: 0 20px; */
}
.box {
  /* position: relative; */
  /* width: 1300px; */
  /* padding: 40px 10px 20px 20px; */
}

.one {
  /* position: relative; */
  display: inline-block;
  width: calc((100% - 80px) / 5);
  height: 302px;
  background: #ffffff;
  border-radius: 5px;
  margin-right: 20px;
  /* padding: 20px; */
  margin-bottom: 20px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: box-shadow 0.5s;
}
.one:hover {
  /* transform: scale(1.02); */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.one:nth-child(5n) {
  margin-right: 0;
}

.oneImg {
  display: block;
  width: 178px;
  height: 178px;
  margin: 0 auto;
}
.oneTitle {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
}

.oneBottom {
  position: absolute;
  width: 100%;
  padding: 0 20px;
  bottom: 20px;
  left: 0;
  box-sizing: border-box;
}
.priceAll {
  align-items: end;
  font-size: 12px;
  font-weight: 400;
  color: #777777;
}
.price {
  font-size: 20px;
  font-weight: 500;
  color: #c93a42;
}
.price1 {
  font-size: 12px;
  font-weight: 400;
}
.btn {
  width: 100%;
  height: 30px;
  background: #f08300;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 10px;
}

.more {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 126px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 35px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  text-align: center;
}
.one1 {
  padding: 0;
  /* box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
border-radius: 4px; */
}
.seriesTitle {
  width: 1300px;
  height: 98px;
  background: #ffffff;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 30px;
  box-sizing: border-box;
}
.xian2 {
  width: 4px;
  height: 30px;
  background: #f08300;
  border-radius: 2px;
  margin-right: 10px;
}
.seriesTitle1 {
  font-size: 28px;
  font-weight: 550;
  color: #333333;
  align-items: center;
  margin-bottom: 10px;
}
.arrow {
  height: 35px;
  background: #f08300;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  padding: 0 18px;
  align-items: center;
}
.arrow img {
  width: 18px;
  height: 18px;
  margin-left: 6px;
}
.secondAll {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  height: 52px;
  padding: 10px 0;
}
.second {
  width: max-content;
  padding: 0 10px;
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  align-items: center;
}
.second div {
  display: inline-block;
}
.second > div:last-child .xian3 {
  display: none;
}
.second_active {
  color: #dca01e;
}
.xian3 {
  width: 1px;
  height: 20px;
  border-left: 1px solid #aeaeae;
  margin: 0 14px;
  position: relative;
  top: 6px;
}

.two {
  position: relative;
  display: inline-block;
  width: 228px;
  height: 285px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  /* padding: 10px; */
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: box-shadow 0.5s;
  /* transform 0.5s, */
}
.four_two .two:nth-child(2) {
  border-left: none;
}
.four_two .two:nth-child(3) {
  border-top: none;
}
.four_two .two:last-child {
  border-top: none;
  border-left: none;
}
.four_two .two:hover {
  border: none;
  /* transform: scale(1.02); */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;

  border: 1px solid #e5974a;
}

.twoImg {
  display: block;
  width: 183px;
  height: 183px;
  margin: auto;
}

.twoTitle {
  font-size: 16px;
  color: #333333;
  line-height: 20px;
  height: 41px;
  margin: 0 0 20px;
}
.price2 {
  font-size: 24px;
  font-weight: 500;
  color: #de7e2f;
  line-height: 24px;
}
.banner {
  height: 83px;
  width: 1300px;
  margin: 10px 0 0;
}
.listTitle1 {
  margin: 30px 0 20px;
  font-size: 28px;
  font-weight: 550;
  color: #333333;
  text-align: left;
  align-items: center;
}
.fourAll {
  padding: 20px 20px 0;
}
.fourrr {
  position: relative;
  width: 140px;
  height: 140px;
  background: #ffffff;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  padding: 16px 0;
  box-sizing: border-box;
  text-align: center;
}
.fourrr:hover {
  /* color: #efc845; */
  color: #f08300;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.fourCard {
  text-align: left;
  display: none;
  position: absolute;
  right: -50%;
  bottom: 140px;
  width: 207px;
  height: auto;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  padding: 33px 35px 45px;
  background: url("@/assets/bg.png") no-repeat center center;
  background-size: 100% 100%;
}
.fourrr:hover .fourCard {
  display: block;
}
.fourrrImg {
  width: 66px;
  height: 66px;
  margin: 0 auto 10px;
}
.left {
  width: 623px;
}
.left_top1 {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 16px;
}
.left_img1 {
  width: 100%;
  height: 204px;
  display: block;
  transition: all 1s;
}

.dian {
  min-width: 12px;
  width: 12px;
  height: 12px;
  background: #f08300;
  margin: 0 8px 0 10px;
  border-radius: 50%;
}
.left_title1 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 48px;
  line-height: 24px;
  margin: 12px 0 7px;
}
.left_info1 {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 24px;
  margin-bottom: 18px;
}
.left_img2 {
  width: 133px;
  height: 72px;
  border-radius: 4px;
}
.right {
  /* padding: 12px 24px 2px 12px; */
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #333333;
}
.rightOne {
  align-items: center;
  margin-bottom: 16px;
}
.dian1 {
  width: 12px;
  height: 12px;
  background: linear-gradient(90deg, #e5e5e5 0%, #cacaca 100%);
  border-radius: 50%;
}
.xian4 {
  width: 2px;
  height: 22px;
  border-left: 1px solid #aeaeae;
  margin: 0 10px;
}
.swiper-container3 {
  height: 110px;
  width: 1300px;
  position: relative;
  /* margin-bottom: 40px; */
}
.parther {
  width: 128px;
  height: 104px;
  filter: grayscale(1);
}
.parther:hover {
  filter: none;
}
.fixleft {
  position: fixed;
  /* top: 240px; */
  bottom: 10px;
  left: calc(((100% - 1300px) / 2 - 86px) / 2);
  width: 86px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  border-radius: 10px;
}

.fixleft div {
  height: 112px;
  padding: 12px 22px;
  font-size: 18px;
  font-weight: 400;
  color: #666;
  line-height: 34px;
  box-sizing: border-box;
  border-bottom: 2px solid #e5e5e5;
  transition: all 0.3s;
  letter-spacing: 3px;
}
.fixleft div:first-child {
  border-radius: 10px 10px 0 0;
}
.fixleft div:last-child {
  border-bottom: none;
  border-radius: 0 0 10px 10px;
}

.label:hover {
  /* background: #efc845; */
  background: #f08300;
  color: #fff;
}
.swiper-pagination1 {
  width: auto;
  height: 48px;
  position: absolute;
  top: 0;
  text-align: right;
  padding-right: 48px;
  box-sizing: border-box;
  /* background-color: #999999; */
}
.swiper-button-prev,
.swiper-button-next {
  margin-top: 0;
}
</style>
<style>
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination1 {
  width: auto;
}

.swiper-pagination1 .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #c9c9c9;
  border-radius: 50%;
  display: inline-block;

  opacity: 1;
}

.swiper-pagination1 .swiper-pagination-bullet-active {
  width: 11px;
  height: 11px;
  border: 2px solid #de7e2f;
  box-sizing: border-box;
  border-radius: 50%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0);
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background: #ffffff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  /* background: rgba(239, 200, 69, 0.8); */
  background: #ea8f4b;
  display: inline-block;
  border-radius: 50%;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin-right: 0;
  margin-left: 15px;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination1
  .swiper-pagination-bullet {
  margin-right: 0;
  margin-left: 12px;
}

::v-deep .swiper-pagination.swiper111 {
  width: 100%;
  height: 45px;
  position: absolute;
  bottom: 0;
  background-color: rgba(238, 198, 68, 0.8);
  padding-top: 15px;
}

.more_meng {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
.one1:hover .more_meng {
  display: block;
}
.one1:hover .btn {
  display: none;
}

.bg_fff {
  background: #fff;
}
.swiper-button-next.next1 {
  width: 48px;
  height: 48px;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
  background: url("@/assets/arrow6.png") no-repeat center center;
  background-size: 9px 17px;
  right: 0;
  top: 0;
  box-sizing: border-box;
}
.swiper-button-next.next1:hover {
  width: 48px;
  height: 48px;
  border: 1px solid #de7e2f;
  background: url("@/assets/arrow3.png") no-repeat center center;
  background-size: 9px 17px;
}

.swiper-button-prev.prev1 {
  width: 48px;
  height: 48px;
  border: 1px solid #c9c9c9;
  background: url("@/assets/arrow4.png") no-repeat center center;
  /* border: 1px solid #de7e2f;
  background: url('@/assets/arrow5.png') no-repeat center center; */
  background-size: 9px 17px;
  border-radius: 50%;
  right: 0;
  top: 0;
  left: auto;
  box-sizing: border-box;
}
.swiper-button-prev.prev1:hover {
  width: 48px;
  height: 48px;
  border: 1px solid #de7e2f;
  background: url("@/assets/arrow5.png") no-repeat center center;
  background-size: 9px 17px;
}
.swiper-button-prev.swiper-button-disabled.prev1,
.swiper-button-next.swiper-button-disabled.next1 {
  opacity: 1;
}
.swiper-button-prev.swiper-button-disabled.prev1 {
  border: 1px solid #c9c9c9;
  background: url("@/assets/arrow4.png") no-repeat center center;
  background-size: 9px 17px;
}
.swiper-button-next.swiper-button-disabled.next1 {
  border: 1px solid #c9c9c9;
  background: url("@/assets/arrow6.png") no-repeat center center;
  background-size: 9px 17px;
}
</style>

<template>
  <div class="selection">
    <Header :num111="3" />
    <!-- <div
      class="one"
      v-for="(item, index) in appList"
      :key="'appList_' + index"
      :id="'appList_' + index"
    >
      <img :src="item.app_image" class="oneImg" />
      <div class="card">
        <div class="title">{{ item.app_title }}</div>
        <div class="info aui-ellipsis-3">{{ item.app_intro }}</div>
        <div class="see pointer cor_f08300" @click="fnSee(item.app_id)">
          立即查看
        </div>
      </div>
    </div> -->
    <!-- <div class="left">
      <div
        class="label pointer"
        @click="toHere(index)"
        v-for="(item, index) in appList"
        :key="'appListKey_' + index"
      >
        <a style="text-decoration: none">{{ item.app_title }}</a>
      </div>
    </div> -->

    <div class="middle1" style="padding: 20px 0 47px">
      <div v-for="(item, index) in appList" :key="'appList_' + index"  @click="fnSee(item.app_id)" class="pointer">
        <div class="h_item align-between" v-if="(index + 1) % 2 == 1">
          <div class="align-start">
            <div class="oneImg_out" style="overflow: hidden">
              <el-image
                :src="item.app_image"
                fit="cover"
                class="oneImg"
              ></el-image>
            </div>
            <div>
              <div class="title">{{ item.app_title }}</div>
              <div class="info aui-ellipsis-4">{{ item.app_intro }}</div>
            </div>
          </div>
          <div class="align-center" style="padding-right: 10px">
            <el-image
              :src="require('@/assets/hyyx/h_0' + (index+1) + '.png')"
              fit="contain"
              class="twoImg"
            ></el-image>
            <!-- <div class="num_txt">
              {{index>8?(index+1):('0'+(index+1))}}
            </div> -->
            <div style="width: 131px">
              <div class="more1 align-start">
                <div style="margin-right: 19px" class="more2">了解更多</div>
                <div class="jtImg"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="h_item align-between" v-else>
          <div class="align-center" style="padding-right: 10px">
            <div style="width: 131px;position:relative;left:30px;" class="twoo">
              <div class="more1 align-start">
                <div style="margin-right: 19px" class="more2">了解更多</div>
                <div class="jtImg"></div>
              </div>
            </div>
            <el-image
              :src="require('@/assets/hyyx/h_0' + (index+1) + '.png')"
              fit="contain"
              class="twoImg"
            ></el-image>
            <!-- <div class="num_txt">
              {{index>8?(index+1):('0'+(index+1))}}
            </div> -->
          </div>
          <div class="align-start" style="marign-left:45px;">
            <div>
              <div class="title">{{ item.app_title }}</div>
              <div class="info aui-ellipsis-4">{{ item.app_intro }}</div>
            </div>
            <div class="oneImg_out" style="overflow: hidden">
              <el-image
                :src="item.app_image"
                fit="cover"
                class="oneImg"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { appList } from '../api'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  data() {
    return {
      appList: [],
    }
  },
  methods: {
    fnAppList() {
      //应用领域
      let params = {}
      appList(params).then((data) => {
        if (data.status == 200) {
          this.appList = data.msg
        }
      })
    },
    fnSee(idd) {
      this.$router.push({
        name: 'Hyyx',
        params: {
          id: idd + '.html',
          // app_id: idd,
          // keyword: 'new',
        },
      })
    },
    toHere(index) {
      document.getElementById('appList_' + index).scrollIntoView({
        behavior: 'smooth', //顺滑的滚动
        block: 'center', //容器上下的中间
      })
    },
  },
  mounted() {
    document.title = '本牛'
    this.fnAppList()
  },
}
</script>
<style scoped>
.num_txt{
  color: rgba(236,121,2, .15);
  font-size: 186px;
  font-weight: 900;
  font-style: italic;
}
.more2 {
  position: relative;
  left: -20px;
  transition: all 0.5s;
  opacity: 0;
}
.h_item:hover .more2 {
  left: 0px;
  opacity: 1;
}
.h_item:hover .oneImg {
  scale: 1.1;
}
.h_item:hover {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}
.oneImg_out{
  min-width: 316px;
}
.h_item {
  padding: 22px 18px;
  background-color: #fff;
  transition: all 0.5s;
  margin-bottom: 19px;
}
.more1 {
  width: 121px;
  height: 39px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  box-sizing: border-box;
  padding-left: 12px;
  transition: all 0.5s;
  position: relative;
}

.h_item:hover .more1 {
  width: 121px;
  height: 39px;
  background: #ec7902;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  box-sizing: border-box;
  padding-left: 12px;
}

.jtImg {
  width: 32px;
  height: 16px;
  background: url('@/assets/arrow_right1.png') no-repeat center center;
  background-size: 100% 100%;
  position: absolute;
  left: 42px;
  /* left:-100px; */
  transition: all 0.5s;
  /* margin-right: 100px; */
}

.h_item:hover .jtImg {
  background: url('@/assets/arrow_right4.png') no-repeat center center;
  background-size: 100% 100%;
  /* margin-right: -19px; */
  left: 100px;
}
.twoImg {
  width: 220px;
  height: 155px;
}

.one {
  position: relative;
  width: 100%;
  /* height: 315px; */
  /* padding: 40px 0; */
  box-sizing: border-box;
  /* margin-bottom: 15px; */
}
.selection .one:first-child {
  margin-bottom: none;
}
.oneImg {
  display: block;
  width: 316px;
  min-width: 316px;
  height: 200px;
  transition: all 0.5s;
}
.h_item:nth-child(2n + 1) .oneImg_out {
  margin-right: 50px;
}
.h_item:nth-child(2n) .oneImg_out {
  margin-left: 50px;
}
.card {
  position: absolute;
  width: 667px;
  height: 235px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  margin: auto;
  z-index: 1;
  padding: 26px 50px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.title {
  font-size: 18px;
  font-weight: 400;
  color: #333;
  margin-bottom: 17px;
  line-height: 18px;
}

.info {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #999;
}
.see {
  font-size: 20px;
  font-weight: 500;
  color: #efc845;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  width: 100px;
  margin: auto;
}
.left {
  position: fixed;
  top: 220px;
  left: 208px;
  overflow-y: scroll;
  height: 420px;
  z-index: 1;
}

.left::-webkit-scrollbar {
  display: none;
}
.label {
  width: 121px;
  /* height: 30px; */
  background: rgba(255, 255, 255, 0.95);
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  text-align: center;
  margin-bottom: 15px;
}
.label a {
  color: #333333;
}
.label:hover {
  background: #f08300;
}
.label:hover a {
  color: #fff;
}
</style>

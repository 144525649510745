<template>
  <div class="footer_contain">
    <div class="footer flex-wrap">
      <!-- <div class="flex-con"></div> -->
      <div class="footer_item">
        <div class="font-fff-bold" style="margin-bottom: 19px">服务政策</div>
        <div
          class="font-fff pointer"
          style="margin-top: 9px"
          v-for="(item, index) in data3"
          :key="'data3_' + index"
          @click="fnFw(item.service_id)"
        >
          {{ item.service_title }}
        </div>
        <!-- <div class="font-fff" style="margin-top: 9px">售后服务</div>
        <div class="font-fff" style="margin-top: 9px">隐私政策</div> -->
      </div>
      <div class="flex-con"></div>
      <div class="flex-con"></div>
      <div class="footer_item">
        <div class="font-fff-bold" style="margin-bottom: 19px">关于我们</div>
        <div
          class="font-fff pointer"
          style="margin-top: 9px"
          v-for="(item, index) in data1"
          :key="'data1_' + index"
          @click="fnAboutUsDetail(item.about_id)"
        >
          {{ item.about_title }}
        </div>
        <!-- <div class="font-fff" style="margin-top: 9px">站点地图</div> -->
      </div>
      <div class="flex-con"></div>
      <div class="flex-con"></div>
      <div class="footer_item">
        <div class="font-fff-bold" style="margin-bottom: 19px">联系我们</div>
        <!-- <div class="align-start" style="margin-top: 9px">
          <el-image
            style="width: 20px; height: 20px; margin-right: 9px"
            :src="require('@/assets/footer_phone.png')"
            fit="cover"
          ></el-image>
          <div class="font-fff">123-45678975</div>
        </div> -->
        <div
          class="align-start"
          style="margin-top: 9px"
          v-for="(item, index) in data2"
          :key="'data2_' + index"
        >
          <el-image
            style="width: 20px; height: 20px; margin-right: 9px"
            :src="item.contact_icon"
            fit="cover"
          ></el-image>
          <div class="font-fff">{{ item.contact_content || '' }}</div>
        </div>
      </div>
      <div class="flex-con"></div>
      <div class="flex-con"></div>
      <div class="xian"></div>
      <div class="flex-con"></div>
      <div class="flex-con"></div>
      <div>
        <el-image
          style="width: 123px; height: 123px; margin-bottom: 4px"
          :src="data.content2"
          fit="cover"
        ></el-image>
        <div class="font-fff" style="text-align: center">公众号</div>
      </div>
      <div class="flex-con"></div>
      <div>
        <el-image
          style="width: 123px; height: 123px; margin-bottom: 4px"
          :src="data.content1"
          fit="cover"
        ></el-image>
        <div class="font-fff" style="text-align: center">小程序</div>
      </div>
    </div>
    <div class="font-fff" style="margin-top: 40px; text-align: center">
      <!-- 地址：{{ zongUrl }}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
      <a class="beian pointer" href="http://beian.miit.gov.cn" target="_blank"
        >苏ICP备2023015553号</a
      >
      <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <span class="beian pointer" >
        值税电信业务经营许可证
      </span> -->
    </div>
    <el-dialog :visible.sync="dialogVisible" width="840px">
      <div class="dialog">
        <div
          style="
            background: #ffffff;
            border-radius: 4px;
            height: 635px;
            padding: 0 20px;
            overflow-y: scroll;
          "
        >
          <div
            style="
              font-size: 16px;
              text-align: center;
              font-weight: 500;
              color: #333333;
              line-height: 57px;
            "
          >
            {{ type == 1 ? fwb.service_title : fwb.about_title }}
          </div>
          <div
            class="font-fff"
            v-html="type == 1 ? fwb.service_content : fwb.about_content"
          ></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  loginAgreement,
  contactUs,
  aboutUs,
  servicePolicy,
  policyDetail,
  aboutUsDetail,
} from '../api'
export default {
  data() {
    return {
      dialogVisible: false,
      type: 1, //1服务政策 2关于我们
      data: {
        content1: '',
        content2: '',
      },
      zongUrl: '',
      data1: [],
      data2: [],
      data3: [],
      fwb: {},
    }
  },
  methods: {
    fnLoginAgreement1() {
      let params = {
        type: 1,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          localStorage.setItem('gzhMa', data.msg.content2)
        }
      })
    },
    fnLoginAgreement2() {
      let params = {
        type: 2,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          this.zongUrl = data.msg.content1
        }
      })
    },
    fnContactUs() {
      let params = {}
      contactUs(params).then((data) => {
        if (data.status == 200) {
          this.data2 = data.msg
        }
      })
    },
    fnAboutUs() {
      let params = {}
      aboutUs(params).then((data) => {
        if (data.status == 200) {
          this.data1 = data.msg
        }
      })
    },
    fnServicePolicy() {
      let params = {}
      servicePolicy(params).then((data) => {
        if (data.status == 200) {
          this.data3 = data.msg
        }
      })
    },
    fnFw(service_id) {
      // this.dialogVisible = true
      // this.type = 1
      // let params = {
      //   type:1
      //   idd: service_id,
      // }
      if (this.$router.history.current.name == 'About') {
        this.$bus.$emit('footer', 1, service_id)
      }
      this.$router.push({
        name: 'About',
        params: {
          id: 1 + '-' + service_id + '.html',
        },
      })

      // policyDetail(params).then((data) => {
      //   if (data.status == 200) {
      //     this.fwb = data.msg
      //   }
      // })
    },
    fnAboutUsDetail(about_id) {
      if (this.$router.history.current.name == 'About') {
        this.$bus.$emit('footer', 2, about_id)
      }
      this.$router.push({
        name: 'About',
        params: {
          id: 2 + '-' + about_id + '.html',
        },
      })
      // this.dialogVisible = true
      // this.type = 2
      // let params = { about_id: about_id }
      // aboutUsDetail(params).then((data) => {
      //   if (data.status == 200) {
      //     this.fwb = data.msg
      //   }
      // })
    },
    // fnAbout() {
    //   this.$router.push({
    //     name: 'About',
    //     params: {},
    //   })
    // },
  },
  mounted() {
    this.fnLoginAgreement1()
    this.fnLoginAgreement2()
    this.fnContactUs()
    this.fnAboutUs()
    this.fnServicePolicy()
  },
}
</script>

<style scoped>
.footer_contain {
  height: 286px;
  padding: 35px 0 43px;
  background: #4a4a4a;
  box-sizing: border-box;
}
.footer {
  width: 1080px;
  margin: auto;
  min-height: 150px;
}
.footer_item {
  text-align: left;
}
.xian {
  width: 1px;
  min-height: 150px;
  background: #fff;
}
.beian {
  color: #fff;
  text-decoration-line: none;
}
</style>

<template>
    <div>
        <EnHeader :num111="0" />
        <div class="bg_fff">
            <!-- <el-image style="width: 100%" :src="require('@/assets/en/banner1.png')" fit="cover"></el-image> -->
            <img style="width: 100%" :src="require('@/assets/en/banner1.png')" fit="cover" alt="">
            <div class="w1200">
                <div class="title1">BUSINESS CATEGORY</div>
                <div class="title2">BENNIU (JIANGSU) E-COMMERCE CO., LTD</div>
                <div class="box1 align-start">
                    <div>
                        <div class="box2">
                            <el-image class="img1" :src="require('@/assets/en/home_1.png')" fit="cover"></el-image>
                        </div>
                        <div class="box3">
                            RUBBER AND PLASTIC
                        </div>
                    </div>
                    <div>
                        <div class="box2">
                            <el-image class="img1" :src="require('@/assets/en/home_2.png')" fit="cover"></el-image>
                        </div>
                        <div class="box3">
                            GLASS WOOL
                        </div>
                    </div>
                    <div>
                        <div class="box2">
                            <el-image class="img1" :src="require('@/assets/en/home_3.png')" fit="cover"></el-image>
                        </div>
                        <div class="box3">
                            AIR DUCT
                        </div>
                    </div>
                    <div>
                        <div class="box2">
                            <el-image class="img1" :src="require('@/assets/en/home_4.png')" fit="cover"></el-image>
                        </div>
                        <div class="box3">
                            CERAMIC FIBER
                        </div>
                    </div>
                    <div>
                        <div class="box2">
                            <el-image class="img1" :src="require('@/assets/en/home_5.png')" fit="cover"></el-image>
                        </div>
                        <div class="box3">
                            AEROGEL
                        </div>
                    </div>
                    <div>
                        <div class="box2">
                            <el-image class="img1" :src="require('@/assets/en/home_6.png')" fit="cover"></el-image>
                        </div>
                        <div class="box3">
                            SHELL TREASURE OUTER PROTECTION
                        </div>
                    </div>
                </div>
            </div>
            <el-image style="width: 100%;height:560px;position: relative;" :src="require('@/assets/en/home_bg1.png')"
                fit="cover"></el-image>
            <div class="w1200 align-between-bottom" style="padding: 0;margin:-470px auto 0;position: relative;z-index: 1;">
                <div>
                    <div class="align-start">
                        <div class="xian1"></div>
                        <div class="title3">COMPANY <br>PROFILE</div>
                    </div>
                    <div class="title4">Global insulation and energy-saving e-commerce platform Global insulation and
                        energy-saving e-commerce platform</div>
                </div>
                <el-image class="img2" :src="require('@/assets/en/home_10.png')" fit="cover"></el-image>
            </div>
            <div class="w1200" style="padding: 0;">
                <div class="title5">
                    A global insulation and energy-saving e-commerce platform that gathers high-quality resources from the
                    global insulation and energy-saving industry, strictly selects high-quality industry products, and
                    provides
                    one-stop procurement services for insulation and energy-saving materials for various production
                    enterprises
                    and distribution partners.
                </div>
                <div class="title1">PLATFORM ADVANTAGES</div>
                <div class="title2">BENNIU (JIANGSU) E-COMMERCE CO., LTD</div>
                <div class="align-around" style="padding: 40px 50px 60px;">
                    <div class="box4">
                        <el-image class="img3" :src="require('@/assets/en/home_icon1.png')" fit="cover"></el-image>
                        <div>8 years of deep industry cultivation</div>
                    </div>
                    <div class="box4">
                        <el-image class="img3" :src="require('@/assets/en/home_icon2.png')" fit="cover"></el-image>
                        <div>Hundreds of insulation products</div>
                    </div>
                    <div class="box4">
                        <el-image class="img3" :src="require('@/assets/en/home_icon3.png')" fit="cover"></el-image>
                        <div>Directly supplied by the source manufacturer</div>
                    </div>
                    <div class="box4">
                        <el-image class="img3" :src="require('@/assets/en/home_icon4.png')" fit="cover"></el-image>
                        <div>National logistics layout</div>
                    </div>
                </div>
            </div>
            <div class="bg_1">
                <div class="title1" style="color: #fff;">APPLICATION AREA</div>
                <div class="title2" style="color: #fff;margin-bottom: 60px;">BENNIU (JIANGSU) E-COMMERCE CO., LTD</div>
                <div class="swiper-container swiper-container2" id="swiper-container3">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide swiper-slide1 pointer" v-for="(item, index) in appList" :key="index">
                            <img :src="item.app_thumb" class="lyu" />
                            <div class="all">
                                <div class="align-center" style="margin: auto">
                                    <div>
                                        <div class="font-fff-24 all_title1" style="text-align: center; margin-bottom: 30px">
                                            {{ item.app_title }}
                                        </div>
                                        <div class="all_info aui-ellipsis-3">
                                            {{ item.app_intro }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-next pointer next2"></div>
                    <div class="swiper-button-prev pointer prev2"></div>
                </div>
            </div>
            <div class="w1200">
                <div class="title1">NEWS AND INFORMATIION</div>
                <div class="title2" style="margin-bottom: 65px;">BENNIU (JIANGSU) E-COMMERCE CO., LTD</div>
                <div class="align-end">
                    <div class="align-between-top box5">
                        <el-image class="img4" :src="require('@/assets/en/home_11.png')" fit="cover"></el-image>
                        <div>
                            <div class="title6 aui-ellipsis-2">Organizational Capability White Paper | How Does an
                                Organization Evolve at the Turning Point of the Big Era?</div>
                            <div class="title7 aui-ellipsis-7">Your exploration is very meaningful and has paved a path for
                                the development of cooperatives that suits you.Your exploration is very meaningful and has
                                paved a path for the development of cooperatives that suits you.Your exploration is very
                                meaningful and has paved a path for the development of cooperatives that suits you Your
                                exploration is very meaningful and has paved a path for the development of cooperatives that
                                suits you.Your exploration is very meaningful and has paved a path for the development of
                                cooperatives that suits you.Your exploration is very meaningful and has paved a path for the
                                …</div>
                        </div>
                        <div style="min-width:145px;text-align: right;">
                            <div class="title8">04.10</div>
                            <div class="title9">2023</div>
                        </div>

                    </div>
                </div>
                <div class="align-around" style="flex-wrap: wrap;">
                    <div class="align-start-top box7">
                        <div
                            style="min-width:88px;height: 88px;background: #F6F6F6;padding:18px;box-sizing: border-box;text-align: right;">
                            <div class="title8">04.10</div>
                            <div class="title9">2023</div>
                        </div>
                        <div class="box6">
                            <el-image class="right2" :src="require('@/assets/en/right2.png')" fit="cover"></el-image>
                            <div class="title6 aui-ellipsis-2" style="margin-bottom:8px;">Advantages of using Aerogel
                                insulation blanket for chemical vent flare</div>
                            <div class="title7 aui-ellipsis-2">Your exploration is very meaningful and has paved a path for
                                the development of cooperatives that…</div>
                        </div>
                    </div>
                    <div class="align-start-top box7">
                        <div
                            style="min-width:88px;height: 88px;background: #F6F6F6;padding:18px;box-sizing: border-box;text-align: right;">
                            <div class="title8">04.10</div>
                            <div class="title9">2023</div>
                        </div>
                        <div class="box6">
                            <el-image class="right2" :src="require('@/assets/en/right2.png')" fit="cover"></el-image>
                            <div class="title6 aui-ellipsis-2" style="margin-bottom:8px;">Advantages of using Aerogel
                                insulation blanket for chemical vent flare</div>
                            <div class="title7 aui-ellipsis-2">Your exploration is very meaningful and has paved a path for
                                the development of cooperatives that…</div>
                        </div>
                    </div>
                    <div class="align-start-top box7">
                        <div
                            style="min-width:88px;height: 88px;background: #F6F6F6;padding:18px;box-sizing: border-box;text-align: right;">
                            <div class="title8">04.10</div>
                            <div class="title9">2023</div>
                        </div>
                        <div class="box6">
                            <el-image class="right2" :src="require('@/assets/en/right2.png')" fit="cover"></el-image>
                            <div class="title6 aui-ellipsis-2" style="margin-bottom:8px;">Advantages of using Aerogel
                                insulation blanket for chemical vent flare</div>
                            <div class="title7 aui-ellipsis-2">Your exploration is very meaningful and has paved a path for
                                the development of cooperatives that…</div>
                        </div>
                    </div>
                    <div class="align-start-top box7">
                        <div
                            style="min-width:88px;height: 88px;background: #F6F6F6;padding:18px;box-sizing: border-box;text-align: right;">
                            <div class="title8">04.10</div>
                            <div class="title9">2023</div>
                        </div>
                        <div class="box6">
                            <el-image class="right2" :src="require('@/assets/en/right2.png')" fit="cover"></el-image>
                            <div class="title6 aui-ellipsis-2" style="margin-bottom:8px;">Advantages of using Aerogel
                                insulation blanket for chemical vent flare</div>
                            <div class="title7 aui-ellipsis-2">Your exploration is very meaningful and has paved a path for
                                the development of cooperatives that…</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <EnFooter />
    </div>
</template>
<script>
import Swiper from 'swiper/bundle'
import EnHeader from '@/components/EnHeader.vue'
import EnFooter from '@/components/EnFooter.vue'
export default {
    components: {
        EnHeader,
        EnFooter,
    },
    data() {
        return {
            tdkTitle: '本牛',
            tdkKeywords: '本牛',
            tdkDescription: '本牛',
            appList: [{
                app_thumb: require('@/assets/en/home_7.png'),
                app_title: 'Industrial factory building',
                app_intro: 'In industrial production, equipment insulation plays an important role. The quality of insulation directly affects the safety of production, the quality of products, and the efficiency of energy conservation and environmental protection.'
            }, {
                app_thumb: require('@/assets/en/home_8.png'),
                app_title: 'Industrial factory building',
                app_intro: 'In industrial production, equipment insulation plays an important role. The quality of insulation directly affects the safety of production, the quality of products, and the efficiency of energy conservation and environmental protection.'
            }, {
                app_thumb: require('@/assets/en/home_9.png'),
                app_title: 'Industrial factory building',
                app_intro: 'In industrial production, equipment insulation plays an important role. The quality of insulation directly affects the safety of production, the quality of products, and the efficiency of energy conservation and environmental protection.'
            }, {
                app_thumb: require('@/assets/en/home_7.png'),
                app_title: 'Industrial factory building',
                app_intro: 'In industrial production, equipment insulation plays an important role. The quality of insulation directly affects the safety of production, the quality of products, and the efficiency of energy conservation and environmental protection.'
            }, {
                app_thumb: require('@/assets/en/home_8.png'),
                app_title: 'Industrial factory building',
                app_intro: 'In industrial production, equipment insulation plays an important role. The quality of insulation directly affects the safety of production, the quality of products, and the efficiency of energy conservation and environmental protection.'
            }, {
                app_thumb: require('@/assets/en/home_9.png'),
                app_title: 'Industrial factory building',
                app_intro: 'In industrial production, equipment insulation plays an important role. The quality of insulation directly affects the safety of production, the quality of products, and the efficiency of energy conservation and environmental protection.'
            }],
            fwb: 'Coke oven gas is a byproduct of  coal carbonization and coking  <img src="https://img1.baidu.com/it/u=1237920042,2107282987&fm=253&fmt=auto&app=138&f=PNG?w=500&h=500" alt="">',
        }
    },
    metaInfo() {
        return {
            title: this.tdkTitle,
            meta: [
                { name: 'keywords', content: this.tdkKeywords },
                { name: 'description', content: this.tdkDescription },
            ],
        }
    },
    methods: {

    },
    mounted() {
        new Swiper('#swiper-container3', {
            slidesPerView: 3,
            spaceBetween: 60,
            slidesPerGroup: 3,
            pagination: {
                el: '.swiper-pagination1',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            observer: true,
        })
    }
}
</script>
  
<style scoped>
.right2 {
    position: absolute;
    right: 20px;
    top: 18px;

    width: 18px;
    height: 18px;
}

.box7 {
    width: 528px;
    margin-top: 40px;
}

.box6 {
    padding: 16px;
    padding-right: 58px;
    border: 1px solid #F6F6F6;
    position: relative;
}

.img4 {
    width: 355px;
    height: 240px;
    position: absolute;
    left: -295px;
    top: 20px;
}

.title9 {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
}

.title8 {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
}

.box5 {
    width: 905px;
    height: 280px;
    background: #F6F6F6;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 40px 26px 0 120px;
    position: relative;
}

.title7 {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
}

.title6 {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin-bottom: 20px;
}

@import url('swiper/swiper-bundle.css');

.swiper-slide1 {
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    /* transition: transform 0.5s, box-shadow 0.5s; */
}

.all_info {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    line-height: 24px;
    width: 185px;
    position: relative;
    bottom: -100px;
    transition: all 0.5s;
    margin: auto;
}

.all {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* padding: 0 50px; */
    box-sizing: border-box;
    background: rgba(27, 27, 27, 0.72);

    border: 3px solid #de7e2f;
    margin: 0 auto;
}

.swiper-slide1 .half {
    display: block;
}

.swiper-slide1 .all {
    display: flex;
    opacity: 0;
    transition: all 0.5s;
    width: 360px;
}

.swiper-slide1:hover .half {
    display: none;
}

.swiper-slide1:hover .all_title1 {
    top: 0;
}

.swiper-slide1:hover .all_info {
    bottom: 0;
}

.swiper-slide1:hover .all {
    /* display: flex; */
    opacity: 1;
    margin: 16px auto 0;
    width: 286px;
    height: 215px;
}

.swiper-slide1:hover .lyu {
    transform: scale(1.1);
    /* transition-duration: 1s;
  transition-timing-function:ease-out; */
}

.swiper-container {
    width: 1200px;
    height: 260px;
    overflow: hidden;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
}

/* .swiper-pagination {
    width: 100%;
    height: 15px;
    position: absolute;
    bottom: 42px;
    text-align: right;
    padding-right: 60px;
    box-sizing: border-box;
} */

.swiper-container2 {
    width: 1200px;
    height: 260px;
    /* padding-top: 68px;
    margin-top: 53px; */
}

.swiper-container2 .swiper-slide {
    position: relative;
    height: 260px;
    width: 360px;
    /* border-radius: 4px; */
}

img.lyu {
    height: 260px;
    width: 360px;
    object-fit: cover;
    transition: transform 1s;
}

.swiper-button-prev:after {
    display: none;
}

.swiper-button-next:after {
    display: none;
}

.swiper-button-prev {
    width: 30px;
    height: 60px;
    background: url('@/assets/en/left1.png') no-repeat center center;
    background-size: 30px 60px;
    left: 0;
    top: 115px;
}

.swiper-button-next {
    width: 30px;
    height: 60px;
    background: url('@/assets/en/right1.png') no-repeat center center;
    background-size: 30px 60px;
    right: 0;
    top: 115px;
}

.swiper-button-next.swiper-button-disabled.next2 {
    border-radius: 0;
    border: none;
    background: url('@/assets/en/right1.png') no-repeat center center;
    background-size: 30px 60px;
}

.swiper-button-prev.swiper-button-disabled.prev2 {
    border-radius: 0;
    border: none;
    background: url('@/assets/en/left1.png') no-repeat center center;
    background-size: 30px 60px;
}

.bg_1 {
    padding-top: 1px;
    width: 100%;
    height: 560px;
    background: url('@/assets/en/home_bg2.png') no-repeat center center;
    background-size: 100% 100%;
}

.img3 {
    width: 66px;
    height: 66px;
    display: block;
    margin: 0 auto 10px;
}

.box4 {
    width: 180px;
    height: 180px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding-top: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 19px;
    text-align: center;
}

.img2 {
    min-width: 726px;
    height: 540px;
    border: 10px solid;
    border-image: linear-gradient(90deg, rgba(239, 200, 69, 1), rgba(220, 160, 30, 1)) 10 10;
}

.title5 {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin-top: 20px;
}

.title4 {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    margin-top: 84px;
    margin-right: 28px;
}

.title3 {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 2px;
}

.xian1 {
    width: 20px;
    height: 129px;
    background: linear-gradient(180deg, #EFC845 0%, #DCA01E 100%);
    margin-right: 20px;
}

.img1 {
    width: 270px;
    margin: auto;
    display: block;
    position: relative;
    top: -58px;
}

.box1 {
    width: 1044px;
    margin: auto;
    flex-wrap: wrap;
}

.box2 {
    margin-top: 100px;
    width: 294px;
    height: 212px;
    background: linear-gradient(90deg, #EFC845 0%, #DCA01E 100%);
}

.box1>div:nth-child(3n+2) {
    margin: 0 81px;
}

.box3 {
    width: 294px;
    height: 32px;
    background: #333333;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 32px;
    text-align: center;
}

.title1 {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
    letter-spacing: 2px;
    margin: 60px 0 16px;
    text-align: center;
}

.title2 {
    font-size: 14px;
    font-weight: 400;
    color: #EFC845;
    line-height: 20px;
    text-align: center;
}

.w1200 {
    padding: 35px 0 60px;
}

.tab_out {
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 60px;
}

/* .tab_out > div {
    margin-right: 40px;
    padding: 25px 0;
  }
  .tab_out .active {
    font-size: 14px;
    font-weight: 500;
    color: #efc845;
    line-height: 20px;
    position: relative;
  }
  .tab_out .active .xian {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  }
  .fwb >>> img {
    display: block;
    margin: auto;
  } */
</style>
  
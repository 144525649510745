<template>
  <div class="container font-fff-14">
    <div class="c_top" style="position: relative;">
      <div class="font-333-16-24 guoji pointer" @click="fnGuo">Chinese Station</div>
      <div class="c_top_in align-between">
        <div class="align-center">
            <img style="width: auto; height: 94px; margin-right: 30px" :src="require('@/assets/logo_1.png')"
            @click="toHome()" fit="cover" class="pointer" alt="">
          <div>
            <div style="margin-bottom: 12px" class="font-fff-22">
              Benniu (Jiangsu) E-commerce Co., LTD
            </div>
            <div class="">
              Global insulation and energy-saving e-commerce platform
            </div>
          </div>
        </div>
        <div class="">
          <div class="align-start" style="margin-bottom: 12px">
            <!-- <el-image style="width: 24px; height: 24px; margin-right: 6px" :src="require('@/assets/phone1.png')"
              fit="cover"></el-image> -->
              <img style="width: 24px; height: 24px; margin-right: 6px" :src="require('@/assets/phone1.png')"
              fit="cover" alt="">
            <span style="text-decoration: underLine">400-025-9025</span>
          </div>
          <div class="align-start">
            <!-- <el-image style="width: 24px; height: 24px; margin-right: 6px" :src="require('@/assets/en/icon1.png')"
              fit="cover"></el-image> -->
              <img style="width: 24px; height: 24px; margin-right: 6px" :src="require('@/assets/en/icon1.png')"
              fit="cover" alt="">
            <span>kefu@niutop.com</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tab_box">
      <div class="tab_box_in align-start">
        <div class="tab_item pointer" :class="{ active: index == tab2Index }" @click="fnTab2(index)"
          v-for="(item, index) in tab2Item" :key="'tab2_' + index">{{ item }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    num111: {
      Type: Number,
      default: 999,
    }
  },
  data() {
    return {
      tab2Index: -1,
      tab2Item: [
        'Home',
        'Company products',
        'About the company',
        'News and information',
      ],
    }
  },
  mounted() {
    // console.log(this.$route);
    this.tab2Index = this.num111
  },
  methods: {
    fnTab2(index) {
      this.tab2Index = index
      let tab2Url = ['home', 'products', 'enmain', 'infolist']
      this.$router.push({
        name: tab2Url[index],
        params: {
          id: '.html',
        },
      })
    },
    fnGuo(){
      // window.open('https://www.niutop.com/en.html', "_blank")
      this.$router.push({
          name: 'Main',
          params: {
            id: '.html',
          },
      })
      // window.open(
      //   this.$router.resolve({ name: 'home', params: { id: '.html' } })
      //     .href,
      //   '_blank'
      // )
    }
  }
}
</script>

<style scoped>
.container {}

.c_top {
  height: 110px;
  background: linear-gradient(180deg, #9c9b96 0%, #676664 100%);
}

.c_top_in,
.tab_box_in {
  width: 1200px;
  margin: auto;
  height: 100%;
}

.tab_box {
  height: 40px;
  background: #4a4a4a;
}

.tab_item {
  padding: 0 44px;
  line-height: 40px;
}

.tab_item.active {
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
}
.guoji{
  position: absolute;
  right: 20px;
  top:10px;
  color: #fff;
}
</style>

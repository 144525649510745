<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap m_t14">
      <div>
        <MyInfo />
      </div>

      <div class="my_right flex-con">
        <div class="my_order" style="text-align: left">
          <div
            class="font-333-20-bold align-start"
            style="
              background: rgba(255, 226, 189, 0.2);
              height: 46px;
              padding: 0 12px 0 22px;
            "
          >
            <div
              class="align-center pointer"
              @click="fnBack"
              style="margin-right: 14px"
            >
              <img src="../assets/back.png" class="topppImg" />
              <!-- <div class="back font-666-16">返回</div> -->
            </div>
            <div>订单详情</div>
          </div>
          <div
            class="font-333-16-bold"
            style="padding: 0 12px 0 22px; line-height: 56px"
          >
            订单编号：<span class="font-666-16">{{ data.order_number }}</span>
          </div>
        </div>

        <div class="my_order">
          <div
            class="font-333-20-bold align-start"
            style="
              background: rgba(255, 226, 189, 0.2);
              height: 46px;
              padding: 0 12px 0 22px;
            "
          >
            收货信息
          </div>

          <div class="order_box">
            <div
              class="flex-wrap"
              style="padding: 20px 0; text-align: center"
              v-if="data.order_is_pay != 3 && data.order_refund_status == 0"
            >
              <div class="flex-con">
                <div class="order_step active align-center">1</div>
                <div class="font-333-16-bold" style="margin-top: 20px">
                  提交订单
                </div>
              </div>
              <div class="flex-con">
                <div class="step_out">
                  <div
                    class="order_step align-center"
                    :class="{ active: data.order_is_pay != 1 }"
                  >
                    2
                  </div>
                  <span
                    class="step_xian"
                    :class="{ active: data.order_is_pay != 1 }"
                  ></span>
                </div>
                <div class="font-333-16-bold" style="margin-top: 20px">
                  订单支付
                </div>
              </div>
              <div class="flex-con">
                <div class="step_out">
                  <div
                    class="order_step align-center"
                    :class="{
                      active:
                        (data.order_is_pay == 2 && data.order_status == 2) ||
                        data.order_status == 3,
                    }"
                  >
                    3
                  </div>
                  <span
                    class="step_xian"
                    :class="{
                      active:
                        (data.order_is_pay == 2 && data.order_status == 2) ||
                        data.order_status == 3,
                    }"
                  ></span>
                </div>
                <div class="font-333-16-bold" style="margin-top: 20px">
                  商品发货
                </div>
              </div>
              <div class="flex-con">
                <div class="step_out">
                  <div
                    class="order_step align-center"
                    :class="{ active: data.order_status == 3 }"
                  >
                    4
                  </div>
                  <span
                    class="step_xian"
                    :class="{ active: data.order_status == 3 }"
                  ></span>
                </div>
                <div class="font-333-16-bold" style="margin-top: 20px">
                  收货完成
                </div>
              </div>
            </div>
            <div class="flex-wrap" style="">
              <div
                class="flex-con font-333-16-bold"
                style="padding: 16px 0 0 26px; text-align: left"
              >
                <div style="margin-bottom: 14px">
                  收件人：
                  <span class="font-666-16">{{ data.order_name || '' }}</span>
                </div>
                <div style="margin-bottom: 14px">
                  收货电话：<span class="font-666-16">{{
                    data.order_phone || ''
                  }}</span>
                </div>
                <div style="margin-bottom: 14px">
                  收货地址：<span class="font-666-16">{{
                    data.order_address || ''
                  }}</span>
                </div>
              </div>
              <div
                class="flex-con font-333-16-bold"
                style="padding: 16px 0 0 26px; text-align: left"
                v-if="data.order_fapiao_type == 1 || data.order_fapiao_type == 2"
              >
                <div style="margin-bottom: 14px">
                  发票类型：{{
                    data.order_fapiao_type == 1 ? '普通发票' : '增值税发票'
                  }}
                  <span class="font-666-16">{{
                    data.order_phone_fp || ''
                  }}</span>
                </div>
                <div style="margin-bottom: 14px">
                  发票抬头：<span class="font-666-16">{{
                    data.order_fapiao_json.fp_header || ''
                  }}</span>
                </div>
                <div style="margin-bottom: 14px">
                  纳税人识别号：<span class="font-666-16">{{
                    data.order_fapiao_json.fp_number || ''
                  }}</span>
                </div>
              </div>
            </div>

            <div class="font-333-16-bold" v-show="data.order_remark" style="padding-left: 26px;margin-bottom: 14px"> 备注： <span class="font-666-16">{{ data.order_remark||'' }}</span> </div>
            <!-- <div
              class="flex-wrap"
              v-if="data.order_fapiao_type == 1 || data.order_fapiao_type == 2"
            >
              <div
                class="flex-con font-333-16-bold"
                style="padding: 16px 0 0 26px; text-align: left"
              >
                <div style="margin-bottom: 14px">
                  收票人：<span class="font-666-16">{{
                    data.order_name_fp || ''
                  }}</span>
                </div>
                <div style="margin-bottom: 14px">
                  收票电话：<span class="font-666-16">{{
                    data.order_phone_fp || ''
                  }}</span>
                </div>
                <div style="margin-bottom: 14px">
                  收票地址：<span class="font-666-16">{{
                    data.order_address_fp || ''
                  }}</span>
                </div>
              </div>
              
            </div> -->
          </div>
        </div>
        <div class="my_order">
          <div
            class="font-333-20-bold align-start"
            style="
              background: rgba(255, 226, 189, 0.2);
              height: 46px;
              padding: 0 12px 0 22px;
            "
          >
            订单信息
          </div>
          <div
            class="flex-wrap font-333-16-bold"
            style="
              text-align: center;
              padding: 0 12px 0 22px;
              line-height: 56px;
              border: 1px solid #eeeeee;
            "
          >
            <div style="width: 312px; text-align: left">商品信息</div>
            <div style="min-width: 100px">规格</div>
            <div class="flex-con"></div>
            <div style="width: 125px">单价</div>
            <div style="width: 125px">数量×单位</div>
            <div style="width: 125px">实付金额</div>
            <div style="width: 125px">状态</div>
          </div>

          <div class="order_box" style="margin-top: 10px">
            <div class="order_item">
              <div
                class="flex-wrap font-333"
                style="margin-top: 16px; padding: 0 12px 16px 22px"
              >
                <div class="flex-con">
                  <div
                    class="flex-wrap"
                    style="width: 100%"
                    v-for="(item, index) in goods"
                    :key="'goods_' + index"
                  >
                    <div
                      style="width: 312px; text-align: left"
                      class="align-top"
                    >
                      <el-image
                        style="
                          width: 140px;
                          min-width: 140px;
                          height: 140px;
                          margin-right: 12px;
                        "
                        :src="item.goods_thumb"
                        fit="cover"
                      ></el-image>
                      <div
                        style="pmargin-right: 30px; width: 130px;word-break: break-all;"
                        class="font-333-16"
                      >
                        {{ item.goods_name || '' }}
                      </div>
                    </div>

                    <div style="min-width: 100px; text-align: left">
                      <div class="flex-wrap">
                        <div class="">
                          <div
                            v-for="(item, index) in item.guige_name"
                            :key="'guige_name_' + item.goods_id + '_' + index"
                            class="font-333-16"
                          >
                            {{ item }}：
                          </div>
                        </div>
                        <div class="flex-con">
                          <div
                            v-for="(item, index) in item.guige_value"
                            :key="'guige_value_' + item.goods_id + '_' + index"
                            class="font-666-16"
                            style="line-height: 24px;"
                          >
                            {{ item }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex-con"></div>
                    <div
                      style="width: 125px; min-width: 125px; max-width: 125px"
                      class="align-center font-333-16-bold"
                    >
                      {{ item.unit_price>0?item.unit_price:'面议' }}
                    </div>
                    <div style="width: 125px" class="align-center font-666-16">
                      {{ item.num || 0 }} × {{ item.gu_name.split('/')[1] }} 
                    </div>
                    <div
                      style="width: 125px"
                      class="align-center font-333-16-bold"
                    >
                      {{
                        item.unit_price > 0
                          ? ((item.unit_price * item.num * 100) / 100).toFixed(
                              2
                            )
                          : '面议'
                      }}
                    </div>
                  </div>
                  <!-- <div class="flex-wrap">
              <div style="width: 255px; text-align: left" class="align-top">
                <el-image
                  style="width: 100px; height: 100px; margin-right: 16px"
                  :src="require('@/assets/zhanweitu/zhanwei.png')"
                  fit="cover"
                ></el-image>
                <div style="padding-right: 40px">AAS压制玻璃棉隔声保温板</div>
              </div>
              <div style="min-width: 100px; text-align: left">
                长度：50MM <br />
                宽度：1.2M<br />
                厚度：0.6M<br />
                数量：20
              </div>
              <div class="flex-con"></div>
              <div style="width: 125px" class="C52B33 align-center">268.87</div>
              <div style="width: 125px" class="align-center">1</div>
              <div style="width: 125px" class="C52B33 align-center">268.87</div>
            </div> -->
                </div>
                <div
                  style="width: 125px"
                  class="order_status align-center font-333-16-bold"
                >
                  <div>
                    <div
                      class=""
                      v-show="data.order_is_pay == 2 && data.order_status == 1"
                    >
                      待发货
                    </div>
                    <div
                      class=""
                      v-show="data.order_is_pay == 2 && data.order_status == 2"
                    >
                      待收货
                    </div>
                    <div class="" v-show="data.order_is_pay == 1">待付款</div>
                    <div
                      class=""
                      v-show="
                        data.order_status == 3 && data.order_refund_status == 0
                      "
                    >
                      已完成
                    </div>
                    <div class="" v-show="data.order_is_pay == 3">已取消</div>
                    <div class="" v-show="data.order_refund_status > 0">
                      {{
                        data.order_refund_status == 1
                          ? '售后中'
                          : data.order_refund_status == 2
                          ? '已同意'
                          : data.order_refund_status == 3
                          ? '未同意'
                          : ''
                      }}
                    </div>

                    <div
                      v-show="data.order_refund_status > 0"
                      class="font-333-16 pointer"
                      @click="fnShXq"
                      style="margin-top: 6px"
                    >
                      售后详情
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="my_order">
          <div
            class="font-333-20-bold align-start"
            style="
              background: rgba(255, 226, 189, 0.2);
              height: 46px;
              padding: 0 12px 0 22px;
            "
          >
            总计
          </div>

          <div class="order_box" style="margin-top: 10px">
            <div class="flex-wrap">
              <div
                class="flex-con font-333-16-bold"
                style="padding: 16px 0 0 0; text-align: right;line-height: 24px;"
              >
                <div style="margin-bottom: 14px">产品总件数：</div>
                <div style="margin-bottom: 14px">总计：</div>
                <div style="margin-bottom: 14px">运费：</div>
              </div>
              <div
                style="
                  min-width: 124px;
                  padding: 16px 26px 0 0;
                  text-align: right;
                "
                class="font-333-16"
              >
                <div style="margin-bottom: 14px">
                  {{ data.order_nums || 0 }}件
                </div>
                <div style="margin-bottom: 14px">
                  ¥{{ data.order_price>0?data.order_price-data.order_freight:'面议'}}
                </div>
                <div style="margin-bottom: 14px">{{data.order_freight>0?('¥'+data.order_freight):'包邮'}}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="align-end order_header font-333"
          style="border: none; padding-right: 0"
        >
          <div class="font-333-14">
            订单总额：
            <span class="font-EC7902-16">¥{{ data.order_price>0?data.order_price:'面议' }}</span>
          </div>
          <div class="kuang11 align-center pointer" @click="initDownOrder">
            订单导出
          </div>
          <div
            class="kuang22 align-center pointer"
            v-show="data.order_is_pay == 2 && data.order_status == 1"
            @click="fnCui"
          >
            催促发货
          </div>
          <div
            class="kuang22 align-center pointer"
            @click="fnSh"
            v-show="data.order_status == 3"
          >
            申请售后
          </div>
          <div
            class="kuang22 align-center pointer"
            v-show="
              (data.order_is_pay == 2 && data.order_status == 2) ||
              data.order_status == 3
            "
            @click="fnWl"
          >
            查看物流
          </div>
          <div
            class="kuang22 align-center pointer"
            v-show="data.order_is_pay == 2 && data.order_status == 2"
            @click="fnShou()"
          >
            确认收货
          </div>

          <div
            class="kuang22 align-center pointer"
            v-show="data.order_is_pay == 1"
            @click="fnCancle()"
          >
            取消订单
          </div>
          <div
            class="kuang22 align-center pointer"
            v-show="data.order_is_pay == 3"
            @click="fnDelete()"
          >
            删除订单
          </div>
          <div
            class="kuang22 align-center pointer"
            v-show="data.order_is_pay == 1"
            @click="fnPay()"
          >
            去支付
          </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="840px">
          <div class="dialog">
            <div class="align-start font-333" style="margin-bottom: 14px">
              <div class="pointer">
                <a :href="orderUrl">{{ orderUrl }} 点击下载</a>
              </div>
            </div>
          </div>
        </el-dialog>
        <el-dialog
          :visible.sync="dialogVisible111"
          width="980px"
          custom-class="confirm_dialog"
        >
          <div class="dialog" style="padding: 0">
            <div class="dialog_title align-start"> <img :src="require('@/assets/tip_delete.png')" alt="" style="width:26px;height:26px;margin-right:18px;">{{txtCancle}}订单</div>
            <div class="font-333-18-bold" style="text-align: center;margin-top: 36px;">
              确认{{ txtCancle }}吗？
            </div>
            <div class="align-around" style="margin-top: 36px">
              <div
                class="dialog_btn2 align-center pointer"
                @click="dialogVisible111 = false"
              >
                取消
              </div>
              <div class="dialog_btn1 align-center pointer" @click="fnCun">确认</div>
            </div>
          </div>
        </el-dialog>
        <el-dialog
          :visible.sync="dialogVisible2"
          width="840px"
          title="售后详情"
          center
        >
          <div class="dialog">
            <div class="align-start" style="margin-bottom: 14px">
              <div class="width115">售后类型:</div>
              <div>
                {{ data.order_refund_type || '' }}
              </div>
            </div>
            <div class="align-start" style="margin-bottom: 14px">
              <div class="width115">售后原因:</div>
              <div>
                {{ data.order_refund_reason || '' }}
              </div>
            </div>
            <div class="flex-wrap" style="margin-bottom: 14px">
              <div class="width115">图片:</div>
              <div class="flex-con after">
                <el-image
                  style="
                    width: 200px;
                    min-width: 200px;
                    height: 200px;
                    margin: 0 16px 16px 0;
                  "
                  fit="cover"
                  v-for="(item, index) in data.order_refund_imgs"
                  :key="'order_refund_imgs_' + index"
                  :src="item"
                ></el-image>
              </div>
            </div>
            <div
              class="align-start"
              style="margin-bottom: 14px"
              v-show="data.order_refund_status == 3"
            >
              <div class="width115">拒绝原因:</div>
              <div>
                {{ data.order_refund_remark || '' }}
              </div>
            </div>
            <div
              class="align-start"
              style="margin-bottom: 14px"
              v-show="data.order_refund_status == 2"
            >
              <div class="width115">已同意</div>
            </div>
            <!-- <div class="align-end" style="margin-top: 50px" v-show="data.order_refund_status == 3">
              <div
                class="btn222 align-center pointer"
                @click="fnSq"
                style="margin-left: 20px"
              >
                重新申请
              </div>
            </div> -->
          </div>
        </el-dialog>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import right from '@/components/right.vue'
import {
  myOrderInfo,
  receiptMyOrder,
  onlyPay,
  cancleOrder,
  delMyOrder,
  orderDownload,
} from '../api'
export default {
  components: {
    Header,
    Footer,
    MyInfo,
    right,
  },
  data() {
    return {
      value1: '',
      order_id: '',
      data: {},
      goods: [],
      orderUrl: '',
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible111: false,
      isCancle: false,
      txtCancle: '删除',
    }
  },
  methods: {
    fnShXq() {
      this.dialogVisible2 = true
    },
    initData() {
      let params = {
        order_id: this.order_id,
      }
      myOrderInfo(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.goods = data.goods
        }
      })
    },
    fnWl() {
      this.$router.push({
        name: 'SeeWl',
        params: {
          order_id: this.order_id,
        },
      })
    },
    fnShou() {
      let params = {
        order_id: this.order_id,
      }
      receiptMyOrder(params).then((data) => {
        if (data.status == 200) {
          this.initData()
        }
      })
    },
    fnPay() {
      let params = {
        order_id: this.order_id,
        pay_type: this.data.order_pay_type,
        payFrom: 1,
      }
      onlyPay(params).then((data) => {
        if (data.status == 200) {
          if (this.data.order_pay_type == 1) {
            return
          } else if (this.data.order_pay_type == 2) {
            //支付宝
            window.open(data.msg)
          } else if (this.data.order_pay_type == 4) {
            this.$router.push({
              name: 'Pay',
              params: {
                id: '.html',
                type: this.data.order_pay_type,
                number: data.number,
                price: data.price,
                order_id: this.order_id,
              },
            })
          } else {
            //微信
            this.$router.push({
              name: 'Pay',
              params: {
                id: '.html',
                url: data.msg,
                type: this.data.order_pay_type,
                order_id: this.order_id,
              },
            })
          }
        } else if (data.status == 100) {
          return
        }
      })
    },
    fnCancle() {
      let params = {
        order_id: this.order_id,
      }
      cancleOrder(params).then((data) => {
        if (data.status == 200) {
          this.initData()
        }
      })
    },
    fnCancle() {
      this.txtCancle = '取消'
      this.isCancle = true
      this.dialogVisible111 = true
    },
    fnCun() {
      let params = {
        order_id: this.order_id,
      }
      let delOrCle = delMyOrder
      if (this.isCancle) {
        delOrCle = cancleOrder
      }
      delOrCle(params).then((data) => {
        if (data.status == 200) {
          this.dialogVisible111 = false
          if (!this.isCancle) {
            this.$router.go(-1)
          }
          this.initData()
        }
      })
    },
    fnDelete() {
      this.txtCancle = '删除'
      this.isCancle = false
      this.dialogVisible111 = true
    },
    // fnSq(){
    //   this.dialogVisible2 = false
    //   this.fnSh()
    // },
    fnSh() {
      if (this.data.order_refund_status > 0) {
        this.$message.error('已申请售后,不可再次申请')
        return
      }
      this.$router.push({
        name: 'ApplySh',
        params: {
          id: this.order_id + '-' + this.data.order_number,
          // order_id: this.order_id,
          // order_number: this.data.order_number,
        },
      })
    },
    fnCui() {
      this.$message.success('商家已收到催货通知,请耐心等待~')
    },
    initDownOrder() {
      let params = {
        order_id: this.order_id,
      }
      orderDownload(params).then((data) => {
        if (data.status == 200) {
          // window.location.href = data.msg
          // this.data = data.msg
          this.orderUrl = data.msg2
          window.location.href = this.orderUrl
          // this.dialogVisible = true
        }
      })
    },
    fnBack() {
      this.$router.go(-1)
    },
  },
  mounted() {
    document.title = '本牛'
    let can = this.$route.params.id.split('.')[0]
    this.order_id = can
    this.initData()
  },
}
</script>

<style scoped>
::v-deep .confirm_dialog.el-dialog {
  border-radius: 4px;
}
::v-deep .confirm_dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 42px;
}
::v-deep .confirm_dialog .el-dialog__header {
  padding: 0;
}
::v-deep .confirm_dialog .el-dialog__headerbtn {
  position: absolute;
  top: 22px;
  right: 40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 24px;
}
::v-deep .confirm_dialog .el-dialog__headerbtn .el-dialog__close {
  color: #979797;
}
.kuang11 {
  width: 115px;
  height: 34px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #fa9c22;
  font-size: 16px;
  font-weight: 400;
  color: #fa9c22;
  /* margin: 0 16px; */
  margin-left:16px;
}
.kuang22 {
  width: 115px;
height: 34px;
background: #FA9C22;
border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-left:16px;
}
.order_status div {
  /* margin-top: 6px; */
  text-align: center;
}
.order_status div:first-child {
  margin: 0;
}
.order_header {
  height: 60px;
  padding: 0 26px;
}
.order_box {
  background: #ffffff;
  border-radius: 4px;
}

.my_order {
  margin-bottom: 16px;

  border: 1px solid #eeeeee;
}

.btn_padding {
  padding: 1px 11px;
  margin-left: 10px;
}
.ljzf {
  padding: 1px 11px;
  border: 1px solid #c52b33;
  display: inline-block;
}
.C52B33 {
  color: #c52b33;
}

.order_step {
  margin: auto;
  width: 41px;
  height: 41px;
  font-weight: 500;
  color: #aaaaaa;
  background: #ffffff;
  border: 2px solid #d5d5d5;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.order_step.active {
  color: #ffffff;
  background: #fa9c22;
  border: none;
}
.step_xian {
  width: 100%;
  height: 7px;
  background: #d5d5d5;
  position: absolute;
  top: 17px;
  left: -50%;
  /* border-bottom: 7px solid #d5d5d5; */
}
.step_xian.active {
  background: #fa9c22;
}
.step_out {
  position: relative;
  width: 100%;
}

.bg_fff {
  background: #fff;
  border-radius: 2px;
  margin-left: 15px;
  width: 115px;
  height: 34px;
}
.bg_f08300 {
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  margin-left: 15px;
  color: #fff;
  width: 115px;
  height: 34px;
}

.width115 {
  width: 115px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;
}
.btn2 {
  width: 115px;
  height: 32px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  margin-left: 16px;
}
.btn1 {
  color: #777777;
  background: none;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
}
.topppImg {
  width: 18px;
  height: 18px;
  /* margin-right: 4px; */
}
</style>

import { render, staticRenderFns } from "./SureOrder.vue?vue&type=template&id=45a3360b&scoped=true&"
import script from "./SureOrder.vue?vue&type=script&lang=js&"
export * from "./SureOrder.vue?vue&type=script&lang=js&"
import style0 from "./SureOrder.vue?vue&type=style&index=0&id=45a3360b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a3360b",
  null
  
)

export default component.exports
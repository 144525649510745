<template>
  <div>
    <Header :num111="5" />
    <div class="middle1 flex-wrap" style="margin-top: 14px">
      <div class="my_left">
        <div class="my_zx font-fff-20-bold align-center">内容资讯</div>
        <div class="my_menu">
          <div
            v-for="(item, index1) in cate"
            :key="'cate_' + index1"
            @click="fnCate(index1, item.ic_id)"
            class="align-start order1 pointer"
            :class="{ order1_active: index1 == index }"
          >
            {{ item.ic_name || '' }}
          </div>
          <div
            @click="fnCate('active')"
            class="align-start order1 pointer"
            :class="{ order1_active: index == 'active' }"
          >
            本牛活动
          </div>
        </div>
      </div>
      <div class="my_right flex-con">
        <div
          class="align-between"
          style="
            background: rgba(255, 226, 189, 0.2);
            height: 46px;
            padding: 0 12px 0 22px;
            margin-bottom:10px;
          "
        >
          <div class="font-333-20-bold">
            {{ index == 'active' ? '本牛活动' : cate[index].ic_name }}
          </div>
        </div>
        <div style="padding-left: 15px">
          <div
            class="zx flex-wrap pointer"
            @click="fnXq(item.activity_id)"
            v-for="(item, index2) in data1"
            :key="'data1_' + item.activity_id + '_' + index2"
            v-if="index == 'active'"
          >
          <div class="img_out">
            <el-image
              :src="item.activity_thumb"
              fit="cover"
              class="left_img"
            ></el-image>
          </div>
            
            <div class="flex-con">
              <div class="left_title aui-ellipsis-1">
                {{ item.activity_title || '' }}
              </div>
              <div class="left_info aui-ellipsis-2">
                {{ item.activity_intro || '' }}
              </div>
              <div class="time">{{ item.activity_time || '' }}</div>
            </div>
          </div>
        </div>
        <div style="padding-left:15px;">
        <div
          class="zx flex-wrap pointer"
          @click="fnXq(item.info_id)"
          v-for="(item, index3) in data"
          :key="'data_' + item.info_id + '_' + index3"
          v-if="index != 'active'"
        >
        <div class="img_out">
          <el-image
            :src="item.info_thumb"
            fit="cover"
            class="left_img"
          ></el-image>
          </div>
          
          <div class="flex-con">
            <div class="left_title aui-ellipsis-1">
              {{ item.info_title || '' }}
            </div>
            <div class="left_info aui-ellipsis-2">
              {{ item.info_intro || '' }}
            </div>
            <div class="time">{{ item.info_time || '' }}</div>
          </div>
        </div>
      </div>
        <div
          class="font-777"
          style="text-align: -webkit-right; margin-top: 20px"
        >
          <Page
            :limit="limit"
            :totalNum="total"
            @onLimit="changeLimit"
            @onPage="changePage"
          />
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'
import {
  limit,
  allActivityList,
  informationList,
  oneInfoList,
  tdkPages,
} from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    right,
  },
  data() {
    return {
      data: [],
      data1: [], //活动
      cate: [],
      page: 1,
      limit: limit,
      total: 0,
      index: '',
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    fnGetTdk(type) {
      let params = {
        type: type,
      }
      tdkPages(params).then((data) => {
        if (data.status == 200) {
          this.tdkTitle = data.msg.title || '本牛'
          this.tdkKeywords = data.msg.keyword || '本牛'
          this.tdkDescription = data.msg.desc || '本牛'
        }
      })
    },
    initCate() {
      let params = {}
      informationList(params).then((data) => {
        if (data.status == 200) {
          this.cate = data.msg
          if (this.index == 'active') {
            this.initActive()
          } else {
            this.page = 1
            this.initCateList(this.cate[this.index].ic_id)
          }
        }
      })
    },
    initActive() {
      let params = {
        page: this.page,
      }
      allActivityList(params).then((data) => {
        if (data.status == 200) {
          this.data1 = data.msg
          this.total = data.count
        }
      })
    },
    fnCate(index, ic_id) {
      if (index == this.index) {
        return
      } else {
        this.index = index
        this.page = 1
        if (this.index == 'active') {
          this.$router.push({
            name: 'activity',
            params: {
              id: '.html',
            },
          })
          this.initActive()
        } else {
          this.$router.push({
            name: 'news',
            params: {
              id: '.html',
            },
          })
          this.initCateList(ic_id)
        }
      }
    },
    initCateList(ic_id) {
      let params = {
        cate_id: ic_id,
        page: this.page,
      }
      oneInfoList(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count
        }
      })
    },
    fnXq(idd) {
      if (this.index == 'active') {
        this.toZx3(idd)
        // this.$router.push({
        //   name: 'zixun3',
        //   params: {
        //     id: idd + '.html',
        //   },
        // })
      } else {
        this.toZx(idd)
        // this.$router.push({
        //   name: 'zixun2',
        //   params: {
        //     // cate_id: idd,
        //     id: idd + '.html',
        //   },
        // })
      }
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      this.initData()
    },
  },
  mounted() {
    let can = this.$route.name == 'news' ? 0 : 'active'
    this.index = can
    this.initCate()
    this.fnGetTdk(6)
  },
}
</script>
<style scoped>
.order1 {
  height: 48px;
  font-size: 18px;
  color: #666;
  font-weight: 500;
  border-left: 4px solid #fff;
  padding-left: 86px;
}
.order1_active {
  border-left: 4px solid #ff940a;
  color: #ff940a;
  background-color: rgba(255, 226, 189, 0.2);
}
.order1:hover {
  border-left: 4px solid #ff940a;
  color: #ff940a;
  background-color: rgba(255, 226, 189, 0.2);
}
.my_zx {
  height: 63px;

  background: #fa9c22;
  margin-bottom: 20px;
}
.my_left {
  width: 256px;
  min-width: 256px;
  background: #ffffff;
  text-align: left;
  margin-right: 16px;
  /* padding-bottom: 20px; */
}
.toppp {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  margin-top: 20px;
  align-items: center;
}

.topppImg {
  width: 20px;
  height: 20px;
  margin: 0 4px;
}

.box {
  /* height: 624px; */
  margin-top: 20px;
}

.left {
  width: 230px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  text-align: center;
  margin-right: 10px;
}

.all {
  align-items: center;
  height: 57px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 21px;
}

.allImg {
  width: 20px;
  height: 20px;
  margin-left: -27px;
  margin-right: 7px;
}

.label {
  margin-bottom: 10px;
}

.label_active {
  color: #f08300;
}

.right {
  min-height: 624px;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  /* overflow-y: scroll; */
}

.rightTit {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 21px;
}

.zx {
  align-items: center;
  padding: 30px 50px 30px 15px;
  border-bottom: 1px solid #eee;
  transition: all 0.5s;
}
.zx:hover{
box-shadow: 0px 2px 9px 0px rgba(102,102,102,0.27);
}

.left_img {
  width: 242px;
  height: 118px;
  transition: all 0.5s;
}
.img_out {
  overflow: hidden;
  
  margin-right: 40px;
}
.zx:hover .img_out .left_img {
  scale: 1.2;
}

.left_title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}

.left_info {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  line-height: 26px;
  height: 52px;
  margin: 16px 0;
}

.time {
  font-size: 16px;
  font-weight: 400;
  color: #999;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',{staticClass:"middle1 align-start-top",staticStyle:{"margin-top":"15px"}},[_c('div',{staticClass:"my_left"},[_c('div',{staticClass:"my_zx font-fff-20-bold align-center"},[_vm._v("帮助中心")]),_c('div',{staticClass:"my_menu"},_vm._l((_vm.helpList),function(item,index){return _c('div',{key:'help_' + index},[_c('div',{staticClass:"align-between order1 pointer",class:{ order1_active: !item.choose },on:{"click":function($event){return _vm.fnSwitch(item)},"mouseenter":function($event){return _vm.mouseenterOut(item)},"mouseleave":function($event){return _vm.mouseleaveOut(item)}}},[_c('div',{staticClass:"align-start"},[_c('el-image',{staticStyle:{"width":"26px","height":"26px","margin":"0 22px 0 26px"},attrs:{"src":!item.choose || item.hover
                  ? item.pt_icon_yes
                  : item.pt_icon_no,"fit":"cover"}}),_c('div',{},[_vm._v(_vm._s(item.pt_name || ''))])],1),_c('el-image',{style:(!item.choose
                  ? 'width: 11px; height: 7px;'
                  : 'width: 7px; height: 11px;'),attrs:{"src":!item.choose
                  ? require('@/assets/bottom_arrow2.png')
                  : require('@/assets/arrow_right3.png'),"fit":"cover"}})],1),(!item.choose)?_c('div',{staticClass:"pointer",staticStyle:{"padding-left":"79px"}},_vm._l((item.son),function(item,index){return _c('div',{key:'problem_' + index,staticClass:"order1_item font-666-18 align-start",class:{ FF940A: _vm.problem_id == item.problem_id },on:{"click":function($event){return _vm.fnXq(item.problem_id)}}},[_vm._v(" "+_vm._s(item.problem_title || '')+" ")])}),0):_vm._e()])}),0)]),_c('div',{staticClass:"my_right"},[_c('div',{staticClass:"help_head"},[_vm._v(_vm._s(_vm.helpObj.problem_title||''))]),_c('div',{staticClass:"fwb",domProps:{"innerHTML":_vm._s(_vm.helpObj.problem_content)}})])]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
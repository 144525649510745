<template>
  <div class="container">
    <div class="w1200 flex-wrap test-border-line" style="padding: 40px">
      <div class="flex-con">
        <div class="font-333-30-bold m_b20">COMPANY VISION</div>
        <div class="font-333-16-bold letter1">
          REGARDING THE FUTURE DEVELOPMENT OF THE HONNIU PLATFORM AND
          EXPECTATIONS FOR THE COMPANY.REGARDING THE FUTURE DEVELOPMENT
          OF THE HONNIU PLATFORM AND EXPECTATIONS FOR THE COMPANY
        </div>
      </div>
      <div class="xian"></div>
      <div style="padding-top: 12px;">
        <div class="align-start" style="margin-bottom: 30px">
          <el-image
            style="width: 40px; height: 40px; margin-right: 20px"
            :src="require('@/assets/en/icon2.png')"
            fit="cover"
          ></el-image>
          <span style="text-decoration: underLine">400-025-9025</span>
        </div>
        <div class="align-start">
          <el-image
            style="width: 40px; height: 40px; margin-right: 20px"
            :src="require('@/assets/en/icon3.png')"
            fit="cover"
          ></el-image>
          <span>kefu@niutop.com</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.container {
  background: #e5e5e5;
}
.xian {
  width: 1px;
  height: 144px;
  background: #333333;
  margin: 0 157px 0 50px;
}
</style>

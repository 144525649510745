<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top: 14px">
      <div>
        <MyInfo :num111="10" />
      </div>

      <div class="my_right flex-con">
        <div class="border_eee">
          <div class="">
            <div class="align-between cheng_head11" style="padding-right: 12px">
              <div class="font-333-20-bold">发票抬头</div>
              <div
                class="tag pointer align-center"
                @click="fnOpen(1)"
              >
                新增发票
              </div>
            </div>
            <div
              class="flex-wrap font-333-16-bold"
              style="line-height: 56px;padding:0 22px;border-bottom:1px solid #EEEEEE;"
            >
              <div style="min-width: 260px">发票抬头</div>
              <div style="width: 188px;text-align: center;">采购性质</div>
              <div style="width: 210px;text-align: center;">发票类型</div>
              <div class="flex-con"></div>
              <div style="width: 227px;text-align: right;padding-right:38px;">操作</div>
            </div>
          </div>
          <div class="my_order" style="margin: 0">
            <div
              class="flex-wrap font-333-16"
              style="margin-bottom: 24px"
              v-for="(item, index) in data"
              :key="'fapiao_' + index"
            >
              <div class="align-start font-333-16" style="min-width: 260px">
                {{ item.fp_header || '' }}
              </div>
              <div class="align-center font-666-16" style="width: 188px">
                {{ item.fp_user_type == 1 ? '个人' : '企业' }}
              </div>
              <div class="align-center font-666-16" style="width:210px">
                {{ item.fp_type == 1 ? '普通发票' : '增值税发票' }}
              </div>
              <div class="flex-con"></div>
              <div style="width: 227px" class="align-center">
                <div
                  class="tag align-center"
                  v-if="item.fp_status == 2"
                >
                  默认收票
                </div>
                <div v-else style="width:104px;"></div>
                <div
                  style="margin: 0 12px;color: #6eaaf9;"
                  @click.stop="fnOpen(2, index)"
                  class="pointer font-999-16"
                >
                  修改
                </div>
                <div
                  @click.stop="fnDelete(item.fp_id)"
                  class="pointer font-999-16"
                  v-if="!from"
                >
                  删除
                </div>
                <div @click.stop="select(item)" class="pointer font-999-16 cor_FA9C22" v-else>
                  选择
                </div>
              </div>
            </div>
            <div
              style="text-align: center; margin: 160px auto"
              v-show="data.length == 0"
            >
              <el-image
                style="width: 160px; height: 160px; margin-bottom: 16px"
                :src="require('@/assets/quesheng/no_content.png')"
                fit="cover"
              ></el-image>
              <div class="font-666-14">暂无内容~</div>
            </div>
          </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="980px" custom-class="fp_dialog">
          <div class="dialog">
            <div class="dialog_title align-start">新增/修改发票</div>
            <div class="align-start font-333-16-bold" style="margin-bottom: 36px;margin-top: 36px;">
              <div class="width115"><span class="cor_FA9C22">*</span>发票类型:</div>
              <div
                class="tab1 align-center pointer"
                :class="{ active: obj.fp_type == 2 }"
                @click="fnSet(2)"
              >
                增值税发票
              </div>
              <div
                class="tab1 align-center pointer"
                :class="{ active: obj.fp_type == 1 }"
                @click="fnSet(1)"
              >
                普通发票
              </div>
            </div>
            <div
              class="align-start font-333-16-bold"
              style="margin-bottom: 36px"
              v-show="obj.fp_type == 1"
            >
              <div class="width115"><span class="cor_FA9C22">*</span>采购性质:</div>
              <div class="align-start">
                <input
                  type="radio"
                  name="fp_user_type"
                  value="2"
                  v-model="obj.radio1"
                  data-idd="2"
                />
                <div class="font-333-16">企业</div>
              </div>
              <div class="align-start" style="margin-left: 16px">
                <input
                  type="radio"
                  name="fp_user_type"
                  value="1"
                  v-model="obj.radio1"
                  data-idd="1"
                />
                <div class="font-333-16">个人</div>
              </div>
            </div>
            <div class="align-start font-333-16-bold" style="margin-bottom: 36px">
              <div class="width115"><span class="cor_FA9C22">*</span>发票抬头:</div>
              <input
                type="text"
                placeholder="请填写发票抬头"
                v-model="obj.fp_header"
              />
            </div>

            <div
              class="align-start font-333-16-bold"
              style="margin-bottom: 36px"
              v-show="obj.fp_type == 2 || (obj.fp_type == 1 && obj.radio1 == 2)"
            >
              <div class="width115">
                <span class="cor_FA9C22">*</span>纳税人识别号:
              </div>
              <input
                type="text"
                name=""
                value=""
                placeholder="请填写"
                v-model="obj.fp_number"
              />
            </div>
            <div
              class="align-start font-333-16-bold"
              style="margin-bottom: 36px"
              v-show="obj.fp_type == 1"
            >
              <div class="width115"><span class="cor_FA9C22">*</span>电子邮箱:</div>
              <input
                type="text"
                name=""
                value=""
                placeholder="请填写电子邮箱"
                v-model="obj.fp_email1"
              />
            </div>
            <div v-show="obj.fp_type == 2">
              <div class="align-start font-333-16-bold" style="margin-bottom: 36px">
                <div class="width115">
                  <span class="cor_FA9C22">*</span>注册地址:
                </div>
                <input
                  type="text"
                  placeholder="请填写注册地址"
                  v-model="obj.fp_address"
                />
              </div>
              <div class="align-start font-333-16-bold" style="margin-bottom: 36px">
                <div class="width115">
                  <span class="cor_FA9C22">*</span>注册电话:
                </div>
                <input
                  type="text"
                  name=""
                  value=""
                  placeholder="请填写注册电话"
                  v-model="obj.fp_phone"
                />
              </div>
              <div class="align-start font-333-16-bold" style="margin-bottom: 36px">
                <div class="width115">
                  <span class="cor_FA9C22">*</span>开户银行:
                </div>
                <input
                  type="text"
                  placeholder="请填写开户银行"
                  v-model="obj.fp_bank_name"
                />
              </div>
              <div class="align-start font-333-16-bold" style="margin-bottom: 36px">
                <div class="width115">
                  <span class="cor_FA9C22">*</span>银行账号:
                </div>
                <input
                  type="text"
                  name=""
                  value=""
                  placeholder="请填写银行账号"
                  v-model="obj.fp_bank_account"
                />
              </div>
              <div class="align-start font-333-16-bold" style="margin-bottom: 36px">
                <div class="width115">
                  <span class="cor_FA9C22">*</span>电子邮箱:
                </div>
                <input
                  type="text"
                  name=""
                  value=""
                  placeholder="请填写电子邮箱"
                  v-model="obj.fp_email2"
                />
              </div>
            </div>
            <div class="align-start font-333-16-bold" style="margin-bottom: 36px">
              <div class="width115">
                <span style="color: rgba(0, 0, 0, 0)">*</span>是否为默认:
              </div>
              <div class="align-start">
                <input
                  type="checkbox"
                  name="fp_status"
                  value="2"
                  v-model="obj.checkbox1"
                />
                <div class="font-333-16">设为默认</div>
              </div>
            </div>
            <div class="align-center" style="margin-top: 45px">
              <div
                class="dialog_btn2 align-center pointer"
                @click="dialogVisible = false"
              >
                取消
              </div>
              <div class="dialog_btn1 align-center pointer" @click="addFp">
                保存
              </div>
            </div>
          </div>
        </el-dialog>
        <el-dialog
          :visible.sync="dialogVisible111"
          width="980px"
          custom-class="confirm_dialog"
        >
          <div class="dialog" style="padding: 0">
            <div class="dialog_title align-start"> <img :src="require('@/assets/tip_delete.png')" alt="" style="width:26px;height:26px;margin-right:18px;">删除发票抬头</div>
            <div class="font-333-18-bold" style="text-align: center;margin-top: 36px;">
              确认删除吗？
            </div>
            <div class="align-center" style="margin-top: 36px">
              <div
                class="dialog_btn2 align-center pointer"
                @click="dialogVisible111 = false"
              >
                取消
              </div>
              <div class="dialog_btn1 align-center pointer" @click="fnCun">确认</div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import right from '@/components/right.vue'
import { insertFaPiao, faPiaoList, faPiaoUpdate, delFP } from '../api'
export default {
  components: {
    Header,
    Footer,
    MyInfo,
    right,
  },
  data() { 
    return {
      dialogVisible: false,
      dialogVisible111: false,
      iddDelete: '',
      type: 1,
      data: [],
      obj: {
        fp_type: '',
        fp_header: '',
        fp_number: '',
        fp_address: '',
        fp_phone: '',
        fp_bank_name: '',
        fp_bank_account: '',
        fp_email1: '',
        fp_email2: '',
        fp_status: '',
        radio1: false,
        checkbox1: false,
      },
      from: this.$route.params.from,
    }
  },
  methods: {
    initData() {
      let params = {}
      faPiaoList(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
        }
      })
    },
    addFp() {
      this.initData()
      if (!this.obj.fp_type) {
        this.$message.error('请选择发票类型')
        return
      }
      // let allCk2 = document.querySelectorAll("input[type='radio']:checked")
      if (this.obj.fp_type == 1) {
        if (!this.obj.radio1) {
          this.$message.error('请选择采购性质')
          return
        }
      }
      if (!this.obj.fp_header) {
        this.$message.error('请输入发票抬头')
        return
      }
      if (
        this.obj.fp_type == 2 ||
        (this.obj.fp_type == 1 && this.obj.radio1 == 2)
      ) {
        if (!this.obj.fp_number) {
          this.$message.error('请输入纳税人识别号')
          return
        }
      }
      if (this.obj.fp_type == 1) {
        if (!this.obj.fp_email1) {
          this.$message.error('请输入电子邮箱')
          return
        }
      }
      if (this.obj.fp_type == 2) {
        if (!this.obj.fp_address) {
          this.$message.error('请输入注册地址')
          return
        }
        if (!this.obj.fp_phone) {
          this.$message.error('请输入注册电话')
          return
        }
        if (!this.obj.fp_bank_name) {
          this.$message.error('请输入开户银行')
          return
        }
        if (!this.obj.fp_bank_account) {
          this.$message.error('请输入银行账号')
          return
        }
        if (!this.obj.fp_email2) {
          this.$message.error('请输入电子邮箱')
          return
        }
      }

      this.obj.fp_user_type = this.obj.radio1 || 0
      this.obj.fp_status = this.obj.checkbox1 ? 2 : 1
      let params = {
        fp_type: this.obj.fp_type,
        fp_user_type: this.obj.fp_user_type,
        fp_header: this.obj.fp_header,
        fp_number: this.obj.fp_number,
        fp_address: this.obj.fp_address,
        fp_phone: this.obj.fp_phone,
        fp_bank_name: this.obj.fp_bank_name,
        fp_bank_account: this.obj.fp_bank_account,
        fp_email: this.obj.fp_email1,
        fp_status: this.obj.fp_status,
      }
      if (this.type == 1) {
        insertFaPiao(params).then((data) => {
          if (data.status == 200) {
            this.dialogVisible = false
            this.initData()
          }
        })
      } else {
        params.fp_id = this.obj.fp_id
        params.fp_email = this.obj.fp_email2
        faPiaoUpdate(params).then((data) => {
          if (data.status == 200) {
            this.dialogVisible = false
            this.initData()
          }
        })
      }
    },
    fnOpen(type, index) {
      this.initData()
      this.type = type
      this.dialogVisible = true
      if (type == 2) {
        this.obj = this.data[index]
        this.obj.radio1 = this.obj.fp_user_type ? this.obj.fp_user_type : false
        this.obj.checkbox1 = this.obj.fp_status == 2 ? true : false
        if (this.obj.fp_type == 1) {
          this.obj.fp_email1 = this.obj.fp_email || ''
        } else {
          this.obj.fp_email2 = this.obj.fp_email || ''
        }
      } else {
        for (let key in this.obj) {
          this.obj[key] = ''
        }
        this.obj.radio1 = false
        this.obj.checkbox1 = false
      }
    },
    fnCun() {
      let params = {
        fp_id: this.iddDelete,
      }
      delFP(params).then((data) => {
        if (data.status == 200) {
          this.dialogVisible111 = false
          this.initData()
        }
      })
    },
    fnDelete(idd) {
      this.dialogVisible111 = true
      this.iddDelete = idd
    },
    fnSet(type) {
      this.obj.fp_type = type
    },
    fnAddFp() {
      this.$router.push({
        name: 'AddFp',
        params: {},
      })
    },
    select(item) {
      if (this.$route.params.from) {
        this.$bus.$emit(
          'addressObj',
          item,
          this.$route.params.from,
          this.$route.params.fapiao_type
        )
        this.$router.go(-1)
      }
    },
  },
  mounted() {
    document.title = '本牛'
    this.initData()
  },
}
</script>

<style scoped>

::v-deep .fp_dialog.el-dialog {
  border-radius: 4px;
}
::v-deep .fp_dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 42px;
}
::v-deep .fp_dialog .el-dialog__header {
  padding: 0;
}
::v-deep .fp_dialog .el-dialog__headerbtn {
  position: absolute;
  top: 22px;
  right: 40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 24px;
}
::v-deep .fp_dialog .el-dialog__headerbtn .el-dialog__close {
  color: #979797;
}
::v-deep .confirm_dialog.el-dialog {
  border-radius: 4px;
}
::v-deep .confirm_dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 42px;
}
::v-deep .confirm_dialog .el-dialog__header {
  padding: 0;
}
::v-deep .confirm_dialog .el-dialog__headerbtn {
  position: absolute;
  top: 22px;
  right: 40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 24px;
}
::v-deep .confirm_dialog .el-dialog__headerbtn .el-dialog__close {
  color: #979797;
}
.my_order {
  padding: 22px 22px 0;
}
.btn_padding {
  width: 103px;
  height: 27px;
  border: 1px solid #d8d8d8;
}
.tag {
  width: 103px;
  height: 27px;
  border: 1px solid #FA9C22;
  /* margin-left: 12px; */
  color: #FA9C22;
  font-size: 16px;
}
.tab1 {
  height: 36px;
  border-radius: 1px;
  border: 1px solid #d8d8d8;
  padding: 0 12px;
  margin: 0 19px 0 0;
  color:#999;
  box-sizing: border-box;
  width:364px;
}

.tab1.active {
  color: #ffffff;
  background: #f08300;
border: 1px solid #FA9C22;
  /* background: linear-gradient(90deg, #efc845 0%, #dca01e 100%); */
}

input[type='text'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 36px;
  padding: 0 12px;
  color: #333333;
  font-size: 16px;
  outline: none;
  font-weight: normal;
  width:748px;
}
input[type='text']::-webkit-input-placeholder {
  color: #999;
}
input[type='text']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
}


/* input[type='text'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 24px;
  width: 490px;
  padding: 0 12px;
  color: #333333;
  font-size: 12px;
  outline: none;
}
input[type='text']::-webkit-input-placeholder {
  color: #aaa;
}
input[type='text']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
} */
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  background: url('@/assets/check2.png') no-repeat center center;
  background-size: 100% 100%;
}
input[type='checkbox']:checked {
  background: url('@/assets/checked21.png') no-repeat center center;
  background-size: 100% 100%;
}
input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #d8d8d8;
  margin: 0 15px 0 0;
}
input[type='radio']:checked {
  border: 1px solid #333;
  position: relative;
}
input[type='radio']:checked:after {
  position: absolute;
  width: 13px;
  height: 8px;
  top: 50%;
  left: 50%;
  margin-left: -7px;
  margin-top: -7px;
  content: '';
  color: #000;
  display: inline-block;
  visibility: visible;
  border: 2px solid #333;
  border-top: none;
  border-right: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.btn2 {
  width: 115px;
  height: 32px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  margin-left: 16px;
}
.btn1 {
  color: #777777;
  background: none;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
}
/* .dialog {
  padding: 0 107px 20px 90px;
} */
.width115 {
  width: 162px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;
}
</style>

<template>
  <div class="fixright">
    <img
      :src="require('@/assets/kefu.png')"
      alt=""
      style="width: 77px; height: 97px; display: block; margin: 0 auto 9px"
    />
    <div
      class="fixOne"
      v-for="(item, index) in data"
      :key="'first_' + index"
      @click="fnClick(index)"
      @mouseenter="mouseenterOut(index)"
      @mouseleave="mouseleaveOut()"
      :style="
        index == 0
          ? 'border-radius:10px 10px 0px 0px '
          : index == 5
          ? 'margin-top:9px'
          : ''
      "
      :class="{ pointer: index != 2 }"
      :id="item.idd"
    >
      <img
        :src="showHover && indexHover == index ? item.img1 : item.img2"
        alt=""
      />
      <div>{{ item.title }}</div>
      <div class="phone font-fff-24-bold align-center" v-show="index == 2">
        <img :src="data[2].img1" alt="" style="margin-bottom: 0" />
        <span
          style="
            width: 2px;
            height: 33px;
            background: rgba(255, 255, 255, 0.57);
            margin: 0 5px;
          "
        ></span>
        <div class="aui-ellipsis-1">{{ phoneHeader }}</div>
      </div>
    </div>
    <!-- <div class="keFu" id="keFu" v-if="showKF">
      <div class="align-end" style="padding-right: 15px; padding-top: 10px">
        <img
          :src="require('@/assets/close.png')"
          alt=""
          style="width: 30px"
          @click="showKF = false"
          class="pointer"
        />
      </div>
      <iframe style="width: 400px; height: 500px"></iframe>
      
    </div> -->
    <!-- src="https://1800498.s4.udesk.cn/im_client/?web_plugin_id=63975" -->
  </div>
</template>

<script>
import { kefuOnline, getHelps } from '../api'
export default {
  data() {
    return {
      phoneHeader: localStorage.getItem('phoneHeader') || '',
      isMain: true,
      showKF: false,
      data: [
        {
          title: '专属客服',
          img1: require('@/assets/fixed_right11.png'),
          img2: require('@/assets/fixed_right1.png'),
          idd: 'customer',
        },
        {
          title: '购物车',
          img1: require('@/assets/fixed_right22.png'),
          img2: require('@/assets/fixed_right2.png'),
        },
        {
          title: '联系我们',
          img1: require('@/assets/fixed_right33.png'),
          img2: require('@/assets/fixed_right3.png'),
        },
        {
          title: '我的',
          img1: require('@/assets/fixed_right44.png'),
          img2: require('@/assets/fixed_right4.png'),
        },
        {
          title: '帮助中心',
          img1: require('@/assets/fixed_right55.png'),
          img2: require('@/assets/fixed_right5.png'),
        },
        {
          title: '返回顶部',
          img1: require('@/assets/fixed_right66.png'),
          img2: require('@/assets/fixed_right6.png'),
        },
      ],
      indexHover: '',
      showHover: false,
      first_id: '',
      timer60: '',
    }
  },
  methods: {
    fnClick(index) {
      if (index == 0) {
      } else if (index == 1) {
        this.fnCar()
      } else if (index == 3) {
        this.fnGrzx()
      } else if (index == 4) {
        this.getHelpId()
      } else if (index == 5) {
        this.toTop()
      }
    },
    fnGrzx() {
      if (localStorage.getItem('allUser')) {
        this.$router.push({
          name: 'OrderZx',
          params: {
            id: 0 + '.html',
          },
        })
      } else {
        this.$bus.$emit('login', 1)
      }
    },
    fnCar() {
      if (localStorage.getItem('allUser')) {
        this.$router.push({
          name: 'Car',
          params: {
            id: '.html',
          },
        })
      } else {
        this.$bus.$emit('login', 1)
      }
    },
    toTop() {
      let intervalId = setInterval(function () {
        window.scrollBy(0, -60)
        if (window.pageYOffset == 0) {
          clearInterval(intervalId)
        }
      }, 10)
      // document.documentElement.scrollTop = 0
    },

    mouseenterOut(index) {
      this.showHover = true
      this.indexHover = index
    },
    mouseleaveOut() {
      this.showHover = false
    },
    initData1() {
      let params = {}
      kefuOnline(params).then((data) => {
        if (data.status == 200) {
          document.getElementById('customer').click()
        }
      })
    },
    getHelpId() {
      let params = {}
      getHelps(params).then((data) => {
        if (data.status == 200) {
          this.first_id = data.first_id
          this.$router.push({
            name: 'help',
            params: {
              id: this.first_id + '.html',
            },
          })
        }
      })
    },
  },
  mounted() {
    this.isMain = this.$router.history.current.name == 'Main' ? true : false
    let _this = this
    this.$bus.$on('goodsKf', (val) => {
      if (val == 60) {
        clearTimeout(_this.timer60)
        _this.timer60 = setTimeout(() => {
          this.initData1()
        }, 60000)
      } else {
        document.getElementById('customer').click()
      }
    })
  },
}
</script>
<style scoped>
.phone {
  width: 86px;
  background-color: #e78b37;
  position: absolute;
  top: 0;
  left: 0;
  height: 70px;
  text-align: center;
  line-height: 70px;
  border-radius: 10px 0px 0px 10px;
  transition: all 0.3s;
  z-index: -1;
}

.fixOne:hover .phone {
  position: absolute;
  left: -235px;
  width: 234px;
}

.fixright {
  position: fixed;
  /* top: 300px; */
  bottom: 10px;
  /* right: 25px; */
  width: 86px;
  z-index: 999;
  right: calc(((100% - 1300px) / 2 - 86px) / 2);
}
.fixOne {
  height: 70px;
  position: relative;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  line-height: 17px;
  text-align: center;
  background: #ffffff;
  border-bottom: 2px solid #ededed;
  transition: background 0.3s;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding-top: 6px;
}

.fixOne:last-child {
  border-radius: 0px 0px 10px 10px;
  border-bottom: none;
}
.fixOne:nth-child(4) {
  /* border-bottom: none; */
}
.fixOne img {
  width: 32px;
  height: 32px;
  margin-bottom: 3px;
}
.fixOne:hover {
  background: #f08300;
  color: #ffffff;
}

.keFu {
  border-radius: 4px;
  background: #5d7cb6;
  width: 400.3px;
  height: 540px;
  position: fixed;
  bottom: 0;
  right: 88px;
  z-index: 2;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
</style>

<template>
  <div>
    <Header :carName222="carName222" />
    <div class="middle1">
      <div class="toppp flex-wrap">
        <div class="pointer font-333-16-16" @click="toMain()">首页</div>
        <div
          class="align-center pointer"
          v-if="cateName.first_cate_id"
          @click="fnSearch(1)"
        >
          <el-image
            style="width: 7px; height: 11px; margin: 0 8px"
            :src="require('@/assets/arrow_right3.png')"
            fit="cover"
          ></el-image>
          <div class="topLabel font-333-16-16">
            {{ cateName.first_cate_name }}
          </div>
        </div>
        <div
          class="align-center pointer"
          v-if="cateName.second_cate_id"
          @click="fnSearch(2)"
        >
          <el-image
            style="width: 7px; height: 11px; margin: 0 8px"
            :src="require('@/assets/arrow_right3.png')"
            fit="cover"
          ></el-image>
          <div class="topLabel font-333-16-16">
            {{ cateName.second_cate_name }}
          </div>
        </div>
        <div
          class="align-center pointer"
          v-if="cateName.third_cate_id"
          @click="fnSearch(3)"
        >
          <el-image
            style="width: 7px; height: 11px; margin: 0 8px"
            :src="require('@/assets/arrow_right3.png')"
            fit="cover"
          ></el-image>
          <div class="topLabel font-333-16-16">
            {{ cateName.third_cate_name }}
          </div>
        </div>
        <div class="align-center" v-if="cateName.goods_name">
          <el-image
            style="width: 7px; height: 11px; margin: 0 8px"
            :src="require('@/assets/arrow_right3.png')"
            fit="cover"
          ></el-image>
          <div class="topLabel font-333-16-16">{{ cateName.goods_name }}</div>
        </div>
      </div>
      <div class="card11 flex-wrap">
        <div class="left" style="position: relative">
          <div v-if="index > 0 || data.goods.haveVideo == 1" class="bigDiv">
            <img
              :src="goods_images[index]"
              alt=""
              style="width: 960px; height: 960px"
            />
          </div>
          <div class="swiper-container gallery-top" style="height: 320px">
            <div class="swiper-wrapper swiper-wrapper1">
              <div
                class="swiper-slide swiper-no-swiping"
                v-for="(item, index1) in goods_images"
                :key="'goods_images1_' + index1"
              >
                <div
                  v-if="index1 == 0 && data.goods.haveVideo == 2"
                  style="position: relative; height: 320px"
                >
                  <video
                    id="video111"
                    autoplay
                    controls
                    @play="videoIsPlay = true"
                    @pause="fnPause"
                    style="width: 100%; height: 100%; object-fit: contain"
                    :src="item"
                    preload
                  ></video>
                  <img
                    v-show="
                      !videoIsPlay && index1 == 0 && data.goods.haveVideo == 2
                    "
                    :src="require('@/assets/play2.png')"
                    alt=""
                    class="img222 pointer"
                    @click="changeVideoStatus()"
                  />
                </div>

                <div
                  v-else
                  :id="'smDiv' + index1"
                  style="position: relative; height: 320px"
                >
                  <img :src="item" />
                  <div class="zoom" :id="'zoom' + index1"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-container gallery-thumbs">
            <div
              class="swiper-wrapper"
              style="padding: 0 20px; box-sizing: border-box"
            >
              <div
                class="swiper-slide"
                v-for="(item, index2) in goods_images"
                :key="'goods_images2_' + index2"
              >
                <!-- v-for="(item, index) in attr_id ? guigeImg : goods_images" -->
                <div
                  v-if="index2 == 0 && data.goods.haveVideo == 2"
                  style="height: 68px; width: 68px; position: relative"
                  class="pointer kuang1"
                >
                  <img
                    :src="
                      item +
                      '?x-oss-process=video/snapshot,t_1000,f_jpg,w_0,h_0,m_fast,ar_auto'
                    "
                  />
                  <img
                    :src="require('@/assets/play1.png')"
                    alt=""
                    class="img111"
                  />
                </div>
                <img class="pointer kuang1" v-else :src="item" />
              </div>
            </div>
            <div
              class="swiper-button-next swiper-button-white"
              style="top: 48px"
            ></div>
            <div
              class="swiper-button-prev swiper-button-white"
              style="top: 48px"
            ></div>
          </div>
        </div>
        <div class="flex-con" style="position: relative">
          <div class="shou11 flex-wrap">
            <div class="align-center">
              <el-image
                style="width: 23px; height: 20px; margin-right: 18px"
                class="pointer"
                :src="
                  data.is_collect == 1
                    ? require('@/assets/collect6.png')
                    : require('@/assets/collect5.png')
                "
                fit="cover"
                @click="fnCollect(data.is_collect)"
              ></el-image>
              <div>{{ data.is_collect == 1 ? '收藏' : '取消收藏' }}</div>
            </div>
          </div>

          <div class="title">{{ data.goods.goods_name || '' }}</div>
          <div v-if="data.goods.goods_floor_price > 0">
            <div class="price1">
              价格：<span class="price2">{{
                attr_id ? guigeObj.price : data.goods.goods_floor_price
              }}</span>
              <span class="price3">{{ data.goods.gu_name || '' }}</span>
              <span class="hua-xian-jia-xq">
                {{
                  attr_id
                    ? guigeObj.price_old > 0
                      ? '¥' + guigeObj.price_old
                      : ''
                    : data.goods.goods_old_price > 0
                    ? '¥' + data.goods.goods_old_price
                    : ''
                }}
              </span>
            </div>
          </div>
          <div
            v-else
            class="price2"
            style="margin-bottom: 8px; font-size: 18px"
          >
            <span class="price4">¥</span>&nbsp;
            <span class="price2">价格面议</span>
            <span class="hua-xian-jia-xq">
              {{
                attr_id
                  ? guigeObj.price_old > 0
                    ? guigeObj.price_old
                    : ''
                  : data.goods.goods_old_price > 0
                  ? data.goods.goods_old_price
                  : ''
              }}
            </span>
          </div>
          <div class="xian" style="margin: 26px 0 21px"></div>
          <div class="info flex-wrap">
            <div style="position: relative; top: -17px">起订量：&nbsp;</div>
            <div class="flex-con">
              <div class="flex-wrap">
                <span class="info1"
                  >{{ data.goods.goods_limit_buy || 1 }}
                  {{ String(data.goods.gu_name).split('/')[1] }}
                </span>
                <div class="tips_out flex-con" style="margin-left: 10px;">
                  <img
                    :src="require('@/assets/tips1.png')"
                    style="
                      width: 26px;
                      height: 26px;
                      display: block;
                      position: relative;
                      top: -3px;
                    "
                    class="pointer"
                  />
                  <div
                    class="tips_in font-666-16"
                    style="line-height: 26px; left: 46px; bottom: 6px"
                  >
                    若您购买的数量不满起订量，可以联系
                    <a class="pointer cor_EC7902" @click="fnKf">在线客服</a>
                  </div>
                </div>
              </div>
              <div style="margin-top: 4px" class="font-333-14">
                提示：商品价格由起订量决定，起订量越大，价格越优惠！
                <!-- {{ guigeObj.price_str || '' }} -->
              </div>
            </div>
            <!-- <span class="font-666-18" style="margin-left: 25px">运费：</span>
            <span class="font-333-18">包邮</span> -->
          </div>
          <div class="info align-start">
            配送至：&nbsp;
            <div
              class="city align-start font-333-16 pointer"
              @mouseenter="mouseenterOut()"
              @mouseleave="mouseleaveOut()"
            >
              <img :src="require('@/assets/address.png')" class="local" />
              {{ ssid1.sheng_name + ' ' + ssid1.shi_name }}

              <div class="all_city font-333-16" id="all_city">
                <div
                  class="align-start"
                  style="border-bottom: 2px solid #ec7902"
                >
                  <div
                    class="select_city align-center"
                    :class="{ active: select1 != 2 }"
                    @click="fnSelect22(1)"
                  >
                    {{ ssid2.sheng_name || '' }}
                    <img :src="require('@/assets/bottom_arrow1.png')" />
                  </div>
                  <div
                    class="select_city align-center"
                    :class="{ active: select1 == 2 }"
                    @click="fnSelect22(2)"
                  >
                    {{ ssid2.shi_name }}
                    <img :src="require('@/assets/bottom_arrow1.png')" />
                  </div>
                </div>
                <div
                  class="city_out align-start"
                  style="flex-wrap: wrap; padding-top: 10px"
                >
                  <div
                    class="align-center"
                    v-show="select1 == 1"
                    v-for="(item, index) in sheng"
                    :key="'sheng_' + index"
                  >
                    <div
                      class="pointer"
                      :class="{
                        active:
                          ssid2.shi_name != '请选择' &&
                          (ssid2.sheng_id
                            ? ssid2.sheng_id == item.district_id
                            : ssid1.sheng_name == item.district),
                      }"
                      @click.prevent="
                        fnSelect11(1, item.district, item.district_id)
                      "
                    >
                      {{ item.district || '' }}
                    </div>
                  </div>
                  <div
                    class="align-center"
                    v-show="select1 == 2"
                    v-for="(item, index) in shi"
                    :key="'shi_' + index"
                  >
                    <div
                      class="pointer"
                      :class="{
                        active: ssid2.shi_id
                          ? ssid2.shi_id == item.district_id
                          : ssid1.shi_name == item.district,
                      }"
                      @click.prevent="
                        fnSelect11(2, item.district, item.district_id)
                      "
                    >
                      {{ item.district || '' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span class="font-666-18">运费：</span>
              <span class="font-333-18">
                {{
                  attr_id
                    ? guigeObj.yunfei
                      ? guigeObj.yunfei
                      : '包邮'
                    : '请选择规格'
                }}</span
              >
            </div>
          </div>
          <div class="info1 align-start" style="margin-bottom: 20px">
            <div class="font-666-18">支付方式：&nbsp;</div>
            <div style="width: 167px" class="align-start">
              <img
                src="../assets/main1_42.png"
                class="pay"
                style="width: 22px; height: 22px"
              />
              <div class="pay1">支付宝</div>
            </div>
            <div style="width: 167px" class="align-start">
              <img
                src="../assets/main1_43.png"
                class="pay"
                style="width: 25px; height: 25px"
              />
              <div class="pay1">微信</div>
            </div>
            <div style="width: 167px" class="align-start">
              <img src="../assets/wallet.png" class="pay" />
              <div class="pay1">对公转账</div>
            </div>

            <!-- <img src="../assets/main1_44.png" class="pay" />
            <div class="pay1">银联支付</div> -->
          </div>
          <div class="info1 align-start" style="margin-bottom: 32px">
            <div class="font-666-18">商品服务：&nbsp;</div>
            <div
              class="align-center"
              v-for="(item, index) in data.advantage"
              :key="'advantage_' + index"
            >
              <img :src="item.pa_icon" class="pay" />
              <div class="pay1">{{ item.pa_name }}</div>
            </div>
          </div>
          <div class="xian" style="margin-bottom: 25px"></div>
          <div class="info flex-wrap" style="margin-bottom: 18px">
            产品规格：
            <div class="tips_out flex-con">
              <img
                :src="require('@/assets/tips1.png')"
                style="width: 26px; height: 26px; display: block"
                class="pointer"
              />
              <div class="tips_in font-666-16" style="line-height: 26px">
                若以下产品规格不满足你的需求，可以点击填写产品
                <span class="cor_EC7902 pointer" @click="fnWrite"
                  >需求表单</span
                >
                进行填写你的需求，我们收到你的需求后，会第一时间联系你；您也可以直接联系
                <span class="cor_EC7902 pointer" @click="fnKf">在线客服</span>
              </div>
            </div>
          </div>
          <div>
            <!-- <div
            :style="open ? 'height:auto;' : 'max-height:98px;overflow:hidden;'"
          > -->
            <div
              class="font-666-18 align-top"
              v-for="(item, index1) in goods_guige"
              :key="'guige1_' + index1"
              style="line-height: 30px"
            >
              <div>{{ item.attr_group_name || '' }}：</div>
              <div class="flex-con">
                <div class="flex-wrap2">
                  <div
                    class="label1 align-center pointer"
                    @click="fnSecond(index1, index, item.attr_id)"
                    v-for="(item, index) in item.attr_list"
                    :class="{ active: numAll[index1] == index }"
                    :key="'guige2_' + index"
                  >
                    {{ item.attr_name || '' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="show flex-wrap" style="display: none">
            <div class="show1"></div>
            <div style="width: 65px; position: relative">
              <div class="show2 pointer" @click="fnQieOpen">
                <img
                  :src="
                    open
                      ? require('@/assets/top_arrow1.png')
                      : require('@/assets/bottom_arrow1.png')
                  "
                  class="showImg"
                />
              </div>
            </div>

            <div class="show3 flex-con"></div>
          </div>

          <div class="xian" style="margin: 28px 0"></div>
          <div class="flex-wrap" style="margin-top: 16px">
            <el-input-number
              v-model="num"
              @change="handleChange"
              :min="data.goods.goods_limit_buy || 0"
            ></el-input-number>
            <div class="carAll align-center pointer" @click="fnGoodsInCar">
              <img src="../assets/shop_car2.png" class="car" />
              <div>加入购物车</div>
            </div>
            <div class="btn pointer align-center" @click="fnBuy">
              <div class="buy3"></div>

              <div>立即购买</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom flex-wrap">
        <div class="goods_all">
          <div class="goods">
            <div class="goods_tj align-center">产品推荐</div>
            <div
              class="goods1 flex-wrap pointer"
              v-for="(item, index) in recommendGoods"
              :key="'recommendGoods_' + index"
              @click="fnGoodsDetails(item.goods_id)"
            >
              <el-image
                :src="item.goods_thumb"
                fit="cover"
                class="goodImg"
              ></el-image>
              <div class="flex-con">
                <div class="goods_title">{{ item.goods_name || '' }}</div>
                <div class="price">
                  <span class="">¥</span
                  >{{
                    item.goods_floor_price > 0 ? item.goods_floor_price : '面议'
                  }}
                  <span class="font-333-16-16">{{
                    item.goods_floor_price > 0 ? item.danwei : ''
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-con" style="background: #ffffff">
          <div
            class="goods_tj align-start"
            style="padding-left: 35px; margin-bottom: 0"
          >
            商品详情
          </div>
          <div class="fwb" v-html="data.goods.goods_detail || ''"></div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible111"
      width="980px"
      custom-class="confirm_dialog"
    >
      <!-- :show-close="false" -->
      <div class="dialog" style="padding: 0">
        <div class="dialog_title align-start">
          <img
            :src="require('@/assets/tip_delete.png')"
            alt=""
            style="width: 26px; height: 26px; margin-right: 18px"
          />
          取消收藏
        </div>
        <div
          class="font-333-18-bold"
          style="text-align: center; margin-top: 36px"
        >
          确认取消吗？
        </div>
        <div class="align-center" style="margin-top: 36px">
          <div
            class="dialog_btn2 align-center pointer"
            @click="dialogVisible111 = false"
          >
            取消
          </div>
          <div class="dialog_btn1 align-center pointer" @click="fnCun">
            确认
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible222" width="1300px">
      <div class="dialog">
        <div class="kuang">
          <div class="title1 font-333-36-bold">填写表单信息</div>
          <div class="mb align-start">
            <div class="mb1 align-start">
              <div class="info111">
                <span class="FF940A m_r5 xing">*</span>姓名:
              </div>
              <input
                type="text"
                placeholder="长度不超过20个字符"
                v-model="name"
                style="width: 402px"
              />
            </div>
            <div class="mb1 align-start">
              <div class="info111" style="width: 242px">
                <span class="FF940A m_r5 xing">*</span>联系电话:
              </div>
              <input
                type="tel"
                placeholder="请输入您的联系方式"
                v-model="phone"
                style="width: 402px"
              />
            </div>
          </div>
          <div class="mb mb1 align-start">
            <div class="info111">
              <span style="color: rgba(0, 0, 0, 0)">*</span>产品:
            </div>
            <input
              type="text"
              placeholder=""
              v-model="product"
              style="width: 1045px"
            />
          </div>
          <div class="mb mb2 flex-wrap">
            <div class="info111">
              <span style="color: rgba(0, 0, 0, 0)">*</span>规格:
            </div>
            <textarea class="flex-con" placeholder="" v-model="spec"></textarea>
          </div>
          <div style="margin: 37px 0 0 185px; padding-bottom: 46px">
            <div class="blue align-start">
              <div class="align-start" style="margin-right: 133px">
                <img
                  :src="require('@/assets/download.png')"
                  alt=""
                  style="width: 18px; height: 24px; margin-right: 9px"
                />
                <a :href="demo" class="pointer down1">下载模板</a>
              </div>

              <input
                type="file"
                accept="application/msexcel,text/plain,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                name="file"
                value="附件上传您的需求"
                id="fileImg"
                @change="uploadData"
                class="pointer input1"
              />
              <!-- accept="text/plain,application/pdf,application/msword" -->
            </div>
            <div class="tips">
              提示：收集电话只为方便联系您，您所需要的产品规格尽量填写清晰，方便后续平台联系您
            </div>
            <div class="btn333 pointer" @click="fnTi">提交表单</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import Swiper from 'swiper/bundle'
import { Autoplay } from 'swiper'
// import AMapLoader from '@amap/amap-jsapi-loader'
// window._AMapSecurityConfig = {
//   securityJsCode: '3e5b8432e18fab0a39a8272d72f3c203',
// }
import {
  goodsDetail,
  goodsJoinDetail,
  doCollect,
  getAttrInfo,
  putGoodsInCar,
  insertLookHistory,
  brandOrder,
  uploadFile,
  loginAgreement,
  getAreaCity,
} from '../api'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  data() {
    return {
      goods_guige: [],
      num: 1,
      data: {
        goods: {
          // haveVideo: 1,
        },
      },
      cateName: {},
      open: false,
      recommendGoods: [],
      goods_id: this.$route.params.id.split('.')[0],
      numAll: [],
      guigeIds: [],
      isFull: false,
      attr_id: '',
      guigeObj: {},
      guigeImg: [],
      goods_images: [],
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
      index: 0,
      videoIsPlay: true,
      galleryThumbs: '',
      galleryTop: '',
      dialogVisible111: false,
      dialogVisible222: false,
      carName222: '',

      demo: '',
      name: '',
      phone: '',
      product: '',
      spec: '',
      file: '',

      select1: 1,
      sheng: [],
      shi: [],

      ssid1: {
        sheng_id: '',
        shi_id: '',
        sheng_name: '',
        shi_name: '',
      },
      ssid2: {
        sheng_id: '',
        shi_id: '',
        sheng_name: '',
        shi_name: '',
      },
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    // initAMap() {
    //   let _this=this
    //   AMapLoader.load({
    //     key: '8392d0c015e1e33a1913b3d9b1ea2b3e', // 申请好的Web端开发者Key，首次调用 load 时必填
    //     version: '2.0', // 指定要加载的 JS API 的版本，缺省时默认为 1.4.15
    //     plugins: ['AMap.CitySearch'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    //   })
    //     .then((AMap) => {
    //       let citysearch = new AMap.CitySearch()
    //       citysearch.getLocalCity(function(status, result) {
    //         if (status === 'complete' && result.info === 'OK') {
    //             if (result && result.city && result.bounds) {
    //               _this.ssid1.sheng_name=result.province
    //               _this.ssid1.shi_name=result.city
    //               let obj1 = _this.sheng.find(
    //                 (item) => item.district == _this.ssid1.sheng_name
    //               )
    //               _this.ssid1.sheng_id=obj1.district_id
    //               _this.shi=obj1.items
    //               let obj2 = _this.shi.find(
    //                 (item) => item.district == _this.ssid1.shi_name
    //               )
    //               _this.ssid1.shi_id=obj2.district_id
    //               _this.ssid2=JSON.parse(JSON.stringify(_this.ssid1))
    //             }
    //         } else {
    //           this.$message.error('result.info')
    //         }
    //     });
    //     })
    //     .catch((e) => {
    //       console.log(e)
    //     })
    // },
    mouseenterOut() {
      document.getElementById('all_city').style.display = 'block'
    },
    mouseleaveOut() {
      document.getElementById('all_city').style.display = 'none'
    },
    initSsq() {
      let params = {}
      getAreaCity(params).then((data) => {
        if (data.status == 200) {
          this.sheng = data.data
        }
      })
    },
    fnSelect11(type, name, id) {
      if (type == 1) {
        this.ssid2.sheng_id = id
        this.ssid2.sheng_name = name
        this.fnSelect22(2)
        this.ssid2.shi_name = '请选择'
        return
      } else {
        this.ssid2.shi_id = id
        this.ssid2.shi_name = name
        this.ssid1 = JSON.parse(JSON.stringify(this.ssid2))
        this.select1 = 1
        // this.isFull ? this.fnGetAttrInfo(this.guigeIds.join(':')) : ''
        this.fnGetAttrInfo(this.guigeIds.join(':'))
        document.getElementById('all_city').style.display = 'none'
      }
    },
    fnSelect22(type) {
      this.select1 = type
      if (type == 2) {
        let obj = this.sheng.find(
          (item) => item.district_id == this.ssid2.sheng_id
        )
        this.shi = obj.items
      }
    },
    fnTi() {
      if (!localStorage.getItem('allUser')) {
        this.$bus.$emit('login', 1)
        return
      }
      if (!this.name) {
        this.$message.error('请输入姓名')
        return
      }
      if (!this.phone) {
        this.$message.error('请输入联系电话')
        return
      }
      // if (!this.product) {
      //   this.$message.error('请输入产品')
      //   return
      // }
      let params = {
        name: this.name,
        phone: this.phone,
        product: this.product,
        spec: this.spec,
        file: this.file,
      }
      brandOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success('提交成功')
          this.dialogVisible222 = false
          this.name = ''
          this.phone = ''
          this.product = ''
          this.spec = ''
          this.file = ''
        }
      })
    },
    uploadData(e) {
      let files = e.target.files[0]
      let formData = new FormData()
      formData.append('file', files)
      // console.log(files)
      let _self = this
      return new Promise((resolve, reject) => {
        uploadFile(formData)
          .then((response) => {
            this.file = response.msg
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fnWrite() {
      this.dialogVisible222 = true
      this.fnAgreement(9)
    },
    fnAgreement(type) {
      let params = {
        type: type,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          if (type == 9) {
            this.demo = data.msg.content1 || ''
          }
        }
      })
    },
    initData() {
      let params = {
        goods_id: this.goods_id,
      }
      goodsDetail(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.tdkTitle = data.msg.goods.goods_title || '本牛'
          this.tdkKeywords = data.msg.goods.goods_keyword || '本牛'
          this.tdkDescription = data.msg.goods.goods_desc || '本牛'
          this.goods_guige = data.msg.goods.goods_guige
          let _this=this
          for (let index = 0; index < this.goods_guige.length; index++) {
            _this.guigeIds[index]=this.goods_guige[index].attr_list[0].attr_id
          }
          this.goods_images = data.msg.goods.goods_images
          this.recommendGoods = data.msg.recommendGoods
          this.num = data.msg.goods.goods_limit_buy || 1

          this.ssid1.sheng_name = data.address.sheng
          this.ssid1.shi_name = data.address.shi
          this.ssid1.sheng_id = data.address.address_sheng
          this.ssid1.shi_id = data.address.address_shi
          this.ssid2 = JSON.parse(JSON.stringify(this.ssid1))

          this.$nextTick(() => {
            setTimeout(() => {
              this.listenVideo()
            }, 100)
          })
          if (this.goods_guige) {
            this.numAll = new Array(this.goods_guige.length)
            this.numAll.fill(0)
            this.fnGetAttrInfo(this.guigeIds.join(':'))
          }
          if (this.data.goods.haveVideo == 2) {
            this.videoIsPlay = true
          } else {
            setTimeout(() => {
              this.lookBigImg()
            }, 1000)
          }
        }
      })
    },
    initData1() {
      let params = {
        goods_id: this.goods_id,
      }
      goodsJoinDetail(params).then((data) => {
        if (data.status == 200) {
          this.cateName = data.msg
        }
      })
    },
    fnUpdate(idd) {
      this.$router.push({
        name: 'goodDetail',
        params: {
          id: idd + '.html',
        },
      })
      this.goods_id = idd
      this.initData()
      this.initData1()
      this.initHistory()
      document.documentElement.scrollTop = 0
    },
    initHistory() {
      let params = {
        goods_id: this.goods_id,
      }
      insertLookHistory(params).then((data) => {
        if (data.status == 200) {
        }
      })
    },
    fnCun() {
      let params = {
        goods_id: this.goods_id,
      }
      doCollect(params).then((data) => {
        if (data.status == 200) {
          this.data.is_collect = 1
          this.dialogVisible111 = false
        }
      })
    },
    fnCollect(num) {
      if (!localStorage.getItem('allUser')) {
        this.$bus.$emit('login', 1)
        return
      }
      if (num == 2) {
        this.dialogVisible111 = true
        return
      }
      let params = {
        goods_id: this.goods_id,
      }
      doCollect(params).then((data) => {
        if (data.status == 200) {
          this.data.is_collect = num == 1 ? 2 : 1
        }
      })
    },
    fnGoodsInCar() {
      if (!localStorage.getItem('allUser')) {
        this.$bus.$emit('login', 1)
        return
      }
      if (!this.attr_id) {
        this.$message.error('请选择产品规格')
        return
      }
      let params = {
        goods_id: this.goods_id,
        num: this.num,
        attr_id: this.attr_id,
      }
      putGoodsInCar(params).then((data) => {
        if (data.status == 200) {
          // this.$bus.$emit('carNum111')
          this.$message.success(data.msg)
          this.carName222 = 111
        }
      })
    },
    fnGetAttrInfo(sign_id) {
      let params = {
        goods_id: this.goods_id,
        sign_id: sign_id,
        num: this.num,
        city: this.ssid1.shi_id,
      }
      getAttrInfo(params).then((data) => {
        if (data.status == 200) {
          this.attr_id = data.msg.attr_id
          this.guigeObj = data.msg
          this.guigeImg = []
          this.guigeImg.push(data.msg.pic_url || this.data.goods.goods_thumb)
          // console.log(this.guigeImg);
        }
      })
    },
    fnSecond(index1, index, attr_id) {
      this.$set(this.numAll, index1, index)
      // console.log(this.numAll)
      this.guigeIds[index1] = attr_id
      // let length1 = []
      // if (this.isFull) {
      //   this.fnGetAttrInfo(this.guigeIds.join(':'))
      //   return
      // }
      // for (let i = 0; i < this.numAll.length; i++) {
      //   if (this.numAll[i] < 0) {
      //     length1.push(0)
      //   }
      // }
      // this.isFull = length1.length > 0 ? false : true
      // this.isFull ? this.fnGetAttrInfo(this.guigeIds.join(':')) : ''
      this.fnGetAttrInfo(this.guigeIds.join(':'))
    },
    fnBuy() {
      if (!localStorage.getItem('allUser')) {
        this.$bus.$emit('login', 1)
        return
      }
      if (!this.attr_id) {
        this.$message.error('请选择产品规格')
        return
      }
      this.$router.push({
        name: 'SureOrder',
        params: {
          id:
            1 +
            '-' +
            this.goods_id +
            '-' +
            this.attr_id +
            '-' +
            this.num +
            '.html',
        },
      })
    },
    fnQieOpen() {
      this.open = !this.open
    },
    handleChange(value) {
      // console.log(value)
      this.num = value
      if (this.attr_id) {
        this.fnGetAttrInfo(this.guigeIds.join(':'))
      }
    },
    //复制链接
    copyText() {
      let copyInput = document.createElement('input') // 创建标签
      copyInput.value = window.location.href // 标签赋值
      document.body.appendChild(copyInput) // 添加标签
      copyInput.select() // 选取文本框内容
      document.execCommand('copy') // 调用浏览器复制
      document.body.removeChild(copyInput) // 复制成功后再将构造的标签 移除
      this.$message.success('链接已复制')
    },
    fnSearch(type) {
      if (type == 1) {
        this.$router.push({
          path: `/${this.cateName.gc_url_one}`,
        })
      }
      if (type == 2) {
        this.$router.push({
          path: `/${this.cateName.gc_url_one}/${this.cateName.gc_url_two}`,
        })
      }
      if (type == 3) {
        this.$router.push({
          path: `/${this.cateName.gc_url_one}/${this.cateName.gc_url_two}/${this.cateName.gc_url_three}`,
        })
      }
    },
    changeVideoStatus() {
      let video = document.getElementById('video111')
      // console.log(video)
      if (video.paused) {
        video.play()
      } else {
        video.pause()
      }
    },
    fnPause() {
      let video = document.getElementById('video111')
      if (this.index > 0) {
        video.style.display = 'none'
      } else {
        video.style.display = 'block'
        this.videoIsPlay = false
      }
    },
    lookBigImg() {
      let smDiv = document.getElementById('smDiv' + this.index) //获取小图容器
      // console.log(smDiv + '||' + 620)
      let bigDiv = document.querySelector('.bigDiv') //获取放大图容器
      let zoom = document.getElementById('zoom' + this.index) //获取放大器
      let bigImg = document.querySelector('.bigDiv>img') //获取放大后大图

      //光标移入悬浮小图容器
      smDiv.addEventListener('mouseover', function (e) {
        zoom.style.display = 'block'
        bigDiv.style.display = 'block'
      })
      //光标移出小图容器
      smDiv.addEventListener('mouseout', function (e) {
        zoom.style.display = 'none'
        bigDiv.style.display = 'none'
      })

      smDiv.addEventListener('mousemove', function (e) {
        //计算放大镜的top
        // offsetY:鼠标坐标到元素顶部的距离
        // offsetHeight：元素的像素高度，包含元素的垂直内边距和边框，水平滚动条的高度，且是一个整数
        var top = e.offsetY - zoom.offsetHeight / 2

        //计算放大镜的left
        // offsetX:鼠标坐标到元素的左侧的距离
        // offsetWidth:除了外边距(margin)以外,所有的宽度(高度)之和
        var left = e.offsetX - zoom.offsetWidth / 2

        //计算放大镜最大的top与left
        var maxTop = smDiv.clientHeight - zoom.offsetHeight
        var maxLeft = smDiv.clientWidth - zoom.offsetWidth

        //限制放大镜移动范围不可超过小图容器的边界
        top = top < 0 ? 0 : top
        top = top > maxTop ? maxTop : top
        left = left < 0 ? 0 : left
        left = left > maxLeft ? maxLeft : left

        // 放大镜的位置
        zoom.style.top = top + 'px'
        zoom.style.left = left + 'px'

        //放大图在容器中的位置，大图是小图的三倍，所以乘以3
        bigImg.style.top = -3 * top + 'px'
        bigImg.style.left = -3 * left + 'px'
      })
    },
    debounce(func, delay) {
      let timer
      return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
          func.apply(this, arguments)
        }, delay)
      }
    },

    listenVideo() {
      let _this = this
      const slideChangeHandler = this.debounce(function () {
        console.log(Date.parse(new Date()) + '||' + this.activeIndex + '||655')

        _this.index = this.activeIndex
        // console.log("666||" + _this.data.goods.haveVideo);
        if (_this.data.goods.haveVideo == 2) {
          let video = document.getElementById('video111')
          if (this.activeIndex == 0) {
            video.style.display = 'block'
            let playPromise = video.play()
            if (playPromise !== undefined) {
              playPromise
                .then(() => {
                  video.play()
                })
                .catch(() => {
                  video.play()
                })
            }
          } else {
            _this.lookBigImg()
          }
        } else {
          _this.lookBigImg()
        }
      }, 300)
      let galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 3,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
      let galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        slideToClickedSlide: true,
        centeredSlides: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        thumbs: {
          swiper: galleryThumbs,
        },
        on: {
          slideChange: slideChangeHandler,
        },
      })
    },
    fnKf() {
      this.$bus.$emit('goodsKf')
    },
  },
  mounted() {
    this.initData()
    this.initData1()
    this.initHistory()
    this.fnAgreement(9)
    this.initSsq()
    // this.initAMap()
  },
}
</script>
<style scoped>
.city_out > div {
  min-width: 90px;
  box-sizing: border-box;
  margin-right: 10px;
  margin-bottom: 10px;
}
.city_out > div > div {
  padding: 1px 4px;
}
.city_out > div > div:hover {
  background: #ec7902;
  color: #fff;
  border-radius: 4px;
}
.city_out > div > div.active {
  background: #ec7902;
  color: #fff;
  border-radius: 4px;
}
.all_city {
  border: 1px solid #c9c9c9;
  padding: 15px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 23px;
  width: 700px;
  z-index: 9999999999;
  display: none;
  min-height: 360px;
}
.city:hover .all_city {
  display: block;
}
.select_city {
  padding: 0 20px;
  height: 30px;
  border: 1px solid #c9c9c9;
  width: max-content;
  border-radius: 6px 6px 0 0;
  margin-right: 8px;
  border-bottom: none;
  position: relative;
  bottom: -2px;
}
.select_city.active {
  border: 2px solid #ec7902;
  border-bottom: 3px solid #fff;
}
.select_city img {
  margin-left: 10px;
  width: 16px;
  height: 16px;
  display: block;
}

@import url('swiper/swiper-bundle.css');

::v-deep .confirm_dialog.el-dialog {
  border-radius: 4px;
}
::v-deep .confirm_dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 42px;
}
::v-deep .confirm_dialog .el-dialog__header {
  padding: 0;
}
::v-deep .confirm_dialog .el-dialog__headerbtn {
  position: absolute;
  top: 22px;
  right: 40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 24px;
}
::v-deep .confirm_dialog .el-dialog__headerbtn .el-dialog__close {
  color: #979797;
}

.btn333 {
  width: 187px;
  height: 64px;
  background: #ff940a;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 64px;
  text-align: center;
  margin: 39px 0 0 376px;
  letter-spacing: 2px;
}

.down1 {
  height: 18px;
  font-size: 18px;
  font-weight: 400;
  color: #6eaaf9;
  /* line-height: 18px ; */
  text-decoration: none;
}
.xing {
  display: inline-block;
  font-size: 30px;
  line-height: 15px;
  font-weight: 500;
  position: relative;
  top: 10px;
}
.tips {
  margin-top: 30px;

  font-size: 18px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
}
input[type='tel'],
input[type='text'],
input[type='password'] {
  height: 46px;
  margin: 0;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 400;
  color: #333;
  line-height: 46px;
  background: transparent;
  outline: none;
  border: 1px solid #dcdcdc;
}
input[type='tel']:focus,
input[type='text']:focus,
input[type='password']:focus {
  border: 1px solid #dcdcdc;
}

textarea {
  max-width: 1045px;
  height: 341px;
  border: 1px solid #dcdcdc;
  padding: 10px;
  font-size: 18px;
  color: #333;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0);
  outline: none;
  box-sizing: border-box;
  font-family: '微软雅黑';
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: 16px;
  color: #999999;
}
.blue {
  font-size: 12px;
  font-weight: 400;
  color: #3b9bff;
  text-align: right;
  margin-top: 17px;
}
.kuang {
  margin: auto;
  /* width: 580px; */
}

.title1 {
  padding-top: 68px;
  margin-bottom: 68px;
  text-align: center;
}

.info111 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  width: 149px;
  box-sizing: border-box;
  padding-right: 19px;
  text-align: right;
  /* margin-right: 19px; */
}
.mb {
  margin-bottom: 32px;
}

.mb1 {
  align-items: center;
}

.mb2 {
  align-items: baseline;
}
.input1::file-selector-button {
  width: 101px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 3px;
  margin-right: 18px;
  cursor: pointer;
}
.input1::-ms-browse {
  width: 101px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  padding-bottom: 3px;
  cursor: pointer;
}

.input1 {
  font-size: 18px;
  font-weight: 400;
  color: #6eaaf9;
}
.down1 {
  height: 18px;
  font-size: 18px;
  font-weight: 400;
  color: #6eaaf9;
  /* line-height: 18px ; */
  text-decoration: none;
}

.tips_out {
  position: relative;
  max-width: 26px;
}
.tips_out:hover {
  width: auto;
  max-width:100%;
}
.tips_out:hover .tips_in {
  display: block;
}

.tips_in {
  display: none;
  position: absolute;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  left: 36px;
  bottom: -7px;
}

.goods_tj {
  box-sizing: border-box;
  height: 65px;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 12px;
}
.buy3 {
  width: 25px;
  height: 34px;
  background: url('@/assets/buy3.png') no-repeat center center;
  background-size: 25px 34px;
  margin-right: 19px;
}
.btn:hover .buy3 {
  background: url('@/assets/buy4.png') no-repeat center center;
  background-size: 25px 34px;
}
.kuang1:hover {
  border: 2px solid #ec7902;
  box-sizing: border-box;
}
.toppp {
  /* font-size: 14px;
  font-weight: 400;
  color: #777777; */
  margin: 25px 0;
  align-items: center;
}

.topppImg {
  width: 20px;
  height: 20px;
  margin: 0 4px;
}

.topLabel {
  /* height: 24px;
  border-radius: 1px;
  border: 1px solid #d8d8d8;
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  line-height: 24px;
  padding: 0 12px; */
}

.card11 {
  background: #ffffff;
  /* border-radius: 4px; */
  padding: 53px 43px 73px 37px;
  box-sizing: border-box;
  margin-bottom: 14px;
  /* min-height: 500px; */
}

.left {
  width: 320px;
  margin-right: 50px;
}

.swiper-container {
  width: 320px;
  height: 320px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-wrapper1,
.swiper-wrapper1 .swiper-slide {
  width: 320px;
  height: 320px;
}
.swiper-wrapper,
.swiper-slide {
  width: 68px;
  height: 68px;
  margin: 0 10px 0 0;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
}

.gallery-top {
  height: 40%;
  width: 100%;
}

.gallery-thumbs {
  height: 68px;
  box-sizing: border-box;
  margin: 20px 0;
}

.gallery-thumbs .swiper-slide {
  height: 100%;
  opacity: 1;
}

.gallery-thumbs .swiper-slide-thumb-active {
  opacity: 1;
}

.gallery-thumbs img {
  height: 68px;
  width: 68px;
}

.swiper-button-prev {
  width: 18px;
  height: 19px;
  background: url('../assets/arrow7.png') no-repeat center center;
  background-size: 11px 19px;
  left: 0;
}

.swiper-button-next {
  width: 18px;
  height: 19px;
  background: url('../assets/arrow8.png') no-repeat center center;
  background-size: 11px 19px;
  right: 0;
}

.swiper-button-next:hover {
  width: 18px;
  height: 19px;
  background: url('../assets/arrow3.png') no-repeat center center;
  background-size: 11px 19px;
  right: 0;
}
.swiper-button-prev:hover {
  width: 18px;
  height: 19px;
  background: url('../assets/arrow5.png') no-repeat center center;
  background-size: 11px 19px;
  left: 0;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}
.shou {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  align-items: center;
  margin-left: 20px;
}
.shou11 {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  align-items: center;
  /* margin-left: 20px; */
  position: absolute;
  right: 0;
  top: -25px;
}
.title {
  /* font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-top: 16px; */
  margin-bottom: 20px;

  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}
.price1 {
  /* font-size: 14px;
  font-weight: 400;
  color: #777777; */
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}
.price2 {
  /* font-size: 20px;
  font-weight: 400;
  color: #c52b33; */

  font-size: 30px;
  font-weight: bold;
  color: #ec7902;
  line-height: 18px;
}
.price3 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
}
.price4 {
  color: #333;
}
.label {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #dea422;
  height: 23px;
  line-height: 23px;
  border-radius: 2px;
  border: 1px solid #dea422;
  padding: 0 8px;
  margin-bottom: 10px;
}
.xian {
  border-bottom: 1px dashed #999;
}
.info {
  margin-bottom: 25px;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
}
.info1 {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
}
.font-666-18 {
  font-size: 18px;
  font-weight: 400;
  color: #666;
  line-height: 18px;
}
.font-333-18 {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
}
.local {
  width: 13px;
  height: 15px;
  margin-right: 15px;
}
.city {
  border: 1px solid #e5e5e5;
  padding: 0 15px 0 10px;
  box-sizing: border-box;
  height: 23px;
  margin-right: 48px;
  position: relative;
}
.pay {
  width: 22px;
  height: 22px;
}
.pay1 {
  /* margin: 0 26px 0 4px; */
  width: 140px;
  box-sizing: border-box;
  padding-left: 20px;
}
/* .info1 {
  font-size: 12px;
  font-weight: 400;
  color: #777777;
  margin-bottom: 10px;
  align-items: baseline;
} */
.show {
  align-items: baseline;
  margin-bottom: 15px;
}
.show1 {
  width: 95px;
  height: 18px;
  border-top: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
  border-top-right-radius: 5px;
}
.show2 {
  position: absolute;
  top: -14px;
  left: -1px;
  width: 65px;
  height: 18px;
  border: 1px solid #d8d8d8;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.showImg {
  width: 20px;
  height: 20px;
  margin: auto;
  display: block;
}
.show3 {
  width: 95px;
  height: 18px;
  border-top: 1px solid #d8d8d8;
  border-left: 1px solid #d8d8d8;
  border-top-left-radius: 5px;
}
::v-deep .el-input-number {
  width: 214px;
  height: 57px;
  border: 2px solid #c9c9c9;
  line-height: 55px;
}
.label1 {
  height: 30px;
  border-radius: 5px;
  border: 1px solid #c9c9c9;
  padding: 3px 19px;
  box-sizing: border-box;
  margin-right: 13px;
  margin-bottom: 10px;
}
.label1.active,
.label1:hover {
  /* background: linear-gradient(90deg, #efc845 0%, #dca01e 100%); */
  border: none;
  background: #ec7902;
  color: #ffffff;
  /* padding: 3px 9px; */
  /* border: 1px solid rgba(0,0,0,0); */
}
::v-deep .el-icon-plus:before {
  content: '\e6d9';
  font-weight: bold;
}
::v-deep .el-icon-minus {
  font-weight: bold;
}
/* ::v-deep .el-input-number__decrease:hover{
  color:#333 !important;
} */
::v-deep .el-input-number__increase {
  border-left: 2px solid #c9c9c9;
  width: 57px;
  height: 57px;
  margin-top: -1px;
  background: #f9f9f9;
}
::v-deep .el-input-number__decrease {
  background: #f9f9f9;
  border-right: 2px solid #c9c9c9;
  width: 57px;
  height: 57px;
  margin-top: -1px;
}
::v-deep .el-input-number .el-input {
  height: 57px;
  /* font-size: 18px; */
}
::v-deep .el-input-number .el-input__inner {
  padding-left: 0;
  padding-right: 0;
  border: none;

  font-size: 18px;
}
::v-deep .el-input__inner {
  height: 57px;
}
.carAll {
  /* height: 32px;
  border-radius: 2px;
  padding: 0 41px;
  font-size: 14px;
  font-weight: 500;
  color: #f08300;
  align-items: center;
  
  box-sizing: border-box;
  position: relative;
  top: -1px;

  background: rgba(240, 131, 0, 0.2);
  border: 1px solid #f08300; */

  margin: 0 15px 0 80px;
  width: 253px;
  height: 63px;
  background: #ec7902;
  border-radius: 5px;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}
.carAll:hover {
  background: #edb380;
}
.car {
  width: 29px;
  height: 29px;
  margin-right: 25px;
}
.btn {
  /* background: rgba(240, 131, 0, 0.2);
  border: 1px solid #f08300;
  width: 182px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-top: 0;
  box-sizing: border-box;
  position: relative;
  top: -1px; */

  width: 183px;
  height: 63px;
  background: #ffffff;
  border: 2px solid #ec7902;
  border-radius: 5px;
  font-size: 24px;
  font-weight: 500;
  color: #ec7902;
  box-sizing: border-box;
  margin: 0;
}

.btn:hover {
  color: #ffffff;
  background: #ec7902;
}
.goods_all {
  width: 347px;
  margin-right: 12px;
}
.goods {
  /* padding: 20px 20px 10px; */
  background-color: #ffffff;
  box-sizing: border-box;
  width: 100%;
}
.goods1 {
  align-items: baseline;
  padding: 18px 20px;
  border-bottom: 1px solid #eeeeee;
}
.goodImg {
  width: 92px;
  height: 92px;
  margin-right: 8px;
}

.price {
  font-size: 18px;
  font-weight: bold;
  color: #ec7902;
  line-height: 18px;
}
.red {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.goods_title {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 15px;
  line-height: 28px;
}
.fwb {
  height: auto;
  line-height: 22px;
  padding: 27px 68px 27px 75px;
  box-sizing: border-box;
}
.fwb >>> img {
  width: 100%;
  display: block;
}

.swiper-slide.swiper-slide-visible {
  width: 68px !important;
}

.img111 {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 24px !important;
  height: 24px !important;
}

.img222 {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50px !important;
  height: 50px !important;
}

.bigDiv {
  position: absolute;
  top: 0px;
  left: 330px;
  width: 320px;
  height: 320px;
  overflow: hidden;
  /* border: 1px solid #000; */
  display: none;
  z-index: 999;
}
.zoom {
  position: absolute;
  width: 110px;
  height: 111px;
  background-color: #8484847b;
  top: 0;
  left: 0;
  cursor: move;
  pointer-events: none;
  display: none;
}
.bigDiv > img {
  position: absolute;
  top: 0;
  left: 0;
}
</style>

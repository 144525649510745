import { render, staticRenderFns } from "./Whqc.vue?vue&type=template&id=27b11e5c&scoped=true&"
import script from "./Whqc.vue?vue&type=script&lang=js&"
export * from "./Whqc.vue?vue&type=script&lang=js&"
import style0 from "./Whqc.vue?vue&type=style&index=0&id=27b11e5c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b11e5c",
  null
  
)

export default component.exports
<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top: 15px">
      <div>
        <MyInfo @update="initDataShua" :num="type" />
      </div>
      <div class="my_right flex-con">
        <div class="my_order">
          <div
            class="align-between"
            style="
              background: rgba(255, 226, 189, 0.2);
              height: 46px;
              padding: 0 12px 0 22px;
            "
          >
            <div class="font-333-20-bold">全部订单</div>
            <div class="align-center">
              <div class="head_search align-center">
                <input
                  type="text"
                  name=""
                  value=""
                  placeholder="请输入订单号"
                  v-model="order_no"
                  @keyup.enter="fnSearch"
                />
                <div class="search_txt align-center pointer" @click="fnSearch">
                  查询
                </div>
              </div>
              <div
                class="font-666-16 pointer kuang11 align-center"
                style="margin-left: 24px"
                @click="initDownOrder('')"
              >
                全部导出
              </div>
            </div>
          </div>
          <div
            class="flex-wrap font-333-16-bold"
            style="
              text-align: center;
              padding: 0 12px 0 22px;
              line-height: 56px;
            "
          >
            <div style="width: 312px; text-align: left">商品信息</div>
            <div style="min-width: 100px">规格</div>
            <div class="flex-con"></div>
            <div style="width: 125px">实付金额</div>
            <div style="width: 175px">下单时间</div>
            <div class="flex-con"></div>
            <div style="width: 175px">交易状态</div>
            <div style="width: 100px">操作</div>
          </div>
        </div>
        <div class="order_box">
          <div
            class="order_item"
            v-for="(item, index) in data"
            :key="'orderZx_' + index"
            :id="'order_' + item.order_id"
          >
            <div class="align-between order_header">
              <div class="align-start">
                <input
                  type="checkbox"
                  name="checkbox"
                  value=""
                  class="checkbox"
                  @change="oneCheck"
                  :data-idd="item.order_id"
                />
                <div class="font-333-16-bold">
                  订单号：<span >{{
                    item.order_number
                  }}</span>
                </div>
              </div>
              <div class="align-center font-666-16">
                <div class="kuang22 align-center pointer" @click="lookHt(item)">
                  合同
                </div>
                <div
                  class="kuang11 align-center pointer"
                  @click="initDownOrder(item.order_id)"
                  style="margin-left: 24px"
                >
                  订单导出
                </div>
              </div>
            </div>
            <div
              class="flex-wrap font-333"
              style="padding: 16px 12px 16px 22px"
            >
              <div style="width: 412px" class="shopping pointer">
                <div
                  class="flex-wrap"
                  v-for="(item1, index) in item.goods"
                  :key="'goods_' + item1.goods_id + '_' + index"
                  @click="fnGoodsDetails(item1.goods_id)"
                >
                  <div style="width: 312px; text-align: left" class="align-top">
                    <el-image
                      style="
                        width: 140px;
                        min-width: 140px;
                        height: 140px;
                        margin-right: 12px;
                      "
                      :src="item1.goods_thumb"
                      fit="cover"
                    ></el-image>
                    <div
                      style="margin-right: 30px; width: 130px;word-break: break-all;"
                      class="font-333-16"
                    >
                      {{ item1.goods_name || '' }}
                    </div>
                  </div>
                  <div style="min-width: 100px; text-align: left">
                    <div class="flex-wrap">
                      <div class="">
                        <div
                          v-for="(item, index) in item1.guige_name"
                          :key="'guige_name_' + item.goods_id + '_' + index"
                          class="font-333-16"
                        >
                          {{ item }}：
                        </div>
                      </div>
                      <div class="flex-con">
                        <div
                          v-for="(item, index) in item1.guige_value"
                          :key="'guige_value_' + item.goods_id + '_' + index"
                          class="font-666-16"
                          style="line-height: 24px;"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-con"></div>
                  
                </div>
              </div>
              <div
                    style="width: 125px; min-width: 125px; max-width: 125px"
                    class="align-center font-333-16-bold cor_FA9C22"
                  >
                    {{ item.order_price > 0 ? item.order_price : '面议' }}
                  </div>
              <div
                style="width: 175px; max-width: 225px"
                class="font-666-16 align-center"
              >
                {{
                  item.order_create_time
                    ? item.order_create_time.substring(0, 10)
                    : ''
                }}
              </div>
              <div class="flex-con"></div>
              <div
                style="width: 175px; text-align: center"
                class="order_status align-center"
              >
                <div>
                  <div class="font-333-16-bold">
                    {{
                      item.order_is_pay == 1
                        ? '待付款'
                        : item.order_is_pay == 2 && item.order_status == 1
                        ? '待发货'
                        : item.order_is_pay == 2 && item.order_status == 2
                        ? '待收货'
                        : item.order_status == 3 &&
                          data.order_refund_status == 0
                        ? '已完成'
                        : item.order_refund_status > 0
                        ? item.order_refund_status == 1
                          ? '售后中'
                          : item.order_refund_status == 2
                          ? '已同意'
                          : item.order_refund_status == 3
                          ? '未同意'
                          : ''
                        : ''
                    }}
                  </div>
                  <div
                    class="font-333-16 pointer hover_FA9C22"
                    v-show="item.order_refund_status > 0 && type == 5"
                    @click="fnShXq(item)"
                  >
                    售后详情
                  </div>
                  <div
                    class="font-333-16 pointer hover_FA9C22"
                    @click="fnOrderXq(item.order_id)"
                  >
                    订单详情
                  </div>
                </div>
              </div>
              <div
                style="width: 100px; text-align: center"
                class="order_status align-center"
              >
                <div>
                  <!-- <div
                class="C52B33"
                v-show="item.order_is_pay == 2 && item.order_status == 1"
              >
                待发货
              </div> -->
                  <div
                    class="ljzf pointer align-center"
                    v-show="item.order_is_pay == 1"
                    @click="fnPay(item.order_id, item.order_pay_type)"
                  >
                    立即支付
                  </div>
                  <div
                    class="ljzf pointer align-center"
                    v-show="item.order_is_pay == 2 && item.order_status == 2"
                    @click="fnShou(item.order_id)"
                  >
                    确认收货
                  </div>

                  <div
                    class="font-333-16 pointer hover_FA9C22"
                    v-show="item.order_is_pay == 1"
                    @click="fnCancle(item.order_id)"
                  >
                    取消订单
                  </div>
                  <div
                    class="font-333-16 pointer hover_FA9C22"
                    v-show="item.order_is_pay == 3"
                    @click="fnDelete(item.order_id)"
                  >
                    删除订单
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style="text-align: center; margin: 160px auto"
            v-show="data.length == 0"
          >
            <el-image
              style="width: 160px; height: 160px; margin-bottom: 16px"
              :src="require('@/assets/quesheng/no_content.png')"
              fit="cover"
            ></el-image>
            <div class="font-666-14">暂无内容~</div>
          </div>

          <div
            class="align-between"
            style="border: none; padding-left: 0; margin-top: 22px"
            v-show="total > 0"
          >
            <div class="align-start">
              <input
                type="checkbox"
                name=""
                value=""
                class="checkbox"
                id="allCheck"
                @change="allCheck"
              />
              <div class="font-333-16">全选</div>
              <div
                class="kuang33 pointer align-center font-fff-16"
                style="margin-left: 12px"
                @click="initDownOrder('dao')"
              >
                订单导出
              </div>
            </div>
            <div class="align-center font-777">
              <Page
                :limit="limit"
                :totalNum="total"
                @onLimit="changeLimit"
                @onPage="changePage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible4"
      width="840px"
      title="售后详情"
      center
    >
      <div class="dialog">
        <div class="align-start" style="margin-bottom: 14px">
          <div class="width115">售后类型:</div>
          <div>
            {{ ShObj.order_refund_type || '' }}
          </div>
        </div>
        <div class="align-start" style="margin-bottom: 14px">
          <div class="width115">售后原因:</div>
          <div>
            {{ ShObj.order_refund_reason || '' }}
          </div>
        </div>
        <div class="flex-wrap" style="margin-bottom: 14px">
          <div class="width115">图片:</div>
          <div class="flex-con after">
            <el-image
              style="
                width: 200px;
                min-width: 200px;
                height: 200px;
                margin: 0 16px 16px 0;
              "
              fit="cover"
              v-for="(item, index) in ShObj.order_refund_imgs"
              :key="'order_refund_imgs_' + index"
              :src="item"
            ></el-image>
          </div>
        </div>
        <div
          class="align-start"
          style="margin-bottom: 14px"
          v-show="ShObj.order_refund_status == 3"
        >
          <div class="width115">拒绝原因:</div>
          <div>
            {{ ShObj.order_refund_remark || '' }}
          </div>
        </div>
        <div
          class="align-start"
          style="margin-bottom: 14px"
          v-show="ShObj.order_refund_status == 2"
        >
          <div class="width115">已同意</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" width="840px">
      <div class="dialog">
        <div class="align-start font-333" style="margin-bottom: 14px">
          <div class="">
            <a :href="orderUrl">{{ orderUrl }} 点击下载</a>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible111"
      width="980px"
      custom-class="confirm_dialog"
    >
      <div class="dialog" style="padding: 0">
        <div class="dialog_title align-start"> <img :src="require('@/assets/tip_delete.png')" alt="" style="width:26px;height:26px;margin-right:18px;">{{txtCancle}}订单</div>
        <div class="font-333-18-bold" style="text-align: center;margin-top: 36px;">
          确认{{ txtCancle }}吗？
        </div>
        <div class="align-center" style="margin-top: 36px">
          <div
            class="dialog_btn2 align-center pointer"
            @click="dialogVisible111 = false"
          >
            取消
          </div>
          <div class="dialog_btn1 align-center pointer" @click="fnCun">
            确认
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible2"
      title="本牛平台-产品供销合同"
      center
      width="1040px"
      custom-class="ht_dialog"
    >
      <div class="dialog">
        <div style="line-height: 24px">
          <div class="font-333-18-bold m_b30">合同编号：<span style="color:#F18D16;">{{ tableData.order_number }}</span></div> 
          <div class="align-between m_b10">
            <div class="font-333-18-bold">供方：<span class="font-666-18">本牛（江苏）电子商务有限公司</span></div> 
            <div class="font-333-18-bold" style="width: 300px">签订地点：<span class="font-666-18">江苏泰兴</span></div>
          </div>
          <div class="m_b20">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="font-666-18">本牛平台系统（www.niutop.com/小程序）</span>
          </div>
          <div class="align-between">
            <div class="font-333-18-bold">
              需方：<span class="font-666-18">{{
                user_type == 1 ? tableData.order_name : user_company_name
              }}</span>
            </div>
            <div class="font-333-18-bold" style="width: 300px">
              签订时间：<span class="font-666-18">{{ tableData.order_create_time }}</span>
            </div>
          </div>
        </div>
        <table class="table m_t30" style="table-layout: automatic">
          <tr class="font-666-18-bold">
            <td>序号</td>
            <td>商品名</td>
            <td>规格</td>
            <td>数量×单位</td>
            <td>价格</td>
            <td>小计</td>
            <!-- <td>支付状态</td> -->
          </tr>
          <tr v-for="(item, index) in tableData.goods" :key="'table_' + index" class="font-666-18">
            <td>{{ index + parseInt(1) }}</td>
            <td>{{ item.goods_name || '' }}</td>
            <td>
              <div class="flex-wrap">
                <div>
                  <div
                    v-for="(item, index) in item.guige_name"
                    :key="'table_' + item.goods_id + '_' + index"
                  >
                    {{ item }}：
                  </div>
                </div>
                <div>
                  <div
                    v-for="(item, index) in item.guige_value"
                    :key="'table1_' + item.goods_id + '_' + index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </td>
            <td>{{ item.num || 0 }} × {{ item.gu_name.split('/')[1] }}</td>
            <td>{{ item.unit_price>0?item.unit_price:'面议' }}</td>
            <td>
              {{ item.unit_price>0?((item.unit_price * item.num * 100) / 100).toFixed(2): '面议' }}
            </td>
            <!-- <td :rowspan="tableData.goods.length" v-show="index == 0">
              待支付
            </td> -->
          </tr>
        </table>
        <div class="font-333-18-bold" style="text-align: right; margin: 15px 0"> 
          总计(含税、含运费)：<span style="color:#F18D16;">{{ tableData.order_price>0?tableData.order_price: '面议'  }}</span>
          <span style="color:#F18D16;" v-show="tableData.order_price>0">(大写：{{
            tableData.moneyCn
          }})</span>
          
          
        </div>

        <div style="line-height: 32px" class="font-666-18">
          <!-- <p>
            1.交货地点：<span style="text-decoration: underline">{{
              tableData.order_address || ''
            }}</span
            >。收货人：<span style="text-decoration: underline">{{
              tableData.order_name || ''
            }}</span
            >，联系电话：<span style="text-decoration: underline">{{
              tableData.order_phone || ''
            }}</span
            >。
          </p> -->
          <div>
            供需双方本着平等互利的合作原则，就产品供需一事，经友好协商达成如下条款：
          </div>
          <div>
            一、产品名称、型号、规格、数量、金额：按订单要求。单价按双方订单报价约定。单价会受原料、运输价格、市场行情等因素影响，产生溢价波动，供方有权进行价格调整，具体调整幅度根据实际情况确定。
          </div>
          二、质量要求、技术标准：供方按需方要求提供所需产品，供方应保证所有提供给需方的产品，是按行业标准或企业标准生产的合格产品。
          <br />
          三、包装标准、包装物的供应与回收：费用由供方承担，包装物不予回收，由需方按照相关规定合理处理，否则需另行商定费用。
          <br />
          四、交货时间、具体数量：供方凭需方采购订单（可通过本牛平台系统、邮件、企业微信等方式下单）生产/备货/发货，一般情况7个工作日之内完成发货。
          <br />
          五、交货地点、运输方式及费用负担：交货地点<span
            style="text-decoration: underline"
            >{{ tableData.order_address || '' }}</span
          >
          ，运输方式汽运，运费由供方承担，产品毁损、灭失的风险自供方交与第一承运人后由供方承担，在需方签收货物后由需方承担。
          <br />
          六、货物的交付：供方发货时须随附付货清单，货物到达需方指定地点后，为保证货物安全，需方事先在本牛平台系统填写指定专人签收货物，指定签收人
          <span style="text-decoration: underline">{{
            tableData.order_name || ''
          }}</span
          >（联系电话：<span style="text-decoration: underline">{{
            tableData.order_phone || ''
          }}</span
          >）
          凭单查验货物，并签字或盖章确认。如签收人变动时，需方要及时本牛平台系统内修改信息，确保指定签收有效。供方未收到需方签收人变动的信息时，原签收人同样有效。若指定签收人未能签收送货单据，需方现场其他工作人员的签收依然合法有效。
          <br />
          七、货物的验收：货到柒天内验收，逾期视为产品合格。<br />
          八、结算方式： <br />
          1、现款现货，即需方以本牛平台系统直接付款或电汇方式付清采购订单全款后供方再安排发货。需方须指定签收人
          <span style="text-decoration: underline">{{
            tableData.order_name || ''
          }}</span>
          接受供方开具的发票，如发票签收人变动时，需方要及时在本牛平台系统修改信息，确保指定签收有效。供方未收到需方发票签收人变动的信息时，原签收人同样有效。
          <br />九、违约责任：
          <br />1、任一方违反本合同约定，违约方需赔偿守约方因此遭受的所有损失。
          <br />
          2、若需方无故退货或者拒收的，供方有权单方终止本合同，供方可以选择按照合同总金额的25%或者该批次订单金额的35%向需方主张有关责任，若上述金额低于500元的，按照500元计算，若上述金额不足以弥补供方损失的，供方可以继续主张有关权利。
          <br />3、本合同损失包括但不限于直接损失、利润亏损、第三人索赔、及为主张权利而产生的合理费用，如差旅费、调查取证费、律师费、诉讼费等。
          <br />4、若受新冠疫情影响或其他不可抗力因素导致逾期交货的，不认为是违约，交货时间相对应可以递延。
          <br />十、解决合同纠纷的方式：由双方当事人友好协商解决；协商或者调解不成的，均可向合同签订地人民法院提起诉讼。
          <br />十一、本合同壹式贰份，供、需双方各执壹份，电子合同存档，均具同等法律效力，并经双方盖章后生效。
          <br />十二、未尽事宜由双方协商解决。
          <br />十三、开户行:工商银行滨江支行，银行账号: 1115927319000037085
        </div>

        <div
          class="align-between font-333-18-bold"
          style="
            text-align: center;
            font-weight: 600;
            margin: 30px 0;
            padding: 0 40px;
          "
        >
          <div>
            <div>
              需方： {{ tableData.order_contact_name || showcname || '' }}
            </div>
            <div style="margin: 10px 0">_____________________________</div>
            <div>(代表人签字)</div>
          </div>
          <div style="position: relative">
            <img
              src="https://benbull.oss-cn-nanjing.aliyuncs.com/07e17e6e13ce21fe14bfdd3c357d6932.png"
              alt=""
              style="position: absolute; width: 100px; height: 100px; left: 35%"
            />
            <div>供方： 本牛 (江苏)电子务有限公司(章)</div>
            <div style="margin: 10px 0">_____________________________</div>
            <div>(代表人签字)</div>
          </div>
        </div>
        <div class="align-end">
          <div
            class="btn222 align-center pointer"
            @click="fnToQs"
            style="margin-top: 50px"
            v-show="
              (tableData.order_is_pay == 1 || tableData.order_is_pay == 2) &&
              tableData.order_contact_down_can == 1
            "
          >
            签 署
          </div>
        </div>
        <div
          class="align-end"
          style="padding-top: 50px"
          v-show="tableData.order_contact_down_can > 1"
        >
          <div>(合同已签署，点击下载或打印)</div>
          <div
            class="btn222 align-center pointer"
            @click="fnToDown"
            style="margin-left: 30px"
          >
            下 载
          </div>
          <div
            class="btn222 align-center pointer"
            @click="fnToDown(1)"
            style="margin-left: 30px"
          >
            打 印
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible3" width="940px">
      <div class="dialog">
        <div class="align-start font-333-18-bold" style="margin-bottom: 40px">
          <div class="width145"><span class="E28832">*</span>主体:</div>
          <div class="align-start" style="margin-right: 16px">
            <input
              type="radio"
              name="fp_user_type"
              value="1"
              v-model="obj.contact_type"
              @change="changeCType()"
            />
            <div class="font-333-18">个人</div>
          </div>
          <div class="align-start">
            <input
              type="radio"
              name="fp_user_type"
              value="2"
              v-model="obj.contact_type"
              @change="changeCType()"
            />
            <div class="font-333-18">企业</div>
          </div>
        </div>
        <div class="align-start font-333-18-bold" style="margin-bottom: 20px">
          <div class="width145"><span class="E28832">*</span>名称:</div>
          <input
            type="text"
            placeholder="请输入名称"
            v-model="obj.contact_name"
            class="htInput"
          />
        </div>
        <div class="align-start font-333-18-bold" style="margin-bottom: 50px">
          <div class="width145"><span class="E28832">*</span>手机号:</div>
          <input
            type="text"
            name=""
            value=""
            placeholder="请输入手机号"
            v-model="obj.contact_phone"
            class="htInput"
          />
        </div>
        <div class="align-center" style="margin-top: 40px">
          <div
            class="btn2 btn1 align-center pointer"
            @click="dialogVisible3 = false"
          >
            取消
          </div>
          <div class="btn2 align-center bg_f08300 pointer" @click="fnSureHt">
            确定
          </div>
        </div>
      </div>
    </el-dialog>
    <Footer />
    <!-- <right /> -->
    <iframe style="display: none" id="printIframe" :src="htPdf"></iframe>
  </div>
</template>

<script>
import right from '@/components/right.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import MyInfo from '@/components/MyInfo.vue'
import {
  limit,
  myOrders,
  cancleOrder,
  receiptMyOrder,
  delMyOrder,
  onlyPay,
  orderDownload,
  qianshu,
  getContactDownurl,
  HOST_Url,
} from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    MyInfo,
    right,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      value1: '',
      page: 1,
      type: 0,
      data: [],
      limit: limit,
      total: 0,
      order_no: '',
      orderUrl: '',
      dialogVisible111: false,
      iddDelete: '',
      tableData: {},
      showcname: '',
      obj: {
        contact_type: 1,
        contact_name: '',
        contact_phone: '',
      },
      htPdf: '',
      user_company_name: '',
      user_type: '',
      order_num: '',
      isCancle: false,
      txtCancle: '删除',
      ShObj: {},
    }
  },
  methods: {
    fnShXq(item) {
      this.ShObj = item
      this.dialogVisible4 = true
    },
    fnToDown(type) {
      let params = {
        order_id: this.tableData.order_id,
      }
      getContactDownurl(params).then((data) => {
        if (data.status == 200) {
          if (type == 1) {
            //打印
            this.htPdf = data.msg
            setTimeout(() => {
              document.getElementById('printIframe').contentWindow.print()
            }, 300)
          } else {
            window.location =
              HOST_Url + 'api/Timing/xiazai?order_id=' + this.tableData.order_id
          }
        }
      })
    },
    changeCType() {
      if (this.obj.contact_type == 1) {
        this.obj.contact_name = this.tableData.order_name || ''
      } else {
        this.obj.contact_name = this.showcname || ''
      }
      this.obj.contact_phone = this.tableData.order_phone || ''
    },
    lookHt(item) {
      this.dialogVisible2 = true
      this.tableData = item
      // console.log(this.tableData);
    },
    fnToQs() {
      // console.log(this.tableData);
      if (this.tableData.order_contact_phone) {
        this.qianHt(1)
      } else {
        this.dialogVisible3 = true
        this.obj.contact_name = this.tableData.order_name || ''
        this.obj.contact_phone = this.tableData.order_phone || ''
      }
    },
    fnSureHt() {
      this.qianHt()
    },
    qianHt(type) {
      let params
      if (type) {
        params = {
          order_id: this.tableData.order_id,
        }
      } else {
        if (!this.obj.contact_name) {
          this.$message.error('请输入名称')
          return
        }
        if (!this.obj.contact_phone) {
          this.$message.error('请输入手机号')
          return
        }
        params = {
          order_id: this.tableData.order_id,
          contact_type: this.obj.contact_type,
          contact_name: this.obj.contact_name,
          contact_phone: this.obj.contact_phone,
        }
      }
      qianshu(params).then((data) => {
        if (data.status == 200) {
          this.dialogVisible2 = false
          this.dialogVisible3 = false
          window.open(data.msg)
        }
      })
    },
    initData() {
      let params = {
        page: this.page,
        type: this.type,
        order_no: this.order_no,
      }
      myOrders(params).then((data) => {
        if (data.status == 200) {
          // console.log("674||" + this.order_num);
          this.data = data.msg
          this.total = data.orderNums
          this.showcname = data.showcname
          if (this.order_num) {
            this.toHere(this.order_num)
          }
        }
      })
    },
    initDownOrder(order_id) {
      let all_idd = []
      if (order_id == 'dao') {
        let allCk2 = document.querySelectorAll("input[name='checkbox']:checked")
        if (allCk2.length == 0) {
          this.$message.error('请选择订单')
          return
        }
        allCk2.forEach((element) => {
          all_idd.push(element.dataset.idd)
        })

        // console.log(all_idd.join(","));
        // return
      }
      let params = {
        type: this.type,
        ids: order_id == 'dao' ? all_idd.join(',') : order_id,
      }
      orderDownload(params).then((data) => {
        if (data.status == 200) {
          // window.location.href = data.msg
          // this.data = data.msg
          this.orderUrl = data.msg2
          window.location.href = this.orderUrl
          // this.dialogVisible = true
        }
      })
    },
    fnSearch() {
      this.page = 1
      this.initData()
    },
    fnPay(order_id, order_pay_type) {
      let params = {
        order_id: order_id,
        pay_type: order_pay_type,
        payFrom: 1,
      }
      onlyPay(params).then((data) => {
        if (data.status == 200) {
          if (order_pay_type == 1) {
            return
          } else if (order_pay_type == 2) {
            //支付宝
            window.open(data.msg)
          } else if (order_pay_type == 4) {
            this.$router.push({
              name: 'Pay',
              params: {
                id: '.html',
                type: order_pay_type,
                number: data.number,
                price: data.price,
                order_id: order_id,
              },
            })
          } else {
            //微信
            this.$router.push({
              name: 'Pay',
              params: {
                id: '.html',
                url: data.msg,
                type: order_pay_type,
                order_id: order_id,
              },
            })
          }
        } else if (data.status == 100) {
          return
        }
      })
    },
    fnOrderXq(idd) {
      this.$router.push({
        name: 'OrderXq',
        params: {
          id: idd + '.html',
        },
      })
    },
    fnCancle(idd) {
      this.txtCancle = '取消'
      this.isCancle = true
      this.dialogVisible111 = true
      this.iddDelete = idd
    },
    // fnCancle(idd) {
    //   let params = {
    //     order_id: idd,
    //   };
    //   cancleOrder(params).then((data) => {
    //     if (data.status == 200) {
    //       this.initData();
    //     }
    //   });
    // },
    fnCun() {
      let params = {
        order_id: this.iddDelete,
      }
      let delOrCle = delMyOrder
      if (this.isCancle) {
        delOrCle = cancleOrder
      }
      delOrCle(params).then((data) => {
        if (data.status == 200) {
          this.dialogVisible111 = false
          this.initData()
        }
      })
    },
    fnDelete(idd) {
      this.txtCancle = '删除'
      this.isCancle = false
      this.dialogVisible111 = true
      this.iddDelete = idd
    },
    // fnDelete(idd) {
    //   let params = {
    //     order_id: idd,
    //   }
    //   delMyOrder(params).then((data) => {
    //     if (data.status == 200) {
    //       this.initData()
    //     }
    //   })
    // },
    fnShou(idd) {
      let params = {
        order_id: idd,
      }
      receiptMyOrder(params).then((data) => {
        if (data.status == 200) {
          this.initData()
        }
      })
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      // console.log(this.page+'|||'+childrenData);
      if (this.page != childrenData) {
        document.querySelector('#allCheck').checked = false
      }
      this.page = childrenData
      this.initData()
    },
    oneCheck(e) {
      // console.log(e.target.checked)
      let allCk1 = document.querySelectorAll("input[name='checkbox']")
      let allCk2 = document.querySelectorAll("input[name='checkbox']:checked")
      if (allCk1.length == allCk2.length && allCk2.length > 0) {
        document.querySelector('#allCheck').checked = true
      } else if (allCk1.length != allCk2.length) {
        document.querySelector('#allCheck').checked = false
      }
    },
    allCheck(e) {
      let allCk = document.querySelectorAll("input[name='checkbox']")
      if (allCk.length > 0) {
        allCk.forEach((element) => {
          if (e.target.checked) {
            element.checked = true
          } else {
            element.checked = false
          }
        })
      }
    },
    initDataShua(idd) {
      // console.log(idd);
      this.type = idd
      this.initData()
    },
    toHere(num) {
      document.getElementById('order_' + num).scrollIntoView(true)
    },
  },
  mounted() {
    document.title = '本牛'
    // console.log(localStorage.getItem('allUser'))
    this.user_type = JSON.parse(localStorage.getItem('allUser')).user_type
    this.user_company_name = JSON.parse(
      localStorage.getItem('allUser')
    ).user_company_name

    // user_type:''
    // alert(HOST_Url)
    // this.$bus.$on('orderType', (val) => {
    //   console.log('376|||' + val)
    //   if (val || val == 0) {
    //     this.type = val
    //     this.initData()
    //   }
    // })
    let can = this.$route.params.id.split('.')[0]
    this.type = can
    this.initData()
  },
}
</script>

<style scoped>
::v-deep .confirm_dialog.el-dialog {
  border-radius: 4px;
}
::v-deep .confirm_dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 42px;
}
::v-deep .confirm_dialog .el-dialog__header {
  padding: 0;
}
::v-deep .confirm_dialog .el-dialog__headerbtn {
  position: absolute;
  top: 22px;
  right: 40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 24px;
}
::v-deep .confirm_dialog .el-dialog__headerbtn .el-dialog__close {
  color: #979797;
}
::v-deep .ht_dialog .el-dialog__title{
  font-size: 24px;
font-weight: 500;
color: #333333;
padding:45px  0 68px;
}
::v-deep .ht_dialog .el-dialog__header{
  padding: 45px 64px 68px;
}
::v-deep .ht_dialog  .el-dialog__body {

    padding: 25px 64px 30px;
}
.kuang11 {
  width: 103px;
  height: 27px;
  border: 1px solid #d8d8d8;
}
.kuang22 {
  width: 82px;
  height: 27px;
  border: 1px solid #d8d8d8;
}
.kuang11:hover,
.kuang22:hover {
  border: 1px solid #fa9c22;
  color: #fa9c22;
}

.kuang33 {
  width: 103px;
  height: 27px;
  background: #fa9c22;
  /* border: 1px solid #D8D8D8; */
}
.table,
tr,
td {
  border-collapse: collapse;
  border: 1px solid #bbb;
  width: 100%;
  text-align: center;
}
td {
  width: 14.2%;
  text-align: center;
  padding: 15px 5px;
  line-height: 20px;
}
td:nth-child(2) {
  /* text-align: left; */
}
.table {
  table-layout: automatic;
  margin-top: 30px;
}

.order_status div {
  margin-top: 10px;
}
.order_status div:first-child {
  margin: 0;
}
.order_header {
  height: 46px;
  padding: 0 12px 0 22px;
  background: rgba(255, 226, 189, 0.2);
}
.order_box {
  /* background: #ffffff; */
  border-radius: 4px;
}

.my_order {
  margin-bottom: 16px;

  border: 1px solid #eeeeee;
}

input[type='text'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-right: none;
  box-sizing: border-box;
  height: 27px;
  width: 125px;
  padding: 0 12px;
  color: #333333;
  font-size: 16px;
  outline: none;
}
input[type='text']::-webkit-input-placeholder {
  color: #999;
}
input[type='text']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-right: none;
}
.search_txt {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  width: 52px;
  height: 27px;
  background: #fa9c22;
}
.btn_padding {
  padding: 1px 11px;
  margin-left: 10px;
  border: 1px solid #d8d8d8;
}
.ljzf {
  display: inline-block;
  width: 103px;
  height: 27px;
  border: 1px solid #fa9c22;
  font-size: 16px;
  font-weight: 500;
  color: #fa9c22;
  line-height: 24px;
}
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  margin: 0;
  /* border: 1px solid #d8d8d8; */
  margin-right: 12px;
}
input[type='radio'],
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  margin-right: 12px;
  background: url('@/assets/check2.png') no-repeat center center;
  background-size: 100% 100%;
}
input[type='radio']:checked,
input[type='checkbox']:checked {
  background: url('@/assets/checked21.png') no-repeat center center;
  background-size: 100% 100%;
}
.order_item {
  margin-top: 16px;
  background-color: #fff;

  border: 1px solid #eeeeee;
}

.C52B33 {
  color: #c52b33;
}
.shopping > div {
  margin-bottom: 16px;
}
.shopping > div:last-child {
  margin-bottom: 0;
}
.my_right {
  background-color: #fff;
  padding: 10px;
}

.width115 {
  width: 115px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;
}

.width145 {
  width: 145px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;
}
.btn2 {
  width: 115px;
  height: 32px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 16px;
  color: #ffffff;
  margin-left: 20px;
}
.btn1 {
  color: #777777;
  background: none;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
}
input[type='text'].htInput {
  background: #ffffff;
  border: 1px solid #eee;
  box-sizing: border-box;
  height: 38px;
  width: 706px;
  padding: 0 12px;
  color: #333333;
  font-size: 18px;
  outline: none;
}
input[type='text'].htInput::-webkit-input-placeholder {
  color: #aaa;
}
input[type='text'].htInput:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
}
.none {
  display: none;
}
</style>
<style media="print">
@page {
  size: auto; /* auto is the initial value */
  margin: 0mm; /* this affects the margin in the printer settings */
} /*使用css控制布局去掉页眉页脚的方法  ，ie 360 谷歌 都支持 */
</style>

import http from '../utils/request'


export const HOST_Url = 'https://service.niutop.com/'
// export const HOST_Url = 'https://niutop.xuanwumobile.com/'



// export const userQuery=(data) => {//查询用户列表
//     return http.post('user/query',data)
// }

export const limit = 20



export const joinInNiu = (data) => { 
  //招商中心
  return http.post('api/Home/joinInNiu', data)
}

export const getHelps = (data) => {
  //帮助中心
  return http.post('api/Home/getHelps', data)
}

export const getHelpInfo = (data) => {
  //帮助中心详情
  return http.post('api/Home/getHelpInfo', data)
}

export const kefuOnline = (data) => {
  //客服在线判断
  return http.post('api/Ma/kefuOnline', data)
}

export const pcRegister = (data) => {
  //注册
  return http.post('api/Login/pcRegister', data)
}

export const sendCode = (data) => {
  //发送验证码
  return http.post('api/Login/sendCode', data)
}
export const codeLogin = (data) => {
  //验证码登录
  return http.post('api/Login/codeLogin', data)
}

export const passwordLogin = (data) => {
  //手机号密码登录
  return http.post('api/Login/passwordLogin', data)
}

export const forgetPasswordFirstStep = (data) => {
  //忘记密码第一步
  return http.post('api/Login/forgetPasswordFirstStep', data)
}

export const forgetPasswordSecondStep = (data) => {
  //忘记密码第二步
  return http.post('api/Login/forgetPasswordSecondStep', data)
}

export const servicePolicy = (data) => {
  //服务政策
  return http.post('api/Foot/servicePolicy', data)
}

export const aboutUs = (data) => {
  //关于我们
  return http.post('api/Foot/aboutUs', data)
}

export const aboutUsDetail = (data) => {
  //关于我们详情
  return http.post('api/Foot/aboutUsDetail', data)
}

export const contactUs = (data) => {
  //联系我们
  return http.post('api/Foot/contactUs', data)
}
export const indexData = (data) => {
  //首页数据
  return http.post('api/Home/indexData', data)
}
export const goodsCate = (data) => {
  //首页二级、三级分类
  return http.post('api/Home/goodsCate', data)
}
export const appList = (data) => {
  //应用领域列表
  return http.post('api/Apps/appList', data)
}

export const appDetail = (data) => {
  //应用领域详情
  return http.post('api/Apps/appDetail', data)
}

export const loginAgreement = (data) => {
  //配置
  return http.post('api/Foot/loginAgreement', data)
}

export const doCollect = (data) => {
  //收藏/取消收藏
  return http.post('api/Home/doCollect', data)
}

export const goodsList = (data) => {
  //产品列表
  return http.post('api/Home/goodsList', data)
}

export const secondAndThirdCate = (data) => {
  //产品列表分类 一级二级三级全部
  return http.post('api/Home/secondAndThirdCate', data)
}

export const selectGoodsCate = (data) => {
  //产品列表分类 点击二级获取该二级的全部三级
  return http.post('api/Home/selectGoodsCate', data)
}

export const getGoodsCateInfo = (data) => {
  //产品列表分类 点击三级获取所属二级信息
  return http.post('api/Home/getGoodsCateInfo', data)
}

export const goodsDetail = (data) => {
  //产品详情
  return http.post('api/Home/goodsDetail', data)
}

export const goodsJoinDetail = (data) => {
  //获取产品上面各级信息
  return http.post('api/Home/goodsJoinDetail', data)
}

export const getAttrInfo = (data) => {
  //获取某个sku的价格
  return http.post('api/Selfcar/getAttrInfo', data)
}

export const putGoodsInCar = (data) => {
  //加入购物车
  return http.post('api/Selfcar/putGoodsIncar', data)
}

export const mycarPc = (data) => {
  //我的购物车
  return http.post('api/Selfcar/mycarPc', data)
}

export const carDel = (data) => {
  //购物车——删除
  return http.post('api/Selfcar/carDel', data)
}

export const carUpDown = (data) => {
  //购物车——加减
  return http.post('api/Selfcar/carUpDown', data)
}

export const carSetNum = (data) => {
  //购物车——设置值
  return http.post('api/Selfcar/carSetNum', data)
}

export const sureOrderCar = (data) => {
  //查看订单详情（从购物车处）
  return http.post('api/Selfcar/sureOrderCar', data)
}

export const myAddress = (data) => {
  //我的地址
  return http.post('api/My/myAddress', data)
}

export const myAddressAdd = (data) => {
  //新增地址
  return http.post('api/My/myAddressAdd', data)
}

export const myAddressEdit = (data) => {
  //编辑地址
  return http.post('api/My/myAddressEdit', data)
}

export const getOneAddress = (data) => {
  //我的地址详情
  return http.post('api/My/getOneAddress', data)
}

export const delAddress = (data) => {
  //删除我的地址
  return http.post('api/My/delAddress', data)
}

export const getArea = (data) => {
  //获取省市区
  return http.post('api/Base/getArea', data)
}

export const getAreaCity = (data) => {
  //获取省市
  return http.post('api/Base/getAreaCity', data)
}

export const insertFaPiao = (data) => {
  //新增发票
  return http.post('api/Home/insertFaPiao', data)
}

export const faPiaoList = (data) => {
  //我的发票列表
  return http.post('api/Home/faPiaoList', data)
}

export const setDefault = (data) => {
  //我的发票设置默认
  return http.post('api/Home/setDefault', data)
}

export const faPiaoUpdate = (data) => {
  //编辑发票
  return http.post('api/Home/faPiaoUpdate', data)
}

export const delFP = (data) => {
  //删除发票
  return http.post('api/Home/delFP', data)
}

export const payOrder = (data) => {
  //购买
  return http.post('api/Selfcar/payOrder', data)
}

export const sureOrder = (data) => {
  //立即购买商品--看详情
  return http.post('api/Selfcar/sureOrder', data)
}

export const myOrders = (data) => {
  //我的订单
  return http.post('api/Selforder/myOrders', data)
}

export const orderDownload = (data) => {
  //订单导出
  return http.post('api/Selforder/orderDownload', data)
}

export const myOrderInfo = (data) => {
  //我的某一个订单详情
  return http.post('api/Selforder/myOrderInfo', data)
}

export const cancleOrder = (data) => {
  //手动取消订单
  return http.post('api/Selforder/cancleOrder', data)
}

export const receiptMyOrder = (data) => {
  //确认收货
  return http.post('api/Selforder/receiptMyOrder', data)
}

export const delMyOrder = (data) => {
  //删除订单
  return http.post('api/Selforder/delMyOrder', data)
}

export const onlyPay = (data) => {
  //单独为未支付订单做的支付
  return http.post('api/Selfcar/onlyPay', data)
}

export const mycarNum = (data) => {
  //购物车数量
  return http.post('api/Selfcar/mycarNum', data)
}

export const getWlInfo = (data) => {
  //查看物流详情
  return http.post('api/Selforder/getWlInfo', data)
}

export const afterSaleOrder = (data) => {
  //申请售后
  return http.post('api/Selforder/afterSaleOrder', data)
}

export const qianshu = (data) => {
  //获取签署合同链接
  return http.post('api/Selforder/qianshu', data)
}

export const getContactDownurl = (data) => {
  //获取合同下载链接
  return http.post('api/Selforder/getContactDownurl', data)
}


export const showUserBase = (data) => {
  //用户基础信息展示
  return http.post('api/Home/showUserBase', data)
}

export const updateUserBase = (data) => {
  //更新用户基本信息
  return http.post('api/Home/updateUserBase', data)
}

export const checkOldPhone = (data) => {
  //验证旧手机号
  return http.post('api/Home/checkOldPhone', data)
}

export const bandNewPhone = (data) => {
  //绑定新手机号
  return http.post('api/Home/bandNewPhone', data)
}

export const getPost = (data) => {
  //岗位列表
  return http.post('api/Home/getPost', data)
}

export const getBuy = (data) => {
  //购买意向列表
  return http.post('api/Home/getBuy', data)
}

export const getIndustry = (data) => {
  //行业类别列表
  return http.post('api/Home/getIndustry', data)
}

export const commitApply = (data) => {
  //提交企业认证
  return http.post('api/Home/commitApply', data)
}

export const showAuth = (data) => {
  //企业认证信息展示
  return http.post('api/Home/showAuth', data)
}

export const authStatus = (data) => {
  //企业认证状态
  return http.post('api/Home/authStatus', data)
}

export const uploadFile = (data) => {
  //上传文件
  return http.post('api/Base/uploadFile', data)
}

export const myActivity = (data) => {
  //我的活动
  return http.post('api/Home/myActivity', data)
}

export const myCollect = (data) => {
  //我的收藏
  return http.post('api/Home/myCollect', data)
}

export const myLookHistory = (data) => {
  //我的浏览历史
  return http.post('api/Home/myLookHistory', data)
}

export const delLookHistory = (data) => {
  //删除浏览历史
  return http.post('api/Home/delLookHistory', data)
}

export const insertLookHistory = (data) => {
  //插入浏览历史
  return http.post('api/Home/insertLookHistory', data)
}

export const tiChengList = (data) => {
  //提成明细
  return http.post('api/Home/tiChengList', data)
}

export const activityList = (data) => {
  //活动列表--8条
  return http.post('api/Home/activityList', data)
}

export const allActivityList = (data) => {
  //所有活动列表
  return http.post('api/Home/allActivityList', data)
}

export const activityDetail = (data) => {
  //活动详情
  return http.post('api/Home/activityDetail', data)
}

export const applyActivity = (data) => {
  //报名活动
  return http.post('api/Home/applyActivity', data)
}

export const afterSaleCate = (data) => {
  //售后类型
  return http.post('api/Home/afterSaleCate', data)
}

export const policyDetail = (data) => {
  //服务政策详情
  return http.post('api/Foot/policyDetail', data)
}

export const fxxy = (data) => {
  //个人中心->分销协议
  return http.post('api/Home/fxxy', data)
}

export const brandOrder = (data) => {
  //品牌订制
  return http.post('api/Home/brandOrder', data)
}

export const appOrder = (data) => {
  //应用领域订制
  return http.post('api/Apps/appOrder', data)
}

export const myLower = (data) => {
  //推广用户
  return http.post('api/Home/myLower', data)
}

export const informationList = (data) => {
  //内容资讯
  return http.post('api/Home/informationList', data)
}

export const oneInfoList = (data) => {
  //某个分类下的资讯列表
  return http.post('api/Home/oneInfoList', data)
}

export const infoDetail = (data) => {
  //资讯详情
  return http.post('api/Home/infoDetail', data)
}

export const noticeDetail = (data) => {
  //公告详情
  return http.post('api/Home/noticeDetail', data)
}

export const hotSearch = (data) => {
  //热门搜索
  return http.post('api/Home/hotSearch', data)
}

export const bannerDetail = (data) => {
  //轮播图详情
  return http.post('api/Home/bannerDetail', data)
}

export const storeInfo = (data) => {
  //店铺详情
  return http.post('api/Store/storeInfo', data)
}

export const getCateInfoByUrl = (data) => {
  //获取分类id
  return http.post('api/Home/getCateInfoByUrl', data)
}

export const tdkPages = (data) => {
  //获取tdk
  return http.post('api/Home/tdkPages', data)
}


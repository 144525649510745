<template>
  <div class="footer_contain">
    <div class="footer">
      <div class="align-between">
        <div
          class="align-center"
          v-for="(item, index) in bnPtys"
          :key="'bnPtys_' + index"
        >
          <img
            :src="item.advantage_icon1"
            alt=""
            style="
              margin-right: 10px;
              width: 48px;
              height: 48px;
              display: block;
            "
          />
          <div class="font-fff-20-bold">{{ item.advantage_name || '' }}</div>
        </div>
      </div>
      <div class="flex-wrap" style="margin: 35px 0 0">
        <div class="footer_item">
          <div
            class="font-fff-20-bold"
            style="margin-bottom: 30px; padding-top: 15px"
          >
            服务政策
          </div>
          <div
            class="font-999-16 pointer"
            style="margin-top: 9px"
            v-for="(item, index) in data3"
            :key="'data3_' + index"
            @click="fnFw(item.service_id)"
          >
            {{ item.service_title }}
          </div>
        </div>
        <div class="flex-con"></div>
        <div class="flex-con"></div>
        <div class="footer_item">
          <div
            class="font-fff-20-bold"
            style="margin-bottom: 30px; padding-top: 15px"
          >
            关于我们
          </div>
          <div
            class="font-999-16 pointer"
            style="margin-top: 9px"
            v-for="(item, index) in data1"
            :key="'data1_' + index"
            @click="fnAboutUsDetail(item.about_id)"
          >
            {{ item.about_title }}
          </div>
          <div
            class="font-999-16 pointer"
            style="margin-top: 9px"
            @click="fnJoinUs"
          >
            加入我们
          </div>
        </div>
        <div class="flex-con"></div>
        <div class="flex-con"></div>
        <div class="footer_item">
          <div
            class="font-fff-20-bold"
            style="margin-bottom: 30px; padding-top: 15px"
          >
            联系我们
          </div>
          <div
            class="align-start font-999-18"
            style="margin-top: 20px"
            v-for="(item, index) in data2"
            :key="'data2_' + index"
          >
            <!-- <el-image
              style="width: 20px; height: 20px; margin-right: 9px"
              :src="item.contact_icon"
              fit="cover"
            ></el-image> -->
            <div>{{ item.contact_icon || '' }}：</div>
            <div>{{ item.contact_content || '' }}</div>
          </div>
        </div>

        <div class="flex-con"></div>
        <div class="flex-con"></div>

        <div>
          <div class="align-center">
            <el-image
              style="width: 50px; height: 50px; margin-right: 12px"
              :src="require('@/assets/phone2.png')"
              fit="cover"
            ></el-image>
            <div class="font-fff-33-bold">{{ phoneHeader }}</div>
          </div>
          <div class="align-center font-fff-18" style="margin: 12px 0 14px">
            <!-- 工作时间: 周一到周五 9:00-18:00 -->
          </div>
          <div
            style="border-top: 2px solid #626262; padding: 15px 22px 0"
            class="align-between"
          >
            <div>
              <el-image
                style="width: 98px; height: 98px; margin-bottom: 8px"
                :src="data.content2"
                fit="cover"
              ></el-image>
              <div class="font-fff-18" style="text-align: center">公众号</div>
            </div>
            <div>
              <el-image
                style="width: 98px; height: 98px; margin-bottom: 8px"
                :src="data.content1"
                fit="cover"
              ></el-image>
              <div class="font-fff-18" style="text-align: center">小程序</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="font-999-18"
        style="
          margin-top: 20px;
          text-align: center;
          line-height: 68px;
          border-top: 2px solid rgba(126, 127, 128, 0.51);
        "
      >
        <!-- 地址：{{ zongUrl }}-->
        <span class=" ">
          <a href="https://service.niutop.com/xukezheng.jpg" class="beian pointer font-999-18" target="_blank">增值电信业务经营许可证</a>
           | Copyright© www.niutop.com 2015 - 2023
          Corporation All Rights Reserved
        </span>
        <a
          class="beian pointer font-999-18"
          href="http://beian.miit.gov.cn"
          target="_blank"
          >苏ICP备2023015553号</a
        > 
        <a
          class="beian pointer font-999-18"
          href="https://www.niutop.com/sitemap.xml"
          target="_blank"
          > 网站地图</a
        >
        
      </div>
      <el-dialog :visible.sync="dialogVisible" width="840px">
        <div class="dialog">
          <div
            style="
              background: #ffffff;
              border-radius: 4px;
              height: 635px;
              padding: 0 20px;
              overflow-y: scroll;
            "
          >
            <div
              style="
                font-size: 16px;
                text-align: center;
                font-weight: 500;
                color: #333333;
                line-height: 57px;
              "
            >
              {{ type == 1 ? fwb.service_title : fwb.about_title }}
            </div>
            <div
              class="font-fff"
              v-html="type == 1 ? fwb.service_content : fwb.about_content"
            ></div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  loginAgreement,
  contactUs,
  aboutUs,
  servicePolicy,
  policyDetail,
  aboutUsDetail,
} from '../api'
export default {
  data() {
    return {
      dialogVisible: false,
      type: 1, //1服务政策 2关于我们
      data: {
        content1: '',
        content2: '',
      },
      zongUrl: '',
      data1: [],
      data2: [],
      data3: [],
      fwb: {},
      bnPtys: JSON.parse(localStorage.getItem('bnPtys')),
      phoneHeader: localStorage.getItem('phoneHeader'),
    }
  },
  methods: {
    fnJoinUs(){
      this.$router.push({
        name: 'courtship'
      })
    },
    fnLoginAgreement1() {
      let params = {
        type: 1,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          localStorage.setItem('gzhMa', data.msg.content2)
        }
      })
    },
    fnLoginAgreement2() {
      let params = {
        type: 2,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          this.zongUrl = data.msg.content1
        }
      })
    },
    fnContactUs() {
      let params = {}
      contactUs(params).then((data) => {
        if (data.status == 200) {
          this.data2 = data.msg
        }
      })
    },
    fnAboutUs() {
      let params = {}
      aboutUs(params).then((data) => {
        if (data.status == 200) {
          this.data1 = data.msg
        }
      })
    },
    fnServicePolicy() {
      let params = {}
      servicePolicy(params).then((data) => {
        if (data.status == 200) {
          this.data3 = data.msg
        }
      })
    },
    fnFw(service_id) {
      // this.dialogVisible = true
      // this.type = 1
      // let params = {
      //   type:1
      //   idd: service_id,
      // }
      if (this.$router.history.current.name == 'About') {
        this.$bus.$emit('footer', 1, service_id)
      }
      this.$router.push({
        name: 'About',
        params: {
          id: 1 + '-' + service_id + '.html',
        },
      })

      // policyDetail(params).then((data) => {
      //   if (data.status == 200) {
      //     this.fwb = data.msg
      //   }
      // })
    },
    fnAboutUsDetail(about_id) {
      if (this.$router.history.current.name == 'About') {
        this.$bus.$emit('footer', 2, about_id)
      }
      this.$router.push({
        name: 'About',
        params: {
          id: 2 + '-' + about_id + '.html',
        },
      })
      // this.dialogVisible = true
      // this.type = 2
      // let params = { about_id: about_id }
      // aboutUsDetail(params).then((data) => {
      //   if (data.status == 200) {
      //     this.fwb = data.msg
      //   }
      // })
    },
    // fnAbout() {
    //   this.$router.push({
    //     name: 'About',
    //     params: {},
    //   })
    // },
  },
  mounted() {
    this.fnLoginAgreement1()
    this.fnLoginAgreement2()
    this.fnContactUs()
    this.fnAboutUs()
    this.fnServicePolicy()
  },
}
</script>

<style scoped>
.footer_contain {
  padding: 36px 0 0;
  background: #2d3037;
  box-sizing: border-box;
}
.footer {
  width: 1300px;
  margin: auto;
  /* min-height: 150px; */
}
.footer_item {
  text-align: left;
}
.xian {
  width: 1px;
  min-height: 150px;
  background: #fff;
}
.beian {
  text-decoration-line: none;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Main from '../views/main.vue'
// import MyInfo from '../views/MyInfo.vue'
import OrderZx from '../views/OrderZx.vue'
import OrderXq from '../views/OrderXq.vue'
import SeeWl from '../views/SeeWl.vue'
import ApplySh from '../views/ApplySh.vue'
import Rzzx from '../views/Rzzx.vue'
import Jbzl from '../views/Jbzl.vue'
import MyActive from '../views/MyActive.vue'
import MyAddress from '../views/MyAddress.vue'
import FaPiao from '../views/FaPiao.vue'
// import AddFp from '../views/AddFp.vue'
import MyCollect from '../views/MyCollect.vue'
import Income from '../views/Income.vue'
import XieYi from '../views/XieYi.vue'
import Car from '../views/Car.vue'
import Selection from '../views/Selection.vue'
import Brand from '../views/Brand.vue'
import zixun from '../views/zixun.vue'
import zixun1 from '../views/zixun1.vue'
import zixun2 from '../views/zixun2.vue'
import zixun3 from '../views/zixun3.vue'
import MyHistory from '../views/MyHistory.vue'
import Search from '../views/Search.vue'
import HotGoods from '../views/HotGoods.vue'
import Whqc from '../views/Whqc.vue'
import Hyyx from '../views/Hyyx.vue'
import Recommend from '../views/Recommend.vue'
import notice from '../views/notice.vue'
import goodDetail from '../views/goodDetail.vue'
import Login from '../views/Login.vue'
import Login1 from '../views/Login1.vue'
// import Register from '../views/Register.vue'
import FrogetPwd from '../views/FrogetPwd.vue'
import About from '../views/About.vue'
import Pay from '../views/Pay.vue'
import SureOrder from '../views/SureOrder.vue'
import Spread from '../views/Spread.vue'
import lunboXq from '../views/lunboXq.vue'
import Shop from '../views/Shop.vue'
import demo from '../views/demo.vue'
import MainOld from '../views/mainOld.vue'
import help from '../views/help.vue'
import courtship from '../views/courtship.vue'


// 国际站
import enmain from '../views/en/EnMain.vue'
import infolist from '../views/en/infoList.vue'
import infoxq from '../views/en/infoXq.vue'
import products from '../views/en/products.vue'
import home from '../views/en/home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '/jingbeijituan',
    name: 'jingbeijituan',
    component: () => import('../views/jingbeijituan.vue'),
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
  },
  // {
  //   path: '',
  //   redirect: '',
  //   // redirect: 'products',
  // },
  {
    path: '/help/:id',
    name: 'help',
    component: help,
  },
  {
    path: '/courtship.html',
    name: 'courtship',
    component: courtship,
  },
  
  {
    path: '/login1:id',
    name: 'Login1',
    component: Login1,
  },
  {
    path: '/demo' + '.html',
    name: 'demo',
    component: demo,
  },
  {
    path: '',
    name: 'Main',
    component: Main,
  },
  {
    path: '/MainOld.html',
    name: 'MainOld',
    component: MainOld,
  },
  {
    path: '/en.html',
    name: 'home',
    component: home,
  },
  {
    path: '/enmain.html',
    name: 'enmain',
    component: enmain,
  },
  {
    path: '/en/infolist.html',
    name: 'infolist',
    component: infolist,
  },
  {
    path: '/en/infoxq/:id',
    name: 'infoxq',
    component: infoxq,
  },
  {
    path: '/en/products.html',
    name: 'products',
    component: products,
  },

  // {
  //   path: '/SureOrder/:goods_id/:attr_id/:num/:type1',
  //   name: 'SureOrder',
  //   component: SureOrder,
  // },
  // {
  //   path: '/SureOrder/:ids/:type1',
  //   name: 'SureOrderCar',
  //   component: SureOrder,
  // },
  {
    path: '/applysh/:id',
    name: 'ApplySh',
    component: ApplySh,
  },
  {
    path: '/pay:id',
    name: 'Pay',
    component: Pay,
  },
  {
    path: '/sureorder/:id',
    name: 'SureOrder',
    component: SureOrder,
  },
  {
    path: '/car:id',
    name: 'Car',
    component: Car,
  },
  {
    path: '/notice/:id',
    name: 'notice',
    component: notice,
  },
  {
    path: '/seewl/:id',
    name: 'SeeWl',
    component: SeeWl,
  },
  {
    path: '/lunboxq/:id',
    name: 'lunboXq',
    component: lunboXq,
  },
  {
    path: '/search/:one?/:two?/:three?',
    name: 'Search',
    component: Search,
  },
  {
    path: '/sale.html',
    name: 'Whqc',
    component: Whqc,
  },
  {
    path: '/sale/:one?/:two?/:three?',
    name: 'Whqc',
    component: Whqc,
  },
  {
    path: '/hotsales.html',
    name: 'HotGoods',
    component: HotGoods,
  },
  {
    path: '/hotsales/:one?/:two?/:three?',
    name: 'HotGoods',
    component: HotGoods,
  },
  {
    path: '/:one([\\da-zA-Z]+)/:two([\\da-zA-Z]+)?/:three([\\da-zA-Z]+)?',
    name: 'Search',
    component: Search,
  },
  {
    path: '/recommend:id',
    name: 'Recommend',
    component: Recommend,
  },

  {
    path: '/login:id',
    name: 'Login',
    component: Login,
  },

  // {
  //   path: '/Register',
  //   name: 'Register',
  //   component: Register,
  // },
  {
    path: '/frogetpwd:id',
    name: 'FrogetPwd',
    component: FrogetPwd,
  },
  {
    path: '/about/:id',
    name: 'About',
    component: About,
  },
  {
    path: '/orderzx/:id',
    name: 'OrderZx',
    component: OrderZx,
  },
  {
    path: '/rzzx:id',
    name: 'Rzzx',
    component: Rzzx,
  },
  {
    path: '/jbzl:id',
    name: 'Jbzl',
    component: Jbzl,
  },
  {
    path: '/myactive:id',
    name: 'MyActive',
    component: MyActive,
  },
  {
    // path: '/MyAddress/:from/:fapiao_type',
    path: '/myaddress:id',
    name: 'MyAddress',
    component: MyAddress,
  },
  {
    // path: '/FaPiao/:from/:fapiao_type',
    path: '/fapiao:id',
    name: 'FaPiao',
    component: FaPiao,
  },
  {
    path: '/mycollect:id',
    name: 'MyCollect',
    component: MyCollect,
  },
  {
    path: '/myhistory:id',
    name: 'MyHistory',
    component: MyHistory,
  },
  {
    path: '/spread:id',
    name: 'Spread',
    component: Spread,
  },
  {
    path: '/income:id',
    name: 'Income',
    component: Income,
  },
  {
    path: '/xieyi:id',
    name: 'XieYi',
    component: XieYi,
  },
  {
    path: '/orderxq:id',
    name: 'OrderXq',
    component: OrderXq,
  },
  {
    path: '/juhe:id',
    name: 'zixun',
    component: zixun,
  },
  {
    path: '/news:id',
    name: 'news',
    component: zixun1,
  },
  {
    path: '/activity:id',
    name: 'activity',
    component: zixun1,
  },  
  {
    path: '/news/:id',
    name: 'zixun2',
    component: zixun2,
  },
  {
    path: '/activity/:id',
    name: 'zixun3',
    component: zixun3,
  },
  {
    path: '/oem:id',
    name: 'Brand',
    component: Brand,
  },
  {
    path: '/market:id',
    name: 'Selection',
    component: Selection,
  },
  {
    path: '/market/:id',
    name: 'Hyyx',
    component: Hyyx,
  },
  {
    path: '/gongying/:id',
    name: 'goodDetail',
    component: goodDetail,
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
  },
  {
    path: '/demo',
    name: 'demo',
    component: demo,
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router

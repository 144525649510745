<template>
  <div style="height: 220px">
    <div class="head1 align-center">
      <div class="font-333-16-24 guoji pointer" @click="fnGuo">国际站</div>
      <div class="head1_in align-between">
        <img
          style="height: 94px"
          :src="require('@/assets/logo_1.png')"
          fit="cover"
          class="pointer"
          @click="toMain()"
          alt=""
        />
        <div>
          <div class="align-end font-333-16-24 m_t10">
            <div class="align-center pointer dengFu" @click="fnGrzx">
              <!-- <img
                style="width: 24px; height: 24px; margin-right: 8px"
                :src="require('@/assets/denglu.png')"
                fit="cover"
                alt=""
              /> -->
              <div style="width: 24px; height: 24px; margin-right: 8px"></div>
              <span class="pointer" @click="toLogin()" v-if="!allUser"
                >注册/登录</span
              >
              <span class="pointer" @click="fnTui" v-else>退出登录</span>
            </div>
            <div class="xian11"></div>
            <div class="align-center touFu pointer">
              <!-- <img
                style="width: 24px; height: 24px; margin-right: 8px"
                :src="require('@/assets/kefu1.png')"
                fit="cover"
                alt=""
              /> -->
              <div style="width: 24px; height: 24px; margin-right: 8px"></div>
              <span
                ><a style="text-decoration: none" @click="fnKf"
                  >在线客服</a
                ></span
              >
            </div>
            <div class="xian11"></div>
            <div class="align-center pointer renFu" @click="fnGrzx">
              <!-- <img
                style="width: 24px; height: 24px; margin-right: 8px"
                :src="require('@/assets/grzx1.png')"
                fit="cover"
                alt=""
              /> -->
              <div style="width: 24px; height: 24px; margin-right: 8px"></div>
              <span>个人中心</span>
            </div>

            <div class="xian11"></div>
            <div class="align-center">
              <img
                style="width: 24px; height: 24px; margin-right: 8px"
                :src="require('@/assets/phone3.png')"
                fit="cover"
                alt=""
              />
              <span
                style="
                  text-decoration: underLine;
                  min-width: 82px;
                  color: #de7e2f;
                  font-weight: bold;
                "
                >{{ phoneHeader }}</span
              >
            </div>
          </div>
          <div class="align-center m_t18">
            <div
              class="tab2 align-center pointer hvr-underline-from-center"
              :class="{ active: index == tab2Index }"
              @click="fnTab2(index)"
              v-for="(item, index) in tab2Item"
              :key="'tab2_' + index"
            >
              <div class="tab2_item">
                {{ item }}
                <span class="xian"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="head2" :class="{ head2_fixed: showFixed }">
      <div class="head2_in">
        <div class="align-between">
          <div class="align-start">
            <div class="all_goods align-center font-000-18-bold">全部商品</div>
            <div
              style="background: #ffffff; height: 62px; width: 818px"
              class="align-start"
            >
              <img
                style="width: 18px; height: 18px; margin: 0 12px 0 24px"
                :src="require('@/assets/search_icon1.png')"
                fit="cover"
                alt=""
              />
              <input
                type="text"
                name=""
                value=""
                v-model="keyword"
                placeholder="请输入商品名称搜索"
                @keyup.enter="fnSearch(0)"
              />
            </div>

            <div
              class="search_txt align-center pointer font-fff-18-bold"
              @click="fnSearch(0)"
            >
              搜索
            </div>
          </div>
          <div class="head_shop align-center pointer my_car" @click="fnCar">
            <img
              style="width: 24px; height: 24px; margin-right: 8px"
              :src="require('@/assets/shop_car2.png')"
              fit="cover"
              alt=""
            />
            <span class="font-fff-18">我的购物车</span>
            <span class="shop_num align-center">{{ shopCarNumber || 0 }}</span>
          </div>
        </div>
        <div class="font-fff m_t9">
          热门搜索：<span
            v-for="(item, index) in hotSearch"
            :key="'hot_' + index"
            @click="fnHot(item.hs_name)"
            class="pointer"
            >{{ item.hs_name || '' }} &nbsp;
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  goodsCate,
  loginAgreement,
  uploadFile,
  appOrder,
  mycarNum,
  indexData,
} from '../api'
export default {
  props: {
    num111: {
      Type: Number,
      default: 999,
    },
    carName222:{
      Type: Number,
      default: 0,
    }
  },
  data() {
    return {
      keyword: '',
      allUser: localStorage.getItem('allUser')
        ? JSON.parse(localStorage.getItem('allUser')).user_id
        : '',
      phoneHeader: '',
      tab2Index: -1,
      tab2Item: [
        '首页',
        '尾货清仓',
        '热卖产品',
        '行业优选',
        '品牌定制',
        '内容资讯',
      ],
      hotSearch: JSON.parse(localStorage.getItem('hotSearch')),
      shopCarNumber: localStorage.getItem('shopCarNumber'),
      showFixed: false,
      scroll11: '',
    }
  },
  mounted() {
    setTimeout(() => {
      this.$bus.$emit('goodsKf',60)
    }, 500);
    document.documentElement.scrollTop = 0
    if (localStorage.getItem('allUser')) {
      let expire = JSON.parse(localStorage.getItem('allUser')).expire
      let time111 = JSON.parse(localStorage.getItem('allUser')).time111
      if (Date.now() > expire + time111) {
        localStorage.setItem('allUser', '')
      }
    }

    this.fnAgreement(10)
    this.fnCarNum()

    this.$bus.$on('keywordGet', (val) => {
      this.keyword = val || ''
      // alert(this.keyword)
      // console.log('627||'+this.keyword);
    })
    if (this.num111 != 999) {
      this.tab2Index = this.num111
    } else {
      if (this.$route.name == 'goodDetail') {
        this.tab2Index = ''
        return
      }
    }
    // if (localStorage.getItem('phoneHeader')) {
    //   this.phoneHeader = localStorage.getItem('phoneHeader')
    // } else {
    //   this.fnAgreement(10)
    // }

    this.$bus.$on('carNum111', () => {
      this.fnCarNum()
    })
    window.addEventListener('scroll', this.handleScroll)

    this.$bus.$on('ossSignMain', (val) => {
      this.allUser = localStorage.getItem('allUser')
        ? JSON.parse(localStorage.getItem('allUser')).user_id
        : ''
    })

    if (this.$route.query.s) {
      localStorage.setItem('from_source', this.$route.fullPath.split('=')[1])
      console.log(localStorage.getItem('from_source'))
    }


    
    
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    fnKf() {
      this.$bus.$emit('goodsKf')
    },
    handleScroll() {
      // console.log(document.getElementById("main").offsetHeight)
      //或者使用document.querySelector('.class或者#id').scrollTop
      // let top111 = this.$refs.top111.offsetHeight
      this.scroll11 =
        document.documentElement.scrollTop || document.body.scrollTop
      // console.log(this.scroll11);
      if (this.scroll11 < 94) {
        this.showFixed = false
      } else {
        this.showFixed = true
      }
    },
    fnTab2(index) {
      this.keyword = ''
      this.tab2Index = index
      let tab2Url = ['Main', 'Whqc', 'HotGoods', 'Selection', 'Brand', 'zixun']
      if (index == 1) {
        this.$router.push({
          path: `/sale.html`,
        })
      } else if (index == 2) {
        this.$router.push({
          path: `/hotsales.html`,
        })
      } else {
        this.$router.push({
          name: tab2Url[index],
          params: {
            // num: index,
            id: '.html',
          },
        })
      }
    },

    fnCarNum() {
      let params = {}
      mycarNum(params).then((data) => {
        if (data.status == 200) {
          localStorage.setItem('shopCarNumber', data.msg)
          this.shopCarNumber = data.msg
          // this.$message.success(data.msg)
        }
      })
    },
    fnCar() {
      if (localStorage.getItem('allUser')) {
        this.$router.push({
          name: 'Car',
          params: {
            id: '.html',
          },
        })
      } else {
        this.$bus.$emit('login', 1)
        // this.$router.push('/Login')
      }
    },
    fnTui() {
      this.toLogin()
      localStorage.setItem('allUser', '')
    },
    fnAgreement(type) {
      let params = {
        type: type,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          if (type == 9) {
            this.demo = data.msg.content1 || ''
          }
          if (type == 10) {
            this.phoneHeader = data.msg.content1 || ''
            localStorage.setItem('phoneHeader', data.msg.content1)
          }
        }
      })
    },
    fnGrzx() {
      // this.$router.push('/MyInfo/MyActive')
      // return;
      if (localStorage.getItem('allUser')) {
        this.$router.push({
          name: 'OrderZx',
          params: {
            id: 0 + '.html',
          },
        })
      } else {
        this.$bus.$emit('login', 1)
        // this.$router.push('/Login')
      }
    },
    fnSearch(type, one, two, three) {
      if (
        this.$router.history.current.name == 'HotGoods' ||
        this.$router.history.current.name == 'Whqc' ||
        this.$router.history.current.name == 'Recommend' ||
        this.$router.history.current.name == 'Search'
      ) {
        if (this.$router.history.current.name == 'Search') {
          if (type != 0) {
            if (type == 1) {
              this.$router.push({
                path: `/${one}`,
              })
            }
            if (type == 2) {
              this.$router.push({
                path: `/${one}/${two}`,
              })
            }
            if (type == 3) {
              this.$router.push({
                path: `/${one}/${two}/${three}`,
              })
            }
            this.$bus.$emit('cate_id')
            this.cateShow = false
          } else {
            if (this.keyword) {
              // console.log(this.$route);
              // this.$router.push({
              //   path: `/search`,
              //   query: {
              //     keyword: this.keyword,
              //   },
              // });
              this.$bus.$emit('keyword', this.keyword)
            } else {
              // this.$router.push({
              //   path: `/search`,
              // })
              this.$message.error('请输入搜索内容')
            }
          }
        } else {
          if (type != 0) {
            if (type == 1) {
              this.$router.push({
                path: `/${one}`,
              })
            }
            if (type == 2) {
              this.$router.push({
                path: `/${one}/${two}`,
              })
            }
            if (type == 3) {
              this.$router.push({
                path: `/${one}/${two}/${three}`,
              })
            }
          } else {
            // let search = 'Search'
            // this.$router.push({
            //   path: `/list`,
            //   query: {
            //     keyword: this.keyword,
            //   },
            // })
            // this.$bus.$emit('keyword', this.keyword)
            if (this.keyword) {
              this.$bus.$emit('keyword', this.keyword)
            } else {
              this.$message.error('请输入搜索内容')
            }
          }
        }
        return
      } else {
        if (type == 0) {
          if (this.keyword) {
            this.$router.push({
              path: `/search`,
              query: {
                keyword: this.keyword,
              },
            })
            // this.$bus.$emit('keyword', this.keyword)
          } else {
            // this.$router.push({
            //   path: `/search`,
            // })
            this.$message.error('请输入搜索内容')
          }
        }
        if (type == 1) {
          this.$router.push({
            path: `/${one}`,
          })
        }
        if (type == 2) {
          this.$router.push({
            path: `/${one}/${two}`,
          })
        }
        if (type == 3) {
          this.$router.push({
            path: `/${one}/${two}/${three}`,
          })
        }
      }
    },
    fnHot(hs_name) {
      this.keyword = hs_name
      this.fnSearch(0)
    },
    fnGuo(){
      // window.open('https://www.niutop.com/en.html', "_blank")
      this.$router.push({
          name: 'home',
      })
      // window.open(
      //   this.$router.resolve({ name: 'home', params: { id: '.html' } })
      //     .href,
      //   '_blank'
      // )
    }
  },
  watch: {
    carName222: {
      handler: function (newVal, oldVal) {
        // console.log(newVal+'||'+oldVal);
        if(newVal==111){
          this.fnCarNum()
        }
      },
    },
  },
}
</script>

<style scoped>
.guoji{
  position: absolute;
  right: 20px;
  top:10px;
}
.renFu div {
  background: url('@/assets/grzx2.png') no-repeat center center;
  background-size: 24px 24px;
}
.renFu:hover div {
  background: url('@/assets/grzx3.png') no-repeat center center;
  background-size: 24px 24px;
}
.renFu:hover span {
  color: #de7e2f;
}
.dengFu div {
  background: url('@/assets/denglu2.png') no-repeat center center;
  background-size: 24px 24px;
}
.dengFu:hover div {
  background: url('@/assets/denglu1.png') no-repeat center center;
  background-size: 24px 24px;
}
.dengFu:hover span {
  color: #de7e2f;
}
.touFu a {
  color: #333;
}
.touFu:hover a {
  color: #de7e2f;
}
.touFu div {
  background: url('@/assets/kefu3.png') no-repeat center center;
  background-size: 24px 24px;
}
.touFu:hover div {
  background: url('@/assets/kefu2.png') no-repeat center center;
  background-size: 24px 24px;
}
.my_car:hover {
  background: #edb380;
}
.head_shop {
  width: 160px;
  height: 62px;
  background: #de7e2f;
  border-radius: 5px;
  position: relative;
}
.shop_num {
  min-width: 26px;
  height: 26px;
  padding: 0 6px;
  background: #fff;
  border-radius: 13px;
  box-sizing: border-box;
  color: #ed9d3b;
  font-size: 14px;
  position: absolute;
  right: -13px;
  top: -13px;
}
.search_txt {
  width: 112px;
  height: 62px;
  background: #de7e2f;
  border-radius: 0px 5px 5px 0px;
}
input[type='text'] {
  background: #ffffff;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  height: 40px;
  width: 730px;
  padding: 0 12px;
  color: #333333;
  font-size: 18px;
  outline: none;
}
input[type='text']::-webkit-input-placeholder {
  color: #666666;
}
input[type='text']:active {
  box-sizing: border-box;
  border: none;
}
.head2 {
  height: 126px;
  background: #2d3037;
}
.head2_fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}
.head2_in {
  width: 1300px;
  margin: auto;
  padding-top: 26px;
}
.head1 {
  height: 94px;
  background: #e6e8ed;
}
.head1_in {
  width: 1300px;
  margin: auto;
}
.xian11 {
  width: 2px;
  height: 19px;
  background: #333333;
  margin: 0 25px;
}
.tab2 {
  line-height: 42px;
  font-size: 18px;
  color: #333;
  margin-left: 66px;
}
.tab2:hover .tab2_item {
  color: #de7e2f;
  font-weight: bold;
}

.tab2.active .tab2_item {
  color: #de7e2f;
  font-weight: bold;
  position: relative;
}

.tab2_item {
  width: 100%;
  text-align: center;
}

.tab2.active .tab2_item .xian {
  position: absolute;
  width: 100%;
  height: 4px;
  background: #de7e2f;
  bottom: 0;
  left: 0;
  border-radius: 3px;
}
.all_goods {
  width: 177px;
  height: 62px;
  background: #e6e8ed;
  border-radius: 5px 0px 0px 5px;
}
</style>

<template>
  <div>
    <Header />
    <div class="middle1" style="margin-top:14px;">
      <div class="toppp flex-wrap">
        <div class="pointer" @click="toMain()">首页</div>
        <img src="../assets/arrow1.png" class="topppImg" />
        <div>公告</div>
      </div>
      <div class="contain">
        <div class="notice_title">{{ data.notice_title || '' }}</div>
        <div class="notice_time">发布时间：{{ data.notice_time || '' }} &nbsp;&nbsp;  浏览：{{data.notice_read_number||0}}次</div>
        <div class="fwb" v-html="data.notice_content"></div>
      </div>
    </div>
    <!-- <right /> -->
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { noticeDetail } from '../api'
export default {
  components: {
    Header,
    Footer,
    right
  },
  data() {
    return {
      data: {},
      notice_id: '',
    }
  },
  methods: {
    initData() {
      let params = {
        notice_id: this.notice_id,
      }
      noticeDetail(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
        }
      })
    },
  },
  mounted() {
    document.title = '本牛'
    let can = this.$route.params.id.split('.')[0]
    this.notice_id = can
    this.initData()
  },
}
</script>
<style scoped>
.toppp {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  margin: 20px 0;
  align-items: center;
}

.topppImg {
  width: 20px;
  height: 20px;
  margin: 0 4px;
}
.contain {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  overflow-y: scroll;
  max-height:700px;
  box-sizing: border-box;
}
.contain::-webkit-scrollbar {
  display: none;
}
.fwb {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.notice_title {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  text-align: center;
}
.notice_time {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin: 8px auto 20px;
  text-align: center;
}
</style>

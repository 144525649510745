<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top: 14px">
      <div>
        <MyInfo :num111="6" />
      </div>

      <div class="my_right flex-con">
        <div class="order_box">
          <div class="align-start cheng_head11">
            <div class="font-333-20-bold">认证中心</div>
            <el-image
              v-if="status != 0"
              style="width: 30px; height: 30px; margin-left: 15px"
              :src="require('@/assets/rz' + status + '.png')"
              fit="cover"
            ></el-image>
          </div>
          <div style="padding-right: 32px">
            <div class="font-333-16-bold flex-wrap" style="margin-top: 22px">
              <div class="width102 align-end">
                <span class="cor_FA9C22">*</span>企业名称:
              </div>
              <input
                type="text"
                name=""
                value=""
                v-model="data.company_name"
                placeholder="请输入企业名称"
                class="flex-con"
                :readonly="status == 1 || status == 2"
                :class="{ border_none: status == 1 || status == 2 }"
              />
            </div>
            <div class="font-333-16-bold flex-wrap" style="margin-top: 22px">
              <div class="width102 align-end">
                <span class="cor_FA9C22">*</span>姓名:
              </div>
              <input
                type="text"
                name=""
                value=""
                v-model="data.company_user_name"
                placeholder="请输入姓名"
                class="flex-con"
                :readonly="status == 1 || status == 2"
                :class="{ border_none: status == 1 || status == 2 }"
              />
            </div>
            <div class="font-333-16-bold flex-wrap" style="margin-top: 22px">
              <div class="width102 align-end">
                <span class="cor_FA9C22">*</span>手机号:
              </div>
              <input
                type="text"
                name=""
                value=""
                v-model="data.company_user_phone"
                placeholder="请输入手机号"
                class="flex-con"
                :readonly="status == 1 || status == 2"
                :class="{ border_none: status == 1 || status == 2 }"
              />
            </div>
            <div class="font-333-16-bold flex-wrap" style="margin-top: 22px">
              <div class="width102 align-end">
                <span class="cor_FA9C22">*</span>身份证:
              </div>
              <input
                type="text"
                name=""
                value=""
                v-model="data.company_user_card"
                placeholder="请输入身份证"
                class="flex-con"
                :readonly="status == 1 || status == 2"
                :class="{ border_none: status == 1 || status == 2 }"
              />
            </div>
            <div class="font-333-16-bold align-top" style="margin-top: 22px">
              <div class="width102">
                <span class="cor_FA9C22">*</span>营业执照:
              </div>
              <!-- <el-upload
          :data="dataObj"
          name="file"
          class="avatar-uploader"
          action="https://benbull.oss-cn-nanjing.aliyuncs.com"
          accept=".jpg, .png, .jpeg"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :on-success="handleAvatarSuccess"
        >
          <img
            v-if="data.company_image"
            :src="data.company_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload> -->
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                name="file"
                value=""
                id="fileImg"
                @change="uploadData"
                v-show="status == 0 || status == 3"
                class="input1"
              />
              <el-image
                style="width: 100px; height: 100px"
                :src="data.company_image"
                fit="cover"
                v-show="status == 1 || status == 2"
              ></el-image>
            </div>
            <div class="font-333-16-bold align-start" style="margin-top: 22px">
              <div class="width102"><span class="cor_FA9C22"></span></div>
              <el-image
                style="width: 100px; height: 100px"
                :src="data.company_image"
                fit="cover"
                v-show="(status == 0 || status == 3) && data.company_image"
              ></el-image>
            </div>
          </div>
          <div
            class="btn align-center pointer"
            @click="fnTi"
            v-show="status == 0 || status == 3"
          >
            {{ status == 0 ? '提交' : '重新提交' }}
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import right from '@/components/right.vue'
import { commitApply, showAuth, authStatus, uploadFile } from '../api'
export default {
  components: {
    Header,
    Footer,
    MyInfo,
    right,
  },
  data() {
    return {
      data: {
        company_image: '',
      },
      status: 0,
    }
  },
  methods: {
    fnTi() {
      if (!this.data.company_name) {
        this.$message.error('请输入企业名称')
        return
      }
      if (!this.data.company_user_name) {
        this.$message.error('请输入姓名')
        return
      }
      if (!this.data.company_user_phone) {
        this.$message.error('请输入手机号')
        return
      }
      if (!this.data.company_user_card) {
        this.$message.error('请输入身份证')
        return
      }
      if (!this.data.company_image) {
        this.$message.error('请上传营业执照')
        return
      }
      let params = {
        company_name: this.data.company_name,
        company_image: this.data.company_image,
        company_user_name: this.data.company_user_name,
        company_user_phone: this.data.company_user_phone,
        company_user_card: this.data.company_user_card,
      }
      commitApply(params).then((data) => {
        if (data.status == 200) {
          this.$message.success('提交成功')
          this.initStatus()
        }
      })
    },
    initData() {
      let params = {}
      showAuth(params).then((data) => {
        if (data.status == 200) {
          if (data.msg) {
            this.data = data.msg
          }
        }
      })
    },
    uploadData(e) {
      let formData = new FormData()
      let files = e.target.files[0]
      formData.append('file', files)
      console.log(files)
      console.log(formData)
      let _self = this
      return new Promise((resolve, reject) => {
        uploadFile(formData)
          .then((response) => {
            this.data.company_image = response.msg
            // console.log('133||'+response.msg);
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    initStatus() {
      let params = {}
      authStatus(params).then((data) => {
        if (data.status == 200) {
          this.status = data.msg
        }
      })
    },
  },
  mounted() {
    document.title = '本牛'
    this.initStatus()
    this.initData()
  },
}
</script>

<style scoped>
.input1::file-selector-button {
  width: 101px;
  height: 29px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  /* border-radius: 5px; */
  font-size: 16px;
  font-weight: 400;
  color: #999;
  padding-bottom: 3px;
  margin-right: 18px;
  cursor: pointer;
}
.input1 {
  font-size: 16px;
  font-weight: 400;
  color: #6eaaf9;
}
.my_order {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 26px;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}
.order_box {
  background: #ffffff;
  box-sizing: border-box;
  text-align: left;
  border: 1px solid #eeeeee;
}
input[type='text'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 32px;
  /* width: 250px; */
  padding: 0 12px;
  color: #333333;
  font-size: 16px;
  outline: none;
}
input[type='text']::-webkit-input-placeholder {
  color: #999;
}
input[type='text']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
}

.btn {
  font-size: 20px;
  color: #ffffff;
  margin: 30px auto 35px;
  width: 256px;
  height: 46px;
  background: #fa9c22;
  border-radius: 4px;
}
.width102 {
  min-width: 102px;
  text-align: right;
  margin-right: 26px;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<template>
  <div>
    <EnHeader :num111="3" />
    <div class="bg_fff">
      <div style="position: relative;">
        <!-- <el-image style="width: 100%" :src="require('@/assets/en/banner4.png')" fit="cover"></el-image> -->
        <img style="width: 100%" :src="require('@/assets/en/banner4.png')" fit="cover" alt="">
        <div class="box1 align-start">
          News and <br> information <br> _______
        </div>
      </div>
      <div class="w1200">
        <div class="tab_out font-333-14 align-start">
          <div :class="{ active: index == tabIndex }" v-for="(item, index) in tabData" :key="'tabData_' + index">
            {{ item.title }}
            <!-- <span class="xian"></span> -->
          </div>
          <!-- <div class="">Hon Niu Information <span class="xian"></span></div> -->
        </div>
        <div class="font-333-20" style="text-align: center">
          Advantages of using Aerogel insulation blanket for chemical vent flare
        </div>
        <div class="font-333" style="text-align: center; margin: 8px 0 20px">
          Release time:2022-11-04
        </div>
        <div class="font-333 fwb" v-html="fwb"></div>
      </div>
    </div>
    <EnFooter />
  </div>
</template>
<script>
import EnHeader from '@/components/EnHeader.vue'
import EnFooter from '@/components/EnFooter.vue'
export default {
  components: {
    EnHeader,
    EnFooter,
  },
  data() {
    return {
      tdkTitle: '本牛',
      tdkKeywords: '本牛',
      tdkDescription: '本牛',
      tabIndex: 0,
      tabData: [{
        title: 'INDUSTRY INFORMATION'
      }, {
        title: 'HON NIU INFORMATION'
      }],
      fwb: `Coke oven gas is a byproduct of coal carbonization and coking, mainly composed of methane, hydrogen gas, 
      and carbon monoxide. Methane is the most common greenhouse gas, and one ton of methane gas is equivalent to 120 tons of carbon dioxide,
       causing a destructive greenhouse effect. Carbon monoxide can inhibit the combination of hemoglobin and oxygen,
        causing suffocation symptoms and posing a great threat to human health by endangering the brain, heart, liver, kidneys,
         lungs, and other tissues. Therefore, the chemical byproduct coke oven gas that cannot be comprehensively utilized must be vented for combustion and undergo harmless treatment.
         <img src="https://img1.baidu.com/it/u=1927427574,3540234240&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=342" alt="" style="margin:10px auto;">
         Why does the vented burning torch need Aerogel insulation materal? Firstly, it should be understood that torch combustion generates necative pressure byrapidly expanding the volume and suddenly reducing the pressure after steam injection into the mixing chamber,
          which draws combustible gas to the top of*he torh. usualy.the aualiv of steam reachind the ton of the torcn determines the oressure at wnicn nenative nressure can be oenerated. The mioher thesteam caloritc value,
           the greater the negatie pressure produced by steam expansion, and the more obvious the pumping effect: 0n the contrary, if thecalorifc value of the steam reaching the top is not high enough, the steam jet cannot expand in the mixing chamber,
           making it diffcult to form suffcientnegative pressure suction effect.The steam that needs to be sprayed to form negative pressure is transported to the top of the torch through a pipeline.To ensure the heat value of thesteam. the pipeline needs to be covered with insulation materals.
            The selection of insulation materials for torch steam pipelines is very particular, whicecuires understanding the specihc working environment of the pipeline. Firstiv. the steam inside the pioeline is aeneral low-orade steam with a pressureof 1MPa and a temperature of 280-300 C. 
            Ater being transported verticlly for more than 100 meters, if the insulation efect is slightly poor, there is a riskof condensation of the steam. Secondly, torches are used outdoors, and rain and wind have a signicant destructive effect on the insulation materials ofoipelnes. At the same time,
             the venting flare is almost non-stop al year round, and the maintenance is extremely dlfhcult. The maintenance personne needto face the environment of high-temperature operation, nearly 50m high altitude operation,
         and Confned space operation in the tower when replacing thethermal insulation materials of the flare pipeline.
         <img src="https://img0.baidu.com/it/u=4211319354,2337699030&fm=253&fmt=auto&app=138&f=JPG?w=500&h=310" alt="">
         The application of conventional inorganic fber insulation products in the nsulation of torch steam pipelines inevitably leads to water absorpotion sagcinashort service life, high maintenanc investment, severe corrosion under the insulation layer, thick insulation layer, and high energy consumption. Nano slicaAerogel composite insulation felt can effectively sole these problems. The hydrophobicity rate can reach up to 99%, using only 1/3 of the thickness and 1/2of the weight of the traditional insulation layer to avoid the risk of water absorption sagging and ion corrosion. The heat dissipation los of pipelineransportation steam is reduced by more than 50%, greatly saving labor and maintenance costs, as wel as energy consumotion losses. lt can be said to bea once and for all perfect choice.
         `,
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {},
}
</script>

<style scoped>
.box1 {
  width: 1200px;
  margin: auto;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 40px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 56px;
}

.w1200 {
  padding: 35px 0 60px;
}

.tab_out {
  border-bottom: 1px solid #f6f6f6;
  margin-bottom: 60px;
}

.tab_out>div {
  margin-right: 40px;
  padding: 25px 0;
}

.tab_out .active {

  font-weight: bold;
  /* font-size: 14px;
  color: #efc845;
  line-height: 20px;
  position: relative; */
}

.tab_out .active .xian {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
}

.fwb>>>img {
  display: block;
  margin: auto;
}
</style>

<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top:14px;">
      <div>
        <MyInfo :num111="15" />
      </div>

      <div class="my_right flex-con">
        <div class="my_order">
          <div
            class="font-333-20-bold"
            style="text-align: center; margin-bottom: 16px"
          >
            分销协议
          </div>
          <el-tabs v-model="xy_type" type="card" @tab-click="handleClick">
            <el-tab-pane label="公版" name="2" class="pointer"></el-tab-pane>
            <el-tab-pane label="个人" name="1" class="pointer"></el-tab-pane>
          </el-tabs>
          <div
            class="flex-wrap font-333-16"
            style="margin: 20px 0; text-align: left"
          >
            <div class="flex-con">协议名称</div>
            <div style="width: 80px; text-align: center;min-width:80px;">操作</div>
          </div>

          <div class="order_box">
            <div
              class="order_item"
              v-for="(item, index) in data"
              :key="'xieyi_' + index"
            >
              <div class="flex-wrap font-333-16">
                <div class="flex-con">{{ item.xy_name || '' }}</div>
                <div style="width: 80px;min-width:80px;" class="C52B33 align-center">
                  <a
                    class="font-999-16 pointer"
                    style="text-decoration: none"
                    :href="item.xy_url"
                    >下载</a
                  >
                </div>
              </div>
            </div>
            <div
            style="text-align: center; margin: 160px auto"
            v-show="data.length == 0"
          >
            <el-image
              style="width: 160px; height: 160px; margin-bottom: 16px"
              :src="require('@/assets/quesheng/no_content.png')"
              fit="cover"
            ></el-image>
            <div class="font-666-14">暂无内容~</div>
          </div>
          </div>
        </div>
        <div class="align-end" v-if="total > 0">
          <Page
            :limit="limit"
            :totalNum="total"
            @onLimit="changeLimit"
            @onPage="changePage"
          />
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'

import { limit, fxxy } from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    MyInfo,
    right
  },
  data() {
    return {
      data: [],
      page: 1,
      limit: limit,
      total: 0,
      xy_type: '2',
    }
  },
  methods: {
    initData() {
      let params = {
        page: this.page,
        xy_type: this.xy_type,
      }
      fxxy(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count
        }
      })
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      this.initData()
    },
    handleClick(tab, event) {
      this.xy_type = tab._props.name
      this.page = 1
      this.initData()
      console.log(tab._props.name)
    },
  },
  mounted() {
    document.title = '本牛'
    this.initData()
  },
}
</script>

<style scoped>
.my_order {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 26px;
  font-size: 14px;
  box-sizing: border-box;
  min-height: 624px;
}
::v-deep .el-tabs__item {
  color: #333;
  font-size: 16px;
}
::v-deep .el-tabs__item.is-active {
  color: #f08300;
}
::v-deep .el-tabs__item:hover {
  color: #f08300;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0;
}
.order_item {
  margin-bottom: 16px;
}
.order_box > .order_item:last-child {
  margin-bottom: 0;
}
</style>

<template>
  <div>
    <EnHeader :num111="3" />
    <div class="bg_fff">
      <div style="position: relative;">
        <!-- <el-image style="width: 100%" :src="require('@/assets/en/banner4.png')" fit="cover"></el-image> -->
        <img style="width: 100%" :src="require('@/assets/en/banner4.png')" fit="cover" alt="">
        <div class="box1 align-start">
          News and <br> information <br> _______
        </div>
      </div>

      <div class="w1200">
        <div class="tab_out font-333-14 align-start">
          <div class="pointer" :class="{ active: index == tabIndex }" v-for="(item, index) in tabData"
            :key="'tabData_' + index" @click="fnTab(index)">
            {{ item.title }}<span class="xian"></span>
          </div>
        </div>
        <div class="align-start" style="flex-wrap: wrap">
          <div class="info_item pointer" @click="fnXq(1)" v-for="(item, index) in data" :key="'news_' + index">
            <el-image style="width: 100%; height: 260px; border-radius: 4px" :src="item.img" fit="cover"></el-image>
            <div style="padding: 20px 16px 10px; border-bottom: 2px solid #fff">
              <div class="font-333-16-bold m_b10 aui-ellipsis-2">
                {{ item.title }}
              </div>
              <div class="font-666-14 aui-ellipsis-2">
                {{ item.intro }}
              </div>
            </div>
            <div style="height: 40px; padding-right: 16px" class="align-end">
              <el-image class="m_r10" style="width: 18px; height: 18px" :src="require('@/assets/time.png')"
                fit="cover"></el-image>
              <div class="font-aaa">2022-12-19</div>
            </div>
          </div>
        </div>
        <div class="align-center">
          <Page :limit="limit" :totalNum="total" @onLimit="changeLimit" @onPage="changePage" />
        </div>
      </div>
    </div>
    <EnFooter />
  </div>
</template>
<script>
import Page from '@/components/Page.vue'
import EnHeader from '@/components/EnHeader.vue'
import EnFooter from '@/components/EnFooter.vue'
export default {
  components: {
    Page,
    EnHeader,
    EnFooter,
  },
  data() {
    return {
      tdkTitle: '本牛',
      tdkKeywords: '本牛',
      tdkDescription: '本牛',
      page: 1,
      limit: 9,
      total: 9,
      tabIndex: 0,
      tabData: [{
        title: 'INDUSTRY INFORMATION'
      }, {
        title: 'HON NIU INFORMATION'
      }],
      data: [{
        img: require('@/assets/en/news_1.png'),
        title: 'Organizational Capability White Paper | How Does an Organization ',
        intro: 'Your exploration is very meaningful and has paved a path for the development of…'
      }, {
        img: require('@/assets/en/news_3.png'),
        title: 'Organizational Capability White Paper | How Does an Organization ',
        intro: 'Your exploration is very meaningful and has paved a path for the development of…'
      }, {
        img: require('@/assets/en/news_2.png'),
        title: 'Organizational Capability White Paper | How Does an Organization ',
        intro: 'Your exploration is very meaningful and has paved a path for the development of…'
      }, {
        img: require('@/assets/en/news_4.png'),
        title: 'Organizational Capability White Paper | How Does an Organization ',
        intro: 'Your exploration is very meaningful and has paved a path for the development of…'
      }, {
        img: require('@/assets/en/news_5.png'),
        title: 'Organizational Capability White Paper | How Does an Organization ',
        intro: 'Your exploration is very meaningful and has paved a path for the development of…'
      }, {
        img: require('@/assets/en/news_3.png'),
        title: 'Organizational Capability White Paper | How Does an Organization ',
        intro: 'Your exploration is very meaningful and has paved a path for the development of…'
      }, {
        img: require('@/assets/en/news_6.png'),
        title: 'Organizational Capability White Paper | How Does an Organization ',
        intro: 'Your exploration is very meaningful and has paved a path for the development of…'
      }, {
        img: require('@/assets/en/news_7.png'),
        title: 'Organizational Capability White Paper | How Does an Organization ',
        intro: 'Your exploration is very meaningful and has paved a path for the development of…'
      }, {
        img: require('@/assets/en/news_8.png'),
        title: 'Organizational Capability White Paper | How Does an Organization ',
        intro: 'Your exploration is very meaningful and has paved a path for the development of…'
      }]
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    fnXq(idd) {
      this.$router.push({
        name: 'infoxq',
        params: {
          id: idd + '.html'
        },
      })
    },
    fnTab(index) {
      this.tabIndex = index
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      // this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      // this.initData()
    },
  },
}
</script>

<style scoped>
.box1 {
  width: 1200px;
  margin: auto;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 40px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 56px;
}

.info_item:nth-child(3n+2) {
  margin: 0px 28px 40px;
}

.w1200 {
  padding: 35px 0 60px;
}

.tab_out {
  border-bottom: 1px solid #f6f6f6;
  margin-bottom: 60px;
}

.tab_out>div {
  margin-right: 40px;
  padding: 25px 0;
}

.tab_out .active {
  font-size: 14px;
  font-weight: 500;
  color: #efc845;
  line-height: 20px;
  position: relative;
}

.tab_out .active .xian {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
}

.info_item {
  background: #f6f6f6;
  border-radius: 4px;
  width: 381px;
  margin-bottom: 40px;
}
</style>

<template>
  <div class="fixright">
    <div class="fixOne phoneOut">
      <div class="fix1"></div>
      <div>联系方式</div>
      <div class="phone font-333-18">{{ phoneHeader }}</div>
    </div>
    <div class="fixOne pointer" @click="showKF = true">
      <div class="fix2"></div>
      <div>
        <a style="text-decoration: none" class="">在线客服</a>
      </div>
    </div>
    <div class="fixOne">
      <div class="fix3"></div>
      <div>公众号</div>
      <img :src="gzhMa" class="code" />
    </div>
    <div class="fixOne1 pointer" @click="toTop" v-if="isMain == true">
      <div class="fix4"></div>
      <div>顶部</div>
    </div>
    <div class="keFu" id="keFu" v-if="showKF">
      <div class="align-end" style="padding-right: 15px; padding-top: 10px">
        <img
          :src="require('@/assets/close.png')"
          alt=""
          style="width: 30px"
          @click="showKF = false"
          class="pointer"
        />
      </div>
      <iframe
        style="width: 400px; height: 500px"
        src="https://1800498.s4.udesk.cn/im_client/?web_plugin_id=63975"
      ></iframe>
      <!-- :style="
          showKF
            ? 'display:block;width: 400px; height: 500px;'
            : 'display:none;'
        " -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gzhMa: localStorage.getItem("gzhMa") || "",
      phoneHeader: localStorage.getItem("phoneHeader") || "",
      isMain: true,
      showKF: false,
    };
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.isMain = this.$router.history.current.name == "Main" ? true : false;
    setTimeout(() => {
      // this.showKF = true;
    }, 60000);
  },
};
</script>
<style scoped>
.phone {
  display: none;
  background-color: #fff;
  position: absolute;
  right: 50px;
  top: 20px;
  width: 140px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}
.fixright {
  position: fixed;
  top: 300px;
  right: 0;
  width: 50px;
  z-index: 999;
}
.fixOne {
  position: relative;
  padding: 8px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  text-align: center;
  background: #ffffff;
}
.fix1 {
  width: 24px;
  height: 24px;
  background: url("@/assets/main1_26_white.png") no-repeat center center;
  background-size: 100% 100%;
  margin: 0 auto 3px;
}
.fix2 {
  width: 24px;
  height: 24px;
  background: url("@/assets/main1_28_white.png") no-repeat center center;
  background-size: 100% 100%;
  margin: 0 auto 3px;
}
.fix3 {
  width: 24px;
  height: 24px;
  background: url("@/assets/main1_30_white.png") no-repeat center center;
  background-size: 100% 100%;
  margin: 0 auto 3px;
}
.fixOne:hover {
  /* background: #3761e7; */

  background: #f08300;
  color: #ffffff;
}
.fixOne a {
  color: #333;
}
.fixOne:hover a {
  color: #ffffff;
}
.fixOne:hover .fix1 {
  background: url("@/assets/main1_27.png") no-repeat center center;
  background-size: 100% 100%;
}
.fixOne:hover .fix2 {
  background: url("@/assets/main1_29.png") no-repeat center center;
  background-size: 100% 100%;
}
.fixOne:hover .fix3 {
  background: url("@/assets/main1_31.png") no-repeat center center;
  background-size: 100% 100%;
}
.fixOne:hover .phone {
  display: inline;
}
.fixOne1 {
  padding: 14px 8px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
  text-align: center;
  margin-top: 16px;
  background: #ffffff;
}
.fix4 {
  width: 24px;
  height: 24px;
  background: url("@/assets/main1_32.png") no-repeat center center;
  background-size: 100% 100%;
  margin: 0 auto 3px;
}
.code {
  display: none;
  position: absolute;
  right: 50px;
  bottom: 0;
  width: 100px;
  height: 100px;
}
.fixOne:hover .code {
  display: block;
}

.keFu {
  border-radius: 4px;
  background: #5d7cb6;
  width: 400.3px;
  height: 540px;
  position: fixed;
  bottom: 0;
  right: 50px;
  z-index: 2;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
</style>

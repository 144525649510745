<template>
  <div>
    <Header />
    <div class="middle1 flex-wrap" style="margin-top: 14px">
      <div>
        <MyInfo :num111="9" />
      </div>

      <div class="my_right flex-con">
        <div class="border_eee">
          <div class="">
            
            <div class="align-between cheng_head11" style="padding-right: 12px;">
              <div class="font-333-20-bold">通用地址管理</div>
              <div class="tag pointer align-center" @click="fnOpen(1)">
                新增地址
              </div>
            </div>
            <div class="flex-wrap font-333-16-bold" style="line-height: 56px;padding:0 22px;border-bottom:1px solid #EEEEEE;">
              <div style="min-width: 98px;">收件人</div>
              <div style="width: 150px">联系电话</div>
              <div class="flex-con">收获地址</div>
              <div style="width: 80px; text-align: center">操作</div>
            </div>
          </div>
          <div class="my_order" style="margin: 0">
            <div
              class="flex-wrap font-333-16"
              style="margin-bottom: 24px"
              v-for="(item, index) in data"
              :key="'address_' + index"
            >
              <div class="align-start font-333-16" style="min-width: 98px;">
                {{ item.address_name || '' }}
              </div>
              <div class="align-start font-666-16" style="width: 150px">
                {{ item.address_phone || '' }}
              </div>
              <div class="flex-con align-start">
                {{ item.sheng + item.shi + item.qu + item.address_detail }}
                <div class="tag align-center" v-if="item.address_default == 2">
                  默认地址
                </div>
                <div class="tag align-center" v-if="item.address_default2 == 2">
                  默认收票
                </div>
              </div>
              <div style="width: 80px" class="align-between font-999-16">
                <div @click.stop="fnOpen(2, index)" class="pointer" style="color: #6eaaf9;">修改</div>
                <div
                  @click.stop="fnDelete(item.address_id)"
                  class="pointer"
                  v-if="!from"
                >
                  删除
                </div>
                <div @click.stop="select(item)" class="pointer cor_FA9C22"  v-else>
                  选择
                </div>
              </div>
            </div>
          </div>
          <div
          style="text-align: center; margin: 160px auto"
          v-show="data.length == 0"
        >
          <el-image
            style="width: 160px; height: 160px; margin-bottom: 16px"
            :src="require('@/assets/quesheng/no_content.png')"
            fit="cover"
          ></el-image>
          <div class="font-666-14">暂无内容~</div>
        </div>
        </div>
       
        <el-dialog :visible.sync="dialogVisible" width="980px" custom-class="address_dialog">
          <div class="dialog">
            <div class="dialog_title align-start">新增/修改地址</div>

            <div class="align-start font-333-16-bold" style="margin-bottom: 36px;margin-top: 36px;">
              <div class="align-start">
                <div class="width115"><span class="cor_FA9C22">*</span>姓名:</div>
                <input
                  type="text"
                  name=""
                  value=""
                  v-model="obj.address_name"
                  placeholder="长度不超过20个字符"
                  maxlength="20"
                />
              </div>
              <div class="align-start">
                <div class="width115" style="width:127px;">
                  <span class="cor_FA9C22">*</span>联系电话:
                </div>
                <input
                  type="text"
                  name=""
                  value=""
                  v-model="obj.address_phone"
                  placeholder="请输入"
                />
              </div>
            </div>
            <div class="align-start" style="margin-bottom: 36px">
              <div class="width115"><span class="cor_FA9C22">*</span>地址:</div>
              <el-select
                v-model="obj.address_sheng"
                placeholder="请选择省份"
                size="mini"
                style="width: 240px"
                @change="
                  (val) => {
                    handleChange(val, 1)
                  }
                "
              >
                <el-option
                  v-for="item in sheng"
                  :key="item.district_id"
                  :label="item.district"
                  :value="item.district_id"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="obj.address_shi"
                placeholder="请选择城市"
                size="mini"
                style="width: 240px; margin-left: 24px"
                @change="
                  (val) => {
                    handleChange(val, 2)
                  }
                "
              >
                <el-option
                  v-for="item in shi"
                  :key="item.district_id"
                  :label="item.district"
                  :value="item.district_id"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="obj.address_qu"
                placeholder="区/县/街道"
                size="mini"
                style="width: 240px; margin-left: 24px"
                @change="
                  (val) => {
                    handleChange(val, 3)
                  }
                "
              >
                <el-option
                  v-for="item in qu"
                  :key="item.district_id"
                  :label="item.district"
                  :value="item.district_id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="align-start align-top" style="margin-bottom: 36px">
              <div class="width115">
                <span style="color: rgba(0, 0, 0, 0)">*</span>详细地址:
              </div>
              <textarea
                rows=""
                cols=""
                class="address_xq"
                v-model="obj.address_detail"
              ></textarea>
            </div>
            <div class="align-start font-333-16-bold" style="margin-bottom: 36px">
              <div class="align-start">
                <div class="width115">
                  <span style="color: rgba(0, 0, 0, 0)">*</span>邮政编码:
                </div>
                <input
                  type="text"
                  name=""
                  value=""
                  placeholder=""
                  v-model="obj.address_code"
                />
              </div>
              <div class="align-start">
                <div class="width115" style="width:127px;">
                  <span style="color: rgba(0, 0, 0, 0)">*</span>邮箱地址:
                </div>
                <input
                  type="text"
                  name=""
                  value=""
                  placeholder=""
                  v-model="obj.address_email"
                />
              </div>
            </div>
            <div class="align-start" style="margin-bottom: 36px">
              <div class="width115">
                <span style="color: rgba(0, 0, 0, 0)">*</span>是否为默认:
              </div>
              <div class="align-start">
                <input
                  type="checkbox"
                  name=""
                  value=""
                  class="checkbox"
                  id="address_default"
                  v-model="obj.radio1"
                />
                <div class="font-333-16">默认收货地址</div>
              </div>
              <div class="align-start" style="margin-left: 16px">
                <input
                  type="checkbox"
                  name=""
                  value=""
                  class="checkbox"
                  id="address_default2"
                  v-model="obj.radio2"
                />
                <div class="font-333-16">默认收票地址</div>
              </div>
            </div>
            <div class="align-center" style="margin-top: 45px">
              <div
                class="dialog_btn2 align-center pointer"
                @click="dialogVisible = false"
              >
                取消
              </div>
              <div
                class="dialog_btn1 align-center pointer"
                @click="addAddress"
              >
                保存
              </div>
            </div>
          </div>
        </el-dialog>
        <el-dialog
          :visible.sync="dialogVisible111"
          width="980px"
          custom-class="confirm_dialog"
        >
          <div class="dialog" style="padding: 0">
            <div class="dialog_title align-start"> <img :src="require('@/assets/tip_delete.png')" alt="" style="width:26px;height:26px;margin-right:18px;">删除地址</div>

            <div class="font-333-18-bold" style="text-align: center;margin-top: 36px;">
              确认删除吗？
            </div>
            <div class="align-center" style="margin-top: 36px">
              <div
                class="dialog_btn2 align-center pointer"
                @click="dialogVisible111 = false"
              >
                取消
              </div>
              <div class="dialog_btn1 align-center pointer" @click="fnCun">确认</div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import right from '@/components/right.vue'
import {
  getArea,
  myAddress,
  myAddressAdd,
  myAddressEdit,
  getOneAddress,
  delAddress,
} from '../api'
export default {
  components: {
    Header,
    Footer,
    MyInfo,
    right,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible111: false,
      type: 1, //1新增 2编辑
      data: [], //地址列表
      sheng: [],
      shi: [],
      qu: [],
      obj: {
        address_name: '',
        address_phone: '',
        address_detail: '',
        address_code: '',
        address_email: '',
        address_default: 1,
        address_default2: 1,
        address_sheng: '',
        address_shi: '',
        address_qu: '',
        sheng: '',
        shi: '',
        qu: '',
        radio1: false,
        radio2: false,
      },
      iddDelete: '',
      from: this.$route.params.from,
    }
  },
  methods: {
    initData() {
      let params = {
        page: 1,
      }
      myAddress(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
        }
      })
    },
    addAddress() {
      if (!this.obj.address_name) {
        this.$message.error('请输入姓名')
        return
      }
      if (!this.obj.address_phone) {
        this.$message.error('请输入电话')
        return
      }
      let regBox = {
        regMobile: /^1[3456789]\d{9}$/, //手机
      }
      let rPhone = regBox.regMobile.test(this.obj.address_phone)
      if (!rPhone) {
        this.$message.error('请输入正确的电话')
        return
      }
      if (
        !this.obj.address_sheng ||
        !this.obj.address_shi ||
        !this.obj.address_qu
      ) {
        this.$message.error('请选择地址')
        return
      }
      this.obj.address_default = this.obj.radio1 ? 2 : 1
      this.obj.address_default2 = this.obj.radio2 ? 2 : 1
      let params = {
        sheng: this.obj.address_sheng,
        shi: this.obj.address_shi,
        qu: this.obj.address_qu,
        address_name: this.obj.address_name,
        address_phone: this.obj.address_phone,
        address_detail: this.obj.address_detail,
        address_code: this.obj.address_code,
        address_email: this.obj.address_email,
        address_default: this.obj.address_default,
        address_default2: this.obj.address_default2,
      }
      if (this.type == 1) {
        myAddressAdd(params).then((data) => {
          if (data.status == 200) {
            this.dialogVisible = false
            this.initData()
          }
        })
      } else {
        params.address_id = this.obj.address_id
        myAddressEdit(params).then((data) => {
          if (data.status == 200) {
            this.dialogVisible = false
            this.initData()
          }
        })
      }
    },
    fnOpen(type, index) {
      this.initData()
      this.type = type
      this.dialogVisible = true
      if (type == 2) {
        this.obj = this.data[index]
        this.obj.radio1 = this.obj.address_default == 2 ? true : false
        this.obj.radio2 = this.obj.address_default2 == 2 ? true : false
        let obj1 = this.sheng.find(
          (item) => item.district_id == this.obj.address_sheng
        )
        this.shi = obj1.items
        let obj2 = this.shi.find(
          (item) => item.district_id == this.obj.address_shi
        )
        this.qu = obj2.items
      } else {
        for (let key in this.obj) {
          this.obj[key] = ''
        }
        this.obj.radio1 = false
        this.obj.radio2 = false
        this.shi = []
        this.qu = []
      }
    },
    fnCun() {
      let params = {
        address_id: this.iddDelete,
      }
      delAddress(params).then((data) => {
        if (data.status == 200) {
          this.dialogVisible111 = false
          this.initData()
        }
      })
    },
    fnDelete(idd) {
      this.dialogVisible111 = true
      this.iddDelete = idd
      return
    },
    initSsq() {
      let params = {}
      getArea(params).then((data) => {
        if (data.status == 200) {
          this.sheng = data.data
        }
      })
    },
    handleChange(val, type) {
      let obj = {}
      if (type == 1) {
        obj = this.sheng.find((item) => item.district_id == val)
        this.shi = obj.items
        this.obj.address_shi = ''
        this.obj.address_qu = ''
        this.qu = []
      } else if (type == 2) {
        obj = this.shi.find((item) => item.district_id == val)
        this.qu = obj.items
        this.obj.address_qu = ''
      }
    },
    select(item) {
      if (this.$route.params.from) {
        this.$bus.$emit(
          'addressObj',
          item,
          this.$route.params.from,
          this.$route.params.fapiao_type
        )
        this.$router.go(-1)
      }
    },
  },
  mounted() {
    document.title = '本牛'
    this.initData()
    this.initSsq()
  },
}
</script>

<style scoped>
::v-deep .address_dialog.el-dialog {
  border-radius: 4px;
}
::v-deep .address_dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 42px;
}
::v-deep .address_dialog .el-dialog__header {
  padding: 0;
}
::v-deep .address_dialog .el-dialog__headerbtn {
  position: absolute;
  top: 22px;
  right: 40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 24px;
}
::v-deep .address_dialog .el-dialog__headerbtn .el-dialog__close {
  color: #979797;
}
::v-deep .confirm_dialog.el-dialog {
  border-radius: 4px;
}
::v-deep .confirm_dialog .el-dialog__body {
  padding: 0;
  padding-bottom: 42px;
}
::v-deep .confirm_dialog .el-dialog__header {
  padding: 0;
}
::v-deep .confirm_dialog .el-dialog__headerbtn {
  position: absolute;
  top: 22px;
  right: 40px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 24px;
}
::v-deep .confirm_dialog .el-dialog__headerbtn .el-dialog__close {
  color: #979797;
}
.my_order {
 padding: 22px 22px 0;
}
.btn_padding {
  width: 103px;
height: 27px;
border: 1px solid #D8D8D8;
}
.tag {
  width: 103px;
height: 27px;
border: 1px solid #FA9C22;
  margin-left: 12px;
color: #FA9C22;
font-size: 16px;
}
.btn {
  width: 115px;
  height: 32px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  margin-left: 16px;
}
.btn1 {
  color: #777777;
  background: none;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
}
/* .dialog {
  padding: 0 107px 20px 90px;
} */
.width115 {
  width: 143px;
  box-sizing: border-box;
  padding-right: 16px;
  text-align: right;
  font-size: 16px;
  color: #333;
}
input[type='text'],
input[type='number'],
input[type='tel'],
input[type='email'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 36px;
  padding: 0 12px;
  color: #333333;
  font-size: 16px;
  outline: none;
  font-weight: normal;
  width:320px;
}

input[type='text']::-webkit-input-placeholder,
input[type='number']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder,
input[type='email']::-webkit-input-placeholder,
.address_xq::-webkit-input-placeholder {
  color: #999;
}
input[type='text']:active,
input[type='number']:active,
input[type='tel']:active,
input[type='email']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
}
textarea:focus {
  border: 1px solid #d8d8d8;
  outline: none;
}



::v-deep .el-select .el-input__inner:focus {
  border-color: #d8d8d8;
}
::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #d8d8d8;
  border: 1px solid #d8d8d8;
}
::v-deep .el-input--mini .el-input__inner {
  border-radius: 0px;
  height: 36px;
  width:240px;
  font-size: 16px;
  border: 1px solid #d8d8d8;
}
.el-select-dropdown__item{
  font-size: 16px;
}
.el-select-dropdown__item.selected {
  color: #333;
}


.address_xq,
textarea {
  width: 767px;
  height: 168px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  padding: 12px;
  color: #333333;
  font-size: 16px;
  font-family: "微软雅黑";
}

/* input[type='checkbox'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #d8d8d8;
  margin: 0 15px 0 0;
} */
input[type='checkbox'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: url('@/assets/check2.png') no-repeat center center;
  background-size: 100% 100%;
}
input[type='checkbox']:checked {
  background: url('@/assets/checked21.png') no-repeat center center;
  background-size: 100% 100%;
}
/* input[type='checkbox']:checked {
  border: 1px solid #333;
  position: relative;
}
input[type='checkbox']:checked:after {
  position: absolute;
  width: 13px;
  height: 8px;
  top: 50%;
  left: 50%;
  margin-left: -7px;
  margin-top: -7px;
  content: '';
  color: #000;
  display: inline-block;
  visibility: visible;
  border: 2px solid #333;
  border-top: none;
  border-right: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
} */
</style>

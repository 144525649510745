<template>
  <div>
    <Header :num111="5" />
    <div class="middle1">
      <div
        v-for="(item, index) in cate"
        :key="'cate_' + index"
        v-if="item.ic_id != 2"
      >
        <div class="font-333-30" style="margin: 70px 0 47px">
          {{ item.ic_name || '' }}
        </div>

        <div class="flex-wrap">
          <div
            class="bg_fff pointer zixun1"
            style="
              height: 478px;
              width: 616px;
              box-sizing: border-box;
              margin-right: 19px;
            "
            v-if="index == 0"
            v-for="(item, index) in item.info"
            :key="'infoList_' + item.info_id + '_' + index"
            @click="toZx(item.info_id)"
            @mouseenter="mouseenterOutZx1(item.ic_id)"
            @mouseleave="mouseleaveOutZx1()"
          >
            <div class="img2_out">
              <el-image
                :src="item.info_thumb"
                fit="cover"
                style="width: 616px; height: 318px; display: block"
                class="img2"
              ></el-image>
            </div>
            <div style="padding: 17px 72px 0">
              <div class="aui-ellipsis-2 left_title2">
                {{ item.info_title || '' }}
              </div>
              <div class="aui-ellipsis-2 left_info2">
                {{ item.info_intro || '' }}
              </div>

              <el-image
                :src="
                  showHoverZx1 && ic_id == item.ic_id
                    ? require('@/assets/arrow_right2.png')
                    : require('@/assets/arrow_right1.png')
                "
                fit="cover"
                class="left_jt1"
              ></el-image>
            </div>
          </div>

          <div class="flex-con">
            <div
              style="
              height: 146px;
                padding: 0 0 0 40px;
                position: relative;
                box-sizing: border-box;
              "
              :style="{ 'margin-bottom': index == 3 ? '0' : '19px' }"
              class="bg_fff three_right pointer flex-wrap"
              v-if="index > 0 && index < 4"
              v-for="(item, index) in item.info"
              :key="'infoList_' + item.info_id + '_' + index"
              @click="toZx(item.info_id)"
              @mouseenter="mouseenterOutZx2(index,item.ic_id)"
            @mouseleave="mouseleaveOutZx2()"
            >
            <div class="flex-con" style="padding-top:40px;">
              <div class="aui-ellipsis-1 left_title2" style="height:18px;line-height:18px;margin-bottom:15px;">
                {{ item.info_title || '' }}
              </div>
              <div
                class="aui-ellipsis-2 left_info2"
                style="height: 48px;"
              >
                {{ item.info_intro || '' }}
              </div>
            </div>
            <div class="img3_out align-start">
              <el-image
                :src="
                  showHoverZx2 && ic_id == item.ic_id && index == indexZx2
                    ? require('@/assets/arrow_right2.png')
                    : require('@/assets/arrow_right1.png')
                "
                fit="cover"
                class="img3 left_jt1"
              ></el-image>
            </div>
              
              <!-- <div class="align-end">
                <div class="cor_f08300">查看详情</div>
                <el-image
                  :src="require('@/assets/right_arrow4.png')"
                  fit="cover"
                  style="width: 18px; height: 18px; margin-left: 6px"
                ></el-image>
              </div> -->
            </div>
          </div>
        </div>
        <div class="align-end">
            <div class="zx_more pointer" @click="fnMore">
              了解更多
              <div></div>
            </div>
          </div>
      </div>

      <div v-for="(item, index) in cate" :key="'info2_' + index">
        <div v-if="item.ic_id == 2">
          <div class="font-333-30" style="margin: 70px 0 47px">行业资讯</div>
          <div class="align-between">
            <div
              class="pointer hy_item"
              v-if="index < 3"
              v-for="(item, index) in item.info"
              :key="'info3_' + index"
              @click="toZx(item.info_id)"
              @mouseenter="mouseenterOutZx(index)"
              @mouseleave="mouseleaveOutZx()"
            >
              <div class="" style="overflow: hidden">
                <el-image
                  :src="item.info_thumb"
                  fit="cover"
                  class="left_img1"
                ></el-image>
              </div>

              <div style="padding: 0 26px">
                <div
                  class="font-333-26-bold info1_time"
                  style="text-align: right; margin-top: 12px"
                >
                  {{ timeMD(item.info_time) }}
                </div>
                <div class="align-start">
                  <div class="left_title1 aui-ellipsis-2">
                    {{ item.info_title || '' }}
                  </div>
                </div>
                <div class="left_info1 aui-ellipsis-2">
                  {{ item.info_intro || '' }}
                </div>
                <el-image
                  :src="
                    showHoverZx && indexZx == index
                      ? require('@/assets/arrow_right2.png')
                      : require('@/assets/arrow_right1.png')
                  "
                  fit="cover"
                  class="left_jt1"
                ></el-image>
              </div>
            </div>
          </div>
          <div class="align-end">
            <div class="zx_more pointer" @click="fnMore">
              了解更多
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div class="listTitle1 flex-wrap" style="margin: 88px 0 37px">
        <div>本牛活动</div>
      </div>
      <div class="align-between">
        <div
          class="bg_fff pointer active1"
          v-if="index < 3"
          v-for="(item, index) in data1"
          :key="'data1_' + index"
          @click="toZx3(item.activity_id)"
        >
          <div class="img_out1">
            <el-image
              class="img1"
              fit="cover"
              :src="item.activity_thumb"
              style="
                min-width: 420px;
                width: 420px;
                height: 205px;
                display: block;
              "
            ></el-image>
          </div>

          <div style="padding: 35px 25px">
            <div
              class="aui-ellipsis-1 font-333-18-bold"
              style="margin-bottom: 9px"
            >
              {{ item.activity_title || '' }}
            </div>
            <div class="align-between">
              <div class="font-666-18">{{ item.activity_time || '' }}</div>
              <div class="btn1 font-fff-18 align-center">立即参与</div>
            </div>
          </div>
        </div>
      </div>
      <div class="align-end">
        <div class="zx_more pointer" @click="fnMore('active')">
          了解更多
          <div></div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { activityList, informationList, oneInfoList, tdkPages } from '../api'
export default {
  components: {
    Header,
    Footer,
    right,
  },
  data() {
    return {
      data1: [],
      cate: [],
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
      showHoverZx: false,
      showHoverZx1: false,
      showHoverZx2:false,
      indexZx2: '',
      indexZx: '',
      ic_id: '',
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    fnMoreZx() {
      this.$router.push({
        name: 'news',
        params: {
          id: '.html',
          // index: index,
        },
      })
    },
    mouseenterOutZx(index) {
      // console.log(index);
      this.showHoverZx = true
      this.indexZx = index
    },
    mouseleaveOutZx() {
      this.showHoverZx = false
    },
    mouseenterOutZx1(ic_id) {
      // console.log(index);
      this.showHoverZx1 = true
      this.ic_id = ic_id
    },
    mouseleaveOutZx1() {
      this.showHoverZx1 = false
    },
    mouseenterOutZx2(index,ic_id) {
      // console.log(index);
      this.showHoverZx2 = true
      this.indexZx2 = index
      this.ic_id = ic_id
    },
    mouseleaveOutZx2() {
      this.showHoverZx2 = false
    },
    fnGetTdk(type) {
      let params = {
        type: type,
      }
      tdkPages(params).then((data) => {
        if (data.status == 200) {
          this.tdkTitle = data.msg.title || '本牛'
          this.tdkKeywords = data.msg.keyword || '本牛'
          this.tdkDescription = data.msg.desc || '本牛'
        }
      })
    },
    initCate() {
      let params = {}
      informationList(params).then((data) => {
        if (data.status == 200) {
          this.cate = data.msg
        }
      })
    },
    initActive() {
      let params = {}
      activityList(params).then((data) => {
        if (data.status == 200) {
          this.data1 = data.msg
        }
      })
    },
    fnMore(index) {
      this.$router.push({
        name: index == 'active' ? 'activity' : 'news',
        params: {
          id: '.html',
          // index: index,
        },
      })
    },
    fnActive(idd) {
      this.$router.push({
        name: 'zixun3',
        params: {
          id: idd + '.html',
        },
      })
    },
  },
  mounted() {
    this.initActive()
    this.initCate()
    this.fnGetTdk(6)
  },
}
</script>
<style scoped>
.info1_time {
  transition: all 0.5s;
}
.left_img1 {
  width: 100%;
  height: 204px;
  display: block;
  transition: all 1s;
}
.hy_item:hover .info1_time {
  color: #e28a4d;
}
.hy_item:hover .left_jt1 {
  position: relative;
  left: calc(100% - 32px);
}

.left_jt1 {
  width: 32px;
  height: 16px;
  position: relative;
  left: 0;
  transition: all 0.5s;
}
.zixun1:hover .left_jt1 {
  position: relative;
  left: calc(100% - 32px);
}
.hy_item {
  width: 420px;
  height: 405px;
  background-color: #fff;
  transition: all 0.5s;
}
.hy_item:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.hy_item:hover .left_img1 {
  scale: 1.2;
}

.zx_more {
  /* font-size: 16px;
  color: #999;

  height: 17px; */
  text-align: right;
  margin: 20px 0 0;
  position: relative;
  font-size: 23px;
  font-weight: 400;
  font-style: italic;
  /* color: #DE7E2F; */
  color: #999;
  line-height: 24px;
}
.zx_more:hover {
  /* font-size: 16px; */
  color: #de7e2f;
}
.zx_more:hover div {
  width: 100%;
  position: absolute;
  bottom: -13px;
}
.zx_more div {
  width: 0px;
  height: 6px;
  position: absolute;
  background-color: #de7e2f;
  transition: all 0.3s;
  bottom: -13px;

  border-radius: 3px;
}
.img_out1 {
  overflow: hidden;
}
.active1:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.img_out1 .img1 {
  transition: all 0.5s;
}
.active1:hover .img_out1 .img1 {
  scale: 1.2;
}
.active1 {
  transition: all 0.5s;
}
.three_right {
  transition: all 0.3s;
}
.three_right:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.img3_out{
  width:160px;
  padding-left:50px;
  box-sizing: border-box;
}
.img3_out .img3 {
  transition: all 0.3s;
  position: relative;
  left: 0;
}
.three_right:hover .img3_out .img3 {
  left: 45px;
}
.img3_out {
  overflow: hidden;
}
.img2_out {
  overflow: hidden;
}
.img2_out .img2 {
  transition: all 0.5s;
}
.zixun1:hover .img2_out .img2 {
  scale: 1.2;
}
.zixun1 {
  transition: all 0.5s;
}
.zixun1:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.btn1 {
  width: 113px;
  height: 37px;
  background: #ee881e;
  border-radius: 5px;
}
.listTitle1 {
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  text-align: left;
  align-items: center;
  line-height: 30px;
}
.xian {
  width: 4px;
  height: 30px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  margin-right: 10px;
}
.left {
  width: 623px;
  height: 208px;
  padding: 17px 12px 0;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff;
  margin-right: 12px;
}
.left_top1 {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 16px;
}
.dian {
  width: 12px;
  height: 12px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  position: absolute;
  left: 26px;
  top: 28px;
  border-radius: 50%;
}

.left_title1 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 48px;
  line-height: 24px;
  margin: 12px 0 7px;
}
.left_title2 {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 48px;
  line-height: 24px;
  margin: 0 0 7px;
}
.left_info1 {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 24px;
  margin-bottom: 18px;
}

.left_info2 {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  height: 48px;
  line-height: 24px;
  margin-bottom: 10px;
}
.left_img2 {
  width: 133px;
  height: 72px;
  border-radius: 4px;
}
.right {
  padding: 12px 24px 2px 12px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  background-color: #fff;
  border-radius: 4px;
}
.rightOne {
  align-items: center;
  margin-bottom: 10px;
}
.dian1 {
  width: 12px;
  height: 12px;
  background: linear-gradient(90deg, #e5e5e5 0%, #cacaca 100%);
  border-radius: 50%;
}
.xian4 {
  width: 2px;
  height: 22px;
  border-left: 1px solid #aeaeae;
  margin: 0 10px;
}
.arrow {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  align-items: center;
}
.arrowImg {
  width: 18px;
  height: 18px;
  margin-left: 6px;
}
.toppp {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  margin-top: 20px;
  align-items: center;
}
.topppImg {
  width: 20px;
  height: 20px;
  margin: 0 4px;
}
.bg_fff {
  background-color: #fff;
}
</style>

<template>
  <div>
    <Header />
    <div class="middle">
      <div class="align-start" style="margin: 18px 0">
        <div class="font-777 pointer" @click="toMain()">首页</div>
        <el-image
          style="width: 20px; height: 20px; margin: 0 2px"
          :src="require('@/assets/right_arrow2.png')"
          fit="cover"
        ></el-image>
        <div class="font-777" @click="fnShua()">{{ showText }}</div>
        <div v-show="keyword" class="align-center">
          <el-image
            style="width: 20px; height: 20px; margin: 0 2px"
            :src="require('@/assets/right_arrow2.png')"
            fit="cover"
          ></el-image>
          <div class="font-777">
            搜索“<span class="cor_f08300">{{ keyword }}</span
            >”
          </div>
        </div>
      </div>
      <el-image
        style="width: 100%; height: 224px; margin: 0 0 20px"
        :src="allBg"
        fit="cover"
      ></el-image>
      <div class="s_tab align-between">
        <div class="tab_out align-start">
          <div class="tab_item align-center active">默认</div>
          <div class="tab_item align-center pointer" @click="fnPrice">
            价格
            <el-image
              style="width: 20px; height: 20px; margin-left: 4px"
              :src="require('@/assets/shai_icon' + price_sort + '.png')"
              fit="cover"
            ></el-image>
          </div>
          <input
            type="number"
            v-model="lower_price"
            placeholder="最低价"
            style="margin-left: 16px"
          />
          <div class="yi"></div>
          <input type="number" v-model="high_price" placeholder="最高价" />
          <div
            class="font-777-12 align-center sure pointer"
            @click="fnPriceTwo"
          >
            确定
          </div>
        </div>
        <!-- <div>
        <span class="DCA01E">{{ total || 0 }}</span
        >种
      </div> -->
      </div>
      <div>
        <div v-if="data.length > 0">
          <div
            class="two pointer"
            v-for="(item, index) in data"
            :key="'goods_' + index"
            @click="fnGoodsDetails(item.goods_id)"
          >
            <img :src="item.goods_thumb" class="twoImg" />
            <div class="twoTitle aui-ellipsis-2">{{ item.goods_name }}</div>
            <div class="li-label-out">
              <div
                class="li-label"
                v-for="(item, index) in item.goods_tag"
                :key="'goods_tag_' + index"
              >
                {{ item }}
              </div>
            </div>
            <div class="price2">
              <span class="price3">￥</span
              >{{
                item.goods_floor_price > 0 ? item.goods_floor_price : '面议'
              }}
            </div>
            <!-- <div class="price2 price1" v-else><span class="price3">￥</span>面议</div> -->
            <div class="btn bg_f08300">立即抢购</div>
          </div>
        </div>
        <div v-else>
          <div style="text-align: center; margin: 225px auto">
            <el-image
              style="width: 160px; height: 160px; margin-bottom: 16px"
              :src="require('@/assets/quesheng/no_content.png')"
              fit="cover"
            ></el-image>
            <div class="font-666-14">商品完善中~</div>
          </div>
        </div>
        <div class="align-center">
          <Page
            :limit="limit"
            :totalNum="total"
            @onLimit="changeLimit"
            @onPage="changePage"
          />
        </div>
      </div>
    </div>
    <Footer />
    <!-- <right /> -->
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import right from '@/components/right.vue'
import {
  limit,
  goodsList,
  loginAgreement,
  secondAndThirdCate,
  selectGoodsCate,
  getGoodsCateInfo,
  goodsCate,
} from '../api'
export default {
  components: {
    Page,
    Header,
    Footer,
    right,
  },
  data() {
    return {
      allBg: '',
      data: [],
      page: 1,
      limit: limit,
      total: 0,
      keyword: '',
      price_sort: 0,
      lower_price: '',
      high_price: '',
      goods_clear: 0,
      goods_hot: 0,
      showText: '为你推荐',
      arrow1: true,
      arrow2: true,
    }
  },
  methods: {
    initData() {
      let params = {
        page: this.page,
        price_sort: this.price_sort,
        lower_price: this.lower_price,
        high_price: this.high_price,
        goods_clear: this.goods_clear,
        goods_hot: this.goods_hot,
        // first_cate_id: '',
        // second_cate_id: '',
        // third_cate_id: '',
        keyword: this.keyword,
      }
      goodsList(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          this.total = data.count
        }
      })
    },
    fnPrice() {
      if (this.price_sort < 2) {
        this.price_sort++
      } else {
        this.price_sort = 0
      }
      this.initData()
    },
    fnPriceTwo() {
      if (this.lower_price && this.high_price) {
        if (Number(this.high_price) < Number(this.lower_price)) {
          this.$message.error('请输入正确价格')
        } else {
          this.initData()
        }
      } else {
        this.initData()
      }
    },
    fnLoginAgreement(type) {
      let params = {
        type: type,
      }
      loginAgreement(params).then((data) => {
        if (data.status == 200) {
          this.allBg = data.msg.content1 || ''
        }
      })
    },
    changeLimit(childrenData) {
      this.limit = childrenData
      this.initData()
    },
    changePage(childrenData) {
      this.page = childrenData
      this.initData()
    },
    fnShua() {
      this.keyword = ''
      this.page = 1
      this.initData()
      this.$bus.$emit('keywordGet')
    },
  },
  mounted() {
    document.title = '本牛'
    this.$bus.$on('keyword', (val) => {
      this.keyword = val || ''
      this.page = 1
      this.initData()
    })
    this.fnLoginAgreement(8)
    this.initData()
  },
}
</script>

<style scoped>
.btn_padding {
  padding: 1px 11px;
  border: 1px solid #d8d8d8;
}
.yi {
  width: 16px;
  height: 1px;
  background: #d8d8d8;
  margin: 0 4px;
}
.sure {
  height: 24px;
  width: 48px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  margin-left: 16px;
}

.s_cate_title {
  width: 104px;
  height: 40px;
  background: #ebebeb;
  border-radius: 4px 0px 0px 4px;
  color: #777777;
}
.s_cate {
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 10px;
  overflow: hidden;
}
.cate_out {
  flex-flow: row wrap;
}
.cate_out div:not(:first-child) {
  line-height: 40px;
  margin-left: 16px;
}
.more {
  box-sizing: border-box;
  min-width: 80px;
  height: 24px;
  margin: 8px 16px 0 0;
}
.s_tab {
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 10px;
  padding-right: 16px;
}
.tab_item {
  width: 80px;
  height: 40px;
}
.tab_item.active {
  background: #f08300;
  border-radius: 4px 0px 0px 4px;
  color: #ffffff;
}

input[type='number'] {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  height: 24px;
  min-width: 70px;
  padding: 0 12px;
  color: #333333;
  font-size: 12px;
  outline: none;
}
input[type='number']::-webkit-input-placeholder {
  color: #aaa;
}
input[type='number']:active {
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-right: none;
}

.two {
  position: relative;
  display: inline-block;
  width: calc((100% - 40px) / 5);
  height: 274px;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin-right: 10px;
  padding: 6px 8px;
  margin-bottom: 10px;
  font-size: 0;
  vertical-align: top;
  box-sizing: border-box;
  transform: scale(1);
  transition: transform 0.5s, box-shadow 0.5s;
}
.two:hover {
  border: 0;
  transform: scale(1.02);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.twoImg {
  display: block;
  width: 126px;
  height: 126px;
  margin: 0 auto 1px;
}
.two:nth-child(5n + 5) {
  margin-right: 0;
}
.twoTitle {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  height: 40px;
}
.price2 {
  font-size: 18px;
  color: #c52b33;
  margin-top: 4px;
  line-height: 25px;
  height: 27px;
}
.price3 {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.banner {
  height: 83px;
  width: 1080px;
  margin-top: 10px;
}
.label {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #dea422;
  border-radius: 2px;
  border: 1px solid;
  border-image: linear-gradient(
      90deg,
      rgba(239, 200, 69, 1),
      rgba(220, 160, 30, 1)
    )
    1 1;
  margin-top: 4px;
  padding: 3px 8px;
}

.btn {
  width: 100%;
  height: 30px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 5px;
}
.heightauto {
  height: auto;
}
.fl:hover {
  color: #dca01e;
}
</style>

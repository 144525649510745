<template>
  <div>
    <Header :num111="5" />
    <div class="middle1" style="margin-top:14px;">
      <!-- <div class="toppp flex-wrap">
        <div class="pointer" @click="toMain()">首页</div>
        <img src="../assets/arrow1.png" class="topppImg" />
        <div>内容资讯</div>
        
      </div> -->
      <!-- <img src="../assets/arrow1.png" class="topppImg" />
      <div>行业资讯</div>
      <img src="../assets/arrow1.png" class="topppImg" />
      <div>{{ info_title }}</div> -->
      <div class="contain">
        <div class="notice_title font-333-24">{{ data.info_title || '' }}</div>
        <div class="notice_time">
          发布时间：{{ data.info_time || '' }} &nbsp;&nbsp; 浏览：{{
            data.info_read_number || 0
          }}次 &nbsp;&nbsp;
          <span>作者：{{ data.info_author || '' }}</span> &nbsp;&nbsp;
          <span>来源：{{ data.info_from || '' }}</span>
        </div>
        <div v-html="data.info_content" class="font-666-18" style="line-height: 36px;"></div>
      </div>

      <div class="flex-wrap" style="padding: 50px 0;">
        <div
          class="next_box flex-con pointer"
          style="margin-right: 50px"
          v-if="prev"
          @click="toZx(prev.info_id)"
        >
          <div class="align-start">
            <el-image
              style="
                width: 20px;
                min-width: 20px;
                height: 20px;
                margin-right: 10px;
              "
              :src="require('@/assets/main1_38.png')"
              fit="cover"
            ></el-image>
            <div>上一篇</div>
          </div>
          <div class="font-333-18" style="margin-top: 20px">
            {{ prev.info_title || '' }}
          </div>
        </div>
        <div class="flex-con" v-else></div>

        <div
          class="next_box flex-con pointer"
          v-if="next"
          @click="toZx(next.info_id)"
        >
          <div class="align-end">
            <div>下一篇</div>
            <el-image
              style="
                width: 20px;
                min-width: 20px;
                height: 20px;
                margin-left: 10px;
              "
              :src="require('@/assets/main1_39.png')"
              fit="cover"
            ></el-image>
          </div>
          <div class="font-333-18" style="margin-top: 20px; text-align: right">
            {{ next.info_title || '' }}
          </div>
        </div>
        <div class="flex-con" v-else></div>
      </div>
    </div>
    <!-- <right /> -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import right from '@/components/right.vue'
import { infoDetail } from '../api'
export default {
  components: {
    Header,
    Footer,
    right
  },
  data() {
    return {
      data: {},
      // title: '',
      info_id: '',
      prev: {},
      next: {},
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    initData() {
      let params = {
        info_id: this.info_id,
      }
      infoDetail(params).then((data) => {
        if (data.status == 200) {
          this.tdkTitle = data.msg.info_tdktitle || '本牛'
          this.tdkKeywords = data.msg.info_keyword || '本牛'
          this.tdkDescription = data.msg.info_desc || '本牛'
          this.data = data.msg
          this.prev = data.prev
          this.next = data.next
          //  ? data.msg.info_content : ''
          // this.title = data.msg.info_title || ''
          document.documentElement.scrollTop = 0
        }
      })
    },
    fnNext(type, idd) {
      this.$router.push({
        name: 'zixun2',
        params: {
          // cate_id: idd,
          id: idd + '.html',
        },
      })
      this.info_id = idd
      this.initData()
      document.documentElement.scrollTop = 0
    },
  },
  mounted() {
    let can = this.$route.params.id.split('.')[0]
    this.info_id = can
    this.initData()
  },
}
</script>
<style scoped>
.toppp {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  margin: 20px 0;
  align-items: center;
}

.topppImg {
  width: 20px;
  height: 20px;
  margin: 0 4px;
}
.contain {
  background-color: #fff;
  border-radius: 4px;
  padding: 45px 20px 20px;
  /* overflow-y: scroll;
  max-height: 700px; */
  box-sizing: border-box;
}
.contain::-webkit-scrollbar {
  display: none;
}
.fwb {
  /* font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px; */
}
.notice_title {
  /* font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 28px; */
  text-align: center;
}
.notice_time {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin: 16px auto 20px;
  text-align: center;
}
.next_box {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
}
</style>
